import React from "react";
import "../App.css"
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faInfo, faSignOutAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function DialogueBox(props) {
    const [open, setOpen] = React.useState(false);

    const handleDialogueBox = () => {
        props.handleDialogueBox();
    }

    return (
        <Dialog open={props.openDialogueBox} onClose={handleDialogueBox}>
            <DialogTitle style={{ paddingTop: '35px', paddingBottom: '0px' }}>
                <div className="dialogue-icon-container">
                    <FontAwesomeIcon icon={faEnvelope} className="dialogue-icon" size="1x"
                    />
                </div>
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center', maxWidth: '51vh' }}>
                <DialogContentText className="dialogue-box-header">
                    {props.messageType}
                </DialogContentText>
                <DialogContentText className="dialogue-box-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions className='dialogue-button-container'>
                {/* <Button className="dialogue-button-submit" onClick={handleDialogueBox} >
                    Close   
                </Button> */}
                <button className="dialogue-button-submit" type="button" onClick={handleDialogueBox}>Close</button>
            </DialogActions>
        </Dialog>
    );
}
