import React, { useState, useEffect } from 'react';
import "../App.css"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { grey } from '@mui/material/colors';

const tableHeadColour = grey[600];

//Material UI Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeadColour,
        color: theme.palette.common.white,
        fontWeight: 600,
        cursor: 'pointer'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //   border: 0,
    // },
}));
//Material UI Styles

const initialColumns = [
    { id: 'country', label: 'Country', minWidth: 80, maxWidth: 120, align: 'center'},
    { id: 'posting_period', label: 'Posting Period', minWidth: 50, maxWidth: 80, align: 'center' },
    { id: 'business_type', label: 'Business Type', minWidth: 150, maxWidth: 250, align: 'center'},
    { id: 'cpl_no', label: 'CPL No.', minWidth: 70, maxWidth: 85, align: 'center'},
    {
        id: 'cpl_name',
        label: 'CPL Name',
        minWidth: 150,
        maxWidth: 200,
    },
    {
        id: 'item_category',
        label: 'Item Category',
        minWidth: 150,
        maxWidth: 200,
    },
    {
        id: 'brand',
        label: 'Brand',
        minWidth: 120,
        maxWidth: 150,
        
    },
    {
        id: 'package_size',
        label: 'Package Size',
        minWidth: 100,
        maxWidth: 130,
    },
    {
        id: 'package_type',
        label: 'Package Type',
        minWidth: 100,
        maxWidth: 130,
    },
    {
        id: 'sales_unit',
        label: 'Sales Unit',
        minWidth: 50,
        maxWidth: 70,
    },
    {
        id: 'volume',
        label: 'Volume in UC',
        minWidth: 100,
        maxWidth: 100,
        align: 'right',
        format: (value) => Math.round(parseFloat(value)).toLocaleString()
    },
    {
        id: 'country_gross_revenues',
        label: 'Country Gross Revenues',
        minWidth: 100,
        maxWidth: 130,
        align: 'right',
        format: (value) => Math.round(parseFloat(value)).toLocaleString()
    },
    {
        id: 'discount_on_invoice',
        label: 'Discounts - On Invoice',
        minWidth: 100,
        maxWidth: 130,
        align: 'right',
        format: (value) => Math.round(parseFloat(value)).toLocaleString()
    },
    {
        id: 'discount_off_invoice',
        label: 'Discounts - Off Invoice',
        minWidth: 100,
        maxWidth: 130,
        align: 'right',
        format: (value) => Math.round(parseFloat(value)).toLocaleString()
    },
    {
        id: 'total_deduction',
        label: 'Total Deduction',
        minWidth: 100,
        maxWidth: 120,
        align: 'right',
        format: (value) => Math.round(parseFloat(value)).toLocaleString()
    },
    {
        id: 'net_sales_revenue',
        label: 'Net Sales Revenue',
        minWidth: 100,
        maxWidth: 150,
        align: 'right',
        format: (value) => Math.round(parseFloat(value)).toLocaleString()

    },
    {
        id: 'single_or_multi_serve',
        label: 'Single / Multi Serve',
        minWidth: 120,
        maxWidth: 150,
        align: 'center',
        // format: (value) => value.toFixed(2),
    },
];

const TablePriceForecast = (props) => {
    const [admin, setAdmin] = React.useState(props.admin)
    const [columns, setColumns] = React.useState(initialColumns);



    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <StyledTableRow className="bold-font">
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth, width: column.maxWidth }}
                                    // title="Click and wait to Sort"
                                    // onClick={() => handleSorting(column)}
                                >
                                    {column.label}
                                    {/* Sort Icon Handled */}
                                    {/* {column.is_sortable ?
                                        getSortButton(column.sort_type) :
                                        ""} */}
                                    {/* Sort Icon Handled */}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {props.priceForecastList
                            .map((row) => {
                                return (
                                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            let value = row[column.id];
                                            if (column.format) {
                                                
                                                if (/^-\d+(\.\d+)?$/.test(value)){
                                                    value = <p className="m-0 p-0" style={{color:"#E61C2A"}}>{column.format(value)}</p>
                                                }
                                                else{
                                                    value = column.format(value)
                                                }
                                            }
                                            return (
                                                <StyledTableCell key={column.id} align={column.align} title={value} format={Math.round(parseFloat(value)).toLocaleString()}>
                                                    {value}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            }
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default TablePriceForecast;