import React, { useState, useEffect } from 'react';
import DropDownMultiSelectMFACustom from '../../components/dropDownMultiSelectMFACustom'
import { BASE_URL, INPUT_FORMS, MFA, FILTERS, BRAND_CATEGORY_MAPPINGS, DELETE, INPUT_SUGGESTIONS, EXPORT, MFA_IMPORT_DATA } from '../../constant/apiEndpoints';
import APIService from '../../services/APIService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import TableMFABrandCategory from '../../components/tableMFABrandCategory'
import Spinner from 'react-bootstrap/Spinner';
import { ChipSet, Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';
import MFABrandCategoryFormModal from '../../components/mfaBrandCategoryFormModal';
import BrandCategoryDialogueBox from '../../components/brandCategoryDialogueBox';
import toast, { Toaster } from 'react-hot-toast';
import { brandCategoryInitailColumnsBrandForm } from '../../constant/constants'
import { suggestionData } from '../../constant/utils'
import Papa from 'papaparse';

const initialColumns = brandCategoryInitailColumnsBrandForm

const User3 = (props) => {

    const [brandList, setBrandList] = useState([]);
    const [summarizedBrandList, setSummarizedBrandList] = useState([]);
    const [categorySAPList, setCategorySAPList] = useState([]);
    const [selectedBrandList, setSelectedBrandList] = useState([]);
    const [selectedSummarizedBrandList, setSelectedSummarizedBrandList] = useState([]);
    const [selectedCategorySAPList, setSelectedCategorySAPList] = useState([]);
    const [selectedList, setSelectedList] = useState([]); //Button Handle
    const [columns, setColumns] = useState(initialColumns); //Columns Handle
    const [brandCategoryMappingList, setBrandCategoryMappingList] = useState([]); //Table Data Handle
    const [dropDownTimer, setDropDownTimer] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedChipIds, setSelectedChipIds] = useState({
        all: true,
        brand_category: false,
        trv: false,
        dmi: false
    });
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedIdToEdit, setSelectedIdToEdit] = useState();
    const [openDeleteDialogueBox, setOpenDeleteDialogueBox] = useState(false);
    const [suggestions, setSuggestions] = useState(suggestionData);

    //Transforming Data Into AutoSuggest Component Compatible
    const transformDataToAutoSuggestCompatable = (data) => {
        const transformedData = data.map(({ label, value }) => ({ id: label, title: value }));
        return transformedData
    }

    const setSelectedListInParent = (value) => {
        setSelectedList(value)
    }

    const handleIsFormModalOpen = (value) => {
        setIsFormModalOpen(value);
        setSelectedIdToEdit();
    }

    const handleDeleteDialogueBox = () => {
        setOpenDeleteDialogueBox(!openDeleteDialogueBox)
    }

    const handleDeleteButton = () => {
        setOpenDeleteDialogueBox(true)
    }

    //Support Function For Filter List
    const createStringFromObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return encodeURIComponent(newString)
    }

    const getBrandFilter = (brandIDs) => {
        //Getting User Selected Countries By State/User Action
        let selectedBrandIDs = brandIDs !== null ? brandIDs : selectedBrandList
        let selectedBrandFilter = ""
        if (selectedBrandIDs.length !== 0) {
            selectedBrandFilter = `&selected_brand=${createStringFromObjectList(selectedBrandIDs)}`
        }
        return selectedBrandFilter
    }

    const getSummarizedBrandFilter = (sumIDs) => {
        //Getting User Selected Countries By State/User Action
        let selectedSummarizedBrandIDs = sumIDs !== null ? sumIDs : selectedSummarizedBrandList
        let selectedSummarizedBrandFilter = ""
        if (selectedSummarizedBrandIDs.length !== 0) {
            selectedSummarizedBrandFilter = `&selected_summarized_brand=${createStringFromObjectList(selectedSummarizedBrandIDs)}`
        }
        return selectedSummarizedBrandFilter
    }

    const getCategorySAPFilter = (catIDs) => {
        //Getting User Selected Countries By State/User Action
        let selectedCategorySAPIDs = catIDs !== null ? catIDs : selectedCategorySAPList
        let selectedCategorySAPFilter = ""
        if (selectedCategorySAPIDs.length !== 0) {
            selectedCategorySAPFilter = `&selected_category_sap=${createStringFromObjectList(selectedCategorySAPIDs)}`
        }
        return selectedCategorySAPFilter
    }

    //AJAX Call To Load Brand List
    const loadBrandList = async (sumIDs = selectedSummarizedBrandList, catIDs = selectedCategorySAPList) => {
        //Summarized Brand filter
        let selectedSummarizedBrandFilter = getSummarizedBrandFilter(sumIDs);
        //Category SAP Filter
        let selectedCategorySAPFilter = getCategorySAPFilter(catIDs)

        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + FILTERS + `?selected_form=${props.formId}&filter_type=brand` + selectedSummarizedBrandFilter + selectedCategorySAPFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand Category Mapping List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setBrandList(response.results);
            }
        }
    }

    //AJAX Call To Load Summarized Brand List
    const loadSummarizedBrandList = async (brandIDs = selectedBrandList, catIDs = selectedCategorySAPList) => {
        //Brand filter
        let selectedBrandFilter = getBrandFilter(brandIDs)
        //Category SAP Filter
        let selectedCategorySAPFilter = getCategorySAPFilter(catIDs)

        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + FILTERS + `?selected_form=${props.formId}&filter_type=summarized_brand` + selectedBrandFilter + selectedCategorySAPFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setSummarizedBrandList(response.results);
            }
        }
    }

    //AJAX Call To Load Category SAP List
    const loadCategorySAPList = async (brandIDs = selectedBrandList, sumIDs = selectedSummarizedBrandList) => {
        //Brand filter
        let selectedBrandFilter = getBrandFilter(brandIDs)
        //Summarized Brand filter
        let selectedSummarizedBrandFilter = getSummarizedBrandFilter(sumIDs);

        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + FILTERS + `?selected_form=${props.formId}&filter_type=category_sap` + selectedBrandFilter + selectedSummarizedBrandFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setCategorySAPList(response.results);
            }
        }
    }

    //AJAX Call To Load Input Suggestions List
    const loadSuggestion = async () => {
        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + INPUT_SUGGESTIONS + `?selected_form=${props.formId}&filter_type=category_sap`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results) {
                setSuggestions(response.results);
            }
        }
    }

    //AJAX Call To Load SKU Performance List
    const loadBrandCategoryMappingAPICall = async (selectedBrandFilter = "", selectedSummarizedBrandFilter = "", selectedCategorySAPFilter = "") => {
        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + `?selected_form=${props.formId}` + selectedBrandFilter + selectedSummarizedBrandFilter + selectedCategorySAPFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in ski exlusion list API", response.results.message)
        } else {
            setBrandCategoryMappingList(response.results);
        }
        setLoading(false);
    }

    const loadBrandCategoryMappingList = async (useTimer, brandIDs, sumIDs, catIDs) => {
        //Brand filter
        let selectedBrandFilter = getBrandFilter(brandIDs)
        //Summarized Brand filter
        let selectedSummarizedBrandFilter = getSummarizedBrandFilter(sumIDs);
        //Category SAP Filter
        let selectedCategorySAPFilter = getCategorySAPFilter(catIDs)
        //Clearing Timeout Before Adding New Timeout(Previous Timeout)
        if (dropDownTimer) {
            clearTimeout(dropDownTimer);
        }
        //Add Timeout Before Hitting API
        if (useTimer) {
            setDropDownTimer(
                setTimeout(function () {
                    // loadBrandCategoryMappingAPICall(selectedBrandFilter, selectedSummarizedBrandFilter, selectedCategorySAPFilter);
                    toast.promise(loadBrandCategoryMappingAPICall(selectedBrandFilter, selectedSummarizedBrandFilter, selectedCategorySAPFilter), {
                        loading: "Loading. wait a moment!",
                        success: 'Data load success.',
                        error: 'Something went wrong.',
                    },
                        {
                            position: 'top-center',
                            className: 'toast-popup',
                        });
                }, 1500)
            )
        } else {
            loadBrandCategoryMappingAPICall();
        }
    }

    const loadBrandMappingsBasedOnFilters = (objs, valueType) => {
        //Loading Brand Category Values which is dependent on Brand Dropdown
        if (valueType === "Brand") {
            loadBrandCategoryMappingList(true, objs, selectedSummarizedBrandList, selectedCategorySAPList);
        }
        //Loading Brand Category Values which is dependent on Summarized Brand Dropdown
        if (valueType === "Summarized Brand") {
            loadBrandCategoryMappingList(true, selectedBrandList, objs, selectedCategorySAPList);
        }
        //Loading Brand Category Values which is dependent on Category SAP Dropdown
        if (valueType === "Category") {
            loadBrandCategoryMappingList(true, selectedBrandList, selectedSummarizedBrandList, objs);
        }
    }

    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Brand") {
            setSelectedBrandList(objs)
            //To load relevent values in other dropdown filters
            loadSummarizedBrandList(objs, selectedCategorySAPList);
            loadCategorySAPList(objs, selectedSummarizedBrandList);
        }
        else if (valueType === "Summarized Brand") {
            setSelectedSummarizedBrandList(objs)
            //To load relevent values in other dropdown filters
            loadBrandList(objs, selectedCategorySAPList);
            loadCategorySAPList(selectedBrandList, objs);
        }
        else if (valueType === "Category") {
            setSelectedCategorySAPList(objs)
            //To load relevent values in other dropdown filters
            loadBrandList(selectedSummarizedBrandList, objs);
            loadSummarizedBrandList(selectedBrandList, objs);
        }
        //Loading Brand Mapping List In Table
        loadBrandMappingsBasedOnFilters(objs, valueType);
    }

    const handleColumnFiltering = (object) => {
        let groupName = Object.keys(object).find(key => object[key] === true);
        if (groupName === 'all') {
            setColumns(initialColumns)
        } else {
            const filteredColumns = initialColumns.filter(x =>
                x.group === groupName ||
                x.group === 'other'
            );
            console.log("current columns ", filteredColumns)
            setColumns(filteredColumns)
        }
    }

    const toggleSelectedChip = (key) => {
        let tempData = {
            all: false,
            brand_category: false,
            trv: false,
            dmi: false
        }
        let newData = {
            ...tempData,
            [key]: !selectedChipIds[key]
        }
        setSelectedChipIds(newData);
        handleColumnFiltering(newData);
    }

    const handleEditClickInParent = (id) => {
        setIsFormModalOpen(true)
        setSelectedIdToEdit(id)
    }

    const postSubmitAndEditData = () => {
        loadBrandCategoryMappingList(true, selectedBrandList, selectedSummarizedBrandList, selectedCategorySAPList);
    }

    const handleExportButton = async () => {
        //Brand filter
        let selectedBrandFilter = getBrandFilter(null)
        //Summarized Brand filter
        let selectedSummarizedBrandFilter = getSummarizedBrandFilter(null);
        //Category SAP Filter
        let selectedCategorySAPFilter = getCategorySAPFilter(null)

        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + EXPORT + `?selected_form=${props.formId}` + selectedBrandFilter + selectedSummarizedBrandFilter + selectedCategorySAPFilter
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token')).access}`,
            })
        }).then(response =>
            response.blob().then(blob => {
                var today = new Date()
                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = "brand_category_" + date + "_" + time + ".csv";
                a.click();
            })
        );
    }

    const handleDelete = async (comment) => {
        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + DELETE
        let body = {
            "form_id": props.formId,
            "comment": comment,
            "ids": selectedList.map((ele) => { return ele.category_brand_id })
        }
        let response = await new APIService().delete(url, body)
        if (response.error) {
            if (response.status === 400) {
                if (response.results.message.includes("This field may not be blank.")) {
                    toast.error('Comment is required!', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                } else {
                    toast.error('Something went wrong.', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                }
            }
        } else {
            toast.success('Data deleted successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000 });
            loadBrandCategoryMappingList(true, selectedBrandList, selectedSummarizedBrandList, selectedCategorySAPList);
            handleDeleteDialogueBox();
            setSelectedList([])
        }
    }

    const UploadImportData = async (data) => {
        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + MFA_IMPORT_DATA
        let body = {
            "form_id": props.formId,
            "data": data,
        }
        let response = await new APIService().post(url, body)
        if (response.status == 200){
            loadBrandCategoryMappingList(true, selectedBrandList, selectedSummarizedBrandList, selectedCategorySAPList);
        }
        else if (response.status == 400){
            toast.error(response.results.message, { position: 'top-center', className: 'cutoff-popup', duration: 2000 });
        }
        else{
            toast.error(response.results.message, { position: 'top-center', className: 'cutoff-popup', duration: 2000 });
        }
    }

    const handleOnChange = (e) => {

        Papa.parse(e.target.files[0], {
            header: true,
            complete: (results) => {
                toast.promise(UploadImportData(results.data), {
                    loading: "Loading. wait a moment!",
                    success: 'Data added successfully.',
                    error: 'Something went wrong.',
                },
                    {
                        position: 'top-center',
                        className: 'toast-popup',
                    });
            }
        })
        setTimeout(() => {
            e.target.value = ''
        }, 1500)

    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadBrandList();
            loadSummarizedBrandList();
            loadCategorySAPList();
            loadSuggestion();
            loadBrandCategoryMappingList(false, selectedBrandList, selectedSummarizedBrandList, selectedCategorySAPList);
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div className="user-view-container">
            <div className="brand-category-header-container row">
                <div className="col-10 brand-category-filter-container">
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Category" options={categorySAPList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Summarized Brand" options={summarizedBrandList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Brand" options={brandList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div style={{ margin: "2rem 0rem 0rem 0rem", fontSize: ".75rem" }}>
                        <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} />
                    </div>
                </div>
                {props.adminIds.includes(props.formId) ?
                    (<div className="col-2 brand-category-add-delete-export-button-container">
                        {/* Export Button */}
                        <button className="brand-category-export-button mt-3 col" type="button" value="Export Data" onClick={handleExportButton}>Export</button>
                        {/* Delete Button */}
                        {selectedList.length === 0 ?
                            <button className="brand-category-button mt-3 col" type="button" value="+ Add New" onClick={() => handleIsFormModalOpen(true)}><FontAwesomeIcon icon={faPlus} className="search-icon" size="1x" /> Add New</button>
                            : <button className="brand-category-button mt-3 col" type="button" value="Delete" onClick={handleDeleteButton}><FontAwesomeIcon icon={faTrash} className="search-icon" size="1x" /> Delete</button>}
                    </div>) : ""}
            </div>
            <div className="brand-category-body">
                <div className='table-holder'>
                    <div className='brand-category-chips-container'>
                        <ChipSet choice style={{ paddingTop: "5px" }}>
                            <Chip selected={selectedChipIds.all}
                                onInteraction={() => toggleSelectedChip('all')} label="All" />
                            <Chip selected={selectedChipIds.brand_category}
                                onInteraction={() => toggleSelectedChip('brand_category')} label="Brand Category" />
                            <Chip selected={selectedChipIds.trv}
                                onInteraction={() => toggleSelectedChip('trv')} label="TRV" />
                            <Chip selected={selectedChipIds.dmi}
                                onInteraction={() => toggleSelectedChip('dmi')} label="DMI/Others" />
                        </ChipSet>
                    </div>
                    {/* Loader(While Data Loading) */}
                    {loading ? <div className="table-data-note"><Spinner animation="border" />
                        <p></p>
                    </div> : ""}
                    {/* Loader(While Data Loading) */}
                    {/* Table Component */}
                    {brandCategoryMappingList.length > 0 ?
                        <div className="brand-category-table-container">
                            <TableMFABrandCategory columns={columns}
                                brandCategoryMappingList={brandCategoryMappingList}
                                setSelectedListInParent={setSelectedListInParent}
                                handleEditClickInParent={handleEditClickInParent}
                                formId={props.formId} adminIds={props.adminIds} />
                        </div>
                        : ''}
                    {/* Table Component */}
                    {/* No Data Message */}
                    {brandCategoryMappingList.length === 0 && loading === false ? (<div className="table-data-note"><p>No data found.</p>
                        <p></p>
                    </div>) : ""}
                    {/* No Data Message */}
                </div>
  
            </div>
            {/* Loader */}
            <Toaster />
            {/* Loader */}
            {/* Modal */}
            {isFormModalOpen ?
                <MFABrandCategoryFormModal isFormModalOpen={isFormModalOpen} handleIsFormModalOpen={handleIsFormModalOpen}
                    formId={props.formId} selectedIdToEdit={selectedIdToEdit} brandList={transformDataToAutoSuggestCompatable(brandList)} summarizedBrandList={transformDataToAutoSuggestCompatable(summarizedBrandList)}
                    categorySAPList={transformDataToAutoSuggestCompatable(categorySAPList)} postSubmitAndEditData={postSubmitAndEditData}
                    suggestions={suggestions} />
                : ""}
            {/* Modal */}
            {/* Message Dialogue Box */}
            {openDeleteDialogueBox ? <BrandCategoryDialogueBox openDialogueBox={openDeleteDialogueBox}
                handleDeleteDialogueBox={handleDeleteDialogueBox}
                messageType="Delete"
                message="Are you sure you want to delete this Brand"
                handleDelete={handleDelete} /> : ""}
            {/* Message Dialogue Box */}

        </div>
    );
};

export default User3;