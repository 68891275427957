import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import "../App.css"

const DropDownCustomEretail = (props) => {

  const overrideStrings = {
    "allItemsAreSelected": "All selected",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "--Select--"
  }

  // const [options, setOptions] = useState(props.options);
  const [selected, setSelected] = useState([]);

  const handleOnChange = (objs) => {
    setSelected(objs)
    props.setParentDropdownValue(objs, props.title)
  }

  const handleClearFilters = () => {
    setSelected([])
  };


  return (
    <>
      <label style={{ paddingLeft: "5px", fontSize: "small" }}>{props.title}</label>
      {/* <pre>{JSON.stringify(selected)}</pre> */}
      <MultiSelect
        className="brand-category-multi-select-custom-new"
        options={props.options}
        value={selected}
        onChange={handleOnChange}
        labelledBy="Select"
        disableSearch={true}
        overrideStrings={overrideStrings}
      />
        {/* <button onClick={handleClearFilters}>Clear All Filters</button> */}
    </>
  );
};

export default DropDownCustomEretail;

//test