import { toGetComp } from "../constant/constants";

const initialState = {
    navLink: [],
    adminIds: [],
    formId: '',
    path: ''
}

const SidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_NAV_LINKS":

            const obj = toGetComp(action.formId)
            var arr = []
            if (action.adminIds.includes(action.formId)) {
                arr = [...obj.admin]
            }
            // console.log("checking", [...obj.constants, ...arr].sort((a,b)=> (a.position > b.position ? 1 : -1)))

            return {
                ...state,
                formId: action.formId,
                path: obj.constants[0],
                navLink: [...obj.constants, ...arr].sort((a,b)=> (a.position > b.position ? 1 : -1))
            }

        // case "SET_ADMIN_FORM_IDS":

        //     return {
        //         ...state,
        //         adminIds: [...action.adminIds]
        //     }

        default:
            return state;
    }
}

export default SidebarReducer;