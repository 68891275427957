import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import '../App.css';
import DialogueBox from './dialogueBox';
import { BASE_URL, BRAND_CATEGORY_MAPPINGS, CHANNEL_CATEGORY_MAPPINGS, EXCLUSIONS, INPUT_FORMS, INPUT_SUGGESTIONS, MATERIALS, MFA, PACKAGE_MAPPINGS, SKU_PERF } from '../constant/apiEndpoints';
import APIService from '../services/APIService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55vw',
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
    // overflow: 'auto',
    // display: 'flex'
};

const SKUBanPopupModal = (props) => {

    const [materialList, setMaterialList] = React.useState([]);

    const handleOnClose = (value) => {
        props.handleBanPopupModal(value)
    }

    const loadMaterialList = async (ban_number) => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + MATERIALS + ban_number + '/' + `?selected_form=${props.selectedForm}&selected_business_unit=${props.business_unit}&current_quarter=${props.current_quarter}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in ski exlusion list API", response.results.message)
        } else {
            setMaterialList(response.results);
        }
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            toast.promise(loadMaterialList(props.ban_number), {
                loading: "Loading. wait a moment!",
                success: 'Data load success.',
                error: 'Something went wrong.',
            },
                {
                    position: 'top-center',
                    className: 'toast-popup',
                });
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div>
            <Modal
                open={props.isBanPopupModalOpen}
                // onClose={() => props.handleIsFormModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="brand-category-modal-form-header">
                        <p>Ban Number : {props.ban_number}</p>
                        <div className="brand-category-modal-form-close-button" onClick={() => handleOnClose(false)} title="Close">
                            X
                        </div>
                    </div>
                    <div className="sku-ban-popup-modal-form-body">
                        {/* Table(If Data Present) */}
                        {materialList.length > 0 ? (
                            <div className="container table-container row p-2" style={{height: '10vh', overflowX: 'scroll'}}>
                                <div className="col-12">
                                    <table className="table" >
                                        <thead>
                                            <tr>
                                                <th scope="col">Material ID</th>
                                                <th scope="col">Material Description</th>
                                                <th scope="col">Item Category</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {materialList.map((material) => (
                                                <tr key={material.id} className={material.selected ? "selected" : ""}>
                                                    <td title={material.material}>{material.material}</td>
                                                    <td title={material.material_description}>{material.material_description}</td>
                                                    <td title={material.item_category}>{material.item_category}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>) : 
                            (<div>
                                {/* No Data Message */}
                                {materialList.length === 0 && <div className="table-data-note"><p>No data found.</p></div>}
                                {/* No Dta Message */}
                            </div>)}
                    </div>
                </Box>
            </Modal>
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    )
};

export default SKUBanPopupModal;

