import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const DropDownCustom = (props) => {

    const [selectedValue, setSelectedValue] = React.useState("")
    const [selectedLabel, setSelectedLabel] = React.useState("")

    const handleOnChange = (e) => {
        let selectedText = e.target.options[e.target.selectedIndex].text
        setSelectedLabel(selectedText)
        setSelectedValue(e.target.value)
        props.setParentDropdownValue(e.target.value, selectedText, props.title)
    }

    return (
        <>
            <div>
                <label style={{ paddingLeft: "5px", fontSize: "small" }}>{props.title}</label>
            </div>
            <select
                name="teamName"
                className="country-drop-down"
                onChange={handleOnChange}
                value={selectedValue}
                required
            >
                <option value='' disabled> --Select-- </option>
                {props.options.map(each => (
                    <option
                        className="select-dropdown-option"
                        key={each.id}
                        value={each.id}
                        name={each.title}
                    // defaultValue={each.id == selectedValue}
                    >
                        {each.title}
                    </option>
                ))}
            </select>
        </>
    );
};

export default DropDownCustom;