import { combineReducers } from "redux";
import SidebarReducer from "../reducer/sideBar_reducer";
import EmissionReducer from "../reducer/EmissionReducer";
import StewardReducer from "../reducer/StewardReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['sidebar']
}

const rootReducer = combineReducers({
    sidebar: SidebarReducer,
    emissions: EmissionReducer,
    steward: StewardReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistReducer(persistConfig, persistedReducer);