import { ACCOUNTS, BASE_URL, COUNTRIES, USERS, INPUT_FORMS, MFA, FILTERS, BRAND_CATEGORY_MAPPINGS, DELETE } from '../constant/apiEndpoints';
import APIService from '../services/APIService';

export const suggestionData = {
    'brand': [],
    'summarized_brand': [],
    'category_sap': [],
    'category': [],
    'brand_group': [],
    'brand_group_l2': [],
    'brand_ranking': [],
    'category_ranking': [],
    'trv_l2': [],
    'trv_l3': [],
    'trv_l4': [],
    'trv_l5': [],
    'trv_l6': [],
    'trv_l7': [],
    'trv_l3_ranking': [],
    'trv_l4_ranking': [],
    'trv_l5_ranking': [],
    'trv_l6_ranking': [],
    'trv_l7_ranking': [],
    'innovation': [],
    'tccc_indicator_sap': [],
    'sum_brand_ko': [],
    'brand_category_ko': [],
    'category_vr': [],
}
export const channelSuggestionData = {
    'bic_ccusthie4' :[],
    'home_ooh': [],
    'trade_channel_ch3':[],
    'subtrade_channel_ch4':[],
    'channel_vr':[],
}

export const packageSuggestionData = {
    'package_id' :[],
    'package_size': [],
    'package_type':[],
    'package_key':[],
    'final_package':[],
    'reported_pack' :[],
    'pack_type_1': [],
    'pack_type_2':[],
    'rank':[],
    'comment':[],
}

//Transforming Data Into AutoSuggest Component Compatible
const transformDataToAutoSuggestCompatable = (data) => {
    const transformedData = data.map(({ label, value }) => ({ id: label, title: value }));
    return transformedData
}

//AJAX Call To Load Brand List
const loadAPI = async (formId, filter_type, currentSuggestionData, covertData=true) => {
    let newData = currentSuggestionData
    let url = BASE_URL + INPUT_FORMS + MFA + FILTERS + `?selected_form=${formId}` + `&filter_type=${filter_type}`
    let response = await new APIService().get(url);
    if (response.error) {
        console.log("error in Brand Category Mapping List API", response.results.message)
        newData[filter_type] = []
    } else {
        if (response.results.length > 0) {
            if(covertData){
                newData[filter_type] = transformDataToAutoSuggestCompatable(response.results)
            }else{
                newData[filter_type] = response.results
            }
            
        }
    }
    return newData
}

const loadList = (formId, filter_type, currentSuggestionData, covertData=true) => {
    let data = currentSuggestionData
    //async returns promise
    let promise = loadAPI(formId, filter_type, currentSuggestionData, covertData)
    promise.then(
        response => {
            data = response}
    )
    return data
}

export const getSuggestiveSearchRelatedData = (formId) => {
    let current_data = {}
    current_data = loadList(formId, "brand", suggestionData, false);
    current_data = loadList(formId, "summarized_brand", current_data, false);
    current_data = loadList(formId, "category_sap", current_data, false);
    current_data = loadList(formId, "category", current_data);
    current_data = loadList(formId, "brand_group", current_data);
    current_data = loadList(formId, "brand_group_l2", current_data);
    current_data = loadList(formId, "brand_ranking", current_data);
    current_data = loadList(formId, "category_ranking", current_data);
    current_data = loadList(formId, "trv_l2", current_data);
    current_data = loadList(formId, "trv_l3", current_data);
    current_data = loadList(formId, "trv_l4", current_data);
    current_data = loadList(formId, "trv_l5", current_data);
    current_data = loadList(formId, "trv_l6", current_data);
    current_data = loadList(formId, "trv_l7", current_data);
    current_data = loadList(formId, "trv_l3_ranking", current_data);
    current_data = loadList(formId, "trv_l4_ranking", current_data);
    current_data = loadList(formId, "trv_l5_ranking", current_data);
    current_data = loadList(formId, "trv_l6_ranking", current_data);
    current_data = loadList(formId, "trv_l7_ranking", current_data);
    current_data = loadList(formId, "innovation", current_data);
    current_data = loadList(formId, "tccc_indicator_sap", current_data);
    current_data = loadList(formId, "sum_brand_ko", current_data);
    current_data = loadList(formId, "brand_category_ko", current_data);
    current_data = loadList(formId, "category_vr", current_data);
    return current_data
}
