import React from 'react';
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../config/autoconfig";
import Button from "react-bootstrap/Button";
import '../App.css';


const Login = () => {
    const { instance } = useMsal();

    const handleLogin = (instance) => {
        instance.loginRedirect(loginRequest).then((res) => {
            console.log('in handleLogin then response')
        })
    }

    return (
        <div className="login-page" >
            <div className="login-box">
                <div className="login-box-header">
                    <img className="login-page-logo" src="/landing page elemnts-02.png" />
                </div>
                <div className="login-box-body" align="center">
                    <div className="pt-3">
                        <label htmlFor='login-button' className="font-weight-bold">Login Using MSAL</label>
                    </div>
                    <div className="pt-3">
                        <Button variant="primary" id="login-button" className="ml-auto" onClick={() => handleLogin(instance)}>Sign In</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;