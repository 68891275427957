import React, { useState } from "react";
import "../App.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faArrowCircleUp, faArrowCircleDown, faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import { faCompass, faCheckCircle } from '@fortawesome/free-regular-svg-icons'

export default function PriceForecastSearchBar(props) {
    const [search, setSearch] = useState("");

    const handleSearchOnChange = (e) => {
        setSearch(e.target.value)
        if (e.target.value === "") {
            props.handleSearchSubmit("");
        }
    }

    const handleSearchPressEnter = (e) => {
        if (e.key === 'Enter') {
            props.handleSearchSubmit(search);
        }
    }

    const handleSearchReset = (e) => {
        e.preventDefault();
        setSearch("");
        props.handleSearchSubmit("");
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        // loadSKUPerformanceList(selectedBusinessUnits, false, search, columns);
        props.handleSearchSubmit(search);
    }

    return (
        <div className="input-group mt-1">
            <div className="form-outline">
                <input style={{maxHeight: "32px"}} type="text" className="form-control search-input-box" placeholder="Search"
                    value={search} onChange={handleSearchOnChange} onKeyDown={handleSearchPressEnter} />
            </div>
            {/* Conditional Reset Button */}
            {search.length > 0 ?
                <button style={{maxHeight: "32px"}} type="button" className="btn btn-primary search-button"
                    onClick={handleSearchReset} >
                    <FontAwesomeIcon icon={faTimes} className="search-icon" size="1x" />
                </button> : ""}
            {/* Conditional Reset Button */}
            <button style={{maxHeight: "32px"}} type="button" className="btn btn-primary search-button"
                onClick={handleSearchSubmit} >
                <FontAwesomeIcon icon={faSearch} className="search-icon" size="1x" />
            </button>
        </div>
    );
}
