import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const CustomHierarchyDropdown = (props) => {
    // console.log(props, "options")

    const [selectedValue, setSelectedValue] = React.useState("")
    const [selectedLabel, setSelectedLabel] = React.useState("")

    const handleOnChange = (e) => {
        let selectedText = e.target.options[e.target.selectedIndex].text
        setSelectedLabel(selectedText)
        setSelectedValue(e.target.value)
        props.setParentDropdownValue(e.target.value, selectedText, props.title)
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            setSelectedValue(props.preSelectedValue);
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <>
            <div>
                <label style={{ paddingLeft: "5px", fontSize: "small" }}>{props.title}</label>
            </div>
            <select
                name="teamName"
                className="ch-dp-dn brand-category-multi-select-custom"
                onChange={handleOnChange}
                value={selectedValue !== "" ? selectedValue : ""}
                required
            >
                {/* <option value='' disabled> --Select-- </option> */}
                {props.options.map(each => (
                    <option
                        className="select-dropdown-option"
                        key={each.id}
                        value={each.id}
                        name={each.title}
                    // defaultValue={each.id == selectedValue}
                    >
                        {each.title}
                    </option>
                ))}
            </select>
        </>
    );
};

export default CustomHierarchyDropdown;