import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import "../../App.css";
import RoadmapTarget from '../Emission/RoadmapTarget';
import StewardShip from '../StewardShip/StewardShip';

import ManualAdjustmentUser from '../manualAdjustment/user';

const RoadmapRoute = (props) => {
    const [adminIds, setAdminIds] = React.useState(localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')).user.form_admin_list : []);

    const loadComponent = (formId) => {
        switch (formId) {
             case 'emission':
                 return <RoadmapTarget />;    
            default:
                return;
        }
    }

    return (
        <>
            {
                loadComponent(props.formId)
            }
        </>
    );
};

const mapStateProps = (state) => {
    return {
        formId: state.sidebar.formId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateProps, mapDispatchToProps)(RoadmapRoute);