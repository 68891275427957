import React, { useEffect, useState } from 'react';
import '../App.css'
import { ACCOUNTS, BASE_URL, COUNTRIES, USERS, INPUT_FORMS, MFA, FILTERS, BRAND_CATEGORY_MAPPINGS, INPUT_SUGGESTIONS } from '../constant/apiEndpoints';
import APIService from '../services/APIService';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faCompass, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import AutoSuggestInput from './autoSuggestInput'
import DialogueBox from '../components/dialogueBox';
import toast, { Toaster } from 'react-hot-toast';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    height: '95vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
    overflow: 'auto',
    // display: 'flex'
};

const initialObject = {
    'category_brand_id': '',
    'brand': '',
    // 'form_id': '',
    'summarized_brand': '',
    'category_sap': '',
    'category': '',
    'brand_group': '',
    'brand_group_l2': '',
    'brand_ranking': '',
    'category_ranking': '',
    'trv_l2': '',
    'trv_l3': '',
    'trv_l4': '',
    'trv_l5': '',
    'trv_l6': '',
    'trv_l7': '',
    'trv_l3_ranking': '',
    'trv_l4_ranking': '',
    'trv_l5_ranking': '',
    'trv_l6_ranking': '',
    'trv_l7_ranking': '',
    'innovation': '',
    'tccc_indicator_sap': '',
    'sum_brand_ko': '',
    'brand_category_ko': '',
    'category_vr': '',
    'comment': '',
}

const MFABrandCategoryFormModal = (props) => {
    const [isEdit, setIsEdit] = React.useState(false);
    const [dataObject, setDataObject] = useState({
        ...initialObject,
        form_id: props.formId
    })
    const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
    const [messageType, setMessageType] = React.useState("");
    const [message, setMessage] = React.useState(""); 

    const inputOnChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setDataObject(prevState => {
            return { ...prevState, [name]: value }
        })
    }

    const autoSuggestOnChange = (name, value) => {
        setDataObject(prevState => {
            return { ...prevState, [name]: value }
        })
    }

    const handleDialogueBox = () => {
        setOpenDialogueBox(!openDialogueBox)
    }

    const showDialogueBox = (msgType, msg) => {
        setMessage(msg);
        setMessageType(msgType);
        handleDialogueBox();
    }

    const handleOnClose = (value) => {
        setDataObject(initialObject)
        props.handleIsFormModalOpen(value)
    }

    const loadDataObject = async (id) => {
        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + parseInt(id) + '/'
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in ski exlusion list API", response.results.message)
        } else {
            setDataObject(response.results);
            setIsEdit(true);
        }
    }

    const getPayloadData = (obj) => {
        for (var each in obj) {
            if (!obj[each]) {
                obj[each] = null;
            }
        }
        return obj
    }

    const addNewData = async (e) => {
        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS
        let response = await new APIService().post(url, getPayloadData({ ...dataObject, 'form_id': props.formId }))
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {
                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                    
                    showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                }
            }
        } else {
            toast.success('New data added Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.postSubmitAndEditData();
            handleOnClose(false);
        }
    }

    const editExistingData = async (e) => {
        let url = BASE_URL + INPUT_FORMS + MFA + BRAND_CATEGORY_MAPPINGS + parseInt(props.selectedIdToEdit) + '/'
        let response = await new APIService().patch(url, getPayloadData({ ...dataObject, 'form_id': props.formId }))
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                    showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                }
            }
        } else {
            toast.success('Data updated Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.postSubmitAndEditData();
            handleOnClose(false);
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        toast.loading('Submitting, Please Wait!',
            {
                position: 'top-center',
                className: 'toast-popup',
                duration: 1000,
            });
        if (props.selectedIdToEdit !== undefined) {
            editExistingData(e);
        } else {
            addNewData(e);
        }
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (props.selectedIdToEdit !== undefined) {
                toast.promise(loadDataObject(props.selectedIdToEdit), {
                    loading: "Loading. wait a moment!",
                    success: 'Data load success.',
                    error: 'Something went wrong.',
                },
                    {
                        position: 'top-center',
                        className: 'toast-popup',
                    });
            }
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div>
            <Modal
                open={props.isFormModalOpen}
                // onClose={() => props.handleIsFormModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="brand-category-modal-form-header">
                        {isEdit ?
                            <p>Brand : {dataObject.brand} (ID:{props.selectedIdToEdit})</p>
                            : <p>Add New Entry</p>}
                        <div className="brand-category-modal-form-close-button" onClick={() => handleOnClose(false)}>
                            X
                        </div>
                    </div>
                    <div className="brand-category-modal-form-body">
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Brand Name</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="brand" placeholder="Brand" currentValue={dataObject.brand} dataList={props.suggestions.brand} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Summarized Brand</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="summarized_brand" placeholder="Summarized Brand" currentValue={dataObject.summarized_brand} dataList={props.suggestions.summarized_brand} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Category SAP</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="category_sap" placeholder="Category SAP" currentValue={dataObject.category_sap} dataList={props.suggestions.category_sap} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Brand Key</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="brand_key" placeholder="Category SAP" currentValue={dataObject.brand_key} dataList={props.suggestions.brand_key} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Category Brand Key</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="category_brand_key" placeholder="Category SAP" currentValue={dataObject.category_brand_key} dataList={props.suggestions.category_brand_key} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-6">
                                <p className="brand-category-modal-form-note"><strong>NOTE :</strong> Brand, Summarized Brand and Category SAP to be updated as per CO044.</p>
                            </div>
                        </div>
                        <div className="brand-category-modal-form-input-separator">
                            <div className="brand-category-modal-form-input-separator-title" style={{ width: '13vw' }}><p>Brand Category</p></div>
                            <div className="brand-category-modal-form-input-separator-line"><hr /></div>
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Category</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="category" placeholder="Category" currentValue={dataObject.category} dataList={props.suggestions.category} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Brand Group</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="brand_group" placeholder="Brand Group" currentValue={dataObject.brand_group} dataList={props.suggestions.brand_group} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Brand Group L2</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="brand_group_l2" placeholder="Brand Group L2" currentValue={dataObject.brand_group_l2} dataList={props.suggestions.brand_group_l2} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Brand Ranking</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="brand_ranking" placeholder="Brand Ranking" currentValue={dataObject.brand_ranking} dataList={props.suggestions.brand_ranking} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Category Ranking</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="category_ranking" placeholder="Category Ranking" currentValue={dataObject.category_ranking} dataList={props.suggestions.category_ranking} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2"></div>
                        </div>
                        <div className="brand-category-modal-form-input-separator">
                            <div className="brand-category-modal-form-input-separator-title" style={{ width: '5vw' }}><p>TRV</p></div>
                            <div className="brand-category-modal-form-input-separator-line"><hr /></div>
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L2</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l2" placeholder="TRV L2" currentValue={dataObject.trv_l2} dataList={props.suggestions.trv_l2} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L3</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l3" placeholder="TRV L3" currentValue={dataObject.trv_l3} dataList={props.suggestions.trv_l3} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L4</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l4" placeholder="TRV L4" currentValue={dataObject.trv_l4} dataList={props.suggestions.trv_l4} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L5</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l5" placeholder="TRV L5" currentValue={dataObject.trv_l5} dataList={props.suggestions.trv_l5} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L6</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l6" placeholder="TRV L6" currentValue={dataObject.trv_l6} dataList={props.suggestions.trv_l6} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L7</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l7" placeholder="TRV L7" currentValue={dataObject.trv_l7} dataList={props.suggestions.trv_l7} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L3 Ranking</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l3_ranking" placeholder="TRV L3 Ranking" currentValue={dataObject.trv_l3_ranking} dataList={props.suggestions.trv_l3_ranking} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L4 Ranking</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l4_ranking" placeholder="TRV L4 Ranking" currentValue={dataObject.trv_l4_ranking} dataList={props.suggestions.trv_l4_ranking} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L5 Ranking</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l5_ranking" placeholder="TRV L4 Ranking" currentValue={dataObject.trv_l5_ranking} dataList={props.suggestions.trv_l5_ranking} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L6 Ranking</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l6_ranking" placeholder="TRV L6 Ranking" currentValue={dataObject.trv_l6_ranking} dataList={props.suggestions.trv_l6_ranking} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TRV L7 Ranking</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="trv_l7_ranking" placeholder="TRV L7 Ranking" currentValue={dataObject.trv_l7_ranking} dataList={props.suggestions.trv_l7_ranking} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <p>Innovations</p>
                                <AutoSuggestInput name="innovation" placeholder="Innovations" currentValue={dataObject.innovation} dataList={props.suggestions.innovation} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                        </div>
                        <div className="brand-category-modal-form-input-separator">
                            <div className="brand-category-modal-form-input-separator-title" style={{ width: '5vw' }}><p>DMI</p></div>
                            <div className="brand-category-modal-form-input-separator-line"><hr /></div>
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>TCCC Indicator SAP</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="tccc_indicator_sap" placeholder="TCCC Indicator SAP" currentValue={dataObject.tccc_indicator_sap} dataList={props.suggestions.tccc_indicator_sap} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Sum Brand KO</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="sum_brand_ko" placeholder="Sum Brand KO" currentValue={dataObject.sum_brand_ko} dataList={props.suggestions.sum_brand_ko} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Brand Category KO</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="brand_category_ko" placeholder="Brand Category KO" currentValue={dataObject.brand_category_ko} dataList={props.suggestions.brand_category_ko} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Category VR</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="category_vr" placeholder="Category VR" currentValue={dataObject.category_vr} dataList={props.suggestions.category_vr} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2"></div>
                            <div className="brand-category-modal-form-input col-2"></div>
                        </div>
                    </div>
                    <div className="brand-category-modal-form-footer row m-0">
                        <div className="brand-category-modal-form-input col-6">
                            <div style={{ display: "flex" }}>
                                {/* <p>Comment</p>{isEdit ? <p style={{ color: "#E61C2A" }}>*</p> : ''} */}
                                <p>Comment</p><p style={{ color: "#E61C2A" }}>*</p>
                            </div>

                            {/* {isEdit ? <p style={{color:"#E61C2A"}}>Comment*</p> : <p>Comment</p>} */}
                            <textarea className="brand-category-text-input-box"
                                style={{ width: "100%" }}
                                rows="4" cols="50"
                                name="comment"
                                value={dataObject.comment}
                                onChange={inputOnChange}
                            />
                        </div>
                        <div className="brand-category-modal-form-input col-6">
                            <div style={{ float: "right" }}>
                                <button className="brand-category-form-modal-cancel-button mt-3" onClick={() => handleOnClose(false)}>Cancel</button>
                                <button className="brand-category-form-modal-submit-button mt-3" onClick={handleOnSubmit}>Submit</button>
                            </div>
                        </div>

                    </div>
                </Box>
            </Modal>
            {/* Message Dialogue Box */}
            {openDialogueBox ? <DialogueBox openDialogueBox={openDialogueBox}
                handleDialogueBox={handleDialogueBox}
                messageType={messageType}
                message={message} /> : ""}
            {/* Message Dialogue Box */}
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    )
};

export default MFABrandCategoryFormModal;

