import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import '@rmwc/chip/styles';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import DropDownMultiSelectMFACustom from '../../components/dropDownMultiSelectMFACustom'
import SKUExclusionNewlistAndDelistDialogueBox from '../../components/skuExclusionNewlistAndDelistDialogueBox'
import SKUSpecificSearchBar from '../../components/skuSpecificSearchBar';
import SKUSTMFormModal from '../../components/skuSTMFormModal';
import TableSKUSTM from '../../components/tableSKUSTM';
import { BASE_URL, FILTERS, INPUT_FORMS, DELETE, EXPORT, SKU_PERF, EXCLUSIONS, NEWLISTANDDELIST, QUARTERS, STMS, CUTOFFTIME } from '../../constant/apiEndpoints';
import { skuSTMInitialColumnsForm } from '../../constant/constants';
import APIService from '../../services/APIService';
const tableHeadColour = '#F2F2F2';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const initialColumns = skuSTMInitialColumnsForm

//Material UI Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeadColour,
        color: theme.palette.common.black,
        fontWeight: 600,
        cursor: 'pointer'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));
//Material UI Styles


const Admin2 = (props) => {

    const [adminIds, setAdminIds] = useState(props.adminIds);
    const [quarterList, setQuarterList] = React.useState([]);
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [onTimeList, setOnTimeList] = useState([]);
    const [selectedQuarters, setSelectedQuarters] = React.useState([]);
    const [selectedBusinessUnitList, setSelectedBusinessUnitList] = useState([]);
    const [selectedOnTimeList, setSelectedOnTimeList] = useState([]);
    const [selectedList, setSelectedList] = useState([]); //Button Handle
    const [columns, setColumns] = useState(initialColumns); //Columns Handle
    const [skuSTMData, setSKUSTMData] = useState([]); //Table Data Handle
    const [isCutOffTime, setCutOffTime] = useState(false);
    const [dropDownTimer, setDropDownTimer] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedIdToEdit, setSelectedIdToEdit] = useState();
    const [selectedObjToEdit, setSelectedObjToEdit] = useState({});
    const [openDeleteDialogueBox, setOpenDeleteDialogueBox] = useState(false);
    const [search, setSearch] = useState("");

    const setSelectedListInParent = (value) => {
        setSelectedList(value)
    }

    const handleIsFormModalOpen = (value) => {
        setIsFormModalOpen(value);
        setSelectedIdToEdit();
        setSelectedObjToEdit({});
    }

    const handleDeleteDialogueBox = () => {
        setOpenDeleteDialogueBox(!openDeleteDialogueBox)
    }

    const handleDeleteButton = () => {
        setOpenDeleteDialogueBox(true)
    }

    //Support Function For Filter List
    const createStringFromObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return encodeURIComponent(newString)
    }

    //AJAX Call To Load Channel Mapping List
    const loadSKUSTMDataAPICall = async (selectedQuarterFilter = "", selectedBusinessUnitFilter = "", selectedOnTimeFilter = "", searchFilter = "") => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS + `?selected_form=${props.formId}` + selectedQuarterFilter + selectedBusinessUnitFilter + selectedOnTimeFilter + searchFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in channel exlusion list API", response.results.message)
        } else {
            // if (response.results.length > 0) {
            // const transformedData = response.results.map(({ id, title }) => ({ label: title, value: id }));
            setSKUSTMData(response.results);
            // setBrandCategoryListReference(response.results);
            // }
        }
        setLoading(false);
    }

    const handleDelete = async (comment) => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS + DELETE
        let body = {
            "form_id": props.formId,
            "ids": selectedList.map((ele) => { return ele.id })
        }
        let response = await new APIService().delete(url, body)
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("This field may not be blank.")) {
                    toast.error('Comment is required!', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                } else {
                    toast.error('Something went wrong.', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                }
            }
        } else {
            toast.success('Data deleted successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000 });
            loadSKUSTMData(true, selectedQuarters, selectedBusinessUnitList, selectedOnTimeList, search);
            loadFilterData();
            handleDeleteDialogueBox();
            setSelectedList([])
        }
    }
    const handleExportButton = async () => {

        //Quarter Filter
        let selectedQuarterFilter = getQuarterFilter(null)
        //Business Unit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(null)
        //On Time Filter
        let selectedOnTimeFilter = getOnTimeFilter(null)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS + EXPORT + `?selected_form=${props.formId}` + selectedQuarterFilter + selectedBusinessUnitFilter + selectedOnTimeFilter
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token')).access}`,
            })
        }).then(response =>
            response.blob().then(blob => {
                var today = new Date()
                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = "sku_stm_" + date + "_" + time + ".xls";
                a.click();
            })
        );
    }

    const getQuarterFilter = (quarterIDs) => {
        //Getting User Selected Business Units By State/User Action
        let selectedQuarterList = quarterIDs !== null ? quarterIDs : selectedQuarters
        let selectedQuarterFilter = ""
        if (selectedQuarterList.length !== 0) {
            selectedQuarterFilter = `&selected_quarter=${createStringFromObjectList(selectedQuarterList)}`
        }
        return selectedQuarterFilter
    }

    const getBusinessUnitFilter = (businessUnitIDs) => {
        //Getting User Selected Data By State/User Action
        let selectedBusinessUnit = businessUnitIDs !== null ? businessUnitIDs : selectedBusinessUnitList
        let selectedBusinessUnitListFilter = ""
        if (selectedBusinessUnit.length !== 0) {
            selectedBusinessUnitListFilter = `&selected_business_unit=${createStringFromObjectList(selectedBusinessUnit)}`
        }
        return selectedBusinessUnitListFilter
    }

    const getOnTimeFilter = (onTimeIDs) => {
        //Getting User Selected Data By State/User Action
        let selectedOnTime = onTimeIDs !== null ? onTimeIDs : selectedOnTimeList
        let selectedOnTimeFilter = ""
        if (selectedOnTime.length !== 0) {
            selectedOnTimeFilter = `&is_on_time=${createStringFromObjectList(selectedOnTime)}`
        }
        return selectedOnTimeFilter
    }

    const loadQuarterList = async (businessUnitIDs = selectedBusinessUnitList, onTimeIDs = selectedOnTimeList, searchFilterArg=search) => {
        //Business Unit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)
        //On Time filter
        let selectedOnTimeFilter = getOnTimeFilter(onTimeIDs)

        let searchFilter = getSearchFilter(searchFilterArg);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=quarter` + selectedBusinessUnitFilter + selectedOnTimeFilter + searchFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setQuarterList(response.results);
            }
        }
    }

    const loadBusinessUnitList = async (quarterIDs = selectedQuarters, onTimeIDs = selectedOnTimeList, searchFilterArg = search) => {
        //Quarter Filter
        let selectedQuarterFilter = getQuarterFilter(quarterIDs)
        //On Time filter
        let selectedOnTimeFilter = getOnTimeFilter(onTimeIDs)

        let searchFilter = getSearchFilter(searchFilterArg);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=business_unit__name` + selectedQuarterFilter + selectedOnTimeFilter + searchFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setBusinessUnitList(response.results);
            }
        }
    }

    //AJAX Call To Load Category SAP List
    const loadOnTimeList = async (quarterIDs = selectedQuarters, businessUnitIDs = selectedBusinessUnitList, searchFilterArg = search) => {
        //Quarter Filter
        let selectedQuarterFilter = getQuarterFilter(quarterIDs)
        //Business Unit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)

        let searchFilter = getSearchFilter(searchFilterArg);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=is_on_time` + selectedQuarterFilter + selectedBusinessUnitFilter + searchFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setOnTimeList(response.results);
            }
        }
    }

    const getSearchFilter = (seachFilterArg) => {
        let searchFilter = ""
        if (seachFilterArg.length !== 0) {
            searchFilter = `&search=${encodeURIComponent(seachFilterArg)}`
        }
        return searchFilter
    }

    const loadSKUSTMData = async (useTimer, quarterIDs, businessUnitIDs, onTimeIDs, searchFilterArg = "",) => {

        let selectedQuarterFilter = getQuarterFilter(quarterIDs)

        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)

        let selectedOnTimeFilter = getOnTimeFilter(onTimeIDs)

        let searchFilter = getSearchFilter(searchFilterArg);

        //Clearing Timeout Before Adding New Timeout(Previous Timeout)
        if (dropDownTimer) {
            clearTimeout(dropDownTimer);
        }
        //Add Timeout Before Hitting API
        if (useTimer) {
            setDropDownTimer(
                setTimeout(function () {
                    // loadBrandCategoryMappingAPICall(selectedBrandFilter, selectedSummarizedBrandFilter, selectedCategorySAPFilter);
                    toast.promise(loadSKUSTMDataAPICall(selectedQuarterFilter, selectedBusinessUnitFilter, selectedOnTimeFilter, searchFilter), {
                        loading: "Loading. wait a moment!",
                        success: 'Data load success.',
                        error: 'Something went wrong.',
                    },
                        {
                            position: 'top-center',
                            className: 'toast-popup',
                        });
                }, 1500) 
            )
        } else {
            loadSKUSTMDataAPICall();
        }
    }

    const handleSearchSubmit = (search) => {
        setSearch(search);
        loadBusinessUnitList(selectedQuarters, selectedOnTimeList, search);
        loadOnTimeList(selectedQuarters, selectedBusinessUnitList, search);
        loadQuarterList(selectedBusinessUnitList, selectedOnTimeList, search)
        loadSKUSTMData(true, selectedQuarters, selectedBusinessUnitList, selectedOnTimeList, search);
    }

    const handleQuarterSelection = (value) => {
        setSelectedQuarters(value);
        loadBusinessUnitList(value, selectedOnTimeList);
        loadOnTimeList(value, selectedBusinessUnitList);
        loadSKUSTMData(true, value, selectedBusinessUnitList, selectedOnTimeList, search);
    }

    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Business Unit") {
            setSelectedBusinessUnitList(objs)
            //To load relevent values in other dropdown filters
            loadQuarterList(objs, selectedOnTimeList);
            loadOnTimeList(selectedQuarters, objs);
        }
        else if (valueType === "On Time") {
            setSelectedOnTimeList(objs)
            //To load relevent values in other dropdown filters
            loadQuarterList(selectedBusinessUnitList, objs);
            loadBusinessUnitList(selectedQuarters, objs);
        }
        //Loading Channel Mapping List In Table
        loadSTMBasedOnFilters(objs, valueType);
    }


    const loadSTMBasedOnFilters = (objs, valueType) => {
        //Loading Channel Mapping Values which is dependent on Home OOH Dropdown
        if (valueType === "Business Unit") {
            loadSKUSTMData(true, selectedQuarters, objs, selectedOnTimeList, search);
        }
        //Loading Channel Mapping Values which is dependent on Trade Channel Dropdown
        if (valueType === "On Time") {
            loadSKUSTMData(true, selectedQuarters, selectedBusinessUnitList, objs, search);
        }
        //Loading Channel Mapping Values which is dependent on Subtrade Channel Dropdown
        if (valueType === "Item Category") {
            loadSKUSTMData(true, selectedQuarters, selectedBusinessUnitList, selectedOnTimeList, objs, search);
        }
    }

    const loadCutOffTime = async () => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + CUTOFFTIME + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Channel List API", response.results.message)
        } else {
            if (response.results) {
                setCutOffTime(response.results.is_cut_off_time_on);
            }
        }
    }

    const handleEditClickInParent = (obj) => {
        setIsFormModalOpen(true)
        setSelectedIdToEdit(obj.id)
        setSelectedObjToEdit(obj)
    }

    const postSubmitAndEditData = () => {
        loadSKUSTMData(true, selectedQuarters, selectedBusinessUnitList, selectedOnTimeList, search);
        loadFilterData();
    }

    const loadFilterData = () => {
        loadQuarterList();
        loadBusinessUnitList();
        // loadCutOffTime();
        loadOnTimeList();
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadFilterData();
            loadSKUSTMData(false, selectedQuarters, selectedBusinessUnitList, selectedOnTimeList, search);
        }
        return function cleanup() {
            mounted = false
        }

    }, [])

    return (
        <div className="user-view-container">
            <div className="brand-category-header-container row">
                <div className="col-10 brand-category-filter-container">
                    {/* <div className="brand-category-dropdown-container">
                        <div style={{height: "72px"}}>
                            <SKUQuarterSingleSelectDropDown options={quarterList}
                                setParentDropdownValue={handleQuarterSelection} />
                        </div>
                    </div> */}
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Quarter" options={quarterList} setParentDropdownValue={handleQuarterSelection} />
                    </div>
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Business Unit" options={businessUnitList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="On Time" options={onTimeList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="col-3 p-0 seach-box-container">
                        <SKUSpecificSearchBar handleSearchSubmit={handleSearchSubmit} />
                    </div>
                </div>
                <div className="col-2 brand-category-add-delete-export-button-container">
                    <button className="brand-category-export-button mt-3 col" type="button" value="Export Data" onClick={handleExportButton}>Export</button>

                    {selectedList.length === 0 ?
                        <button className="brand-category-button mt-3 col" type="button" value="+ Add New" disabled={isCutOffTime} onClick={() => handleIsFormModalOpen(true)}><FontAwesomeIcon icon={faPlus} className="search-icon" size="1x" /> Add New</button>
                        : <button className="brand-category-button mt-3 col" type="button" value="Delete" disabled={isCutOffTime} onClick={handleDeleteButton}><FontAwesomeIcon icon={faTrash} className="search-icon" size="1x" /> Delete</button>}
                </div>
            </div>
            <div className="brand-category-body">
                <div className='other-mfa-table-holder'>
                    <div className='channel-category-chips-container'>
                        {/* CSS Purpose */}
                    </div>
                    {/* Loader(While Data Loading) */}
                    {loading ? <div className="table-data-note"><Spinner animation="border" />
                        <p></p>
                    </div> : ""}
                    {/* Loader(While Data Loading) */}
                    {/* Cut Off Time Note Banner */}
                    {
                        isCutOffTime && loading !== true ? <div className="sku-exclusion-cut-off-note-container" style={{ backgroundColor: "Yellow" }}>NOTE : Cut Off Time is going on. You will not be able to make any changes.</div> : ""
                    }
                    {/* Cut Off Time Note Banner */}
                    {skuSTMData.length > 0 ?
                        <div className="brand-category-table-container">
                            <TableSKUSTM columns={columns}
                                skuSTMData={skuSTMData}
                                setSelectedListInParent={setSelectedListInParent}
                                handleEditClickInParent={handleEditClickInParent}
                                formId={props.formId}
                                isCutOffTime={isCutOffTime} />
                        </div>
                        : ''}
                    {/* No Data Message */}
                    {skuSTMData.length === 0 && loading === false ? (<div className="table-data-note"><p>No data found.</p>
                        <p></p>
                    </div>) : ""}
                    {/* No Data Message */}
                </div>

            </div>
            {/* Loader */}
            <Toaster />
            {/* Loader */}
            {isFormModalOpen ?
                <SKUSTMFormModal isFormModalOpen={isFormModalOpen} handleIsFormModalOpen={handleIsFormModalOpen}
                    formId={props.formId} selectedIdToEdit={selectedIdToEdit} selectedObjToEdit={selectedObjToEdit}
                    postSubmitAndEditData={postSubmitAndEditData}
                />
                : ""}
            {/* Message Dialogue Box */}
            {openDeleteDialogueBox ? <SKUExclusionNewlistAndDelistDialogueBox openDialogueBox={openDeleteDialogueBox}
                handleDeleteDialogueBox={handleDeleteDialogueBox}
                messageType="Delete"
                message="Are you sure you want to delete"
                handleDelete={handleDelete} /> : ""}
            {/* Message Dialogue Box */}

        </div>
    );

};

export default Admin2;