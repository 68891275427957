const initialState = {
    tableData: [],
    countryList: [],
    regionList: [],
    buList: [],
    yearList: [],
    monthList: [],
    plantList: [],
    columnList: [],
    ciColumnList: [],
    matrix: [],
    addRegionList: [],
    addCountryList: [],
    addBUList: [],
    addMatrix: [],
    addPlantList: [],
    loading: true,
    toast: {}

}

const StewardReducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch (type) {

        case 'SHOW_TOAST': {
            return {
                ...state,
                toast: payload,
                loading: false
            }
        }

        case 'STEWARD_TABLE_DATA':
            return {
                ...state,
                tableData: payload,
                loading: false,
            }

        case 'STEWARD_COUNTRY_LIST':
            return {
                ...state,
                countryList: payload
            }

        case 'STEWARD_BU_LIST':
            return {
                ...state,
                buList: payload
            }

        case 'STEWARD_REGION_LIST':
            return {
                ...state,
                regionList: payload
            }
        case 'STEWARD_REGION_LIST':
            return {
                ...state,
                regionList: payload
            }
        case 'STEWARD_PLANT_LIST':
            return {
                ...state,
                plantList: payload
            }
        case 'STEWARD_YEAR_LIST':
            return {
                ...state,
                yearList: payload
            }
        case 'STEWARD_COLUMN_LIST':
            return {
                ...state,
                columnList: payload
            }
        case 'SET_INITIAL_STATUS':
            return {
                ...state,
                tableData: [],
                countryList: [],
                regionList: [],
                buList: [],
                yearList: [],
                monthList: [],
                plantList: [],
                columnList: [],
                ciColumnList: [],
                toast: {}
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'CI_COLUMN_LIST': {
            return {
                ...state,
                ciColumnList: payload
            }
        }
        case 'STEWARD_REGION_MATRIX': {
            return {
                ...state,
                matrix: payload
            }
        }
        case 'ADD_COUNTRY_LIST': {
            return {
                ...state,
                addCountryList: payload
            }
        }
        case 'ADD_BU_LIST': {
            return {
                ...state,
                addBUList: payload
            }
        }

        case 'ADD_REGION_LIST': {
            return {
                ...state,
                addRegionList: payload
            }
        }
        case 'ADD_REGION_MATRIX': {
            return {
                ...state,
                addMatrix: payload
            }
        }
        case 'ADD_PLANT_LIST': {
            return {
                ...state,
                addPlantList: payload
            }
        }
        default:
            return state
    }
}

export default StewardReducer