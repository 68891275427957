import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import { CSVLink } from "react-csv";
import Select from "react-select";

import MultiSelectDropDown from '../../Emission/multiSelectDropdown';
import TableList from '../../Emission/TableList'
import { month, ciList, ciPartnerOrg } from '../StewardColumn';
import Loading from '../../loadingHome';
import { read, utils, writeFile } from 'xlsx';
import DeleteModal from '../../Emission/DeleteModal';
import EditModal from '../../Emission/EditModal';
import AddModal from '../AddModal';
import { addStewardRow, deleteStewardRow, editStewardRow, getStewardData, updatingDropdown } from '../../../actions/steward_action';


const CommunityInvestment = () => {

    const dispatch = useDispatch()
    const EmissionList = useSelector((state) => state.steward.tableData)
    const region = useSelector((state) => state.steward.regionList)
    const country = useSelector((state) => state.steward.countryList)
    const bu = useSelector((state) => state.steward.buList)
    const year = useSelector((state) => state.steward.yearList)
    const loading = useSelector((state) => state.steward.loading)
    const headers = useSelector((state) => state.steward.columnList)
    const colList = useSelector((state) => state.steward.ciColumnList)
    const matrix = useSelector((state) => state.steward.matrix)
    const toastData = useSelector((state) => state.steward.toast)

    const [selectedRegion, setSelectedRegion] = useState([])
    const [selectedBU, setSelectedBU] = useState([])
    const [selectedCountry, setSelectedCountry] = useState([])
    const [selectedYear, setSelectedYear] = useState([])
    const [selectedMonth, setSelectedMonth] = useState([])
    const [tableData, setTableData] = useState()
    const [exportData, setExportData] = useState([])
    const [checkboxList, setCheckboxList] = useState([])
    const [checkboxId, setCheckboxId] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedDropDown, setSelectedDropDown] = useState([])
    const [editRow, setEditRow] = useState({})
    const [checked, setChecked] = useState(ciList[0])
    const [toggleChecked, setToggleChecked] = useState(false)
    const disabledOption = ['region', 'bu', 'country', 'year', 'month']

    useEffect(() => {
        let updatedRegionData = []
        if (selectedRegion.length > 0) {
            for (let char of EmissionList) {
                if (selectedRegion.includes(char.region)) {
                    updatedRegionData.push(char)
                }
            }
        }
        else {
            updatedRegionData = EmissionList
        }
        let upDatedBuData = []
        if (selectedBU.length > 0) {
            for (let char of updatedRegionData) {
                if (selectedBU.includes(char.bu)) {
                    upDatedBuData.push(char)
                }
            }
        } else {
            upDatedBuData = updatedRegionData
        }
        let updatedCountryData = []
        if (selectedCountry.length > 0) {
            for (let char of upDatedBuData) {
                if (selectedCountry.includes(char.country)) {
                    updatedCountryData.push(char)
                }
            }
        }
        else {
            updatedCountryData = upDatedBuData
        }

        let updatedYearData = []
        if (selectedYear.length > 0) {
            for (let char of updatedCountryData) {
                if (selectedYear.includes(char.year)) {
                    updatedYearData.push(char)
                }
            }
        }
        else {
            updatedYearData = updatedCountryData
        }
        let updatedMonthData = []
        if (selectedMonth.length > 0) {
            for (let char of updatedYearData) {
                if (selectedMonth.includes(char.month)) {
                    updatedMonthData.push(char)
                }
            }
        }
        else {
            updatedMonthData = updatedYearData
        }
        setTableData(updatedMonthData)
    },
        [selectedBU, selectedCountry, selectedRegion, selectedYear, selectedMonth])

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let res = tableData.map(({ id, selected, ...rest }) => ({ ...rest }));
            setExportData(res)
        }
    }, [tableData])
    useEffect(() => {
        dispatch({
            type: 'SET_INITIAL_STATUS',
        })
        toast.promise(dispatch(getStewardData(toggleChecked === false ? checked.value : 'ci_partner_org')), {
            loading: "Loading. wait a moment!",
            success: 'Data loaded Successfully!',
            error: 'Something went wrong.',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });
        //dispatch(getStewardData(toggleChecked === false ? checked.value : 'ci_partner_org'))
    }, [checked, toggleChecked])

    useEffect(() => {
        setTableData(EmissionList)
        let res = EmissionList.map(({ id, selected, ...rest }) => ({ ...rest }));
        setExportData(res)
    }, [EmissionList])

    useEffect(() => {
        let obj = {}
        for (let header of headers) {
            obj[header.key] = ''
        }
        setEditRow(obj)
    }, [headers])

    useEffect(() => {
        if (toastData.message && toastData.message.length > 0 && toastData.type === "error") {
            toast.error(toastData.message, { position: 'top-right', duration: 1500, });
        }
        else if (toastData.message && toastData.message.length > 0 && toastData.type === "success") {
            toast.success(toastData.message, { position: 'top-right', duration: 1500, });
        }
    }, [toastData])

    useEffect(() => {
        if (selectedDropDown === 'Region') {
            dispatch(updatingDropdown(selectedDropDown, selectedRegion, matrix))
            if (selectedCountry.length !== 0) {
                dispatch(updatingDropdown('Country', selectedCountry, matrix))
            }
            if (selectedBU.length !== 0) {
                dispatch(updatingDropdown('BU', selectedBU, matrix))
            }

        }
        if (selectedDropDown === 'BU') {
            dispatch(updatingDropdown(selectedDropDown, selectedBU, matrix))
            if (selectedCountry.length !== 0) {
                dispatch(updatingDropdown('Country', selectedCountry, matrix))
            }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
        if (selectedDropDown === 'Country') {
            dispatch(updatingDropdown(selectedDropDown, selectedCountry, matrix))
            // if (selectedBU.length !== 0) {
            //     dispatch(updatingDropdown('BU', selectedBU, matrix))
            // }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
    }, [selectedRegion, selectedBU, selectedCountry])

    const setDropDownValues = (val, valType) => {
        if (valType === 'Region') {
            let regionVal = []
            if (val.length > 0) {
                for (let char of val)
                    regionVal.push(char.value)
            }
            setSelectedRegion(regionVal)
        }
        if (valType === 'BU') {
            let buVal = []
            if (val.length > 0) {
                for (let char of val)
                    buVal.push(char.value)
            }
            setSelectedBU(buVal)
        }
        if (valType === 'Country') {
            let countryVal = []
            if (val.length > 0) {
                for (let char of val)
                    countryVal.push(char.value)
            }
            setSelectedCountry(countryVal)
        }
        if (valType === 'Year') {
            let yearVal = []
            if (val.length > 0) {
                for (let char of val)
                    yearVal.push(char.value)
            }
            setSelectedYear(yearVal)
        }
        if (valType === 'Month') {
            let monthVal = []
            if (val.length > 0) {
                for (let char of val)
                    monthVal.push(char.value)
            }
            setSelectedMonth(monthVal)
        }
        setSelectedDropDown(valType)
    }

    const setSelectedListInParent = (value) => {
        let ids = []
        let lists = []
        for (let char of value) {
            ids.push(char.id)
            lists.push(char)
        }
        setCheckboxList(lists)
        setCheckboxId(ids)
    }
    const handleEditClickInParent = (row) => {
        if (row['cut_off'] == true){
            setOpenAddModal(false)
            toast.error('Cut off time is going on. You will NOT be able to Save any changes.', { position: 'top-center', className: 'cutoff-popup', duration: 1500, });
        }
        else{
            setOpenEditModal(true)
            let obj = {}
            for (let header of headers) {
                obj[header.key] = row[header.key]
            }
            obj['id'] = row.id
            setEditRow(obj)
        }

    }

    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }
    const closeEditModal = () => {
        setOpenEditModal(false)
    }
    const handleDeleteButton = () => {
        setOpenDeleteModal(true)
    }

    const handleDelete = () => {
        setCheckboxId([])
        setCheckboxList([])
        setOpenDeleteModal(false)
        toast.promise(dispatch(deleteStewardRow(toggleChecked === false ? checked.value : 'ci_partner_org', tableData, checkboxList, checkboxId)), {
            loading: "Loading. wait a moment!",
            success: 'Row(s) deleted successfully!',
            error: (err) => err.response.data.message.error,
        },
            {
                position: 'top-center',
                className: 'cutoff-popup',
                duration: 2500,
            });
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setSelectedMonth([])
    }
    const handleEdit = (rowData) => {
        setOpenEditModal(false)
        toast.promise(dispatch(editStewardRow(toggleChecked === false ? checked.value : 'ci_partner_org', tableData, rowData)), {
            loading: "Loading. wait a moment!",
            success: 'Row edited successfully!',
            error: 'Something went wrong.',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setSelectedMonth([])
    }

    const AddModalOpen = () => {
        setOpenAddModal(true)
    }

    const closeAddMOdal = () => {
        setOpenAddModal(false)
    }

    const handleAddModal = (row) => {
        toast.promise(dispatch(addStewardRow(toggleChecked === false ? checked.value : 'ci_partner_org', tableData, row)), {
            loading: "Loading. wait a moment!",
            success: 'Data load success.',
            error: (err) => err.response.data.message.error,
        },
            {
                position: 'top-center',
                className: 'cutoff-popup',
                duration: 2500,
            });
        setOpenAddModal(false)
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setSelectedMonth([])
    }
    const handleSelectChange = (event) => {
        setChecked(event);

    }
    const handleChange = (event) => {
        setToggleChecked(event.target.checked);
        dispatch({
            type: 'SHOW_TOAST',
            payload: {}
        })
        dispatch({
            type: 'SET_INITIAL_STATUS',
        })
    }

    function changeHeaderRank(header) {
        const arrOne = []
        const arrTwo = []
        header = header.filter(val => val.key !== "cut_off");
        header.map(val => {
            if (val.label == "Added_By" || val.label == "Updated_By" || val.label == "Created_Date" || val.label == "Updated_Date" || val.label == "added_by" || val.label == "updated_by" || val.label == "created_date" || val.label == "updated_date") {
                arrOne.push(val)
            }
            else {
                arrTwo.push(val)
            }
        })
        const seq = ["Created_Date", "created_date", "Added_By", "added_by", "Updated_Date","updated_date", "Updated_By","updated_by"];
        const newArr = []
        seq.map(val => {
            const head = header.filter(newval => newval.key == val);
            if (head?.length > 0) {
                newArr.push(head[0])
            }
        })
        const mergedArray = arrTwo.concat(newArr);
        return mergedArray
    }

    const headermapping = (header) => {
        const hea = {};
        header = changeHeaderRank(header);
        header.map(val => {
            const key = val.key;
            hea[key] = val.label;
        })
        return hea
    }

    // Map header names to values
    const mappedData = exportData.map(item => {
        const ndata = headermapping(headers); 
        if(Object.values(ndata).includes("added_by")) {
            ndata['added_by'] = 'created_by'
        }
        else{
            ndata['added_by'] = 'Created_by'
        }    
        return Object.keys(ndata).reduce((obj, key) => {
            obj[ndata[key]] = item[key];
            return obj;
        }, {});
    });

    const handleExport = () => {
        const wb = utils.book_new();
        mappedData.forEach((item) => {
            if (toggleChecked && typeof(item['No of partnering organisations']) === "string"){
                item['No of partnering organisations'] = parseFloat(item['No of partnering organisations'].replace(/,/g, ''))
            }
            else if(toggleChecked && typeof(item['No of partnering organisations']) !== "string"){
                item['No of partnering organisations'] = parseFloat(item['No of partnering organisations'])
            }
            else if (typeof(item['In Kind (eur)']) === "string" && typeof(item['Product Value (euro)']) === "string" && typeof(item['Product Volume (lts)']) === "string" && typeof(item['Cash (euro)']) === "string"){
                item['In Kind (eur)'] = parseFloat(item['In Kind (eur)'].replace(/,/g, ''))
                item['Product Value (euro)'] = parseFloat(item['Product Value (euro)'].replace(/,/g, ''))
                item['Product Volume (lts)'] = parseFloat(item['Product Volume (lts)'].replace(/,/g, ''))
                item['Cash (euro)'] = parseFloat(item['Cash (euro)'].replace(/,/g, ''))
            }
            else{
                item['In Kind (eur)'] = parseFloat(item['In Kind (eur)'])
                item['Product Value (euro)'] = parseFloat(item['Product Value (euro)'])
                item['Product Volume (lts)'] = parseFloat(item['Product Volume (lts)'])
                item['Cash (euro)'] = parseFloat(item['Cash (euro)'])
            }
        }
        )
        const ws = utils.json_to_sheet(mappedData);
        utils.book_append_sheet(wb, ws, 'Data');
        if(toggleChecked){
            writeFile(wb, 'PartneringOrganisation.xlsx');
        }else{
            writeFile(wb, `${checked.label}.xlsx`);
        }
        
    }

    return (
        <div>
            <div className="user-view-container">
                {year && year.length > 0 &&
                    <div style={{ marginTop: "-.5rem" }} className="col-10 brand-category-add-delete-export-button-container">
                        <span style={{ fontSize: ".85rem" }}>
                            Community Investment
                            <Switch
                                checked={toggleChecked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            Partnering Organisation
                        </span>
                    </div>
                }
                {toggleChecked === false && ciList.map((btn) => {
                    return (
                        <span style={{ marginLeft: ".5rem" }}
                            key={btn.value}>
                            <button style={{
                                margin: ".2rem",
                                backgroundColor: checked === btn ? "#FBC02D" : "white",
                                border: checked === btn ? "1px solid #FBC02D" : "1px solid #E0E0E0",
                                color: "black",
                                borderRadius: "5px",
                                fontSize: ".85rem",
                                width: "10rem",
                                height: "2rem"
                            }}
                                onClick={() => handleSelectChange(btn)}
                                value={btn.value} >
                                {btn.label}
                            </button >
                        </span>
                    )
                }
                )}
                <div className="brand-category-header-container row">
                    {region && region.length > 0 && bu && bu.length > 0 && country && country.length > 0 && toggleChecked === false &&
                        <div className="col-10 brand-category-filter-container">
                            <div className="ci-category-dropdown-container">
                                <MultiSelectDropDown title="Region" selected={selectedRegion.length === 0} options={region} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="ci-category-dropdown-container">
                                <MultiSelectDropDown title="BU" selected={selectedBU.length === 0} options={bu} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="ci-category-dropdown-container">
                                <MultiSelectDropDown title="Country" selected={selectedCountry.length === 0} options={country} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="ci-category-dropdown-container">
                                <MultiSelectDropDown title="Year" selected={selectedYear.length === 0} options={year} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="ci-category-dropdown-container">
                                <MultiSelectDropDown title="Month" selected={selectedMonth.length === 0} options={month} setParentDropdownValue={setDropDownValues} />
                            </div>
                            {/* <div style={{ margin: ".6rem 2rem .4rem 0rem", zIndex: 100, fontSize: ".75rem" }} className="ci-category-dropdown-container">
                                <span>Community Priorty </span>
                                <Select
                                    maxMenuHeight={200}
                                    defaultValue={checked}
                                    onChange={handleSelectChange}
                                    options={ciList}
                                />
                            </div> */}
                        </div>
                    }

                    {toggleChecked === true && year && year.length > 0 &&
                        <div className="col-10 brand-category-filter-container">
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Region" selected={selectedRegion.length === 0} options={region} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="BU" selected={selectedBU.length === 0} options={bu} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Country" selected={selectedCountry.length === 0} options={country} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Year" selected={selectedYear.length === 0} options={year} setParentDropdownValue={setDropDownValues} />
                            </div>
                        </div>
                    }
                    {year && year.length > 0 &&
                        <div className="col-2 brand-category-add-delete-export-button-container">
                                <button onClick={handleExport} className="brand-category-export-button mt-3 col" type="button" value="Export Data" >Export</button>
                            {checkboxList.length === 0 ?
                                <button className="brand-category-button mt-3 col" type="button" value="+ Add New" onClick={() => AddModalOpen(true)}><FontAwesomeIcon icon={faPlus} className="search-icon" size="1x" /> Add New</button>
                                : <button className="brand-category-button mt-3 col" type="button" value="Delete" onClick={handleDeleteButton}><FontAwesomeIcon icon={faTrash} className="search-icon" size="1x" /> Delete</button>}
                        </div>
                    }

                    {tableData && tableData.length > 0 &&
                        <div style={{ marginLeft: "-0.8rem", marginTop: "0.5rem" }} className="brand-category-table-container">
                            <TableList columns={toggleChecked === false ? colList : ciPartnerOrg}
                                channelCategoryMappingList={tableData}
                                setSelectedListInParent={setSelectedListInParent}
                                handleEditClickInParent={handleEditClickInParent}
                            //={props.formId} adminIds={adminIds} 
                            />
                        </div>
                    }
                </div>
                {openDeleteModal ?
                    <DeleteModal
                        message={checkboxList.length === EmissionList.length ?
                            "Entire table cannot be deleted " :
                            checkboxList.length > 0 ?
                                "Are you sure that you want to delete the selected row(s) ?" : "Please select atleast one row"}
                        openDialogueBox={openDeleteModal}
                        handleDeleteDialogueBox={closeDeleteModal}
                        handleDelete={handleDelete}
                        rowSelected={checkboxList.length > 0}
                        allSelected={checkboxList.length === EmissionList.length}
                    /> : ''}
                {openEditModal ?
                    <EditModal
                        message={"Edit"}
                        openDialogueBox={openEditModal}
                        handleEditDialogueBox={closeEditModal}
                        handleEdit={handleEdit}
                        rowSelected={editRow}
                        disabledOption={disabledOption}
                        type={toggleChecked === false ? 'ci' : 'part_org'}
                    /> : ''}
                {openAddModal ?
                    <AddModal
                        message={"Add the details"}
                        openDialogueBox={openAddModal}
                        handleAddDialogueBox={closeAddMOdal}
                        handleAdd={handleAddModal}
                        rowSelected={editRow}
                        disabledOption={disabledOption}
                        type={toggleChecked === false ? 'ci' : 'part_org'}
                    /> : ''}
            </div>
            <Toaster />
        </div>
    )
}

export default CommunityInvestment