import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RequestStatus1 from './requestStatus1';
import RequestStatus2 from './requestStatus2';
import RequestStatus3 from './requestStatus3';

const RequestStatusRoute = (props) => {
    const [adminIds, setAdminIds] = React.useState(localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')).user.form_admin_list : []);

    const loadComponent = (formId) => {
        switch (formId) {
            case 'formecomm':
                return <RequestStatus1 formId={formId} />
                break;
            case 'formsku':
                return <RequestStatus2 formId={formId} />
                break;
            case 'formmap':
                return <RequestStatus3 formId={formId} adminIds={adminIds} />;
                break;
            default:
                return;
        }
    }

    return (
        <>
            {
                loadComponent(props.formId)
            }
        </>
    );
};

const mapStateProps = (state) => {
    return {
        formId: state.sidebar.formId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateProps, mapDispatchToProps)(RequestStatusRoute);