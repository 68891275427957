import React from "react";
import "../App.css"
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faInfo, faSignOutAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';

export default function InfoDialogueBox(props) {
   const [open, setOpen] = React.useState(false);

   const handleInfoDialogueBox = () => {
      props.handleInfoDialogueBox();
   }

   return (
      <>
         {props.formId === "formsku" ?
            <Dialog open={props.isInfoDialogueBoxOpen} onClose={handleInfoDialogueBox}>
               <DialogContent style={{ maxWidth: '100%' }}>
                  <DialogTitle className="dialogue-box-description">
                     <b style={{ color: "#E61C2A " }}>SKU Exclusion List Cut Off Time Details: </b>
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     - <b>Exclusion Reason:</b> Open for changes every quarter from <u>Week 3 Monday to Friday of the same week</u>.
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     - <b>Narratives and Comments:</b> Open for changes every quarter from <u>Week 3 Monday till Week 4 Friday.</u>
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     - <b>Admins</b> will be able to update forms after cut off time.
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     <b style={{ color: "#E61C2A " }}>New Launches & delisting and STM:</b> No restrictions/cut off time applicable.
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">For more details, check
                     <u className="mx-2" style={{ color: "#E61C2A " }}>
                        <a target="_blank"
                           href="https://cchellenic.sharepoint.com/:w:/s/projects-BSS-TheEdge/EfmViVqA8oFNqsckwKOSvf0BaJ2XMHO-4lOXfCn6twtRzg?e=vuwaft">
                           BRC / Exploit document <OpenInNewIcon className="mx-1" /></a></u>
                  </DialogTitle>
               </DialogContent>
               <DialogActions className='dialogue-button-container'>
                  <button className="dialogue-button-submit" type="button" onClick={handleInfoDialogueBox}>Close</button>
               </DialogActions>
            </Dialog>
            :
            <Dialog open={props.isInfoDialogueBoxOpen} onClose={handleInfoDialogueBox}>
               <DialogContent style={{ maxWidth: '100%' }}>
                  <DialogTitle className="dialogue-box-description">
                     - Data source for Edge mapping is <b>CCH BW ( co044 report ).</b>
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     - <b>Brand Category Mapping</b> is based on category sap, brand name and summarized brand columns.
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     - <b>Channel Mapping</b> is based  CPL Master data using CH3 and CH4 BW grouping.
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     - <b>Package Mapping</b> is based on Pack size and Pack type material characteristics.
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     - <b>Company Mapping</b> is based on company code MD and includes additional grouping for total CCH.
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     - Mapping table is updated once per month <b>( Week 1 )</b> -centrally by <b>Group FP&A team.</b>
                  </DialogTitle>
               </DialogContent>
               <DialogActions className='dialogue-button-container'>
                  <button className="dialogue-button-submit" type="button" onClick={handleInfoDialogueBox}>Close</button>
               </DialogActions>
            </Dialog>
         }
         {props.formId === "custhier" ?
            <Dialog open={props.isInfoDialogueBoxOpen} onClose={handleInfoDialogueBox}>
               <DialogContent style={{ maxWidth: '100%' }}>
                  <DialogTitle style={{ marginBottom: "-25px" }} className="dialogue-box-description">
                     <b style={{ color: "#E61C2A " }}>Introduction </b>
                  </DialogTitle>
                  <DialogTitle style={{ marginBottom: '-25px', paddingLeft: "40px" }} className="dialogue-box-description">
                     The custom hierarchy application allows users to create localized hierarchies for each country,
                     tailored to specific "object types" such as Brand, Category, CPL, and more.
                  </DialogTitle>
                  <DialogTitle style={{ paddingLeft: "40px" }} className="dialogue-box-description">
                     This feature allows users to establish unique hierarchies/Groups for each object type based on the requirements of each country, providing a highly flexible and customizable solution for organizing
                     and managing data to derive country-specific insights.
                  </DialogTitle>
                  <DialogTitle style={{ marginBottom: "-25px" }} className="dialogue-box-description">
                     <b style={{ color: "#E61C2A " }}>Hierarchy Levels </b>
                  </DialogTitle>
                  <DialogTitle style={{ marginBottom: "-25px", paddingLeft: "40px" }} className="dialogue-box-description">
                     Users can create a hierarchy consisting of up to six levels, which can be subcategories or subgroups.
                  </DialogTitle>
                  <DialogTitle style={{ paddingLeft: "40px" }} className="dialogue-box-description">
                     The first level (Level 1) in the hierarchy is considered the parent category, while all subsequent levels are its child categories,
                     allowing for the grouping and organisation of related information or data.
                  </DialogTitle>
                  {/* <div className="login-box-header"> */}
                  <img className="levels-img" src="/levels.png" />

                  {/* </div> */}
                  <DialogTitle style={{ marginBottom: "-25px" }} className="dialogue-box-description">
                     <b style={{ color: "#E61C2A " }}>Data Sources </b>
                  </DialogTitle>
                  <DialogTitle style={{ marginBottom: "-25px", paddingLeft: "40px" }} className="dialogue-box-description">
                     All data is coming from Azure Enterprise Data Warehouse (Data Mesh). The custom hierarchy application utilises the material master and customer master tables. EDGE hierarchy is the default non-editable hierarchy available for all objects.
                  </DialogTitle>
                  <DialogTitle style={{ marginBottom: "-25px", paddingLeft: "40px" }} className="dialogue-box-description">
                     The object keys within these tables are filtered based on Invoiced UC over the last four years and current-year RCP values sourced from FSM042 (Values more than 0), Volume and EBIT sourced from CO012 (Values not equal to 0). This approach ensures that the object keys in the webforms are relevant to countries.
                  </DialogTitle>
                  <DialogTitle className="dialogue-box-description">
                     Detailed information about the sources used in this application is provided in the table below.
                  </DialogTitle>
                  <TableContainer component={Paper} style={{ width: '98%', marginLeft: "25px" }}>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: '#404040' }}>
                           <TableRow>
                              <TableCell style={{ color: 'white' }}><Typography variant="h6" fontWeight="bold">
                                 Object
                              </Typography></TableCell>
                              <TableCell align="left" style={{ color: 'white' }}><Typography variant="h6" fontWeight="bold">
                                 Data Table
                              </Typography></TableCell>
                              <TableCell align="left" style={{ color: 'white' }}><Typography variant="h6" fontWeight="bold">
                                 Data Column
                              </Typography></TableCell>
                              <TableCell align="left" style={{ color: 'white' }}><Typography variant="h6" fontWeight="bold">
                                 Edge Hierarchy
                              </Typography></TableCell>
                              {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           <TableRow
                              key=""
                              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              style={{ backgroundColor: '#F2F2F2' }}
                           >
                              <TableCell><Typography variant="h6" fontWeight="bold">
                                 Brand
                              </Typography></TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Material Master (ca_mat_03) </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>bic_cbrand</TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Brand Desc</TableCell>
                           </TableRow>
                           <TableRow
                              key=""
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell><Typography variant="h6" fontWeight="bold">
                                 Category
                              </Typography></TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Material Master (ca_mat_03) </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>bic_ccategory</TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Category Desc</TableCell>
                           </TableRow>
                           <TableRow
                              key=""
                              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              style={{ backgroundColor: '#F2F2F2' }}
                           >
                              <TableCell><Typography variant="h6" fontWeight="bold" >
                                 Category - Brand
                              </Typography></TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Material Master (ca_mat_03) </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>bic_ccpbrcat</TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Category, Brand Group (Mapping webform) </TableCell>
                           </TableRow>
                           <TableRow
                              key=""
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell><Typography variant="h6" fontWeight="bold">
                                 Package
                              </Typography></TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Material Master (ca_mat_03) </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>bic_cpackage </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Reported Pack (Mapping webform) </TableCell>
                           </TableRow>
                           <TableRow
                              key=""
                              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              style={{ backgroundColor: '#F2F2F2' }}
                           >
                              <TableCell><Typography variant="h6" fontWeight="bold">
                                 BAN
                              </Typography></TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Material Master (ca_mat_03) </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>bic_cpbartno </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>BAN Desc </TableCell>
                           </TableRow>
                           <TableRow
                              key=""
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell><Typography variant="h6" fontWeight="bold">
                                 CPL
                              </Typography></TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Customer Master (ca_cus_03) </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>bic_ccustpllv </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>CPL Desc </TableCell>
                           </TableRow>
                           <TableRow
                              key=""
                              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              style={{ backgroundColor: '#F2F2F2' }}
                           >
                              <TableCell><Typography variant="h6" fontWeight="bold">
                                 Sub Trade Channel
                              </Typography></TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Customer Master (ca_cus_03) </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>bic_ccusthie4 </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Subtrade Channel Ch4 (Mapping webform) </TableCell>
                           </TableRow>
                           <TableRow
                              key=""
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell><Typography variant="h6" fontWeight="bold">
                                 Trade Channel
                              </Typography></TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Customer Master (ca_cus_03) </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>cust_hie03 </TableCell>
                              <TableCell align="left" style={{ fontSize: '1.2rem' }}>Trade Channel </TableCell>
                           </TableRow>

                        </TableBody>
                     </Table>
                  </TableContainer>
                  <DialogTitle style={{ marginBottom: "-25px" }} className="dialogue-box-description">
                     <b style={{ color: "#E61C2A " }}>Refresh Frequency </b>
                  </DialogTitle>
                  <DialogTitle style={{ paddingLeft: "40px" }} className="dialogue-box-description">
                     The input data for this custom hierarchy webform (from DataMesh) undergoes a daily refresh at 7:00 AM to add any new object keys from the source data. The mapping provided in the webform is synced with the final Power BI reports four times daily (06.00, 09.00, 12.00, 15.00 CET) to ensure fast synchronisation of mapping updates.
                  </DialogTitle>
                  <DialogTitle style={{ marginBottom: "-25px" }} className="dialogue-box-description">
                     <b style={{ color: "#E61C2A " }}>User Guide </b>
                  </DialogTitle>
                  <DialogTitle style={{ paddingLeft: "40px" }} className="dialogue-box-description">A comprehensive user guide with step-by-step instructions to use, create, edit, and delete hierarchies is also provided for reference.
                     <u className="mx-2" style={{ color: "#E61C2A " }}>
                        <a target="_blank"
                           href="https://cchellenic.sharepoint.com/:b:/s/ReportingFactorysetup/EUo1oN9_fY1NmyWcrfPMevoBXzBwVQ0X99BaQZvFSlZwFA?e=mJgPYP">
                           Link: <OpenInNewIcon className="mx-1" /></a></u>
                  </DialogTitle>
               </DialogContent>
               <DialogActions className='dialogue-button-container'>
                  <button className="dialogue-button-submit" type="button" onClick={handleInfoDialogueBox}>Close</button>
               </DialogActions>
            </Dialog>
            : ""
         }
      </>);
}
