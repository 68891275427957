import React from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/autoconfig";
import Button from "react-bootstrap/Button";

const SignIn = () => {
    const handleLogin = (instance) => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    const { instance } = useMsal();

    return (
        <Button variant="secondary" className="ml-auto" onClick={() => handleLogin(instance)}>Sign in using Redirect</Button>
    );
};

export default SignIn;