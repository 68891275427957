import React from 'react';

const RequestStatus2 = () => {
    return (
        <div>
            <h1>RequestStatus2</h1>
        </div>
    );
};

export default RequestStatus2;