// import React, { useEffect, useState } from 'react';
// import '../App.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
// import { faCompass, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
// import Checkbox from '@mui/material/Checkbox';
// import { styled } from '@mui/material/styles';
// import { tableCellClasses } from '@mui/material/TableCell';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';

// import { grey, red, blue } from '@mui/material/colors';
// const tableHeadColour = '#F2F2F2';

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// //Material UI Styles
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: tableHeadColour,
//         color: theme.palette.common.black,
//         fontWeight: 600,
//         cursor: 'pointer',
//         padding: '0px',
//         zIndex: 1
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//         padding: '2px 0px'
//     },
//     // [`&:hover`]: {
//     //     backgroundColor: "blue !important"
//     // },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     [`&:hover`]: {
//         backgroundColor: "white !important"
//     },

//     // '&:nth-of-type(odd)': {
//     //   backgroundColor: theme.palette.action.hover,
//     // },
//     // hide last border
//     // '&:last-child td, &:last-child th': {
//     //   border: 0,
//     // },
// }));
// //Material UI Styles

// const TableBeyondCoreAssortment = (props) => {

//     const [masterChecked, setMasterChecked] = React.useState(false);
//     const [selectedList, setSelectedList] = React.useState([]);

//     // Select/Unselect Table rows
//     const onMasterCheck = (e) => {
//         let tempList = props.brandCategoryMappingList;

//         // Check/ UnCheck All Items
//         tempList.map((each) => (each.selected = e.target.checked));

//         setMasterChecked(e.target.checked);
//         // setBrandCategoryMappingList(tempList);
//         let newList = props.brandCategoryMappingList.filter((e) => e.selected)
//         setSelectedList(newList);
//         props.setSelectedListInParent(newList);
//     }

//     const onItemCheck = (e, row) => {
//         let tempList = props.brandCategoryMappingList;
//         tempList.map((each) => {
//             if (each.category_brand_id === row.category_brand_id){
//             each.selected = e.target.checked;
//             }
//             return each;
//         });

//         //To Control Master Checkbox State
//         const totalItems = props.brandCategoryMappingList.length;
//         const totalCheckedItems = tempList.filter((e) => e.selected).length;

//         setMasterChecked(totalItems === totalCheckedItems);
//         // setBrandCategoryMappingList(tempList);
//         let newList = props.brandCategoryMappingList.filter((e) => e.selected)
//         setSelectedList(newList);
//         props.setSelectedListInParent(newList);
//     }

//     const handleEditClick = (id) => {
//         props.handleEditClickInParent(id);
//     }

//     return (
//         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//             <TableContainer>
//                 <Table stickyHeader aria-label="sticky table">
//                     <TableHead>
//                         <StyledTableRow className="bold-font">
//                             <StyledTableCell
//                             >
//                                 <Checkbox id="mastercheck" checked={masterChecked} onChange={(e) => onMasterCheck(e)}
//                                     {...label}
//                                     // defaultChecked
//                                     size="small"
//                                     sx={{
//                                         '& .MuiSvgIcon-root': { fontSize: 20 },
//                                         color: grey[500],
//                                         '&.Mui-checked': {
//                                             color: red[500],
//                                         },
//                                     }} />
//                             </StyledTableCell>
//                             {props.columns.map((column) => (
//                                 <StyledTableCell
//                                     key={column.id}
//                                     align={column.align}
//                                     style={column.id == 'action' ? { position: 'sticky', right: 1, minWidth: column.minWidth, overflow: 'hidden', zIndex:1 } : { minWidth: column.minWidth }}
//                                     title="Click and wait to Sort"
//                                 >
//                                     {column.label}
//                                 </StyledTableCell>
//                             ))}
//                         </StyledTableRow>
//                     </TableHead>
//                     <TableBody>
//                         {props.brandCategoryMappingList
//                             .map((row) => {
//                                 debugger
//                                 return (
//                                     <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.kbi_id}>
//                                         <StyledTableCell>
//                                             <Checkbox id="rowcheck{row.kbi_id}" checked={row.selected} onChange={(e) => onItemCheck(e, row)}
//                                                 {...label}
//                                                 // defaultChecked
//                                                 size="small"
//                                                 sx={{
//                                                     '& .MuiSvgIcon-root': { fontSize: 20 },
//                                                     color: grey[500],
//                                                     '&.Mui-checked': {
//                                                         color: red[500],
//                                                     },
//                                                 }} />
//                                         </StyledTableCell>
//                                         {props.columns.map((column) => {
//                                             const value = row[column.id];
//                                             return (
//                                                 <StyledTableCell key={column.id} align={column.align}
//                                                     style={column.id === 'action' ?
//                                                         { position: 'sticky', right: 1, backgroundColor: 'white', overflow: 'hidden', color: '#E61C2A', minWidth: column.minWidth } :
//                                                         { minWidth: column.minWidth, minHeight: '10px' }}
//                                                 >
//                                                     {column.id === "action" ? <FontAwesomeIcon icon={faEdit} size="1x" onClick={() => handleEditClick(row.kbi_id)} /> : value}
//                                                 </StyledTableCell>
//                                             );
//                                         })}
//                                     </StyledTableRow>
//                                 );
//                             }
//                             )}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </Paper>
//     );
// };

// export default TableBeyondCoreAssortment;
import { Divider, Radio, Table } from 'antd';
import { useState } from 'react';


const TableBeyondCoreAssortment = (props) => {
  // rowSelection object indicates the need for row selection
  
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
   
    const onSelectChange = (newSelectedRowKeys) => {
     
        props.setSelectedListInParent(newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
      };
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

      const handleCellClick = (record, columnIndex) => {
        const columnName = props.columns[columnIndex].dataIndex;
        const columnValue = record[columnName];
        console.log(columnValue);
      };

      const columnsWithOnClick = props.columns.map((column, index) => ({
        ...column,
        onCell: (record) => ({
          onClick: () => handleCellClick(record, index),
        }),
      }));

  return (
    <div>
      <Table
        columns={props.columns}
        dataSource={props.data}
        rowSelection={rowSelection}
       className="ereailTable"
       pagination={false} 
      />
    </div>
  );
};
export default TableBeyondCoreAssortment;