import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import "../App.css"

const MultipleSelectCheckmarks = (props) => {
  const defaultOptions = [
    { label: "Grapes", value: "grapes" },
    { label: "Mango", value: "mango" },
    { label: "Strawberry", value: "strawberry", disabled: true },
    { label: "Watermelon", value: "watermelon" },
    { label: "Pear", value: "pear" },
    { label: "Apple", value: "apple" },
    { label: "Tangerine", value: "tangerine" },
    { label: "Pineapple", value: "pineapple" },
    { label: "Peach", value: "peach" },
  ];

  const overrideStrings = {
    "allItemsAreSelected": "All selected",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": props.showLabelInDropdown ? props.title : "--Select--"
  }

  // const [options, setOptions] = useState(props.options);
  const [selected, setSelected] = useState([]);

  const handleOnChange = (objs) => {
    setSelected(objs)
    props.setParentDropdownValue(objs, props.title)
  }

  return (
    <div>
      {props.hideTitle ? "" :
        <div>
          <label style={{ paddingLeft: "5px", fontSize: "small" }}>{props.title}</label>
        </div>
      }
      {/* <pre>{JSON.stringify(selected)}</pre> */}
      <MultiSelect
        className="multi-select-custom"
        options={props.options}
        value={selected}
        onChange={handleOnChange}
        labelledBy="Select"
        disableSearch={true}
        overrideStrings={overrideStrings}
      />
    </div>
  );
};

export default MultipleSelectCheckmarks;