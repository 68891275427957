import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import '../App.css';
import DialogueBox from './dialogueBox';
import SKUSingleSelectDropDown from './skuSingleSelectDropDown';
import { BASE_URL, BRAND_CATEGORY_MAPPINGS, CHANNEL_CATEGORY_MAPPINGS, INPUT_FORMS, INPUT_SUGGESTIONS, MFA, SKU_PERF, EXCLUSIONS, NEWLISTANDDELIST, STMS, WEEKS, CATEGORIES, AVAILABLE_QUARTERS, USERS, BUSINESS_UNITS, ACCOUNTS } from '../constant/apiEndpoints';
import APIService from '../services/APIService';
import AutoSuggestInput from './autoSuggestInput';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    height: '94vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
    // overflow: 'auto',
    // display: 'flex'
};

const SKUSTMFormModal = (props) => {

    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [availableQuarterList, setAvailableQuarterList] = useState([]);
    const [onTimeList, setOnTimeList] = useState([{ "id": "No", "title": "No" }, { "id": "Yes", "title": "Yes" }]);
    const [actualLaunchDateList, setActualLaunchDateList] = useState([]);

    const [selectedBusinessUnitList, setSelectedBusinessUnitList] = useState([]);
    const [selectedAvailableQuarterList, setSelectedAvailableQuarterList] = useState([]);
    const [selectedOnTimeList, setSelectedOnTimeList] = useState([]);
    const [selectedProjectDurationList, setSelectedProjectDurationList] = useState([]);
    const [selectedActualLaunchDateList, setSelectedActualLaunchDateList] = useState([]);

    const [projectDescription, setProjectDescription] = useState('');
    const [actualLaunchDate, setActualLaunchDate] = useState('');
    const [projectDuration, setProjectDuration] = useState('');
    const [delayReason, setDelayReason] = useState('');

    const [isEdit, setIsEdit] = React.useState(false);
    const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
    const [messageType, setMessageType] = React.useState("");
    const [message, setMessage] = React.useState("");

    //Dynamic Input onChange Handler
    const inputOnChange = (e) => {
        // const re = /^[0-9]*\.?[0-9]*$/;;
        const value = e.target.value;
        const name = e.target.name;

        if (name === 'project_description') {
            setProjectDescription(value)
        }
        if (name === 'project_duration') {
            setProjectDuration(value)
        }
        if (name === 'delay_reason') {
            setDelayReason(value)
        }
    }

    const handleDialogueBox = () => {
        setOpenDialogueBox(!openDialogueBox)
    }

    const showDialogueBox = (msgType, msg) => {
        setMessage(msg);
        setMessageType(msgType);
        handleDialogueBox();
    }

    const handleOnClose = (value) => {
        props.handleIsFormModalOpen(value)
    }

    const getPayloadData = () => {
        var obj = {}
        obj.year = Number(selectedAvailableQuarterList.slice(0, 4));
        obj.time_frame = selectedAvailableQuarterList.slice(4, 6);
        obj.business_unit = selectedBusinessUnitList;
        obj.project_description = projectDescription;
        obj.is_on_time = selectedOnTimeList;
        obj.form_id = props.formId;

        if (selectedActualLaunchDateList && typeof (selectedActualLaunchDateList) !== "object") {
            obj.actual_launch_date = actualLaunchDateList.find(x => x.id === selectedActualLaunchDateList).title;
        }

        if (delayReason) obj.delay_reason = delayReason;
        obj.project_duration = projectDuration;

        return obj
    }

    const addNewData = async (e) => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS
        let response = await new APIService().post(url, getPayloadData())
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                    // showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                    showDialogueBox("Error", "All Fields Are Required To Submit!")
                }
            }
        } else {
            toast.success('New data added Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.postSubmitAndEditData();
            handleOnClose(false);
        }
    }

    const editExistingData = async (e) => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS + props.selectedIdToEdit + '/'
        let response = await new APIService().patch(url, getPayloadData())
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                    showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                }
            }
        } else {
            toast.success('Data updated Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.postSubmitAndEditData();
            handleOnClose(false);
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        toast.loading('Submitting, Please Wait!',
            {
                position: 'top-center',
                className: 'toast-popup',
                duration: 1000,
            });

        if (props.selectedIdToEdit !== undefined) {
            editExistingData(e);
        } else {
            addNewData(e);
        }
    }

    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Business Unit") {
            setSelectedBusinessUnitList(objs)
        }
        else if (valueType === "Available Quarter") {
            setSelectedAvailableQuarterList(objs)
        }
        else if (valueType === "On Time") {
            setSelectedOnTimeList(objs)
            if (objs === "yes") {
                setDelayReason("")
            }
        }
        else if (valueType === "Actual Launch Date") {
            setSelectedActualLaunchDateList(objs)
        }
    }

    const loadBusinessUnitList = async () => {

        let url = BASE_URL + ACCOUNTS + USERS + BUSINESS_UNITS + `?form_id=${props.formId}`
        let response = await new APIService().get(url)
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setBusinessUnitList(response.results);
            }
        }
    }

    const loadAvailableQuarterList = async () => {

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + AVAILABLE_QUARTERS + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // const transformedData = response.results.map(({ label, value }) => ({ id: value, title: label }));
                setAvailableQuarterList(response.results);
            }
        }
    }

    const loadActualLaunchDateList = async () => {

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + STMS + WEEKS + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // const transformedData = response.results.map(({ label, value }) => ({ id: value, title: label }));
                setActualLaunchDateList(response.results);
            }
        }
    }

    const loadSuggestionData = () => {
        loadBusinessUnitList();
        loadAvailableQuarterList();
        loadActualLaunchDateList();
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (props.selectedIdToEdit !== undefined) {
                setSelectedBusinessUnitList(props.selectedObjToEdit.business_unit)
                setSelectedAvailableQuarterList(props.selectedObjToEdit.quarter_id)
                setSelectedOnTimeList(props.selectedObjToEdit.is_on_time)
                if (props.selectedObjToEdit.actual_launch_date) setSelectedActualLaunchDateList(props.selectedObjToEdit.actual_launch_date.split(" ")[1])
                setProjectDescription(props.selectedObjToEdit.project_description)
                setProjectDuration(props.selectedObjToEdit.project_duration)
                setDelayReason(props.selectedObjToEdit.delay_reason)
                setIsEdit(true);
            }
            toast.loading('Loading, Please Wait!',
                {
                    position: 'top-center',
                    className: 'toast-popup',
                    duration: 2000,
                });
            loadSuggestionData();
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div>
            <Modal
                open={props.isFormModalOpen}
                // onClose={() => props.handleIsFormModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="brand-category-modal-form-header">
                        {isEdit ?
                            <p>ID : {props.selectedIdToEdit}</p>
                            : <p>Add New Entry</p>}
                        <div className="brand-category-modal-form-close-button" onClick={() => handleOnClose(false)}>
                            X
                        </div>
                    </div>
                    <div className="brand-category-modal-form-body">
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-4 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Quarter</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedAvailableQuarterList.length > 0 && isEdit &&
                                    <SKUSingleSelectDropDown title="Available Quarter" options={availableQuarterList}
                                        preSelectedValue={selectedAvailableQuarterList}
                                        setParentDropdownValue={setDropDownValues}
                                        isNonEditableField={false} />}
                                {!isEdit &&
                                    <SKUSingleSelectDropDown title="Available Quarter" options={availableQuarterList}
                                        setParentDropdownValue={setDropDownValues} />}
                            </div>
                            {/* </div> */}
                            <div className="brand-category-modal-form-input col-4 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Business Unit</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedBusinessUnitList.length > 0 && isEdit &&
                                    <SKUSingleSelectDropDown title="Business Unit" options={businessUnitList}
                                        preSelectedValue={selectedBusinessUnitList}
                                        setParentDropdownValue={setDropDownValues}
                                        isNonEditableField={false} />}
                                {!isEdit &&
                                    <SKUSingleSelectDropDown title="Business Unit" options={businessUnitList}
                                        setParentDropdownValue={setDropDownValues} />}

                            </div>
                            <div className="brand-category-modal-form-input col-4 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Project Description</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <input className="sku-stm-input-text-box"
                                    name='project_description'
                                    value={projectDescription}
                                    onChange={inputOnChange}
                                    // disabled={inputDisabled}
                                    type='text'
                                    step="any"
                                    min='0' />
                            </div>

                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">

                            <div className="brand-category-modal-form-input col-2"></div>
                        </div>

                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-4 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Actual Launch Date</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {isEdit &&
                                    <SKUSingleSelectDropDown title="Actual Launch Date" options={actualLaunchDateList}
                                        preSelectedValue={selectedActualLaunchDateList}
                                        setParentDropdownValue={setDropDownValues}
                                        isNonEditableField={false} />}
                                {!isEdit &&
                                    <SKUSingleSelectDropDown title="Actual Launch Date" options={actualLaunchDateList}
                                        setParentDropdownValue={setDropDownValues} />}
                            </div>
                            <div className="brand-category-modal-form-input col-4 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>On Time</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedOnTimeList.length > 0 && isEdit &&
                                    <SKUSingleSelectDropDown title="On Time" options={onTimeList}
                                        preSelectedValue={selectedOnTimeList}
                                        setParentDropdownValue={setDropDownValues}
                                        isNonEditableField={false} />}
                                {!isEdit &&
                                    <SKUSingleSelectDropDown title="On Time" options={onTimeList}
                                        setParentDropdownValue={setDropDownValues} />}
                            </div>
                            <div className="brand-category-modal-form-input col-4 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Delay Reason</p>
                                </div>
                                <input className="sku-stm-input-text-box"
                                    name='delay_reason'
                                    value={delayReason}
                                    onChange={inputOnChange}
                                    disabled={selectedOnTimeList !== "No"}
                                    type='text'
                                    step="any"
                                    min='0' />
                            </div>
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">

                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-4 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Project Duration(In Weeks)</p>
                                </div>
                                <input className="sku-stm-input-text-box"
                                    name='project_duration'
                                    value={projectDuration}
                                    onChange={inputOnChange}
                                    // disabled={inputDisabled}
                                    placeholder=""
                                    type='number'
                                    step="any"
                                    min='0' />
                            </div>

                        </div>
                    </div>
                    <div className="brand-category-modal-form-footer row m-0">
                        <div className="brand-category-modal-form-input col-6">
                            {/* <div style={{ display: "flex" }}>
                                <p>Comment</p><p style={{ color: "#E61C2A" }}>*</p>
                            </div>

                            <textarea className="brand-category-text-input-box"
                                style={{ width: "100%" }}
                                rows="4" cols="50"
                                name="comment"
                                value={dataObject.comment}
                                onChange={inputOnChange}
                            /> */}
                        </div>
                        <div className="brand-category-modal-form-input col-6">
                            <div style={{ float: "right" }}>
                                <button className="brand-category-form-modal-cancel-button mt-3" onClick={() => handleOnClose(false)}>Cancel</button>
                                <button className="brand-category-form-modal-submit-button mt-3" onClick={handleOnSubmit}>Submit</button>
                            </div>
                        </div>

                    </div>
                </Box>
            </Modal>
            {/* Message Dialogue Box */}
            {openDialogueBox ? <DialogueBox openDialogueBox={openDialogueBox}
                handleDialogueBox={handleDialogueBox}
                messageType={messageType}
                message={message} /> : ""}
            {/* Message Dialogue Box */}
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    )
};

export default SKUSTMFormModal;

