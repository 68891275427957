import React, { useState } from 'react'
import '../App.css'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const AutoSuggestInput = (props) => {
  const items = [
    {
      id: 0,
      name: 'Cobol'
    },
    {
      id: 1,
      name: 'JavaScript'
    },
    {
      id: 2,
      name: 'Basic'
    },
    {
      id: 3,
      name: 'PHP'
    },
    {
      id: 4,
      name: 'Java'
    }
  ]

  const movieItems = [
    {
      id: 0,
      title: "Titanic",
      description: "A movie about love",
    },
    { 
      id: 1,
      title: "Dead Poets Society",
      description: "A movie about poetry and the meaning of life",
    },
    {
      id: 2,
      title: "Terminator 2",
      description: "A robot from the future is sent back in time",
    },
    {
      id: 3,
      title: "Alien 2",
      description: "Ripley is back for a new adventure",
    },
  ];

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    props.autoSuggestOnChange(props.name, string)
  }

  const handleOnHover = (result) => {
    // the item hovered
  }

  const handleOnSelect = (item) => {
    // the item selected
    props.autoSuggestOnChange(props.name, item.title)
  }

  // const handleOnFocus = () => {
  //   console.log('Focused')
  // }

  const handleOnClear = () => {
    props.autoSuggestOnChange(props.name, "")
  };

  // const formatResult = (item) => {
  //   return (
  //     <div className="result-wrapper">
  //       <span className="result-span">id: {item.id}</span>
  //       <span className="result-span">name: {item.name}</span>
  //     </div>
  //   );
  // };

  return (
    <div className="brand-category-text-suggest-input-box-container">
          <ReactSearchAutocomplete
            className="brand-category-text-suggest-input-box"
            // placeholder={props.placeholder}
            items={props.dataList}
            fuseOptions={{ keys: ["title"] }} // Search on both fields
            resultStringKeyName="title" // String to display in the results
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            // onFocus={handleOnFocus}
            onClear={handleOnClear}
            showIcon={false}
            inputSearchString={props.currentValue}
            // formatResult={formatResult}
            styling={{
              height: "5vh",
              width: "10vw !important",
              borderRadius: "4px",
              backgroundColor: "#FFFFFF",
              boxShadow: "none",
              fontSize: "2vh",
              fontFamily: "inherit",
              textDecoration: "none",
              textOverflow: "ellipsis",
              resize: "none",
              border: "1px solid #C4C4C4",
              boxSizing: "border-box",
              // iconColor: "green",
              // lineColor: "lightgreen",
              // placeholderColor: "darkgreen",
              // clearIconMargin: "3px 8px 0 0",
              zIndex: 2,
            }}
          />
    </div>
  )
}

export default AutoSuggestInput;