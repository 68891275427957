
import React from 'react';
import {  Table, Form   } from 'antd';
import Paper from "@material-ui/core/Paper";
// import { useVT } from 'virtualizedtableforantd4';
function TableManualAdjustment({columns, data, form, components, rowClass, onChange, filteredInfo}) {
  // const [ vt, set_components ] = useVT(() => ({ scroll: { y: 600 } }), []);
  
  
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Form form={form}>
    <Table  
     columns={columns} 
     dataSource={data} 
     components={components}
    rowClassName={() => rowClass}
    scroll={{ x: 1300 }} 
    pagination={{ pageSize: 50}}
    onChange={onChange}
    filteredInfo={filteredInfo}

    size="small"  />
    </Form>
    </Paper>
  )
}

export default TableManualAdjustment