import React, { useEffect, useState } from 'react';

import { faUpload, faDownload, faEdit, faTrashAlt, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import { useCSVReader } from "react-papaparse";
// import TableDemo from './customTable'
import CustomHierarchyUser from './reactSearch'
import { CSVLink } from "react-csv";
import DeleteModal from './DeleteModal';
import { BASE_URL, CUST_HIER, IMPORT_DATA, TABLE_DATA, DELETE_DATA, COUNTRY_LIST, HIERARCHY_LIST, EXPORT_DATA, FILTERS, OBJECT_LIST } from '../../constant/apiEndpoints';
import APIService from '../../services/APIService';
import CustomHierarchyDropdown from './customHierarchyDropdown';
import CustomHierarchyAddFormModal from './customHierAddFormModal';
import CustomHierarchyEditMappingModal from './editHierarchyMappingModal';
import Spinner from 'react-bootstrap/Spinner';
import DialogueBox from '../../components/dialogueBox';

const CustomHierarchy = (props) => {
    const [userHasAccess, setUserHasAccess] = useState(false);
    const [countryList, setCountryList] = React.useState([]);
    const [hierarchyList, setHierarchyList] = useState([]);
    const [objectTypeList, setObjectTypeList] = React.useState([]);
    const [selectedHierarchy, setSelectedHierarchy] = React.useState("EDGE Hierarchy");
    const [selectedCountryID, setSelectedCountryID] = React.useState("");
    const [selectedCountryTitle, setSelectedCountryTitle] = React.useState([]);
    const [selectedObjectID, setSelectedObjectID] = React.useState();
    const [selectedObjectTitle, setSelectedObjectTitle] = React.useState([]);
    const [tableData, setTableData] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [columns, setColumns] = useState([]); //Columns Handle
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [iseditFormModalOpen, setIsEditFormModalOpen] = useState(false);
    const [editDataObj, setEditDataObj] = useState({});
    const { CSVReader } = useCSVReader();
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
    const [messageType, setMessageType] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [disable, setDisable] = React.useState(false);
    const [headers, setHeaders] = useState([]);
    const [objectTypeID, setObjectTypeID] = useState();

    //AJAX Call To Load Countries
    const loadCountries = async () => {
        const ad_group_ids = localStorage.getItem("userList")
        let url = BASE_URL + CUST_HIER + COUNTRY_LIST
        let response = await new APIService().post(url, ad_group_ids);
        if (response.error) {
            console.log("error in user countrylist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setCountryList(response.results);
                setSelectedCountryID(response.results[0].id)
                setSelectedCountryTitle([response.results[0]])
                loadObjectType(response.results[0].id);
            } else {
                showDialogueBox("No Data", 'No countries have been assigned for this user');
                setLoading(false);
            }
        }
    }

    //AJAX Call To Load Countries
    const loadObjectType = async (country_id) => {
        let url = BASE_URL + CUST_HIER + OBJECT_LIST + `?country=${country_id}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user hierarchylist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setObjectTypeList(response.results);
                setObjectTypeID(response.results[0].id)
                setSelectedObjectID(response.results[0].title);
                setSelectedObjectTitle([response.results[0]]);
                loadHierarchy(country_id, response.results[0].title);
            } else {
                showDialogueBox("No Data", 'No object types exist for this country');
                setLoading(false);
            }
        }
    }

    //AJAX Call To Load Countries
    const loadHierarchy = async (country_id, object_id) => {
        let url = BASE_URL + CUST_HIER + HIERARCHY_LIST + `?country=${country_id}&object_type=${object_id}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user hierarchylist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setHierarchyList(response.results);
                loadTableData(country_id, selectedHierarchy, object_id);
                loadExportData(country_id, selectedHierarchy, object_id);
            } else {
                showDialogueBox("No Data", 'No hierarchy list exist for this country and object type');
            }
            setLoading(false);
        }
    }

    const loadObjectTypeList = async (country) => {
        let url = BASE_URL + CUST_HIER + FILTERS + `?filter_type=object_type&country=${country}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Category List API", response.results.message)
        } else {
            setObjectTypeList(response.results);
            loadHierarchyList(country, selectedObjectID)
        }
    }

    const loadHierarchyList = async (country, object_type) => {

        let url = BASE_URL + CUST_HIER + FILTERS + `?filter_type=hierarchy_name&country=${country}&object_type=${object_type}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Category List API", response.results.message)
        } else {
            setLoading(true)
            setSelectedHierarchy("EDGE Hierarchy")
            setHierarchyList(response.results);
            setLoading(false)
            loadTableData(country, "EDGE Hierarchy", object_type)
            loadExportData(country, "EDGE Hierarchy", object_type)
        }
    }

    //AJAX Call To Load Countries
    const loadHierarchyListAfterAddEdit = async (country_id, object_id) => {
        let url = BASE_URL + CUST_HIER + HIERARCHY_LIST + `?country=${country_id}&object_type=${object_id}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user hierarchylist API", response.results.message)
        } else {
            setHierarchyList(response.results);
        }
    }

    const setDropDownValues = (id, title, valueType) => {
        if (valueType === "Country") {
            setSelectedCountryID(id);
            setSelectedCountryTitle([{
                "id": id,
                "title": title,
                "comp_code": ""
            }]);
            loadObjectTypeList(id)

        }
        else if (valueType === "Object Type") {
            setObjectTypeID(id)
            setSelectedObjectID(title);
            setSelectedObjectTitle([{
                id: id,
                title: title
            }]);
            loadHierarchyList(selectedCountryID, title)
        }
        else if (valueType === "Hierarchy Version") {
            if (title === "EDGE Hierarchy") {
                setSelectedHierarchy(title);
                setDisable(true);
                loadTableData(selectedCountryID, title, selectedObjectID);
                loadExportData(selectedCountryID, title, selectedObjectID);
            }
            else {
                setSelectedHierarchy(title);
                setDisable(false);
                loadTableData(selectedCountryID, title, selectedObjectID)
                loadExportData(selectedCountryID, title, selectedObjectID)
            }
        }
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadCountries();
        }
        return function cleanup() {
            mounted = false
        }
    }, [])


    const handleDialogueBox = () => {
        setOpenDialogueBox(!openDialogueBox)
    }

    const showDialogueBox = (msgType, msg) => {
        setMessage(msg);
        setMessageType(msgType);
        handleDialogueBox();
    }

    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }
    
    const handleDeleteButton = () => {
        setOpenDeleteModal(true)
    }

    const handleDelete = async () => {
        let url = BASE_URL + CUST_HIER + DELETE_DATA + `?form_id=${'custhier'}&country=${selectedCountryID}&object_type=${selectedObjectID}&hierarchy_name=${selectedHierarchy}`
        let response = await new APIService().post(url);
        if (!response.error) {
            toast.success('Data Deleted Successfully.', { position: 'top-center', className: 'toast-popup', duration: 2000, });
            handleIsDeleteFormModalClose(response.results.country, response.results?.object_type, response.results.hierarchy)
        }
    }

    //AJAX Call To Load Countries
    const loadTableData = async (country_id, hierarchy_name, object_type) => {
        setIsTableLoading(true);
        let url = BASE_URL + CUST_HIER + TABLE_DATA + `?selected_form=${'custhier'}&country=${country_id}&object_type=${object_type}&hierarchy_name=${hierarchy_name}`
        let response = await new APIService().get(url);
        if (response.error === false && response.results.message) {
            setUserHasAccess(true)
            setTableData([])
            setColumns([])
            setDisable(true)
            setIsTableLoading(false)
            showDialogueBox("No Data", response.results.message)
        } else if (response.error && response.results.message) {
            setUserHasAccess(false)
            setTableData([])
            setColumns([])
            setDisable(true)
            setIsTableLoading(false)
            showDialogueBox("No Data", response.results.message)
        } else if (response.results) {
            setUserHasAccess(true)
            setDisable(hierarchy_name === "EDGE Hierarchy")
            setTableData(response.results.data)
            setColumns(response.results.column)
            setIsTableLoading(false)
        } else {
            setUserHasAccess(true)
            setTableData([])
            setColumns([])
            setDisable(true)
            setIsTableLoading(false)
            showDialogueBox("Error", "Something Went Wrong!")
        }
    }

    //AJAX Call To Load Countries
    const loadExportData = async (country_id, hierarchy_name, object_type) => {
        let url = BASE_URL + CUST_HIER + EXPORT_DATA + `?form_id=${'custhier'}&country=${country_id}&object_type=${object_type}&hierarchy_name=${hierarchy_name}`
        let response = await new APIService().get(url);
        if (response.results.message) {
            setHeaders([]);
            showDialogueBox("No Data", response.results.message);
        }
        else if (response.results) {
            setHeaders(response.results.column)
        }
        else {
            setHeaders([]);
            showDialogueBox("Error", "Something Went Wrong!");
        }
    }

    const onSubmitDataFromImport = async (arr, isSaved = true) => {
        let url = BASE_URL + CUST_HIER + IMPORT_DATA + `?form_id=${'custhier'}&country=${selectedCountryID}&object_type=${selectedObjectID}&hierarchy_name=${selectedHierarchy}`;
        let response = await new APIService().post(url, arr);
        if (response.error) {
            if (response.status === 400) {
                setIsTableLoading(false);
                showDialogueBox(response.results.message);
            }
        } else if (response.results) {
            setIsTableLoading(false);
            loadTableData(response.results.country, response.results.hierarchy, response.results.object_type);
            loadExportData(response.results.country, response.results.hierarchy, response.results.object_type)
            toast.success('Data Updated Successfully.', { position: 'top-center', className: 'toast-popup', duration: 2000, });
        }
    };

    const handleEditButton = (isOpen) => {
        setIsEditFormModalOpen(isOpen)
    }
    const handleIsFormModalClose = (value, country, objtype, hier) => {
        loadHierarchyListAfterAddEdit(country, objtype)
        setSelectedCountryID(country)
        setObjectTypeID(objtype.toLowerCase());
        setSelectedHierarchy(hier)
        setLoading(true)
        setIsFormModalOpen(value)
        setLoading(false)
        loadTableData(country, hier, objtype)
        loadExportData(country, hier, objtype)
    }

    const handleIsEditFormModalClose = (value, country, objtype, hier) => {
        loadHierarchyListAfterAddEdit(country, objtype)
        setSelectedCountryID(country)
        setObjectTypeID(objtype.toLowerCase())
        setSelectedHierarchy(hier)
        setLoading(true)
        setIsEditFormModalOpen(value)
        setLoading(false)
        loadTableData(country, hier, objtype)
        loadExportData(country, hier, objtype)

    }
    const handleIsDeleteFormModalClose = (country, objtype, hier) => {
        loadHierarchyList(country, objtype)
        setSelectedCountryID(country)
        setObjectTypeID(objtype.toLowerCase())
        setSelectedHierarchy(hier)
        setLoading(true)
        closeDeleteModal()
        setLoading(false)
        loadTableData(country, hier, objtype)
        loadExportData(country, hier, objtype)

    }

    const handleAddFormCancel = (value) => {
        setIsFormModalOpen(value)
    }
    const handleEditFormCancel = (value) => {
        setIsEditFormModalOpen(value)
    }


    //AJAX Call To Load Countries
    const handleTableData = async (data) => {
        let url = BASE_URL + CUST_HIER + TABLE_DATA + `?form_id=${'custhier'}&country=${selectedCountryID}&object_type=${selectedObjectID}&hierarchy_name=${selectedHierarchy}`
        let response = await new APIService().post(url, data);
        if (response.error) {
            console.log("error in user countrylist API", response.results.message)
        } else {
            if (response.results.message)
                showDialogueBox("No Change", response.results.message)
            else {
                loadTableData(response.results.country, response.results.hierarchy, response.results.object_type)
                loadExportData(response.results.country, response.results.hierarchy, response.results.object_type)
                toast.success('Data Updated Successfully.', { position: 'top-center', className: 'toast-popup', duration: 2000, });
            }
        }
    }


    const handleIsFormModalOpen = (value, country, object_type, hierarchy_name) => {
        setIsFormModalOpen(value);
    }

    const converToTableData = async (data) => {
        const newArray = data[0];
        let newArr = [];
        let dataNew = data.filter((val, i) => i !== 0);
        dataNew = dataNew.filter((val, i) => i !== dataNew.length);
        dataNew.map((val) => {
            let result = val.reduce(function (result, field, index) {
                result[newArray[index]] = field;
                return result;
            }, {});
            newArr.push(result);
        });
        newArr.push(newArray)
        await onSubmitDataFromImport(newArr, false);
    };
    const modifiedData = tableData.map((item) => {
        return {
            ...item,
            object_key: `'${item.object_key}'`, // add apostrophe prefix
        };
    });
    return loading ? (
        <div className="table-data-note">
            <Spinner animation="border" />
        </div>
    ) : (
        <div>
            <div className="user-view-container">
                {/* <h5 style={{ marginLeft: "30rem" }}>This is 2017 emissions baseline</h5> */}
                {userHasAccess ?
                    <div className="brand-category-header-container row">
                        <div className="ch-ft col-10 brand-category-filter-container">
                            <div className="cst-hr brand-category-dropdown-container">
                                <CustomHierarchyDropdown title="Country" options={countryList}
                                    preSelectedValue={selectedCountryID}
                                    setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="cst-hr brand-category-dropdown-container">
                                <CustomHierarchyDropdown title="Object Type" options={objectTypeList}
                                    preSelectedValue={objectTypeID}
                                    setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="hier cst-hr brand-category-dropdown-container">
                                <CustomHierarchyDropdown title="Hierarchy Version" options={hierarchyList}
                                    preSelectedValue={selectedHierarchy}
                                    setParentDropdownValue={setDropDownValues} />
                            </div>
                        </div>
                        <div className="ch-act col-2 brand-category-add-delete-export-button-container">
                            <CSVReader
                                onUploadAccepted={async (results) => {
                                    if (results.data) {
                                        setIsTableLoading(true);
                                        await converToTableData(results.data);

                                    }
                                }}
                            >
                                {({ getRootProps, acceptedFile, ProgressBar }) => (
                                    <>
                                        <button className="ch-btn brand-category-button mt-3 col" type="button" value="Import Data"
                                            // icon={<CaretUpFilled />}
                                            style={{ backgroundColor: !disable ? "#E61C2A" : "#9B9595" }}
                                            disabled={disable}
                                            {...getRootProps()}
                                        >
                                            <FontAwesomeIcon icon={faUpload} className="search-icon" size="1x" /> Import
                                            <span className="hover-button"><p>Import data from excel file for the currently selected object, country and hierarchy</p></span>
                                        </button>
                                        <div className="imp-fl">
                                            {acceptedFile && acceptedFile.name}
                                            <ProgressBar />
                                        </div>
                                    </>
                                )}
                            </CSVReader>
                            <CSVLink filename='customhierarchy' headers={headers} data={modifiedData}>
                                <button className="ch-btn brand-category-button mt-3 col" type="button" value="Export Data">
                                    <FontAwesomeIcon icon={faDownload} className="search-icon" size="1x" /> Export
                                    <span className="hover-button"><p>Export the data in csv for the currently selected object, country and hierarchy</p></span>
                                </button>
                            </CSVLink>
                            <button className="ch-btn brand-category-button mt-3 col" type="button" value="Delete Data" style={{ backgroundColor: !disable ? "#E61C2A" : "#9B9595" }}
                                disabled={disable} onClick={handleDeleteButton}>
                                <FontAwesomeIcon icon={faTrashAlt} className="search-icon" size="1x" /> Delete
                                <span className="hover-button"><p>Delete currently selected hierarchy</p></span>
                            </button>

                            <button className="ch-btn brand-category-button mt-3 col" type="button" value="Edit Data" style={{ backgroundColor: !disable ? "#E61C2A" : "#9B9595" }}
                                disabled={disable} onClick={() => handleEditButton(true)}>
                                <FontAwesomeIcon icon={faEdit} className="search-icon" size="1x" /> Edit
                                <span className="hover-button"><p>Edit parameters for current hierarchy</p></span>
                            </button>
                            <button className="ch-btn brand-category-button mt-3 col" type="button" value="Add Data" onClick={() => handleIsFormModalOpen(true)}>
                                <FontAwesomeIcon icon={faPlusSquare} className="search-icon" size="1x" /> Add
                                <span className="hover-button"><p>Add new hierarchy for this object and country</p></span>
                            </button>
                        </div>

                        {isTableLoading ? (
                            <div className="table-data-note">
                                <Spinner animation="border" />
                            </div>
                        ) : (
                            <CustomHierarchyUser
                                columns={columns}
                                rowData={tableData}
                                handleTableData={handleTableData}
                                disable={disable}

                            />
                        )}
                    </div>
                    : ""}
                {openDeleteModal ?
                    <DeleteModal
                        message="Are you sure that you want to delete the currently selected Hierarchy ?"
                        openDialogueBox={openDeleteModal}
                        handleDeleteDialogueBox={closeDeleteModal}
                        handleDelete={handleDelete}
                    /> : ''}
                {isFormModalOpen ?
                    <CustomHierarchyAddFormModal isFormModalOpen={isFormModalOpen} handleIsFormModalClose={handleIsFormModalClose}
                        formId={props.formId} objectType={objectTypeList} country={countryList} hierarchy={hierarchyList}
                        preSelectedObjectType={objectTypeID} preSelectedCountry={selectedCountryID} preSelectedHierarchy={selectedHierarchy}
                        setParentDropdownValue={setDropDownValues} handleAddFormCancel={handleAddFormCancel}
                    />
                    : ""}
                {iseditFormModalOpen ?
                    <CustomHierarchyEditMappingModal isFormModalOpen={iseditFormModalOpen} handleIsEditFormModalClose={handleIsEditFormModalClose}
                        formId={props.formId} objectType={selectedObjectTitle} country={selectedCountryTitle} hierarchy={selectedHierarchy}
                        setParentDropdownValue={setDropDownValues} editDataObj={editDataObj} handleEditFormCancel={handleEditFormCancel}
                    />
                    : ""}
            </div>
            {
                openDialogueBox ? <DialogueBox openDialogueBox={openDialogueBox}
                    handleDialogueBox={handleDialogueBox}
                    messageType={messageType}
                    message={message} /> : ""
            }
            <Toaster />
        </div >

    )
}

export default CustomHierarchy