import React from 'react';
import { useIsAuthenticated } from "@azure/msal-react"
import { Redirect } from "react-router-dom"

const InitialRoute = () => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <Redirect to="/home" />
    }
    return (
        <Redirect to="/login" />
    );
};

export default InitialRoute;