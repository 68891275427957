import React, { useState } from 'react'
import '../App.css'
import Spinner from 'react-bootstrap/Spinner';

const LoadingHome = (props) => {

    return (
        <div className="loading-home-container" >
            <div className="table-data-note"><Spinner animation="border" />
                <p>Loading..</p>
            </div>
        </div>
    )
}

export default LoadingHome;