import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import '../App.css';
import DialogueBox from '../components/dialogueBox';
import { BASE_URL, BRAND_CATEGORY_MAPPINGS,CHANNEL_CATEGORY_MAPPINGS, INPUT_FORMS, INPUT_SUGGESTIONS, MFA } from '../constant/apiEndpoints';
import APIService from '../services/APIService';
import AutoSuggestInput from './autoSuggestInput';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55vw',
    height: '94vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
    // overflow: 'auto',
    // display: 'flex'
};

const initialObject = {
    'bic_ccusthie4' :'',
    'home_ooh': '',
    'trade_channel_ch3':'',
    'subtrade_channel_ch4':'',
    'channel_vr':'',
    'comment':'',
}

const ChannelCategoryFormModal = (props) => {
    const [isEdit, setIsEdit] = React.useState(false);
    const [dataObject, setDataObject] = useState({
        ...initialObject,
        form_id: props.formId
    })
    const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
    const [messageType, setMessageType] = React.useState("");
    const [message, setMessage] = React.useState("");

    const inputOnChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setDataObject(prevState => {
            return { ...prevState, [name]: value }
        })
    }

    const autoSuggestOnChange = (name, value) => {
        setDataObject(prevState => {
            return { ...prevState, [name]: value }
        })
    }

    const handleDialogueBox = () => {
        setOpenDialogueBox(!openDialogueBox)
    }

    const showDialogueBox = (msgType, msg) => {
        setMessage(msg);
        setMessageType(msgType);
        handleDialogueBox();
    }

    const handleOnClose = (value) => {
        setDataObject(initialObject)
        props.handleIsFormModalOpen(value)
    }

    const loadDataObject = async (id) => {
        let url = BASE_URL + INPUT_FORMS + MFA + CHANNEL_CATEGORY_MAPPINGS  + parseInt(id) + '/'
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in ski exlusion list API", response.results.message)
        } else {
            setDataObject(response.results);
            setIsEdit(true);
        }
    }

    const getPayloadData = (obj) => {
        for (var each in obj) {
            if (!obj[each]) {
                obj[each] = null;
            }
        }
        return obj
    }

    const addNewData = async (e) => {
        let url = BASE_URL + INPUT_FORMS + MFA + CHANNEL_CATEGORY_MAPPINGS
        let response = await new APIService().post(url, getPayloadData({ ...dataObject, 'form_id': props.formId }))
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                   showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                }
            }
        } else {
            toast.success('New data added Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.postSubmitAndEditData();
            handleOnClose(false);
        }
    }

    const editExistingData = async (e) => {
        let url = BASE_URL + INPUT_FORMS + MFA + CHANNEL_CATEGORY_MAPPINGS + parseInt(props.selectedIdToEdit) + '/'
        // console.log("Check", {...dataObject, 'form_id': props.form_id})
        let response = await new APIService().patch(url, getPayloadData({ ...dataObject, 'form_id': props.formId }))
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                   showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                }
            }
        } else {
            toast.success('Data updated Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.postSubmitAndEditData();
            handleOnClose(false);
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        toast.loading('Submitting, Please Wait!',
            {
                position: 'top-center',
                className: 'toast-popup',
                duration: 1000,
            });
        if (props.selectedIdToEdit !== undefined) {
            editExistingData(e);
        } else {
            addNewData(e);
        }
    }


    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (props.selectedIdToEdit !== undefined) {
                toast.promise(loadDataObject(props.selectedIdToEdit), {
                    loading: "Loading. wait a moment!",
                    success: 'Data load success.',
                    error: 'Something went wrong.',
                },
                    {
                        position: 'top-center',
                        className: 'toast-popup',
                    });
            }
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div>
            <Modal
                open={props.isFormModalOpen}
                // onClose={() => props.handleIsFormModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="brand-category-modal-form-header">
                        {isEdit ?
                            <p>ChannelID : {props.selectedIdToEdit}</p>
                            : <p>Add New Entry</p>}
                        <div className="brand-category-modal-form-close-button" onClick={() => handleOnClose(false)}>
                            X
                        </div>
                    </div>
                    <div className="brand-category-modal-form-body">
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-5 p-0">
                                <p>Bic ccusthie4</p>
                                <AutoSuggestInput name="bic_ccusthie4" placeholder="Bic ccusthie4" currentValue={dataObject.bic_ccusthie4} dataList={props.suggestions.bic_ccusthie4} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            {/* </div> */}
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Home ooh</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput name="home_ooh" placeholder="home_ooh" currentValue={dataObject.home_ooh} dataList={props.suggestions.home_ooh} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">

                            <div className="brand-category-modal-form-input col-2"></div>
                        </div>

                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-5 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Trade Channel ch3</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput className="brand-category-text-input-box" name="trade_channel_ch3"  placeholder="Trade Channel Ch3" currentValue={dataObject.trade_channel_ch3} dataList={props.suggestions.trade_channel_ch3} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                            <div className="brand-category-modal-form-input col-2 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Channel vr</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput className="brand-category-text-input-box" name="channel_vr"  placeholder="Channel Vr" currentValue={dataObject.channel_vr} dataList={props.suggestions.channel_vr} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">

                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-5 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Subtrade Channelch4</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <AutoSuggestInput className="brand-category-text-input-box" name="subtrade_channel_ch4"  placeholder="Subtrade ChannelCh4" currentValue={dataObject.subtrade_channel_ch4} dataList={props.suggestions.subtrade_channel_ch4} autoSuggestOnChange={autoSuggestOnChange} />
                            </div>

                            <div className="brand-category-modal-form-input col-2"></div>
                            <div className="brand-category-modal-form-input col-2"></div>
                        </div>
                    </div>
                    <div className="brand-category-modal-form-footer row m-0">
                        <div className="brand-category-modal-form-input col-6">
                            <div style={{ display: "flex" }}>
                                <p>Comment</p><p style={{ color: "#E61C2A" }}>*</p>
                            </div>

                            <textarea className="brand-category-text-input-box"
                                style={{ width: "100%" }}
                                rows="4" cols="50"
                                name="comment"
                                value={dataObject.comment}
                                onChange={inputOnChange}
                            />
                        </div>
                        <div className="brand-category-modal-form-input col-6">
                            <div style={{ float: "right" }}>
                                <button className="brand-category-form-modal-cancel-button mt-3" onClick={() => handleOnClose(false)}>Cancel</button>
                                <button className="brand-category-form-modal-submit-button mt-3" onClick={handleOnSubmit}>Submit</button>
                            </div>
                        </div>

                    </div>
                </Box>
            </Modal>
            {/* Message Dialogue Box */}
            {openDialogueBox ? <DialogueBox openDialogueBox={openDialogueBox}
                handleDialogueBox={handleDialogueBox}
                messageType={messageType}
                message={message} /> : ""}
            {/* Message Dialogue Box */}
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    )
};

export default ChannelCategoryFormModal;

