import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import '../../App.css';
import DialogueBox from '../../components/dialogueBox';
import { BASE_URL, CUST_HIER, EDIT_HIER } from '../../constant/apiEndpoints';
import APIService from '../../services/APIService';
import { Input } from '@material-ui/core';
import CustomHierarchyDropdown from './customHierarchyDropdown';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    height: '94vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
};

const CustomHierarchyEditMappingModal = (props) => {
    const [dataObject, setDataObject] = useState({})
    const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
    const [messageType, setMessageType] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [level, setLevel] = React.useState([1, 2, 3, 4, 5, 6]);
    const [selectedValue, setSelectedValue] = React.useState("")

    function range(start, end) {
        let nums = [];
        for (let i = start; i <= end; i++) nums.push(i);
        return nums;
    }

    let nums = range(1, selectedValue);

    const setReleventDropDownValues = (value, selectedtext, valueType) => {
        if (valueType === "No. of Levels") {
            setSelectedValue(selectedtext)
            setDataObject(prevState => ({
                ...prevState,
                labels: {
                    ...prevState.labels,
                    ["num_hier_levels"]: value
                }
            }));
        }
    }

    const handleDialogueBox = () => {
        setOpenDialogueBox(!openDialogueBox)
    }

    const showDialogueBox = (msgType, msg) => {
        setMessage(msg);
        setMessageType(msgType);
        handleDialogueBox();
    }

    const handleOnClose = (value) => {
        props.handleEditFormCancel(value)
    }

    //AJAX Call To Load Countries
    const loadHierarchyEditData = async (object_type, country, hierarchy) => {
        let url = BASE_URL + CUST_HIER + EDIT_HIER + `?form_id=${'custhier'}&country=${country}&object_type=${object_type}&hierarchy_name=${hierarchy}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user countrylist API", response.results.message)
        } else {
            setDataObject(response.results);
            setSelectedValue(response.results.labels.num_hier_levels)
        }
    }

    const editHierarchyData = async (e) => {
        let url = BASE_URL + CUST_HIER + EDIT_HIER + `?form_id=${'custhier'}&country=${props.country}&object_type=${props.objectType}&hierarchy_name=${props.hierarchy}`
        let response = await new APIService().patch(url, dataObject, props.formId)
        if (response.error) {
            if (response.status === 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                    showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                }
            }
        } else {
            if (response.results.message) {
                toast.success(response.results.message, { position: 'top-center', className: 'toast-popup', duration: 1000, });
                props.handleIsEditFormModalClose(false, response.results.country, response.results.object_type, response.results.hierarchy_name);

            }
            else {
                toast.success('Data updated Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
                props.handleIsEditFormModalClose(false, response.results.country, response.results.object_type, response.results.hierarchy_name);
            }
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        toast.loading('Submitting, Please Wait!',
            {
                position: 'top-center',
                className: 'toast-popup',
                duration: 1000,
            });
        editHierarchyData(e)
    }


    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (props.objectType && props.country && props.hierarchy) {
                toast.promise(loadHierarchyEditData(props.objectType[0].id, props.country[0].id, props.hierarchy), {
                    loading: "Loading. wait a moment!",
                    success: 'Data load success.',
                    error: 'Something went wrong.',
                },
                    {
                        position: 'top-center',
                        className: 'toast-popup',
                    });
            }
        }
        return function cleanup() {
            mounted = false
        }
    }, []);

    const handleLevelChange = (e) => {
        setDataObject(prevState => ({
            ...prevState,
            labels: {
                ...prevState.labels,
                [e.target.name]: e.target.value
            }
        }));
    }
    const handleHierarchyChange = (e) => {
        setDataObject(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <div>
            <Modal
                open={props.isFormModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="brand-category-modal-form-header">
                        <p>Edit Current hierarchy parameters</p>
                        <div className="brand-category-modal-form-close-button" onClick={() => handleOnClose(false)}>
                            X
                        </div>
                    </div>
                    <div className="brand-category-modal-form-body">
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                </div>
                                <CustomHierarchyDropdown title="Country" options={props.country}
                                    setParentDropdownValue={props.setParentDropdownValue} />
                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <CustomHierarchyDropdown title="Object Type" options={props.objectType}
                                    setParentDropdownValue={props.setParentDropdownValue} />
                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Hierarchy Version</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <Input name={"hierarchy_name"} value={dataObject.hierarchy_name} onChange={handleHierarchyChange}></Input>
                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div>
                                    <label style={{ paddingLeft: "5px", fontSize: "small" }}>No. of Levels</label>
                                </div>
                                <select
                                    name="teamName"
                                    className="ch-dp-dn brand-category-multi-select-custom"
                                    onChange={(e) => setReleventDropDownValues(e.target.value, e.target.options[e.target.selectedIndex].text, "No. of Levels")}
                                    value={selectedValue !== "" ? selectedValue : ""}
                                    required
                                >
                                    {level.map(each => (
                                        <option
                                            className="select-dropdown-option"
                                            key={each}
                                            value={each}
                                            name={each}
                                        >
                                            {each}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>

                        <div className="brand-category-modal-form-input-container row m-0">

                        </div>

                        <div className="brand-category-modal-form-input-container row m-0">
                            {nums.map(each => (
                                <div key={each} className="brand-category-modal-form-input col-4 p-0">
                                    <div style={{ display: "flex" }}>
                                        <p>Level{each} Label</p><p style={{ color: "#E61C2A" }}>*</p>
                                    </div>
                                    <Input value={dataObject.labels[`level_${each}_label`]} name={`level_${each}_label`} onChange={(e) => handleLevelChange(e)}></Input>
                                    <div className="brand-category-modal-form-input-container row m-0">
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="brand-category-modal-form-input col-6">
                        
                    </div>
                    <div className="brand-category-modal-form-input col-12">
                        <div style={{ float: "right" }}>
                            <button className="brand-category-form-modal-cancel-button mt-3" onClick={() => handleOnClose(false)}>Cancel</button>
                            <button className="brand-category-form-modal-submit-button mt-3" onClick={handleOnSubmit}>Submit</button>
                        </div>
                    </div>
                </Box>
            </Modal>
            {/* Message Dialogue Box */}
            {openDialogueBox ? <DialogueBox openDialogueBox={openDialogueBox}
                handleDialogueBox={handleDialogueBox}
                messageType={messageType}
                message={message} /> : ""}
            {/* Message Dialogue Box */}
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    )
};

export default CustomHierarchyEditMappingModal;
