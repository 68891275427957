import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import '../../App.css';
import DialogueBox from '../../components/dialogueBox';
import { BASE_URL, CUST_HIER, ADD_NEW_HIER } from '../../constant/apiEndpoints';
import APIService from '../../services/APIService';
import { Input } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import { grey, red } from '@mui/material/colors';
import CustomHierarchyDropdown from './customHierarchyDropdown';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    height: '94vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
};

const CustomHierarchyAddFormModal = (props) => {
    const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
    const [messageType, setMessageType] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [level, setLevel] = React.useState([{ id: 1, title: 1 }, { id: 2, title: 2 }, { id: 3, title: 3 }, { id: 4, title: 4 }, { id: 5, title: 5 }, { id: 6, title: 6 }]);
    const [selectedValue, setSelectedValue] = React.useState(1)
    // const [selectedLabel, setSelectedLabel] = React.useState("")

    function range(start, end) {
        let nums = [];
        for (let i = start; i <= end; i++) nums.push(i);
        return nums;
    }
    
    let nums = range(1, selectedValue);
    const handleDialogueBox = () => {
        setOpenDialogueBox(!openDialogueBox)
    }

    const showDialogueBox = (msgType, msg) => {
        setMessage(msg);
        setMessageType(msgType);
        handleDialogueBox();
    }

    const handleAddFormModalClose = (value) => {
        props.handleAddFormCancel(value)
    }

    const [selectedCountry, setSelectedCountry] = useState(props.preSelectedCountry)
    const [selectedObjectType, setSelectedObjectType] = useState(props.preSelectedObjectType)
    const [selectedCopyHierarchyName, setSelectedCopyHierarchyName] = useState(props.preSelectedHierarchy)
    const [selectedNumOfLevels, setSelectedNumOfLevels] = useState(1);
    const [selectedLevel1Label, setSelectedLevel1Label] = useState("")
    const [selectedLevel2Label, setSelectedLevel2Label] = useState("")
    const [selectedLevel3Label, setSelectedLevel3Label] = useState("")
    const [selectedLevel4Label, setSelectedLevel4Label] = useState("")
    const [selectedLevel5Label, setSelectedLevel5Label] = useState("")
    const [selectedLevel6Label, setSelectedLevel6Label] = useState("")
    const [isChecked, setIsChecked] = useState(false)
    const [isCopyExistingHierarchy, setIsCopyExistingHierarchy] = useState(false)
    const [newHierarchyData, setNewHierarchyData] = useState("")


    const getPayloadData = () => {
        return {
            "country": selectedCountry,
            "object_type": selectedObjectType,
            "hierarchy_name": newHierarchyData,
            "no_of_levels": selectedNumOfLevels,
            "labels": {
                "level_1_label": selectedLevel6Label,
                "level_2_label": selectedLevel5Label,
                "level_3_label": selectedLevel4Label,
                "level_4_label": selectedLevel3Label,
                "level_5_label": selectedLevel2Label,
                "level_6_label": selectedLevel1Label,
                "num_hier_levels": selectedNumOfLevels,
            },
            "copy_existing_hierarchy": isCopyExistingHierarchy,
            "copy_hierarchy_name": selectedCopyHierarchyName,
        }
    }

    const handleChange = (e) => {
        const valueType = e.name
        const value = e.value
        if (valueType === "Level1 Label") {
            setSelectedLevel1Label(value)
        } else if (valueType === "Level2 Label") {
            setSelectedLevel2Label(value)
        } else if (valueType === "Level3 Label") {
            setSelectedLevel3Label(value)
        } else if (valueType === "Level4 Label") {
            setSelectedLevel4Label(value)
        } else if (valueType === "Level5 Label") {
            setSelectedLevel5Label(value)
        } else if (valueType === "Level6 Label") {
            setSelectedLevel6Label(value)
        } else if (valueType === "Hierarchy Name") {
            setNewHierarchyData(value)
        }

    }

    const setReleventDropDownValues = (value, selectedtext, valueType) => {
        if (valueType === "Country") {
            setSelectedCountry(value)
        }
        else if (valueType === "Object Type") {
            setSelectedObjectType(selectedtext)
        }
        else if (valueType === "No. Of Levels") {
            setSelectedNumOfLevels(selectedtext)
            setSelectedValue(selectedtext)
        }
        else if (valueType === "Copy Hierarchy From") {
            setSelectedCopyHierarchyName(selectedtext)
        }
    }

    const onItemCheck = (e) => {
        setIsChecked(e.target.checked)
        if (e.target.checked === true) {
            setIsCopyExistingHierarchy(true)
            setSelectedValue("")
            setSelectedLevel1Label("")
            setSelectedLevel2Label("")
            setSelectedLevel3Label("")
            setSelectedLevel4Label("")
            setSelectedLevel5Label("")
            setSelectedLevel6Label("")
            setSelectedNumOfLevels(0)

        }
    }

    const addNewData = async (e) => {
        let url = BASE_URL + CUST_HIER + ADD_NEW_HIER
        let response = await new APIService().post(url, getPayloadData())
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                    showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                }
            }
        } else {
            toast.success('New data added Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.handleIsFormModalClose(false, response.results.country, response.results.object_type, response.results.hierarchy_name);
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        toast.loading('Submitting, Please Wait!',
            {
                position: 'top-center',
                className: 'toast-popup',
                duration: 1000,
            });
        addNewData(e);
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            //     toast.loading('Loading, Please Wait!',
            //         {
            //             position: 'top-center',
            //             className: 'toast-popup',
            //             duration: 4000,
            //         })
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div>
            <Modal
                open={props.isFormModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="brand-category-modal-form-header">
                        <p>Add New Hierarchy</p>
                        <div className="brand-category-modal-form-close-button" onClick={() => handleAddFormModalClose(false)}>
                            X
                        </div>
                    </div>
                    <div className="brand-category-modal-form-body">
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <CustomHierarchyDropdown title="Country" options={props.country}
                                    preSelectedValue={selectedCountry}
                                    setParentDropdownValue={setReleventDropDownValues} />
                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <CustomHierarchyDropdown title="Object Type" options={props.objectType}
                                    preSelectedValue={selectedObjectType}
                                    setParentDropdownValue={setReleventDropDownValues} />
                            </div>

                            {isChecked ?
                                <div className="brand-category-modal-form-input col-3 p-0">
                                    <div style={{ display: "flex" }}>
                                    </div>
                                    <CustomHierarchyDropdown title="Copy Hierarchy From" options={props.hierarchy}
                                        preSelectedValue={selectedCopyHierarchyName}
                                        setParentDropdownValue={setReleventDropDownValues} />
                                </div>
                                : ""}

                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex", marginTop: "5px", fontSize: "small" }}>
                                    <label>Hierarchy Name</label><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <Input name={"Hierarchy Name"} onChange={(e) => handleChange(e.target)}></Input>
                                <div className="brand-category-modal-form-input-container row m-0">
                                </div>
                            </div>
                            {!isChecked ?
                                <div className="brand-category-modal-form-input col-3 p-0">
                                    <CustomHierarchyDropdown title="No. Of Levels" options={level}
                                        setParentDropdownValue={setReleventDropDownValues} />
                                </div>
                                : ""}
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">
                        </div>

                        {!isChecked ?
                            <div className="brand-category-modal-form-input-container row m-0">
                                {nums.map(each => (
                                    <div key={each} className="brand-category-modal-form-input col-4 p-0">
                                        <div style={{ display: "flex" }}>
                                            <p>Level{each} Label</p><p style={{ color: "#E61C2A" }}>*</p>
                                        </div>
                                        <Input name={`Level${each} Label`} onChange={(e) => handleChange(e.target)}></Input>
                                        <div className="brand-category-modal-form-input-container row m-0">
                                        </div>
                                    </div>
                                ))}

                                <div className="brand-category-modal-form-input col-3 p-0 check-bx">
                                    <div style={{ display: "flex" }}>
                                        <p style={{ fontSize: "15px" }}>Copy from Existing Hierarchy</p>
                                    </div>
                                    <Checkbox size="medium"
                                        onChange={(e) => onItemCheck(e)}
                                        sx={{
                                            '& .MuiSvgIcon-root': { fontSize: 20 },
                                            '&.Mui-checked': { color: red[500], },
                                            color: grey[500],
                                        }} />
                                </div>
                            </div>
                            : <div className="brand-category-modal-form-input col-4 p-0">
                                <div style={{ display: "flex" }}>
                                    <p style={{ fontSize: "15px" }}>Copy from Existing Hierarchy</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                <Checkbox size="medium"
                                    checked={isChecked}
                                    onChange={(e) => onItemCheck(e)}
                                    sx={{
                                        '& .MuiSvgIcon-root': { fontSize: 20 },
                                        '&.Mui-checked': { color: red[500], },
                                        color: grey[500],
                                    }} />
                            </div>}
                    </div>
                    <div className="brand-category-modal-form-input col-6">
                    </div>
                    <div className="brand-category-modal-form-input col-12">
                        <div style={{ float: "right" }}>
                            <button className="brand-category-form-modal-cancel-button mt-3" onClick={() => handleAddFormModalClose(false)}>Cancel</button>
                            <button className="brand-category-form-modal-submit-button mt-3" onClick={handleOnSubmit}>Submit</button>
                        </div>
                    </div>
                </Box>
            </Modal>
            {/* Message Dialogue Box */}
            {openDialogueBox ? <DialogueBox openDialogueBox={openDialogueBox}
                handleDialogueBox={handleDialogueBox}
                messageType={messageType}
                message={message} /> : ""}
            {/* Message Dialogue Box */}
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    )
};

export default CustomHierarchyAddFormModal;
