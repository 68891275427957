import React, { useState, useEffect } from 'react'
import Switch from '@mui/material/Switch';

import { useDispatch, useSelector } from 'react-redux';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import { CSVLink } from "react-csv";
import { read, utils, writeFile } from 'xlsx';
import Papa from 'papaparse';
// import { DownloadTableExcel } from 'react-export-table-to-excel';

import MultiSelectDropDown from '../../Emission/multiSelectDropdown';
import { quarter } from '../../Emission/EmissionColumns'
import TableList from '../../Emission/TableList'
import { pandcColumns, zwcColumns, month, pcList, cpColumns, yrColumns } from '../StewardColumn';
import Loading from '../../loadingHome';

import DeleteModal from '../../Emission/DeleteModal';
import EditModal from '../../Emission/EditModal';
import AddModal from '../AddModal';
import CommunityParticipantTable from './CommunityPartTable';
import YouthRoadmapTable from './YouthRoadmap';
import { sendUploadedYouthFile } from '../../../actions/steward_action';
import { addStewardRow, deleteStewardRow, editStewardRow, getStewardData, updatingDropdown } from '../../../actions/steward_action';
import { TableRow } from '@material-ui/core';


const PeopleCommunity = () => {

    const dispatch = useDispatch()
    const EmissionList = useSelector((state) => state.steward.tableData)
    const region = useSelector((state) => state.steward.regionList)
    const country = useSelector((state) => state.steward.countryList)
    const bu = useSelector((state) => state.steward.buList)
    const year = useSelector((state) => state.steward.yearList)
    const headers = useSelector((state) => state.steward.columnList)
    const loading = useSelector((state) => state.steward.loading)
    const matrix = useSelector((state) => state.steward.matrix)
    const toastData = useSelector((state) => state.steward.toast)

    const [selectedRegion, setSelectedRegion] = useState([])
    const [selectedBU, setSelectedBU] = useState([])
    const [selectedCountry, setSelectedCountry] = useState([])
    const [selectedYear, setSelectedYear] = useState([])
    const [selectedMonth, setSelectedMonth] = useState([])
    const [selectedQuarter, setSelectedQuarter] = useState([])
    const [tableData, setTableData] = useState()
    const [previousTableData, setPreviousTableData] = useState()
    const [exportData, setExportData] = useState([])
    const [checkboxList, setCheckboxList] = useState([])
    const [checkboxId, setCheckboxId] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [editRow, setEditRow] = useState({})
    // const [checked, setChecked] = useState(false)
    const [selectedValue, setSelectedValue] = useState(pcList[0])
    const [selectedDropDown, setSelectedDropDown] = useState([])
    const [editTable, setEditTable] = useState(false)
    const disabledOption = ['region', 'bu', 'country', 'year', 'month']
    const pandc = 'people_and_community'
    const zwc = 'zero_waste_cities'



    useEffect(() => {
        let updatedRegionData = []
        if (selectedRegion.length > 0) {
            for (let char of EmissionList) {
                if (selectedRegion.includes(char.region)) {
                    updatedRegionData.push(char)
                }
            }
        }
        else {
            updatedRegionData = EmissionList
        }
        let upDatedBuData = []
        if (selectedBU.length > 0) {
            for (let char of updatedRegionData) {
                if (selectedBU.includes(char.bu)) {
                    upDatedBuData.push(char)
                }
            }
        } else {
            upDatedBuData = updatedRegionData
        }
        let updatedCountryData = []
        if (selectedCountry.length > 0) {
            for (let char of upDatedBuData) {
                if (selectedCountry.includes(char.country)) {
                    updatedCountryData.push(char)
                }
            }
        }
        else {
            updatedCountryData = upDatedBuData
        }

        let updatedYearData = []
        if (selectedYear.length > 0) {
            for (let char of updatedCountryData) {
                if (selectedYear.includes(char.year)) {
                    updatedYearData.push(char)
                }
            }
        }
        else {
            updatedYearData = updatedCountryData
        }
        let updatedMonthData = []
        if (selectedMonth.length > 0) {
            for (let char of updatedYearData) {
                if (selectedMonth.includes(char.month)) {
                    updatedMonthData.push(char)
                }
            }
        }
        else {
            updatedMonthData = updatedYearData
        }
        // setTableData(updatedMonthData)
        // setPreviousTableData(updatedMonthData)
        let updatedQuarterData = []
        if (selectedQuarter.length > 0) {
            for (let char of updatedYearData) {
                if (selectedQuarter.includes(char.quarter)) {
                    updatedQuarterData.push(char)
                }
            }
        }
        else {
            updatedQuarterData = updatedMonthData
        }
        setTableData(updatedQuarterData)
    },
        [selectedBU, selectedCountry, selectedRegion, selectedYear, selectedMonth, selectedQuarter])

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let res = tableData.map(({ id, selected, ...rest }) => ({ ...rest }));
            setExportData(res)
        }
    }, [tableData])
    useEffect(() => {
        // dispatch({
        //     type: 'SHOW_TOAST',
        //     payload: {}
        // })
        // dispatch({
        //     type: 'SHOW_TOAST',
        //     payload: {}
        // })
        // dispatch({
        //     type: 'SET_INITIAL_STATUS',
        // })
        toast.promise(dispatch(getStewardData(selectedValue.value)), {
            loading: "Loading. wait a moment!",
            success: 'Data loaded Successfully!',
            error: 'You do not have Permission!',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });
        //dispatch(getStewardData(checked === false ? pandc : zwc))
    }, [selectedValue])

    useEffect(() => {
        setTableData(EmissionList)
        let res = EmissionList.map(({ id, selected, ...rest }) => ({ ...rest }));
        setExportData(res)
    }, [EmissionList])

    useEffect(() => {
        let obj = {}
        for (let header of headers) {
            obj[header.key] = ''
        }
        if (selectedValue.value === 'zero_waste_city') {
            delete obj.year
            delete obj.month
        }
        setEditRow(obj)
    }, [headers])

    useEffect(() => {
        if (toastData.message && toastData.message.length > 0 && toastData.type === "error") {
            toast.error(toastData.message, { position: 'top-right', duration: 1500, });
        }
        else if (toastData.message && toastData.message.length > 0 && toastData.type === "success") {
            toast.success(toastData.message, { position: 'top-right', duration: 1500, });
        }
    }, [toastData])

    useEffect(() => {
        if (selectedDropDown === 'Region') {
            dispatch(updatingDropdown(selectedDropDown, selectedRegion, matrix))
            if (selectedCountry.length !== 0) {
                dispatch(updatingDropdown('Country', selectedCountry, matrix))
            }
            if (selectedBU.length !== 0) {
                dispatch(updatingDropdown('BU', selectedBU, matrix))
            }

        }
        if (selectedDropDown === 'BU') {
            dispatch(updatingDropdown(selectedDropDown, selectedBU, matrix))
            if (selectedCountry.length !== 0) {
                dispatch(updatingDropdown('Country', selectedCountry, matrix))
            }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
        if (selectedDropDown === 'Country') {
            dispatch(updatingDropdown(selectedDropDown, selectedCountry, matrix))
            // if (selectedBU.length !== 0) {
            //     dispatch(updatingDropdown('BU', selectedBU, matrix))
            // }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
    }, [selectedRegion, selectedBU, selectedCountry])

    const setDropDownValues = (val, valType) => {
        if (valType === 'Region') {
            let regionVal = []
            if (val.length > 0) {
                for (let char of val)
                    regionVal.push(char.value)
            }
            setSelectedRegion(regionVal)
        }
        if (valType === 'BU') {
            let buVal = []
            if (val.length > 0) {
                for (let char of val)
                    buVal.push(char.value)
            }
            setSelectedBU(buVal)
        }
        if (valType === 'Country') {
            let countryVal = []
            if (val.length > 0) {
                for (let char of val)
                    countryVal.push(char.value)
            }
            setSelectedCountry(countryVal)
        }
        if (valType === 'Year') {
            let yearVal = []
            if (val.length > 0) {
                for (let char of val)
                    yearVal.push(char.value)
            }
            setSelectedYear(yearVal)
        }
        if (valType === 'Month') {
            let monthVal = []
            if (val.length > 0) {
                for (let char of val)
                    monthVal.push(char.value)
            }
            setSelectedMonth(monthVal)
        }
        if (valType === 'Quarter') {
            let quarterVal = []
            if (val.length > 0) {
                for (let char of val)
                    quarterVal.push(char.value)
            }
            setSelectedQuarter(quarterVal)
        }
        setSelectedDropDown(valType)
    }

    const setSelectedListInParent = (value) => {
        let ids = []
        let lists = []
        for (let char of value) {
            ids.push(char.id)
            lists.push(char)
        }
        setCheckboxList(lists)
        setCheckboxId(ids)
    }
    const handleEditClickInParent = (row) => {
        if (row['cut_off'] == true) {
            setOpenAddModal(false)
            toast.error('Cut off time is going on. You will NOT be able to Save any changes.', { position: 'top-center', className: 'cutoff-popup', duration: 1500, });
        }
        else{
            setOpenEditModal(true)
            let obj = {}
            for (let header of headers) {
                obj[header.key] = row[header.key]
            }
            if (selectedValue.value === "zero_waste_city") {
                delete obj.year
                delete obj.month
            }
            obj['id'] = row.id
            setEditRow(obj)
        }

    }

    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }
    const closeEditModal = () => {
        setOpenEditModal(false)
    }
    const handleDeleteButton = () => {
        setOpenDeleteModal(true)
    }
    const handleEditTable = () => {
        setEditTable(true)
    }

    const handleDelete = () => {
        setCheckboxId([])
        setCheckboxList([])
        setOpenDeleteModal(false)
        toast.promise(dispatch(deleteStewardRow(selectedValue.value === 'people_and_community' ? pandc : zwc, tableData, checkboxList, checkboxId)), {
            loading: "Loading. wait a moment!",
            success: 'Row(s) deleted successfully!',
            error: (err) => err.response.data.message.error,
        },
            {
                position: 'top-center',
                className: 'cutoff-popup',
                duration: 2500,
            });
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setSelectedMonth([])
    }
//  ############################################################################################ 

    const handleEdit = (rowData) => {

        setEditRow(rowData);
        setCheckboxId(rowData.id);
        setSelectedDropDown(rowData.dropDown);
        // setCheckboxList(rowData.selected);
        setOpenEditModal(false);
        let updatedTableData = EmissionList.map((rowData) => {
            if (rowData.id === rowData.id) {
                return { ...rowData, dropDown: selectedDropDown };
            }
            return rowData;
        });

        toast.promise(dispatch(editStewardRow(selectedValue.value, updatedTableData, rowData)), {
            loading: "Loading. wait a moment!",
            success: 'Row edited successfully!',
            error: 'Something went wrong.',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });

        setTableData(updatedTableData);
        setPreviousTableData(updatedTableData);
        setSelectedQuarter([])
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setEditTable(false);
    };


// ########################################################################################

    const AddModalOpen = () => {
        setOpenAddModal(true)
    }

    const closeAddMOdal = () => {
        setOpenAddModal(false)
    }

    const handleAddModal = (row) => {
        setOpenAddModal(false)
        toast.promise(dispatch(addStewardRow(selectedValue.value === 'people_and_community' ? pandc : zwc, tableData, row)), {
            loading: "Loading. wait a moment!",
            success: 'Data load success.',
            error: (err) => err.response.data.message.error,
        },
            {
                position: 'top-center',
                className: 'cutoff-popup',
                duration: 2500,
            });

        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setSelectedMonth([])
    }
    const initialData = () => {
        dispatch(getStewardData(selectedValue.value))
    }

    const handleCancelButton = (isclick) => {
        setEditTable(isclick)
    }

    // const initialData = dispatch(getStewardData(selectedValue.value))

    const handleSelectChange = (event) => {
        setSelectedValue(event)
        if (event.value !== selectedValue.value) {
            setEditTable(false)
            dispatch({
                type: 'SHOW_TOAST',
                payload: {}
            })
            dispatch({
                type: 'SET_INITIAL_STATUS',
            })
        }
    }

    const handleOnChange = (e) => {

        Papa.parse(e.target.files[0], {
            header: true,
            complete: (results) => {
                //dispatch(sendUploadedFile(tableData, results.data))
                toast.promise(dispatch(sendUploadedYouthFile('youth_empowered_roadmap_targets', tableData, results.data)), {
                    loading: "Loading. wait a moment!",
                    success: 'Data added successfully.',
                    error: 'Something went wrong.',
                },
                    {
                        position: 'top-center',
                        className: 'toast-popup',
                    });
            }
        })
        setTimeout(() => {
            e.target.value = ''
        }, 1500)

    }

    function changeHeaderRank(header) {
        const arrOne = []
        const arrTwo = []
        header = header.filter(val => val.key !== "cut_off");
        header.map(val => {
            if (val.label == "Added_By" || val.label == "Updated_By" || val.label == "Created_Date" || val.label == "Updated_Date" || val.label == "added_by" || val.label == "updated_by" || val.label == "created_date" || val.label == "updated_date") {
                arrOne.push(val)
            }
            else {
                arrTwo.push(val)
            }
        })
        const seq = ["Created_Date", "created_date", "Added_By", "added_by", "Updated_Date","updated_date", "Updated_By","updated_by"];
        const newArr = []
        seq.map(val => {
            const head = header.filter(newval => newval.key == val);
            if (head?.length > 0) {
                newArr.push(head[0])
            }
        })
        const mergedArray = arrTwo.concat(newArr);
        return mergedArray
    }

    const headermapping = (header) => {
        const hea = {};
        header = changeHeaderRank(header);
        header.map(val => {
            const key = val.key;
            hea[key] = val.label;
        })
        return hea
    }

    // Map header names to values
    const mappedData = exportData.map(item => {
        const ndata = headermapping(headers); 
        if(Object.values(ndata).includes("added_by")) {
            ndata['added_by'] = 'created_by'
        }
        else{
            ndata['added_by'] = 'Created_by'
        }    
        return Object.keys(ndata).reduce((obj, key) => {
            obj[ndata[key]] = item[key];
            return obj;
        }, {});
    });

    const handleExport = () => {
        const wb = utils.book_new();
        mappedData.forEach((item) => {
            if ('Total No of Employees in Volunteering' in item){
                if (typeof(item['No of Youth Empowered']) === "string" && typeof(item['Total No of Employees in Volunteering']) === "string"){
                item['No of Youth Empowered'] = parseFloat(item['No of Youth Empowered'].replace(/,/g, ''))
                item['Total No of Employees in Volunteering'] = parseFloat(item['Total No of Employees in Volunteering'].replace(/,/g, ''))
            }
            else{
                item['No of Youth Empowered'] = parseFloat(item['No of Youth Empowered'])
                item['Total No of Employees in Volunteering'] = parseFloat(item['Total No of Employees in Volunteering'])
            }
        }
            else if ('No of Community Participants' in item){
                if (typeof(item['No of Community Participants']) === "string" && typeof(item['No of People in CCH']) === "string"){
                item['No of Community Participants'] = parseFloat(item['No of Community Participants'].replace(/,/g, ''))
                item['No of People in CCH'] = parseFloat(item['No of People in CCH'].replace(/,/g, ''))
            }
            else{
                item['No of Community Participants'] = parseFloat(item['No of Community Participants'])
                item['No of People in CCH'] = parseFloat(item['No of People in CCH'])
            }
        }
            else if ('No of Youth Empowered' in item){
                if (typeof(item['No of Youth Empowered']) === String){
                item['No of Youth Empowered'] = parseFloat(item['No of Youth Empowered'].replace(/,/g, ''))
            }
            else{
                item['No of Youth Empowered'] = parseFloat(item['No of Youth Empowered'])
            }
        }
            
        })
        const ws = utils.json_to_sheet(mappedData);
        utils.book_append_sheet(wb, ws, 'Data');
        writeFile(wb, `${selectedValue.value}.xlsx`);
    }


    return (
        <div>
            {/* {region && region.length > 0 && bu && bu.length > 0 && country && country.length > 0
                && year && year.length && */}
            <div className="user-view-container">
                {pcList.map((btn) => {
                    return (
                        <span style={{ marginLeft: ".5rem" }}
                            key={btn.value}>
                            <button style={{
                                margin: ".2rem",
                                backgroundColor: selectedValue === btn ? "#FBC02D" : "white",
                                border: selectedValue === btn ? "1px solid #FBC02D" : "1px solid #E0E0E0",
                                color: "black",
                                borderRadius: "5px",
                                fontSize: ".85rem",
                                width: "20rem",
                                height: "2rem"
                            }}
                                onClick={() => handleSelectChange(btn)}
                                value={btn.value} >
                                {btn.label}
                            </button >
                        </span>
                    )
                })}
                <div className="brand-category-header-container row">

                    {(selectedValue.value === 'people_and_community' || selectedValue.value === 'zero_waste_cities') &&
                        < div className="col-10 brand-category-filter-container">
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Region" selected={selectedRegion.length === 0} options={region} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="BU" selected={selectedBU.length === 0} options={bu} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Country" selected={selectedCountry.length === 0} options={country} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Year" selected={selectedYear.length === 0} options={year} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Month" selected={selectedMonth.length === 0} options={month} setParentDropdownValue={setDropDownValues} />
                            </div>
                        </div>

                    }
                    {(selectedValue.value === 'community_participants') &&
                        < div className="col-10 brand-category-filter-container">
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Year" selected={selectedYear.length === 0} options={year} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Quarter" selected={selectedQuarter.length === 0} options={quarter} setParentDropdownValue={setDropDownValues} />
                            </div>
                        </div>

                    }
                    {(selectedValue.value === 'youth_empowered_roadmap_targets') &&
                        < div className="col-10 brand-category-filter-container">
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Region" selected={selectedRegion.length === 0} options={region} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="BU" selected={selectedBU.length === 0} options={bu} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Country" selected={selectedCountry.length === 0} options={country} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="brand-category-dropdown-container">
                                <MultiSelectDropDown title="Year" selected={selectedYear.length === 0} options={year} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div style={{ margin: "2rem 0rem 0rem 0rem", fontSize: ".75rem" }}>
                                <input
                                    type={"file"}
                                    id={"csvFileInput"}
                                    accept={".csv"}
                                    onChange={handleOnChange}
                                />
                            </div>
                        </div>

                    }

                    <div className="col-2 brand-category-add-delete-export-button-container">
                        <button onClick={handleExport} className="ex-pc brand-category-export-button mt-3 col" type="button" value="Export Data" >Export</button>

                        {/* <CSVLink filename='people and community' headers={changeHeaderRank(headers)} data={exportData}>
                            <button className="ex-pc brand-category-export-button mt-3 col" type="button" value="Export Data" >Export</button>
                        </CSVLink> */}
                        {(selectedValue.value === 'people_and_community' || selectedValue.value === 'zero_waste_cities') ?
                            <div className="ym-zw">
                                {/* {checkboxList.length === 0 ? */}
                                {checkboxList.length === 0 ?
                                    <button className="bt-pc brand-category-button mt-3 col" type="button" value="+ Add New" onClick={() => AddModalOpen(true)}><FontAwesomeIcon icon={faPlus} className="search-icon" size="1x" /> Add New</button>
                                    : <button className="bt-pc brand-category-button mt-3 col" type="button" value="Delete" onClick={handleDeleteButton}><FontAwesomeIcon icon={faTrash} className="search-icon" size="1x" /> Delete</button>}
                            </div>
                            :
                            <div className="ym-zw">
                                {!editTable ? (
                                    <button className="bt-pc brand-category-button mt-3 col" type="button" value="+ Add New" onClick={() => handleEditTable()}><FontAwesomeIcon icon={faEdit} className="search-icon" size="1x" /> Edit</button>
                                ) : (
                                    <button className="bt-pc brand-category-button mt-3 col" type="button" value="+ Add New" ><FontAwesomeIcon icon={faEdit} className="search-icon" size="1x" /> Edit</button>
                                )}
                            </div>
                        }
                    </div>
                    {tableData && tableData.length > 0 &&
                        <div style={{ marginLeft: "-0.8rem", marginTop: "0.5rem" }} className="brand-category-table-container">
                            {(selectedValue.value !== 'community_participants' && selectedValue.value !== 'youth_empowered_roadmap_targets') &&
                                <TableList columns={selectedValue.value === 'people_and_community' ? pandcColumns : zwcColumns}
                                    channelCategoryMappingList={tableData}
                                    setSelectedListInParent={setSelectedListInParent}
                                    handleEditClickInParent={handleEditClickInParent}
                                //={props.formId} adminIds={adminIds} 
                                />
                            }
                            {(selectedValue.value == 'community_participants') &&
                                <CommunityParticipantTable columns={cpColumns}
                                    tableDataList={tableData}
                                    initialData={initialData}
                                    editTable={editTable}
                                    handleEdit={handleEdit}
                                    handleCancelButton={handleCancelButton}

                                // setSelectedListInParent={setSelectedListInParent}
                                // handleEditClickInParent={handleEditClickInParent}
                                />
                            }
                            {(selectedValue.value == 'youth_empowered_roadmap_targets') &&
                                <YouthRoadmapTable columns={yrColumns}
                                    tableDataList={tableData}
                                    initialData={initialData}
                                    editTable={editTable}
                                    handleEdit={handleEdit}
                                    handleCancelButton={handleCancelButton}
                                />
                            }
                        </div>
                    }
                </div>
                {openDeleteModal ?
                    <DeleteModal
                        message={checkboxList.length === EmissionList.length ?
                            "Entire table cannot be deleted " :
                            checkboxList.length > 0 ?
                                "Are you sure that you want to delete the selected row(s) ?" : "Please select atleast one row"}
                        openDialogueBox={openDeleteModal}
                        handleDeleteDialogueBox={closeDeleteModal}
                        handleDelete={handleDelete}
                        rowSelected={checkboxList.length > 0}
                        allSelected={checkboxList.length === EmissionList.length}
                    /> : ''}
                {openEditModal ?
                    <EditModal
                        message={"Edit"}
                        openDialogueBox={openEditModal}
                        handleEditDialogueBox={closeEditModal}
                        handleEdit={handleEdit}
                        rowSelected={editRow}
                        disabledOption={disabledOption}
                        type={selectedValue.value === 'people_and_community' ? 'pnc' : 'zwc'}
                    /> : ''}
                {openAddModal ?
                    <AddModal
                        message={"Add the details"}
                        openDialogueBox={openAddModal}
                        handleAddDialogueBox={closeAddMOdal}
                        handleAdd={handleAddModal}
                        rowSelected={editRow}
                        disabledOption={disabledOption}
                        type={selectedValue.value === 'people_and_community' ? 'pnc' : 'zwc'}
                    /> : ''}
            </div>
            {/* // } */}
            <Toaster />
        </div >

    )
}

export default PeopleCommunity