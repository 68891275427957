const initialState = {
    tableData: [],
    countryList: [],
    regionList: [],
    buList: [],
    columnList: [],
    loading: true,
    formList: [],
    matrix: [],
    toast: {}
}

const EmissionReducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch (type) {

        case 'SHOW_TOAST': {
            return {
                ...state,
                toast: payload,
                loading: false
            }
        }
        case 'EMISSION_TABLE_DATA':
            return {
                ...state,
                tableData: payload,
                loading: false,
            }

        case 'EMISSION_COUNTRY_LIST':
            return {
                ...state,
                countryList: payload
            }

        case 'EMISSION_BU_LIST':
            return {
                ...state,
                buList: payload
            }

        case 'EMISSION_REGION_LIST':
            return {
                ...state,
                regionList: payload
            }
        case 'EMISSION_COLUMN_LIST':
            return {
                ...state,
                columnList: payload
            }
        case 'SET_EMISSION_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'FORMS_LIST':
            return {
                ...state,
                formList: payload
            }
        case 'EMISSION_REGION_MATRIX': {
            return {
                ...state,
                matrix: payload
            }
        }

        default:
            return state
    }
}

export default EmissionReducer