import React, { useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '22vw',
    // height: '434px',
    minHeight: '37vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
    overflow: 'auto',
    // display: 'flex'
};

export default function DeleteModal(props) {


    const handleDialogueBox = () => {
        props.handleDeleteDialogueBox();
    }

    const handleOnSubmit = () => {
        props.handleDelete();
    }

    const handleOnCancel = () => {
        props.handleDeleteDialogueBox();
    }

    return (
        <Modal
            open={props.openDialogueBox}
            onClose={handleDialogueBox}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="brand-category-dialogue-icon-container">
                    <FontAwesomeIcon icon={faInfoCircle} className="brand-category-dialogue-icon" size="2x"
                    />
                </div>
                {props.allSelected &&
                    <div className="brand-category-dialogue-box-header">
                        <h4>All rows cannot be deleted !</h4>
                    </div>
                }
                {!props.allSelected &&
                    <div className="brand-category-dialogue-box-header">
                        <h4>{props.rowSelected ? "Delete" : "No row selected !"}</h4>
                    </div>
                }
                <div style={{ marginLeft: "2rem", marginTop: ".75rem", maxWidth: "15rem" }}>
                    <p>{props.message}</p>
                </div>
                {props.rowSelected && !props.allSelected &&
                    <div className="brand-category-dialogue-button-container">
                        <Button className="brand-category-dialogue-button-submit" onClick={handleOnSubmit} autoFocus>
                            Yes
                        </Button>
                    </div>
                }
                {props.rowSelected && !props.allSelected &&
                    <div className="brand-category-dialogue-button-container">
                        <button className="brand-category-form-modal-cancel-button mt-3" onClick={handleOnCancel} autoFocus>
                            No
                        </button>
                    </div>
                }
                {!props.rowSelected || props.allSelected &&
                    <div className="brand-category-dialogue-button-container">
                        <Button className="brand-category-dialogue-button-submit" onClick={handleOnCancel} autoFocus>
                            Ok
                        </Button>
                    </div>
                }
            </Box>
        </Modal>

    )
}
