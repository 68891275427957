import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SKUSpecificDropDown = (props) => {

    const [selectedValue, setSelectedValue] = React.useState(props.currentSelectedExclusion)
    const [selectedLabel, setSelectedLabel] = React.useState("")

    const handleOnChange = (e) => {
        // let selectedText = e.target.options[e.target.selectedIndex].text
        // setSelectedLabel(selectedText)
        props.setParentDropdownValue(props.type, e.target.value, props.sku_id)
        setSelectedValue(e.target.value)
    }

    const handleDisable = () => {
        if(props.is_current_quarter === false){
            return true
        }
        else if(props.cutofftime === true){
            if (props.admin){
                return false
            }
            return true
        }
        else {
            if (props.type === 'type' && props.other !== 'A') {
                return true
            }
        }
        return false
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            setSelectedValue(props.currentSelectedExclusion);
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <>
            <select
                name="teamName"
                className="sku-exclusion-drop-down"
                onChange={handleOnChange}
                value={selectedValue !== null ? selectedValue : ''}
                // defaultValue={selectedValue !== null ? selectedValue : ''}
                disabled={handleDisable()}
                required
            >
                <option value='' disabled >Select</option>
                {props.options.map(each => (
                    <option
                        className="select-dropdown-option"
                        key={each.id}
                        value={each.id}
                        name={each.title}
                        title={each.title}
                        // disabled={props.type === "type" ? props.other === "A" && props.is_current_quarter : !props.is_current_quarter}
                        // defaultValue={each.id == selectedValue}
                    >
                        {props.type === "type" ? each.title : each.id}
                    </option>
                ))}
            </select>
        </> 
    );
};

export default SKUSpecificDropDown;