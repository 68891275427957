import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Consolidated1 from './consolidated1';
import Consolidated2 from './consolidated2';
import Consolidated3 from './consolidated3';

const ConsolidatedRoute = (props) => {
    const [adminIds, setAdminIds] = React.useState(localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')).user.form_admin_list : []);

    const loadComponent = (formId) => {
        switch (formId) {
            case 'formecomm':
                return <Consolidated1 formId={formId}/>
                break;
            case 'formsku':
                return <Consolidated2 formId={formId} adminIds={adminIds}/>
                break;
            case 'formmap':
                return <Consolidated3 formId={formId} adminIds={adminIds}/>;
                break;
            default:
                return;
        }
    }

    return (
        <>
            {
                loadComponent(props.formId)
            }
        </>
    );
};

const mapStateProps = (state) => {
    return {
        formId: state.sidebar.formId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateProps, mapDispatchToProps)(ConsolidatedRoute);