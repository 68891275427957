import React, { useState, useEffect } from 'react';
import "../../App.css"
import { BASE_URL, INPUT_FORMS, EXPORT, SKU_PERF, EXCLUSIONS, STATUSES, BULK_UPDATE, QUARTERS, TYPES, CUTOFFTIME, FILTERS, BEST_PERFORMING } from '../../constant/apiEndpoints';
import APIService from '../../services/APIService';
import MultipleSelectCheckmarks from '../../components/dropDownMultiSelectCustom'
import SingleSelectDropDown from '../../components/singleSelectDropDown';
import Spinner from 'react-bootstrap/Spinner';
import SKUSpecificSearchBar from '../../components/skuSpecificSearchBar';
import toast, { Toaster } from 'react-hot-toast';
import SKUDialogueBox from '../../components/skuDialogueBox';
import SKUBanPopupModal from '../../components/skuBanPopupModal';
import TableSKUExclusion from '../../components/tableSKUExclusion'

import { grey } from '@mui/material/colors';
import TableSKUBestPerforming from '../../components/tableSKUBestPerforming';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const initialColumns = [
    { id: 'business_unit_title', label: 'Business Unit', minWidth: 100, maxWidth: 200, align: 'center', is_sortable: true, is_sorted: false, sort_type: "asc", sort_name: "business_unit" },
    { id: 'ban_description', label: 'BAN Description', minWidth: 300, maxWidth: '', align: 'center', is_sortable: true, is_sorted: false, sort_type: "asc", sort_name: "ban_description" },
    {
        id: 'ban_number',
        label: 'BAN Number',
        minWidth: 200,
        maxWidth: 200,
        align: 'center',
        is_sortable: true,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "ban_number",
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'net_contribution',
        label: 'Net Contribution',
        minWidth: 200,
        maxWidth: 200,
        align: 'right',
        is_sortable: true,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "net_contribution",
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'volume',
        label: 'Volume (in UC)',
        minWidth: 150,
        maxWidth: 100,
        align: 'right',
        is_sortable: true,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "volume",
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'exclusion',
        label: 'Exclusion',
        minWidth: 200,
        maxWidth: 100,
        align: 'right',
        is_sortable: false,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "exclusion",
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'type',
        label: 'Narrative',
        minWidth: 300,
        maxWidth: 300,
        align: 'center',
        is_sortable: false,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "type",
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'comment',
        label: 'Comment',
        minWidth: 100,
        maxWidth: 350,
        align: 'center',
        is_sortable: false,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "comment",
        // format: (value) => value.toFixed(2),
    },

];

const User2 = (props) => {

    const [businessUnitList, setBusinessUnitList] = React.useState([]);
    const [bestPerformingBusinessUnitList, setBestPerformingBusinessUnitList] = React.useState([]);
    const [exclusionStatusFilterList, setExclusionStatusFilterList] = React.useState([]);
    const [typeFilterList, setTypeFilterList] = React.useState([]);
    const [itemCategoryList, setItemCategoryList] = useState([]);
    const [quarterList, setQuarterList] = React.useState([]);
    const [bestPerformingQuarterList, setBestPerformingQuarterList] = React.useState([]);
    const [columns, setColumns] = React.useState(initialColumns);
    const [skuPerformanceList, setSkuPerformanceList] = React.useState([]);
    const [skuBestPerformingList, setSkuBestPerformingList] = React.useState([]);
    const [skuExclusionStatuses, setSkuExclusionStatuses] = React.useState([]);
    const [changedSKUExclusionObjects, setChangedSKUExclusionObjects] = React.useState([]);
    const [changedSKUBestPerformingObjects, setChangedSKUBestPerformingObjects] = React.useState([]);
    const [changedSKUDeleteListObjects, setChangedSKUDeleteListObjects] = React.useState([]);
    const [skuExclusionTypes, setSkuExclusionTypes] = React.useState([]);
    const [selectedForm, setSelectedForm] = React.useState(props.formId) 
    const [selectedBusinessUnits, setSelectedBusinessUnits] = React.useState([]);
    const [selectedBestPerformingBusinessUnits, setBestPerformingSelectedBusinessUnits] = React.useState([]);
    const [selectedExclusionStatuses, setSelectedExclusionStatuses] = React.useState([]);
    const [selectedItemCategoryList, setSelectedItemCategoryList] = useState([]);
    const [selectedTypes, setSelectedTypes] = React.useState([]);
    const [selectedQuarters, setSelectedQuarters] = React.useState([]);
    const [selectedbestPerformingQuarters, setSelectedBestPerformingQuarters] = React.useState([])
    const [currentQuarter, setCurrentQuarter] = React.useState("")
    const [currentBestPerformingQuarter, setCurrentBestPerformingQuarter] = React.useState("")
    const [dropDownTimer, setDropDownTimer] = useState(0);
    const [search, setSearch] = useState("");
    const [bestPerformingSearch, setBestPerformingSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [bestPerformingTabLoading, setBestPerformingTabLoading] = useState(true);
    const [openCommentDialogueBox, setOpenCommentDialogueBox] = useState(false);
    const [isBanPopupModalOpen, setIsBanPopupModalOpen] = useState(false);
    const [selectedBan, setSelectedBan] = useState(null);
    const [selectedBU, setSelectedBU] = useState("");
    const [isExclusionCutOffTime, setExclusionCutOffTime] = useState(false);
    const [isNarrativeCutOffTime, setNarrativeCutOffTime] = useState(false);
    const [isTypeCommentApplicable, setIsTypeCommentApplicable] = useState(false);
    const [alertMessage, setAlertMessage] = useState("")
    const [selectedTable, setSelectedTable] = useState("sku_exclusion_table")
    const [admin, setAdmin] = React.useState(props.adminIds.includes(props.formId) ? true : false)

    const handleCommentDialogueBox = () => {
        setOpenCommentDialogueBox(!openCommentDialogueBox)
        if (isTypeCommentApplicable) setIsTypeCommentApplicable(false)
    }

    const handleBanPopupModal = (id = null, selectedBU = "") => {
        if (id !== null) {
            setSelectedBan(id)
        }
        if (selectedBU !== "") {
            setSelectedBU(selectedBU)
        }
        setIsBanPopupModalOpen(!isBanPopupModalOpen)
    }

    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Business Unit") {
            setSelectedBusinessUnits(objs)
        }
        if (valueType === "Quarter") {
            setSelectedQuarters(objs)
        }
        if (valueType === "Exclusion Reason") {
            setSelectedExclusionStatuses(objs)
        }
        if (valueType === "Narrative") {
            setSelectedTypes(objs)
        }
        if (valueType === "Item Category") {
            setSelectedItemCategoryList(objs)
        }
        //Loading KBI Values which is dependent on Business Unit Dropdown
        if (valueType === "Business Unit") {
            loadSKUPerformanceList(objs, true, search, columns, selectedQuarters, selectedExclusionStatuses, selectedTypes, selectedItemCategoryList);
            loadQuarters(objs, selectedExclusionStatuses, selectedTypes, selectedItemCategoryList);
            loadExclusionStatusFilter(objs, selectedTypes, selectedQuarters, selectedItemCategoryList);
            loadTypeFilter(objs, selectedExclusionStatuses, selectedQuarters, selectedItemCategoryList);
            loadItemCategoryList(objs,selectedExclusionStatuses, selectedQuarters, selectedTypes)
        }
        //Loading KBI Values which is dependent on Quarter Dropdown
        if (valueType === "Quarter") {
            loadSKUPerformanceList(selectedBusinessUnits, true, search, columns, objs, selectedExclusionStatuses, selectedTypes, selectedItemCategoryList);
            loadBusinessUnits(selectedExclusionStatuses, selectedTypes, objs, selectedItemCategoryList);
            loadExclusionStatusFilter(selectedBusinessUnits, selectedTypes, objs, selectedItemCategoryList);
            loadTypeFilter(selectedBusinessUnits, selectedExclusionStatuses, objs, selectedItemCategoryList);
            loadItemCategoryList(selectedBusinessUnits,selectedExclusionStatuses, objs, selectedTypes)
        }
        //Loading KBI Values which is dependent on Exclusion Reason Dropdown
        if (valueType === "Exclusion Reason") {
            loadSKUPerformanceList(selectedBusinessUnits, true, search, columns, selectedQuarters, objs, selectedTypes, selectedItemCategoryList);
            loadBusinessUnits(objs, selectedTypes, selectedQuarters, selectedItemCategoryList);
            loadQuarters(selectedBusinessUnits, objs, selectedTypes, selectedItemCategoryList);
            loadTypeFilter(selectedBusinessUnits, objs, selectedQuarters, selectedItemCategoryList);
            loadItemCategoryList(selectedBusinessUnits,objs, selectedQuarters, selectedTypes)            
        }
        //Loading KBI Values which is dependent on Type Dropdown
        if (valueType === "Narrative") {
            loadSKUPerformanceList(selectedBusinessUnits, true, search, columns, selectedQuarters, selectedExclusionStatuses, objs, selectedItemCategoryList);
            loadBusinessUnits(selectedExclusionStatuses, objs, selectedQuarters, selectedItemCategoryList);
            loadQuarters(selectedBusinessUnits, selectedExclusionStatuses, objs, selectedItemCategoryList);
            loadExclusionStatusFilter(selectedBusinessUnits, objs, selectedQuarters, selectedItemCategoryList);
            loadItemCategoryList(selectedBusinessUnits,selectedExclusionStatuses, selectedQuarters, objs)
        }
        if (valueType === "Item Category") {
            loadSKUPerformanceList(selectedBusinessUnits, true, search, columns, selectedQuarters, selectedExclusionStatuses, selectedTypes, objs);
            loadBusinessUnits(selectedExclusionStatuses, selectedTypes, selectedQuarters, objs);
            loadQuarters(selectedBusinessUnits, selectedExclusionStatuses, selectedTypes, objs);
            loadExclusionStatusFilter(selectedBusinessUnits, selectedTypes, selectedQuarters, objs);
            loadTypeFilter(selectedBusinessUnits, selectedTypes, selectedQuarters, objs);
        }
    }

    const setBestPerformingDropDownValues = (objs, valueType) => {
        if (valueType === "Business Unit") {
            setBestPerformingSelectedBusinessUnits(objs)
        }
        if (valueType === "Quarter") {
            setSelectedBestPerformingQuarters(objs)
        }
        //Loading KBI Values which is dependent on Business Unit Dropdown
        if (valueType === "Business Unit") {
            loadSKUBestPerformingList(objs, true, bestPerformingSearch, columns, selectedbestPerformingQuarters);
            loadBestPerformingQuarters(objs);
        }
        //Loading KBI Values which is dependent on Quarter Dropdown
        if (valueType === "Quarter") {
            loadSKUBestPerformingList(selectedBestPerformingBusinessUnits, true, bestPerformingSearch, columns, objs);
            loadBestPerformingBusinessUnits(objs);
        }
    }

    const maintainUserInterationsWithData = (data) => {
        const result = data.map(function (x) {
            var result = changedSKUExclusionObjects.filter(a1 => a1.id == x.id);
            if (result.length > 0) { x.exclusion_id = result[0].exclusion_id; }
            return x
        })

        return result
    }
    const maintainUserInterationsWithDataForBestPerforming = (data) => {
        const result = data.map(function (x) {
            var result = changedSKUBestPerformingObjects.filter(a1 => a1.id == x.id);
            if (result.length > 0) { x.exclusion_id = result[0].exclusion_id; }
            return x
        })

        return result
    }

    const updateChangedSKUExclusions = (data) => {
        // setChangedSKUExclusions(data);
        setIsTypeCommentApplicable(validateData(data));
        setChangedSKUExclusionObjects(data);
    }

    const updateChangedSKUDelete = (data) => {
        // setChangedSKUExclusions(data);
        setChangedSKUDeleteListObjects(data);
    }

    const updateChangedSKUBestPerforming = (data) => {
        // setChangedSKUExclusions(data);
        setChangedSKUBestPerformingObjects(data);
    }

    //Dropdown Handler As Parent Component
    const setExclusionDropDownValues = (data) => {
        setSkuPerformanceList(data);
    }

        //Dropdown Handler As Parent Component
    const setBestPerformingValues = (data) => {
        setSkuBestPerformingList(data);
    }

    const handleQuarterSelection = (value) => {
        setSelectedQuarters(value);
        loadSKUPerformanceList(selectedBusinessUnits, true, search, columns, value, selectedItemCategoryList, selectedExclusionStatuses, selectedTypes, selectedItemCategoryList);
    }

    const handleQuarterSelectionForBestPerforming = (value) => {
        setSelectedBestPerformingQuarters(value);
        loadSKUBestPerformingList(selectedBusinessUnits, true, bestPerformingSearch, columns, value);
    }

    const handleSorting = (newData) => {
        loadSKUPerformanceList(selectedBusinessUnits, true, search, newData);
    }

    const handleBestPerformingSorting = (newData) => {
        loadSKUBestPerformingList(selectedBusinessUnits, true, bestPerformingSearch, newData);
    }

    //Support Function For BusinessUnit List
    const createStringFromBusinessUnitObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return encodeURIComponent(newString)
    }

    //AJAX Call To Load Business Units
    const loadBusinessUnits = async (exclusionStatuses = selectedExclusionStatuses, types = selectedTypes, quarters = selectedQuarters, categories = selectedItemCategoryList) => {
        //ExclusionStatus filter
        let selectedExclusionStatusFilter = getExclusionStatusFilter(exclusionStatuses)
        //Type filter
        let selectedTypeFilter = getTypeFilter(types)
        //Quarter filter
        let selectedQuarterFilter = getQuarterFilter(quarters)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(categories)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=business_unit__name` + selectedExclusionStatusFilter + selectedTypeFilter + selectedQuarterFilter + selectedItemCategoryFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user businessunitlist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setBusinessUnitList(response.results);
            }
        }
    }

    //AJAX Call To Load Best Performing Business Units
    const loadBestPerformingBusinessUnits = async (quarters = selectedbestPerformingQuarters) => {
        
        //Quarter filter
        let selectedQuarterFilter = getQuarterFilter(quarters)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + BEST_PERFORMING + FILTERS + `?selected_form=${props.formId}` + `&filter_type=business_unit__name` + selectedQuarterFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user businessunitlist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setBestPerformingBusinessUnitList(response.results);
            }
        }
    }

    //AJAX Call To Load Exclusion Reason Filter Dta
    const loadExclusionStatusFilter = async (businessUnits = selectedBusinessUnits, types = selectedTypes, quarters = selectedQuarters, categories = selectedItemCategoryList) => {
        //BusinessUnit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnits)
        //Type filter
        let selectedTypeFilter = getTypeFilter(types)
        //Quarter filter
        let selectedQuarterFilter = getQuarterFilter(quarters)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(categories)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=exclusion_id` + selectedBusinessUnitFilter + selectedTypeFilter + selectedQuarterFilter + selectedItemCategoryFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user businessunitlist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setExclusionStatusFilterList(response.results);
            }
        }
    }

    //AJAX Call To Load Type Filter Dta
    const loadTypeFilter = async (businessUnits = selectedBusinessUnits, exclusionStatuses = selectedExclusionStatuses, quarters = selectedQuarters, categories = selectedItemCategoryList) => {
        //BusinessUnit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnits)
        //ExclusionStatus filter
        let selectedExclusionStatusFilter = getExclusionStatusFilter(exclusionStatuses)
        //Quarter filter
        let selectedQuarterFilter = getQuarterFilter(quarters)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(categories)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=type__title` + selectedBusinessUnitFilter + selectedExclusionStatusFilter + selectedQuarterFilter + selectedItemCategoryFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user businessunitlist API", response.results.message)
        } else {
                setTypeFilterList(response.results);
            
        }
    }

    const loadItemCategoryList = async (businessUnits = selectedBusinessUnits, exclusionStatuses = selectedExclusionStatuses, quarters = selectedQuarters, types = selectedTypes) => {
        //BusinessUnit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnits)
        //ExclusionStatus filter
        let selectedExclusionStatusFilter = getExclusionStatusFilter(exclusionStatuses)
        //Quarter filter
        let selectedQuarterFilter = getQuarterFilter(quarters)
        //Type filter
        let selectedTypeFilter = getTypeFilter(types)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=category` + selectedQuarterFilter + selectedBusinessUnitFilter + selectedTypeFilter + selectedExclusionStatusFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Category List API", response.results.message)
        } else {
                setItemCategoryList(response.results);
        }
    }

    //AJAX Call To Load Quarters
    const loadQuarters = async (businessUnits = selectedBusinessUnits, exclusionStatuses = selectedExclusionStatuses, types = selectedTypes, categories = selectedItemCategoryList) => {
        //BusinessUnit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnits)
        //ExclusionStatus filter
        let selectedExclusionStatusFilter = getExclusionStatusFilter(exclusionStatuses)
        //Type filter
        let selectedTypeFilter = getTypeFilter(types)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(categories)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + QUARTERS + `?selected_form=${props.formId}` + selectedBusinessUnitFilter + selectedExclusionStatusFilter + selectedTypeFilter + selectedItemCategoryFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user quarterlist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                const transformedData = response.results.map(({ label, value, is_current_quarter }) => ({ id: value, title: label, is_current_quarter: is_current_quarter }));
                setQuarterList(transformedData);
                //Setting Current Quarter
                var object = transformedData.find(obj => {
                    return obj.is_current_quarter === true
                })
                setCurrentQuarter(object.id)
            }
        }
    }

    //AJAX Call To Load Quarters
    const loadBestPerformingQuarters = async (businessUnits = selectedBusinessUnits) => {
        //BusinessUnit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnits)
        
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + QUARTERS + `?selected_form=${props.formId}` + selectedBusinessUnitFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user quarterlist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                const transformedData = response.results.map(({ label, value, is_current_quarter }) => ({ id: value, title: label, is_current_quarter: is_current_quarter }));
                setBestPerformingQuarterList(transformedData);
                //Setting Current Quarter
                var object = transformedData.find(obj => {
                    return obj.is_current_quarter === true
                })
                setCurrentBestPerformingQuarter(object.id)
            }
        }
    }

    const loadSKUExclusionStatuses = async () => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + STATUSES + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user businessunitlist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // const transformedData = response.results.map(({ id, title }) => ({ label: title, value: id }));
                setSkuExclusionStatuses(response.results);
            }
        }
    }

    const loadSKUExclusionTypes = async () => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + TYPES + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in user businessunitlist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // const transformedData = response.results.map(({ id, title }) => ({ label: title, value: id }));
                setSkuExclusionTypes(response.results);
            }
        }
    }

    const loadCutOffTime = async () => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + CUTOFFTIME + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Channel List API", response.results.message)
        } else {
            if (response.results) {
                setExclusionCutOffTime(response.results.exclusion_cut_off_time_active);
                setNarrativeCutOffTime(response.results.narrative_cut_off_time_active)
            }
        }
    }

    const getBusinessUnitFilter = (businessUnitIDs) => {
        //Getting User Selected Business Units By State/User Action
        let selectedBusinessUnitList = businessUnitIDs !== null ? businessUnitIDs : selectedBusinessUnits
        let selectedBusinessUnitFilter = ""
        if (selectedBusinessUnitList.length !== 0) {
            selectedBusinessUnitFilter = `&selected_business_unit=${createStringFromBusinessUnitObjectList(selectedBusinessUnitList)}`
        }
        return selectedBusinessUnitFilter
    }

    const getExclusionStatusFilter = (exclusionStatusIDs) => {
        //Getting User Selected Exclusion Reason By State/User Action
        let selectedExclusionStatusList = exclusionStatusIDs !== null ? exclusionStatusIDs : selectedExclusionStatuses
        let selectedExclusionStatusFilter = ""
        if (selectedExclusionStatusList.length !== 0) {
            selectedExclusionStatusFilter = `&selected_exclusion=${createStringFromBusinessUnitObjectList(selectedExclusionStatusList)}`
        }
        return selectedExclusionStatusFilter
    }

    const getTypeFilter = (typeIDs) => {
        //Getting User Selected Type By State/User Action
        let selectedTypeList = typeIDs !== null ? typeIDs : selectedTypes
        let selectedTypeFilter = ""
        if (selectedTypeList.length !== 0) {
            selectedTypeFilter = `&selected_type=${createStringFromBusinessUnitObjectList(selectedTypeList)}`
        }
        return selectedTypeFilter
    }

    const getItemCategoryFilter = (itemCategoryIDs) => {
        //Getting User Selected Data By State/User Action
        let selectedItemCategory = itemCategoryIDs !== null ? itemCategoryIDs : selectedItemCategoryList
        let selectedItemCategoryFilter = ""
        if (selectedItemCategory.length !== 0) {
            selectedItemCategoryFilter = `&selected_item_category=${createStringFromBusinessUnitObjectList(selectedItemCategory)}`
        }
        return selectedItemCategoryFilter
    }

    const getQuarterFilter = (quarterIDs) => {
        //Getting User Selected Business Units By State/User Action
        let selectedQuarterList = quarterIDs !== null ? quarterIDs : selectedQuarters
        let selectedQuarterFilter = ""
        if (selectedQuarterList.length !== 0) {
            selectedQuarterFilter = `&selected_quarter=${createStringFromBusinessUnitObjectList([{ "value": selectedQuarterList }])}`
        }
        return selectedQuarterFilter
    }

    const getSearchFilter = (seachFilterArg) => {
        let searchFilter = ""
        if (seachFilterArg.length !== 0) {
            searchFilter = `&search=${encodeURIComponent(seachFilterArg)}`
        }
        return searchFilter
    }

    const getSortFilter = (sorting) => {
        let sortFilter = ""
        let asc = ""
        let desc = ""
        for (var i = 0; i < sorting.length; i++) {
            if (sorting[i].is_sortable && sorting[i].is_sorted) {
                if (sorting[i].sort_type === "asc") {
                    sortFilter = sortFilter + sorting[i].sort_name + ","
                } else {
                    sortFilter = sortFilter + '-' + sorting[i].sort_name + ","
                }
            }
        }
        // if(asc.length > 0) sortFilter = `&asc=${asc}`
        // if(desc.length > 0) sortFilter = sortFilter + `&desc=${desc}`
        return `&order=${sortFilter}`
    }

    //AJAX Call To Load SKU Performance List
    const loadSKUPerformanceAPICall = async (selectedBusinessUnitFilter, searchFilter, sortFilter, selectedQuarterFilter, selectedExclusionStatusFilter, selectedTypeFilter, selectedItemCategoryFilter) => {
        selectedQuarterFilter = selectedQuarterFilter !== undefined ? selectedQuarterFilter : ""
        selectedExclusionStatusFilter = selectedExclusionStatusFilter !== undefined ? selectedExclusionStatusFilter : ""
        selectedTypeFilter = selectedTypeFilter !== undefined ? selectedTypeFilter : ""
        selectedItemCategoryFilter = selectedItemCategoryFilter !== undefined ? selectedItemCategoryFilter : ""
        setLoading(true)
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + `?selected_form=${props.formId}` + selectedBusinessUnitFilter + searchFilter + sortFilter + selectedQuarterFilter + selectedExclusionStatusFilter + selectedTypeFilter + selectedItemCategoryFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in ski exlusion list API", response.results.message)
        } else {
            // if (response.results.length > 0) {
            // const transformedData = response.results.map(({ id, title }) => ({ label: title, value: id }));
            var data = maintainUserInterationsWithData(response.results);
            setSkuPerformanceList(data);
            // setSkuPerformanceListReference(response.results);
            // }
        }
        setLoading(false);
    }

    //AJAX Call To Load SKU Performance List
    const loadSKUBestPerformingAPICall = async (selectedBusinessUnitFilter, searchFilter, sortFilter, selectedQuarterFilter) => {
        selectedQuarterFilter = selectedQuarterFilter !== undefined ? selectedQuarterFilter : ""
        setBestPerformingTabLoading(true)
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + BEST_PERFORMING + `?selected_form=${props.formId}` + selectedBusinessUnitFilter + searchFilter + sortFilter + selectedQuarterFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in ski exlusion list API", response.results.message)
        } else {
            // if (response.results.length > 0) {
            // const transformedData = response.results.map(({ id, title }) => ({ label: title, value: id }));
            var data = maintainUserInterationsWithDataForBestPerforming(response.results);
            setSkuBestPerformingList(data);
            // setSkuPerformanceListReference(response.results);
            // }
        }
        setBestPerformingTabLoading(false);
    }

    const loadSKUPerformanceList = async (businessUnitIDs = null, useTimer, seachFilterArg = "", sorting = [], quarterIDs = null, exclusionIDs = null, typeIDs = null, categories = null) => {
        //BusinessUnit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)
        //ExclusionStatus filter
        let selectedExclusionStatusFilter = getExclusionStatusFilter(exclusionIDs)
        //Type filter
        let selectedTypeFilter = getTypeFilter(typeIDs)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(categories)
        //Quarter filter
        let selectedQuarterFilter = getQuarterFilter(quarterIDs)
        //Search filter
        let searchFilter = getSearchFilter(seachFilterArg);
        //Sorting
        let sortFilter = getSortFilter(sorting)
        //Clearing Timeout Before Adding New Timeout(Previous Timeout)

        if (dropDownTimer) {
            clearTimeout(dropDownTimer);
        }
        //Add Timeout Before Hitting API
        if (useTimer) {
            setDropDownTimer(
                setTimeout(function () {
                    // loadBrandCategoryMappingAPICall(selectedBrandFilter, selectedSummarizedBrandFilter, selectedCategorySAPFilter);
                    toast.promise(loadSKUPerformanceAPICall(selectedBusinessUnitFilter, searchFilter, sortFilter, selectedQuarterFilter, selectedExclusionStatusFilter, selectedTypeFilter, selectedItemCategoryFilter), {
                        loading: "Loading. wait a moment!",
                        success: 'Data load success.',
                        error: 'Something went wrong.',
                    },
                        {
                            position: 'top-center',
                            className: 'toast-popup',
                        });
                }, 1000)
            )
        } else {
            loadSKUPerformanceAPICall(selectedBusinessUnitFilter, searchFilter, sortFilter);
        }
    }

    const loadSKUBestPerformingList = async (businessUnitIDs = null, useTimer, seachFilterArg = "", sorting = [], quarterIDs = null) => {
        //BusinessUnit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)
        //Quarter filter
        let selectedQuarterFilter = getQuarterFilter(quarterIDs)
        //Search filter
        let searchFilter = getSearchFilter(seachFilterArg);
        //Sorting
        let sortFilter = getSortFilter(sorting)
        //Clearing Timeout Before Adding New Timeout(Previous Timeout)

        if (dropDownTimer) {
            clearTimeout(dropDownTimer);
        }
        //Add Timeout Before Hitting API
        if (useTimer) {
            setDropDownTimer(
                setTimeout(function () {
                    // loadBrandCategoryMappingAPICall(selectedBrandFilter, selectedSummarizedBrandFilter, selectedCategorySAPFilter);
                    toast.promise(loadSKUBestPerformingAPICall(selectedBusinessUnitFilter, searchFilter, sortFilter, selectedQuarterFilter), {
                        loading: "Loading. wait a moment!",
                        success: 'Data load success.',
                        error: 'Something went wrong.',
                    },
                        {
                            position: 'top-center',
                            className: 'toast-popup',
                        });
                }, 1000)
            )
        } else {
            loadSKUBestPerformingAPICall(selectedBusinessUnitFilter, searchFilter, sortFilter);
        }
    }

    const handleSearchSubmit = (search) => {
        setSearch(search);
        loadSKUPerformanceList(selectedBusinessUnits, true, search, columns);
    }

    const handleBestPerformingSearchSubmit = (srch) => {
        setBestPerformingSearch(srch);
        loadSKUBestPerformingList(selectedBestPerformingBusinessUnits, true, srch, columns);
    }

    const handleApplyAfterAPICall = () => {
        loadSKUPerformanceList(selectedBusinessUnits, true, search, columns, selectedQuarters, selectedItemCategoryList);
        setChangedSKUExclusionObjects([]);
    }

    const handleBestPerformingApplyAfterAPICall = () => {
        loadSKUBestPerformingList(selectedBestPerformingBusinessUnits, true, bestPerformingSearch, columns, selectedbestPerformingQuarters);
        setChangedSKUBestPerformingObjects([]);
    }

    const getAPIPayload = (data) => {
        let statusData = []
        let typeData = []
        let data_objects = []

        for (var i = 0; i < data.length; i++) {
            let obj = {exclusion_id: data[i].id}
            if (data[i].exclusion_id) obj['exclusion_status_id'] = data[i].exclusion_id
            if (data[i].type_id && data[i].exclusion_id === 'A') obj['exclusion_type_id'] = data[i].type_id
            if (data[i].comment && data[i].exclusion_id === 'A') obj['comment'] = data[i].comment
            data_objects.push(obj)
        }
        return { "data_objects": data_objects };
    }

    const getBestPerformingAPIPayload = (data) => {
        let data_objects = []

        for (var i = 0; i < data.length; i++) {
            let obj = {exclusion_id: data[i].id}
            data_objects.push(obj)
        }
        return { "data_objects": data_objects };
    }

    const handleSubmitAPICall = async () =>{
        if (changedSKUExclusionObjects.length > 0){
            await handleSKUSubmitAPICall()
        }
        if (changedSKUDeleteListObjects.length > 0){
            await handleBestPerformingSubmitAPICall("true")
        }
    }

    const handleSKUSubmitAPICall = async (comment=null) => {
        toast.loading('Loading...', { position: 'top-center', className: 'toast-popup', duration: 1000 });
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + BULK_UPDATE
        let body = getAPIPayload(changedSKUExclusionObjects)
        if (comment) body["comment"] = comment
        let response = await new APIService().patch(url, body)
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("This field may not be blank.")) {
                    toast.error('Comment is required!', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                } else {
                    toast.error('Something went wrong.', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                }
            }
        } else {
            toast.success('Data updated successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000 });
            handleApplyAfterAPICall();
            handleCommentDialogueBox();
        }
    }

    const handleBestPerformingSubmitAPICall = async (del="false") => {
        toast.loading('Loading...', { position: 'top-center', className: 'toast-popup', duration: 1000 });
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + BEST_PERFORMING + BULK_UPDATE + "?delete=" + del
        let body = getBestPerformingAPIPayload(del === "false" ? changedSKUBestPerformingObjects : changedSKUDeleteListObjects)
        let response = await new APIService().patch(url, body)
        if (response.error) {
            if (response.status === 400) {
                toast.error('Something went wrong.', { position: 'top-center', className: 'toast-popup', duration: 2000 });
            }
        } else {
            toast.success('Data updated successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000 });
            if (del === "false"){
            handleBestPerformingApplyAfterAPICall();
            }
            else{
                loadSKUPerformanceList(selectedBusinessUnits, true, search, columns, selectedQuarters, selectedItemCategoryList);
                setChangedSKUDeleteListObjects([]);
                handleCommentDialogueBox();
            }
        }
    }

    const validateData = (data) => {
        let is_type_available = false;
        let is_comment_available = false;
        if (data.length > 0) {
            data.forEach((element) => {
                if (element.exclusion_id === "A") {
                    if (!element.type_id) {
                        is_type_available = true
                        setAlertMessage("Please make sure you have updated Narrative for every 'A' Exclusion Reason in current session.")
                    } else if (element.comment === "" || element.comment === null) {
                        is_comment_available = true
                        setAlertMessage("Please make sure you have updated Comment for every 'A' Exclusion Reason in current session.")
                    }
                } 
            });
        }
        
        return is_type_available || is_comment_available
    }

    const handleSave = () => {
        setIsTypeCommentApplicable(validateData(changedSKUExclusionObjects));
        setOpenCommentDialogueBox(!openCommentDialogueBox)
    }

    const exportDataHandle = async () => {
        //Business Unit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(null)
        //Quarter filter
        let selectedQuarterFilter = getQuarterFilter(null)
        //Exclusion filter
        let selectedExclusionStatusFilter = getExclusionStatusFilter(null)
        //Type filter
        let selectedTypeFilter = getTypeFilter(null)
        // Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(null)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + EXPORT + `?selected_form=${selectedForm}` + selectedBusinessUnitFilter + selectedQuarterFilter + selectedExclusionStatusFilter + selectedTypeFilter + selectedItemCategoryFilter
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token')).access}`,
            })
        }).then(response =>
            response.blob().then(blob => {
                var today = new Date()
                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = "exclusion_data_" + date + "_" + time + ".xls";
                a.click();
            })
        );
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadSKUExclusionStatuses();
            loadSKUExclusionTypes();
            loadCutOffTime();
            loadQuarters();
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadBestPerformingBusinessUnits();
            loadBestPerformingQuarters();
            // loadCutOffTime();
            
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    useEffect(()=>{
        let mounted = true
        if (mounted) {
        if (selectedTable === "sku_exclusion_table"){
            loadSKUPerformanceList(null, false, search, columns, selectedQuarters, selectedItemCategoryList);
            loadBusinessUnits();
            loadExclusionStatusFilter();
            loadTypeFilter();
            loadQuarters();
            loadItemCategoryList();
        }
        else{
            loadSKUBestPerformingList(null, false, bestPerformingSearch, columns, selectedbestPerformingQuarters);
        }
    }
    return function cleanup() {
        mounted = false
    }
    },[selectedTable])


    return (
        <div className="user-view-container">
        {admin && 
            <div className="row p-0 mb-4 ms-4">
                <div className="d-flex flex-row-reverse">
                        <ToggleButtonGroup
                        value={selectedTable}
                        size="small"
                        color="warning"
                        onChange={(e)=>{
                            setSearch("")
                            setBestPerformingSearch("")
                            setSelectedBusinessUnits("")
                            setBestPerformingSelectedBusinessUnits("")
                            setSelectedExclusionStatuses("")
                            setSelectedTypes("")
                            setSelectedItemCategoryList("")
                            setSelectedTable(e.target.value)}}
                        aria-label="text alignment"
                        >
                        <ToggleButton className="" size="small" value="sku_exclusion_table" >
                            sku exclusion table
                        </ToggleButton>
                        <ToggleButton className="" size="small" value="best_performing_table" >
                        Best Performing Bans
                        </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
        }
            <div className="user-view-header row p-0 mb-4 ms-4">
            {selectedTable === "sku_exclusion_table" ?
                <div className="col-8 p-0 m-0 row">
                    <div className="col-2 p-0 m-0">
                        <MultipleSelectCheckmarks key={"sku-business-unit"} title="Business Unit" options={businessUnitList} setParentDropdownValue={setDropDownValues} />
                    </div>
                        <div className="col-2 p-0 m-0">
                            <MultipleSelectCheckmarks title="Exclusion Reason" options={exclusionStatusFilterList} setParentDropdownValue={setDropDownValues} />
                        </div>
                        <div className="col-2 p-0 m-0">
                            <MultipleSelectCheckmarks title="Narrative" options={typeFilterList} setParentDropdownValue={setDropDownValues} />
                        </div>
                        <div className="col-2 p-0 m-0">
                        <MultipleSelectCheckmarks title="Item Category" options={itemCategoryList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="col-3 p-0 seach-box-container">
                        <SKUSpecificSearchBar key={"sku-search"} handleSearchSubmit={handleSearchSubmit} />
                    </div>
                </div>
                :
                <div className="col-8 p-0 m-0 row">
                    <div className="col-3 p-0 m-0">
                        <MultipleSelectCheckmarks key={"best-performance-business-unit"} title="Business Unit" options={bestPerformingBusinessUnitList} setParentDropdownValue={setBestPerformingDropDownValues} />
                    </div>
                    <div className="col-3 p-0 seach-box-container">
                        <SKUSpecificSearchBar key={"best-performing-search"} handleSearchSubmit={handleBestPerformingSearchSubmit} />
                    </div>
                </div>
            }
                {/* <div className="col-3 p-0 mt-4 row">
                    <div className="sku-export-button-container col-6">
                            <input className="sku-export-button mt-2 col" type="button" value="Export" onClick={exportDataHandle} />
                        </div>
                </div> */}
                <div className="col-4 p-0 mt-4 row">
                    <div className="sku-export-button-container col-8">
                    {selectedTable === "sku_exclusion_table" && 
                        <span>
                            <input className="sku-export-button mt-2" type="button" value="Export"
                                onClick={exportDataHandle} />
                        </span>
                        }
                        <span>
                        {selectedTable === "sku_exclusion_table" ?
                            <input className="exclusion-apply-all-button mt-2" type="button" value="Save"
                                style={{ backgroundColor: (changedSKUExclusionObjects.length > 0 || changedSKUDeleteListObjects.length > 0) ? "#E61C2A" : "#9B9595" }}
                                disabled={!(changedSKUExclusionObjects.length > 0 || changedSKUDeleteListObjects.length > 0)}
                                onClick={handleSave} />
                                :
                                <input className="sku-delist-save-button mt-2" type="button" value="Save Changes"
                                style={{ backgroundColor: changedSKUBestPerformingObjects.length > 0 ? "#E61C2A" : "#9B9595" }}
                                disabled={changedSKUBestPerformingObjects.length < 1}
                                onClick={()=>{handleBestPerformingSubmitAPICall("false")}} />
                        }
                        </span>
                    </div>
                    {/* <div className="sku-quarter-text text-center mt-2 col-3"> */}
                    {/* {skuPerformanceList.length > 0 ? `${skuPerformanceList[0].year} - ${skuPerformanceList[0].time_frame}` : ""} */}
                    {/* <MultipleSelectCheckmarks title="Quarter" options={quarterList} setParentDropdownValue={setDropDownValues} hideTitle={true} showLabelInDropdown={true} /> */}
                    {/* </div> */}
                    {(currentQuarter !== "" || currentBestPerformingQuarter !== "")? <div className="sku-quarter-selection-dropdown-container text-center p-0 mt-2 col-4">
                        <SingleSelectDropDown key={selectedTable} options={selectedTable === "sku_exclusion_table" ? quarterList : bestPerformingQuarterList }
                            preSelectedValue={selectedTable === "sku_exclusion_table" ? currentQuarter : currentBestPerformingQuarter}
                            setParentDropdownValue={selectedTable ==="sku_exclusion_table" ? handleQuarterSelection: handleQuarterSelectionForBestPerforming} />
                    </div> : ""}
                </div>
                
            </div>
            {/* Loader(While Data Loading) */}
            {selectedTable === "sku_exclusion_table" && loading ? <div className="table-data-note"><Spinner animation="border" />
                <p></p>
            </div> : ""}

            {selectedTable !== "sku_exclusion_table" && bestPerformingTabLoading ? <div className="table-data-note"><Spinner animation="border" />
                <p></p>
            </div> : ""}

            {/* Loader(While Data Loading) */}
            {/* Cut Off Time Note Banner */}
            {
                selectedTable === "sku_exclusion_table" && !admin &&
                <>
                {
                    isExclusionCutOffTime && isNarrativeCutOffTime && loading !== true ? 
                    <div className="sku-exclusion-cut-off-note-container">
                        NOTE: Cut off time is going on. You will NOT be able to Save any changes.
                    </div> 
                    : 
                    isExclusionCutOffTime && loading !== true ? 
                    <div className="sku-exclusion-cut-off-note-container">
                        NOTE: Cut off time is active on <u>Exclusion Reason</u>. You will NOT be able to make changes to Exclusion Reason Column.
                    </div> 
                    : ""
                }
                </>
            }
            {/* Cut Off Time Note Banner */}
            {/* Table(If Data Present) */}
            {selectedTable === "sku_exclusion_table" ?
            <>
            {(skuPerformanceList.length > 0 && !loading) ?(
                <div className="sku-table-container">
                    <TableSKUExclusion 
                    handleBanPopupModal={handleBanPopupModal}
                    handleSorting={handleSorting}
                    updateChangedSKUExclusions={updateChangedSKUExclusions}
                    setExclusionDropDownValues={setExclusionDropDownValues}
                    skuPerformanceList={skuPerformanceList}
                    skuExclusionStatuses={skuExclusionStatuses} 
                    skuExclusionTypes={skuExclusionTypes}
                    changedSKUExclusionObjects={changedSKUExclusionObjects}
                    changedSKUDeleteListObjects={changedSKUDeleteListObjects}
                    updateChangedSKUDelete={updateChangedSKUDelete} 
                    isExclusionCutOffTime={isExclusionCutOffTime}
                    isNarrativeCutOffTime={isNarrativeCutOffTime}
                    admin={admin}/>
                                    
                    <footer className="sku-legend-container page-footer font-small blue">
                        <div className="footer-copyright text-center pt-3 row">
                            {/* <div className="font-small col-2">A = Common articles bottom 1% NC and bottom 5% Volume</div>
                            <div className="font-small col-2">B = New launches - less than 12 months on the market</div>
                            <div className="font-small col-2">C = Brands : Monster, Monster Espresso, Innocent</div>
                            <div className="font-small col-2">D = Seasonal, IN/OUT activities, sampling, BIB, TANK</div>
                            <div className="font-small col-2">E = Delisted</div>
                            <div className="font-small col-2">F = Customer dedicated pallets if not launched as permanent SKU</div> */}
                            {skuExclusionStatuses.map((element) => (
                                <div className="font-small col-2">{element.id} = {element.title}</div>
                            ))}
                        </div>
                    </footer>
                </div>) : ""
            }
            {/* Table(If Data Present) */}
            {/* No Data Message */}
            {skuPerformanceList.length === 0 && loading === false ? (<div className="table-data-note"><p>No data found.</p>
                <p></p>
            </div>) : ""}
            </>
            :
            <>          
            {(skuBestPerformingList.length > 0 && !bestPerformingTabLoading) ?(
                <div className="sku-table-container">
                    <TableSKUBestPerforming 
                    handleBanPopupModal={handleBanPopupModal}
                    handleSorting={handleBestPerformingSorting}
                    updateChangedSKUBestPerforming={updateChangedSKUBestPerforming}
                    setBestPerformingValues={setBestPerformingValues}
                    skuPerformanceList={skuBestPerformingList}
                    changedSKUBestPerformingObjects={changedSKUBestPerformingObjects} />
                    
                </div>) : ""
            }
            {/* Table(If Data Present) */}
            {/* No Data Message */}
            {skuBestPerformingList.length === 0 && bestPerformingTabLoading === false ? (<div className="table-data-note"><p>No data found.</p>
                <p></p>
            </div>) : ""
        }
            </>
        }
            {/* No Dta Message */}
            {/* Ban Popup Modal */}
            {isBanPopupModalOpen ?
                <SKUBanPopupModal isBanPopupModalOpen={isBanPopupModalOpen} handleBanPopupModal={handleBanPopupModal}
                    ban_number={selectedBan} business_unit={selectedBU} current_quarter={selectedTable ==="sku_exclusion_table" ? currentQuarter : currentBestPerformingQuarter} selectedForm={selectedForm}
                />
                : ""}
            {/* Ban Popup Modal */}
            {/* Message Dialogue Box */}
            {openCommentDialogueBox ? <SKUDialogueBox openDialogueBox={openCommentDialogueBox}
                handleCommentDialogueBox={handleCommentDialogueBox}
                messageType="Info"
                message={isTypeCommentApplicable ? 
               alertMessage :
               "Do you want to Submit the changes made"}
                handleOnSave={handleSubmitAPICall}
                isTypeCommentApplicable={isTypeCommentApplicable} /> : ""}
            {/* Message Dialogue Box */}
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    );
};

export default User2;