import React, { useState } from "react";
import "../App.css"
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faInfo, faSignOutAlt, faEnvelope, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '22vw',
    // height: '434px',
    minHeight: '47vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
    overflow: 'auto',
    // display: 'flex'
};

export default function BrandCategoryDialogueBox(props) {

    const [comment , setComment] = useState("");

    const handleOnChange = (e) => {
        setComment(e.target.value)
    }

    const handleDialogueBox = () => {
        props.handleDeleteDialogueBox();
    }

    const handleOnSubmit = () => {
        props.handleDelete(comment);
    }

    const handleOnCancel = () => {
        props.handleDeleteDialogueBox();
    }

    return (
        <Modal
            open={props.openDialogueBox}
            onClose={handleDialogueBox}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="brand-category-dialogue-icon-container">
                    <FontAwesomeIcon icon={faInfoCircle} className="brand-category-dialogue-icon" size="2x"
                    />
                </div>
                <div className="brand-category-dialogue-box-header">
                    <h4>Are You Sure?</h4>
                </div>
                <div className="brand-category-dialogue-box-description">
                    <p>{props.message}</p>
                </div>
                <div className="brand-category-dialogue-box-input-box-container">
                    <textarea className="brand-category-dialogue-box-input-box" rows="8" cols="50"
                        placeholder="Type reason here..."
                        value={comment}
                        onChange={handleOnChange}
                        required />
                </div>
                <div className="brand-category-dialogue-button-container">
                    <Button className="brand-category-dialogue-button-submit" onClick={handleOnSubmit} autoFocus>
                        Submit
                    </Button>
                                    </div>
                <div className="brand-category-dialogue-button-container">
                <Button className="brand-category-dialogue-button-cancel" onClick={handleOnCancel} autoFocus>
                        No, I changed my mind!
                    </Button>
                </div>
            </Box>
        </Modal>

    )
}
