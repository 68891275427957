

//to set state in redux
const GetNavLinks = (formId, adminIds) => {
    return function (dispatch) {
        var resData = Object.assign({ type: 'GET_NAV_LINKS' }, { formId, adminIds })
        // console.log('setfilter')
        dispatch(resData)
    }
}

export { GetNavLinks };
