export const EmissionColumns = [
    {
        id: 'bu',
        label: 'BU',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'country',
        label: 'Country',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'quarter',
        label: 'Quarter',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'Scope 1 + 2 Intensity (g CO2e/lpb)',
        label: 'Scope 1 + 2 Intensity (g CO2e/lpb)',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'Abs Emission Scope 1 + 2 (tonnes)',
        label: 'Abs Emission Scope 1 + 2 (tonnes)',
        minWidth: 100,
        align: 'right',

    },
    {
        id: 'Abs Emission Scope 3 (tonnes)',
        label: 'Abs Emission Scope 3 (tonnes)',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'Abs Emission 1 + 2 + 3 (tonnes)',
        label: 'Abs Emission 1 + 2 + 3 (tonnes)',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        align: 'right',

    },

];

export const RoadmapTargetColumns = [
    {
        id: 'bu',
        label: 'BU',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'country',
        label: 'Country',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'Abs Emission Scope 1 + 2 (tonnes)',
        label: 'Abs Emission Scope 1 + 2 (tonnes)',
        minWidth: 100,
        align: 'right',

    },
    {
        id: 'Abs Emission Scope 3 (tonnes)',
        label: 'Abs Emission Scope 3 (tonnes)',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'Abs Emission 1 + 2 + 3 (tonnes)',
        label: 'Abs Emission 1 + 2 + 3 (tonnes)',
        minWidth: 100,
        align: 'right',
    },

];

export const quarter = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' }
]

export const month = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

export const year = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
    2026, 2027, 2028, 2029, 2029, 2030]


export const dates = ['Start Date', 'End Date']
export const intVal = ["No of Projects", "No of Youth Empowered", "% Consumer packaging recyclable", "Cummulative No of YE", "Total No of Employees in Volunteering",
    "No of partnering organisations", "Total Placed in Markets in Units", "Total Recovered for Recycling in Units"]
export const floatVal = ["Sustainably Sourced", "Abs Emission 1 + 2 + 3 (tonnes)",
    "Abs Emission Scope 1 + 2 (tonnes)", "Abs Emission Scope 3 (tonnes)",
    "Scope 1 + 2 Intensity (g CO2e/lpb)", "Sustainable Volume (tonnes)",
    "Total Volume (tonnes)", "% Sustainable Volume", "% Sustainable Sourced",
    "% Primary Packaging Collected for recycling or reuse",
    "Cash (euro)", "Product Value (euro)", "Product Volume (lts)", "In Kind (eur)"]
export const textVal = ["Project Name", 'Type of Sweetner', "Program Name"]