import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SKUQuarterSingleSelectDropDown = (props) => {

    const [selectedValue, setSelectedValue] = React.useState("")
    const [selectedLabel, setSelectedLabel] = React.useState("")

    const handleOnChange = (e) => {
        // let selectedText = e.target.options[e.target.selectedIndex].text
        // setSelectedLabel(selectedText)
        props.setParentDropdownValue(e.target.value)
        setSelectedValue(e.target.value)
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (props.preSelectedValue !== undefined) {
                setSelectedValue(props.preSelectedValue);
            }
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <>
            <label style={{ paddingLeft: "5px", fontSize: "small" }}>Quarter</label>
            <select
                name="teamName"
                className="sku-quarter-filter-single-select-dropdown"
                onChange={handleOnChange}
                value={selectedValue !== "" ? selectedValue : ""}
                // value={selectedValue}
                required
            >
                <option value='' disabled >Select</option>
                {props.options.map(each => (
                    <option
                        className="select-dropdown-option"
                        key={each.id}
                        value={each.id}
                        name={each.title}
                        title={each.title}
                    // defaultValue={each.id == selectedValue}
                    >
                        {each.title}
                    </option>
                ))}
            </select>
        </>
    );
};

export default SKUQuarterSingleSelectDropDown;