import React, { useState, useEffect, useRef } from 'react';
import "../../App.css"
import { BASE_URL, INPUT_FORMS, EXPORT, FILTERS, PRC_FRC, DATA, IMPORT } from '../../constant/apiEndpoints';
import APIService from '../../services/APIService';
import MultipleSelectCheckmarks from '../../components/dropDownMultiSelectCustom'
import Spinner from 'react-bootstrap/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PublishIcon from '@material-ui/icons/Publish';
import {Button, CircularProgress, Grid, Tooltip} from '@material-ui/core';
import TablePriceForecast from '../../components/tablePriceForecast';
import PriceForecastSearchBar from '../../components/priceForecastSearchBar';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


const User4 = (props) => {

    const [countryList, setCountryList] = React.useState([]);
    const [postingPeriodFilterList, setPostingPeriodsFilterList] = React.useState([]);
    const [priceForecastList, setPriceForecastList] = React.useState([]);
    const [changedPriceForecastObjects, setChangedPriceForecastObjects] = React.useState([]);
    const [selectedForm, setSelectedForm] = React.useState(props.formId) 
    const [selectedCountries, setSelectedCountries] = React.useState([]);
    const [selectedPostingPeriod, setSelectedPostingPeriod] = React.useState([]);
    const [dropDownTimer, setDropDownTimer] = useState(0);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [admin, setAdmin] = React.useState(props.adminIds.includes(props.formId) ? true : false)
    const [fileName, setFileName] = useState('');
    const fileInputRef = useRef(null);
    const [importLoading, setImportLoading] = useState(false)
    const [errorImporting, setErrorImporting]  = useState(false)
    const [afterImportComment, setAfterImportComment] = useState("")
    
    const [open, setOpen] = React.useState(false);
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const fileName = file.name;
        setFileName(fileName);
        // Pass the file name to your API
        importData(file, selectedCountries);
        event.target.value = '';
      };
    
      const importData = async (file, countries = selectedCountries) => {
        setImportLoading(true)
        let selectedCountryFilter = getCountryFilter(countries)
        const formData = new FormData();
        formData.append('price_forecast_file', file);
        formData.append('ad_group_ids', JSON.parse(localStorage.userList),);
        let url = BASE_URL + INPUT_FORMS + PRC_FRC + IMPORT + `?selected_form=${props.formId}` + selectedCountryFilter
        let response = await new APIService().post(url, 
            formData, "form-data");
        if (response.error) {
            setImportLoading(false)
            setErrorImporting(true)
            let msg = (response.results.message.includes("insert duplicate")? "Duplicate record found, Please check the Excel if you are inserting with the correct country" : "")
            setAfterImportComment("Error while importing the data. " + msg)
            setOpen(true)
        } else {
            setErrorImporting(false)
            setAfterImportComment("Sucessfully imported the file. " + response.results)
            setImportLoading(false)
            setOpen(true)
        }
        loadCountries("",null)
        loadPostingPeriodFilter("", null)
        loadPriceForecastList(true, "", null, null)
    }
    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Country") {
            setSelectedCountries(objs)
            console.log(objs)
        }

        if (valueType === "Posting Period") {
            setSelectedPostingPeriod(objs)
        }
        //Loading KBI Values which is dependent on Country Dropdown
        if (valueType === "Country") {
            loadPriceForecastList(true, search, objs, selectedPostingPeriod);
            loadPostingPeriodFilter(search, objs);
        }
        //Loading KBI Values which is dependent on Posting Period Dropdown
        if (valueType === "Posting Period") {
            loadPriceForecastList(true, search, selectedCountries, objs);
            loadCountries(search, objs);         
        }
    }

    const maintainUserInterationsWithData = (data) => {
        const result = data.map(function (x) {
            var result = changedPriceForecastObjects.filter(a1 => a1.id == x.id);
            if (result.length > 0) { x.exclusion_id = result[0].exclusion_id; }
            return x
        })

        return result
    }
 
    const createStringFromObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return encodeURIComponent(newString)
    }
    const handleSearchSubmit = (search) => {
        setSearch(search);
       
            loadCountries(search, selectedPostingPeriod)
            loadPostingPeriodFilter(search, selectedCountries)
        
            loadPriceForecastList(true, search, selectedCountries, selectedPostingPeriod)
            
    }

    //AJAX Call To Load Country
    const loadCountries = async (searchFilterArg = search, posting_periods = selectedPostingPeriod) => {
        //Posting Period filter
        let postingPeriodFilter = getPostingPeriodFilter(posting_periods)
        let searchFilter = getSearchFilter(searchFilterArg);
        let url = BASE_URL + INPUT_FORMS + PRC_FRC + FILTERS + `?selected_form=${props.formId}` + `&filter_type=country` + postingPeriodFilter + searchFilter
        let response = await new APIService().post(url, 
            {
                ad_group_ids: JSON.parse(localStorage.userList)
            });
        if (response.error) {
            console.log("error in user country list API", response.results.message)
        } else {
            if (response.results.length > 0) {
                
                setCountryList(response.results);
            }
        }
    }

    //AJAX Call To Load Posting Period Filter Data
    const loadPostingPeriodFilter = async (searchFilterArg = search, countries = selectedCountries) => {
        //Country filter
        let selectedCountryFilter = getCountryFilter(countries)
        //Search Filter
        let searchFilter = getSearchFilter(searchFilterArg);

        let url = BASE_URL + INPUT_FORMS + PRC_FRC + FILTERS + `?selected_form=${props.formId}` + `&filter_type=posting_period` + selectedCountryFilter + searchFilter
        let response = await new APIService().post(url, 
            {
                ad_group_ids: JSON.parse(localStorage.userList)
            });
        if (response.error) {
            console.log("error in user posting period API", response.results.message)
        } else {
            if (response.results.length > 0) {
                
                setPostingPeriodsFilterList(response.results);
            }
        }
    }


    const getCountryFilter = (countryIds) => {
        //Getting User Selected Country By State/User Action
        let selectedCountryList = countryIds !== null ? countryIds : selectedCountries
        let selectedCountryFilter = ""
        if (selectedCountryList.length !== 0) {
            selectedCountryFilter = `&country=${createStringFromObjectList(selectedCountryList)}`
        }
        return selectedCountryFilter
    }

    const getPostingPeriodFilter = (postingPeriodIDs) => {
        //Getting User Selected Posting Period By State/User Action
        console.log(postingPeriodIDs)
        let selectedPostingPeriodList = postingPeriodIDs !== null ? postingPeriodIDs : selectedPostingPeriod
        let selectedPostingPeriodFilter = ""
        console.log(selectedPostingPeriodList)
        if (selectedPostingPeriodList.length !== 0) {
            selectedPostingPeriodFilter = `&posting_period=${createStringFromObjectList(selectedPostingPeriodList)}`
        }
        console.log(selectedPostingPeriodFilter)
        return selectedPostingPeriodFilter
    }


    const getSearchFilter = (seachFilterArg) => {
        let searchFilter = ""
        if (seachFilterArg.length !== 0) {
            searchFilter = `&search=${encodeURIComponent(seachFilterArg)}`
        }
        return searchFilter
    }

    //AJAX Call To Load Price Forecast List
    const loadPriceForecastAPICall = async (selectedCountryFilter, selectedPostingPeriodFilter, searchFilter) => {
        selectedCountryFilter = selectedCountryFilter ?? ""
        selectedPostingPeriodFilter = selectedPostingPeriodFilter ?? ""
        searchFilter = searchFilter ?? ""
        console.log(selectedCountryFilter, selectedPostingPeriodFilter)
        setLoading(true)
        let url = BASE_URL + INPUT_FORMS + PRC_FRC + DATA + `?selected_form=${props.formId}` + selectedCountryFilter + selectedPostingPeriodFilter + searchFilter
        let response = await new APIService().post(url, 
            {
                ad_group_ids: JSON.parse(localStorage.userList)
            });
        if (response.error) {
            console.log("error in price forecast list API", response.results.message)
        } else {
            setLoading(false);
            var data = maintainUserInterationsWithData(response.results);
            
            setPriceForecastList(data);
        }
        
    }

    const loadPriceForecastList = async (useTimer, searchFilterArg = "", countries = null, posting_periods = null) => {
        //Posting period filter
        let selectedPostingPeriodFilter = getPostingPeriodFilter(posting_periods)
        console.log(selectedPostingPeriodFilter)
        //Country filter
        let selectedCountryFilter = getCountryFilter(countries)
        //Search Filter
        let searchFilter = getSearchFilter(searchFilterArg);
        //Clearing Timeout Before Adding New Timeout(Previous Timeout)

        if (dropDownTimer) {
            clearTimeout(dropDownTimer);
        }
        //Add Timeout Before Hitting API
        if (useTimer) {
            setDropDownTimer(
                setTimeout(function () {
                    toast.promise(loadPriceForecastAPICall(selectedCountryFilter, selectedPostingPeriodFilter, searchFilter), {
                        loading: "Loading. wait a moment!",
                        success: 'Data load success.',
                        error: 'Something went wrong.',
                    },
                        {
                            position: 'top-center',
                            className: 'toast-popup',
                        });
                }, 1000)
            )
        } else {
            loadPriceForecastAPICall(selectedCountryFilter, selectedPostingPeriodFilter, searchFilter);
        }
    }

    const exportDataHandle = async () => {
        //Country filter
        let selectedCountryFilter = getCountryFilter(null)
        const payload = {
                ad_group_ids: JSON.parse(localStorage.userList)
              };
        let url = BASE_URL + INPUT_FORMS + PRC_FRC + EXPORT + `?selected_form=${selectedForm}` + selectedCountryFilter 
        fetch(url, {
            method: "POST",
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token')).access}`,
            }),
            body: JSON.stringify(payload)
            
        }).then(response =>
            response.blob().then(blob => {
                var today = new Date()
                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = "price_forecast_data_" + date + "_" + time + ".xls";
                a.click();
            })
        );
    }


    useEffect(() => {
        const fetchData = async () => {
          try {
            const [response1, response2] = await Promise.all([
                loadCountries("",null),
                loadPostingPeriodFilter("", null)
            ]);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, []);

      useEffect(()=>{
            loadPriceForecastList(true, search, null, null);
      }, [])

    const handleButtonClick = () => {
        fileInputRef.current.click();
      };

    return (
        <div className="user-view-container">
        <Grid className="mb-1" container spacing={2} alignItems="center">
            <Grid item xs={12} sm={7}>
                <Grid container spacing={2} alignContent="center" alignItems="center">
                    <Grid item xs={3}>
                        <MultipleSelectCheckmarks key={"Country"} title="Country" options={countryList} setParentDropdownValue={setDropDownValues} />
                    </Grid>
                        
                    <Grid item xs={3}>
                        <MultipleSelectCheckmarks key={"Posting Period"} title="Posting Period" options={postingPeriodFilterList} setParentDropdownValue={setDropDownValues} />
                    </Grid>
                    <Grid className="mt-4" item xs={6}>
                        <PriceForecastSearchBar key={"price-forecast-search"} handleSearchSubmit={handleSearchSubmit} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={5}>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <span>
                            <input
                                type="file"
                                id="file-input"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleFileUpload}
                            />
                            {importLoading !== true ?
                            <Tooltip title="Import functionality will discard all the applied filters in the UI">
                                <Button className="mx-2" style={{backgroundColor:"#E61C2A", color: "#FFF", borderRadius: "15px", marginRight: "15px", minWidth: "120px"}} className="pf-export-button mt-2" variant="contained" value="Import"
                                onClick={handleButtonClick}
                                    startIcon={<GetAppRoundedIcon />}>Import</Button>
                                    </Tooltip>
                                    :
                                    <Button className="mx-2" style={{color: "#FFF", borderRadius: "15px", marginRight: "15px", minWidth: "120px"}} className="pf-export-button mt-2" variant="contained" value="Import"
                                disabled={true}
                                    startIcon={<CircularProgress className="mx-1" style={{color:"#FFF"}} size="1rem" />}>Importing</Button>}
                                    
                        </span>
                    </Grid>
                    <Grid item>
                        <span>
                            <Button className="mx-2" style={{backgroundColor:"#E61C2A", color: "#FFF", borderRadius: "15px", minWidth: "120px"}} className="pf-export-button mt-2" variant="contained" value="Import"
                                    onClick={exportDataHandle} 
                                    startIcon={<PublishIcon />}>Export</Button>
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid className="mb-4" container justifyContent="flex-end">
          
            <Grid item>

        {fileName && <p style={{fontSize: "10px", marginRight: "120px"}}>Selected file:  {fileName}</p>}
       
        </Grid>
        </Grid>
      
         
                {loading ? <div className="table-data-note"><Spinner animation="border" />
                <p></p>
             </div> : ""}

            {(priceForecastList.length > 0 && loading === false) ?(
                <div className="sku-table-container">
                    <TablePriceForecast 
                    priceForecastList={priceForecastList}
                    changedPriceForecastObjects={changedPriceForecastObjects}
                    admin={admin}/>
                                    
                    <footer className="sku-legend-container page-footer font-small blue">
                        <div className="footer-copyright text-center pt-3 row">
                           
                        </div>
                    </footer>
                </div>) : ""
            }
            
            {priceForecastList.length === 0 && loading === false ? (<div className="table-data-note"><p>No data found.</p>
                <p></p>
            </div>) : ""}

            <Toaster />

            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={errorImporting ? "error": "success"}>
          {afterImportComment}
        </Alert>
      </Snackbar>
        </div>
    );
};

export default User4;
