import axios from 'axios'

import { BASE_URL } from '../constant/apiEndpoints'

export const sendUploadedYouthFile = (type,tableData, filedata) => async (dispatch) => {
    const newUploadFile = filedata.map(({
        Region: region,
        Bu: bu,
        Country: country,
        Year: year,
        ...rest
    }) => ({
        region, bu, country,year, ...rest
    }))
    var loadDataFile = []
    for (let char of newUploadFile) {
        if (char.region !== '')
            loadDataFile.push(char)
    }
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    const response = await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/upload/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        harmonised_version: 'RM10',
        kbi_type: type,
        rows: loadDataFile
    })
    if (response.data.status === 200) {
        dispatch(getStewardData(type))
    }
    else {
        dispatch({
            type: 'STEWARD_TABLE_DATA',
            payload: tableData
        })
    }
}

export const getStewardData = (type) => async (dispatch) => {
    if (type == 'youth_empowered_roadmap_targets'){
        var version = 'RM10'
    }
    else{
        var version = 'Actual'
    } 
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    const response = await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/get_data/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        harmonised_version: version,
        kbi_type: type
    })

    if (response.data.status === 200) {

        dispatch({
            type: 'STEWARD_TABLE_DATA',
            payload: response.data.message.data
        })
        dispatch({
            type: 'STEWARD_PLANT_LIST',
            payload: response.data.message.region_filters?.plant
        })
        dispatch({
            type: 'STEWARD_COUNTRY_LIST',
            payload: response.data.message.region_filters?.country
        })
        dispatch({
            type: 'STEWARD_BU_LIST',
            payload: response.data.message.region_filters?.bu
        })
        dispatch({
            type: 'STEWARD_REGION_LIST',
            payload: response.data.message.region_filters?.region
        })
        dispatch({
            type: 'STEWARD_COLUMN_LIST',
            payload: response.data.message.headers
        })
        dispatch({
            type: 'STEWARD_REGION_MATRIX',
            payload: response.data.message.region_matrix
        })
        let yearList = []
        response.data.message?.years.map(year => {
            yearList.push({ label: year, value: year })
        })
        dispatch({
            type: 'STEWARD_YEAR_LIST',
            payload: yearList
        })
        if (type.includes('ci_')) {
            let columnList = []
            let list = ['region', 'bu','added_by','updated_by','created_date','updated_date','cut_off']
            response.data.message.headers.map(col => {
                if (!list.includes(col.key)) {
                    columnList.push({

                        id: col.key,
                        label: col.label,
                        minWidth: 100,
                        align: 'right',
                    })
                }
            })
            columnList.push({
                id: 'action',
                label: 'Action',
                minWidth: 50,
                align: 'right',

            })
            dispatch({
                type: 'CI_COLUMN_LIST',
                payload: columnList
            })
        }
    }
}

export const addStewardRow = (type, tableData, row) => async (dispatch) => {
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    const response = await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/add/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        harmonised_version: "Actual",
        kbi_type: type,
        row: row
    })
    if (response.data.status === 200) {
        dispatch(getStewardData(type))
    }
    else {
        dispatch({
            type: 'STEWARD_TABLE_DATA',
            payload: tableData
        })
    }
}
export const editStewardRow = (type, tableData, rowData) => async (dispatch) => {
    if (type == 'youth_empowered_roadmap_targets'){
        var version = 'RM10'
    }
    else{
        var version = 'Actual'
    }
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    const response = await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/edit/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        harmonised_version: version,
        kbi_type: type,
        row: rowData
    })
    if (response.data.status === 200) {
        dispatch(getStewardData(type))
        let yearList = []
        response.data.message?.years.map(year => {
            yearList.push({ label: year, value: year })
        })
        dispatch({
            type: 'STEWARD_YEAR_LIST',
            payload: yearList
        })

    }
    else {
        dispatch({
            type: 'STEWARD_TABLE_DATA',
            payload: tableData
        })

    }
}
export const deleteStewardRow = (type, tableData, checkboxList, checkboxId) => async (dispatch) => {
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    const response = await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/delete/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        harmonised_version: "Actual",
        kbi_type: type,
        rows: checkboxList
    })

    if (response.data.status === 200) {
        dispatch(getStewardData(type))
    }
    else {
        dispatch({
            type: 'STEWARD_TABLE_DATA',
            payload: tableData
        })
    }
}

const dropdownData = (values) => {
    let list = []
    for (let val of values) {
        list.push({ label: val, value: val })
    }
    return list
}

export const updatingDropdown = (selectedType, selectedVal, matrix) => (dispatch) => {

    if (selectedType === 'Region') {
        let validBU = []
        let validCountry = []
        let validPlant = []
        let validYear = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.region) || selectedVal.length === 0) {
                validBU.push(obj.bu)
                validCountry.push(obj.country)
                validPlant.push(obj.plant)
                validYear.push(obj.year)
            }
        }
        dispatch({
            type: 'STEWARD_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
        dispatch({
            type: 'STEWARD_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })
        // dispatch({
        //     type: 'STEWARD_YEAR_LIST',
        //     payload: dropdownData([...new Set(validYear)])
        // })
        dispatch({
            type: 'STEWARD_PLANT_LIST',
            payload: dropdownData([...new Set(validPlant)])
        })

    }
    if (selectedType === 'BU') {
        let validRegion = []
        let validCountry = []
        let validPlant = []
        let validYear = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.bu) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validCountry.push(obj.country)
                validPlant.push(obj.plant)
                validYear.push(obj.year)
            }
        }
        dispatch({
            type: 'STEWARD_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
        dispatch({
            type: 'STEWARD_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })
        // dispatch({
        //     type: 'STEWARD_YEAR_LIST',
        //     payload: dropdownData([...new Set(validYear)])
        // })
        dispatch({
            type: 'STEWARD_PLANT_LIST',
            payload: dropdownData([...new Set(validPlant)])
        })
    }
    if (selectedType === 'Country') {
        let validRegion = []
        let validBU = []
        let validPlant = []
        let validYear = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.country) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validBU.push(obj.bu)
                validPlant.push(obj.plant)
                validYear.push(obj.year)
            }
        }
        dispatch({
            type: 'STEWARD_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })
        dispatch({
            type: 'STEWARD_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })
        // dispatch({
        //     type: 'STEWARD_YEAR_LIST',
        //     payload: dropdownData([...new Set(validYear)])
        // })
        dispatch({
            type: 'STEWARD_PLANT_LIST',
            payload: dropdownData([...new Set(validPlant)])
        })
    }
    if (selectedType === 'Plant') {
        let validRegion = []
        let validBU = []
        let validCountry = []
        let validYear = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.plant) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validBU.push(obj.bu)
                validCountry.push(obj.country)
                validYear.push(obj.year)
            }
        }
        dispatch({
            type: 'STEWARD_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })
        dispatch({
            type: 'STEWARD_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })
        // dispatch({
        //     type: 'STEWARD_YEAR_LIST',
        //     payload: dropdownData([...new Set(validYear)])
        // })
        dispatch({
            type: 'STEWARD_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
    }
    if (selectedType === 'Year') {
        let validRegion = []
        let validBU = []
        let validPlant = []
        let validCountry = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.year) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validBU.push(obj.bu)
                validPlant.push(obj.plant)
                validCountry.push(obj.country)
            }
        }
        dispatch({
            type: 'STEWARD_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })
        dispatch({
            type: 'STEWARD_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })
        dispatch({
            type: 'STEWARD_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
        dispatch({
            type: 'STEWARD_PLANT_LIST',
            payload: dropdownData([...new Set(validPlant)])
        })
    }
}

export const getAddDetails = () => async (dispatch) => {
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    const response = await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/get_region_mappings/", {
        ad_group_ids: JSON.parse(localStorage.userList),
    })
    if (response.data.status === 200) {
        let addRegionList = []
        let addBUList = []
        let addCountryList = []
        let addPlantList = []
        //let regionList = ['REGION 1', 'REGION 2', 'Nigeria', 'Italy', 'BU Russia', 'Egypt']
        // response.data.message.mappings.map(res => {
        //     if (regionList.includes(res.region)) {
        //         addRegionList.push(res.region)
        //         addBUList.push(res.bu)
        //         addCountryList.push(res.country)
        //         addPlantList.push(res.plant)
        //     }
        // })
        response.data.message.mappings.map(res => {
            addRegionList.push(res.region)
            addBUList.push(res.bu)
            addCountryList.push(res.country)
            addPlantList.push(res.plant)
        })
        dispatch({
            type: 'ADD_COUNTRY_LIST',
            payload: dropdownData([...new Set(addCountryList)])
            // payload: [...new Set(response.data.message.countries)]
        })
        dispatch({
            type: 'ADD_BU_LIST',
            payload: dropdownData([...new Set(addBUList)])
        })
        dispatch({
            type: 'ADD_REGION_LIST',
            payload: dropdownData([...new Set(addRegionList)].sort())
        })
        dispatch({
            type: 'ADD_REGION_MATRIX',
            payload: response.data.message.mappings
        })
        dispatch({
            type: 'ADD_PLANT_LIST',
            payload: dropdownData([...new Set(addPlantList)])
        })
    }
}

export const addUpdatingDropdown = (selectedType, selectedVal, matrix) => (dispatch) => {
    if (selectedType === 'Region') {
        let validBU = []
        let validCountry = []
        let validPlant = []
        for (let obj of matrix) {
            if (selectedVal === (obj.region) || selectedVal.length === 0) {
                validBU.push(obj.bu)
                validCountry.push(obj.country)
                validPlant.push(obj.plant)
            }
        }
        dispatch({
            type: 'ADD_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
        dispatch({
            type: 'ADD_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })

        dispatch({
            type: 'ADD_PLANT_LIST',
            payload: dropdownData([...new Set(validPlant)])
        })

    }
    if (selectedType === 'BU') {
        let validRegion = []
        let validCountry = []
        let validPlant = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.bu) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validCountry.push(obj.country)
                validPlant.push(obj.plant)
            }
        }
        dispatch({
            type: 'ADD_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
        dispatch({
            type: 'ADD_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })
        dispatch({
            type: 'ADD_PLANT_LIST',
            payload: dropdownData([...new Set(validPlant)])
        })
    }
    if (selectedType === 'Country') {
        let validRegion = []
        let validBU = []
        let validPlant = []
        for (let obj of matrix) {
            if (selectedVal === (obj.country) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validBU.push(obj.bu)
                validPlant.push(obj.plant)
            }
        }
        dispatch({
            type: 'ADD_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })
        dispatch({
            type: 'ADD_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })
        dispatch({
            type: 'ADD_PLANT_LIST',
            payload: dropdownData([...new Set(validPlant)])
        })
    }
    if (selectedType === 'Plant') {
        let validRegion = []
        let validBU = []
        let validCountry = []
        for (let obj of matrix) {
            if (selectedVal === (obj.plant) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validBU.push(obj.bu)
                validCountry.push(obj.country)
            }
        }
        dispatch({
            type: 'ADD_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })
        dispatch({
            type: 'ADD_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })
        dispatch({
            type: 'ADD_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
    }

}