import React from 'react';
import { Table, Form } from 'antd';
import Paper from "@material-ui/core/Paper";

function CustomHierarchyTable({ columns, data, form, components, rowClass, onChange, onRow }) {
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Form form={form}>
                <Table
                    columns={columns}
                    dataSource={data}
                    // components={components}
                    rowClassName={() => rowClass}
                    scroll={{ x: 1200 }}
                    onChange={onChange}
                    onRow={onRow}
                    pagination={{ pageSize: 50 }}
                    size="small" />
            </Form>
        </Paper>
    )
}

export default CustomHierarchyTable