import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import '../App.css';
import DialogueBox from './dialogueBox';
import SKUSingleSelectAutocomplete from '../components/skuSingleSelectAutocomplete';
import { BASE_URL, BRAND_CATEGORY_MAPPINGS, CHANNEL_CATEGORY_MAPPINGS, INPUT_FORMS, INPUT_SUGGESTIONS, MFA, SKU_PERF, EXCLUSIONS, NEWLISTANDDELIST, QUARTERS, FILTERS, CATEGORIES, AVAILABLE_QUARTERS, STATUSES } from '../constant/apiEndpoints';
import APIService from '../services/APIService';
import SKUAutoSuggestInput from './skuAutoSuggestInput';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    height: '94vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
    // overflow: 'auto',
    // display: 'flex'
};

const SKUExclusionNewlistAndDelistFormModal = (props) => {

    const [quarterList, setQuarterList] = React.useState([]);
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [itemCategoryList, setItemCategoryList] = useState([]);
    const [banDescriptionList, setBanDescriptionList] = useState([]);
    const [banNumberList, setBanNumberList] = useState([]);
    const [materialDescriptionList, setMaterialDescriptionList] = useState([]);
    const [materialNumberList, setMaterialNumberList] = useState([]);
    const [availableQuarterList, setAvailableQuarterList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const [selectedQuarters, setSelectedQuarters] = React.useState(props.previousClosedQuarter);
    const [selectedBusinessUnitList, setSelectedBusinessUnitList] = useState([]);
    const [selectedTypeList, setSelectedTypeList] = useState([]);
    const [selectedItemCategoryList, setSelectedItemCategoryList] = useState([]);
    const [selectedBanDescriptionList, setSelectedBanDescriptionList] = useState([]);
    const [selectedBanNumberList, setSelectedBanNumberList] = useState([]);
    // const [selectedMaterialDescriptionList, setSelectedMaterialDescriptionList] = useState([]);
    // const [selectedMaterialNumberList, setSelectedMaterialNumberList] = useState([]);
    const [selectedAvailableQuarterList, setSelectedAvailableQuarterList] = useState([]);
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);

    const [isEdit, setIsEdit] = React.useState(false);
    const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
    const [messageType, setMessageType] = React.useState("");
    const [message, setMessage] = React.useState("");

    const releventData = {
        "selectedQuarters": selectedQuarters,
        "selectedBusinessUnitList": selectedBusinessUnitList,
        "selectedTypeList": selectedTypeList,
        "selectedItemCategoryList": selectedItemCategoryList,
        "selectedBanDescriptionList": selectedBanDescriptionList,
        "selectedBanNumberList": selectedBanNumberList,
        // "selectedMaterialDescriptionList": selectedMaterialDescriptionList,
        // "selectedMaterialNumberList": selectedMaterialNumberList,
    }

    const handleDialogueBox = () => {
        setOpenDialogueBox(!openDialogueBox)
    }

    const showDialogueBox = (msgType, msg) => {
        setMessage(msg);
        setMessageType(msgType);
        handleDialogueBox();
    }

    const handleOnClose = (value) => {
        props.handleIsFormModalOpen(value)
    }

    const loadDataObject = async (id) => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + id + '/'
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in ski exlusion list API", response.results.message)
        } else {
            // setDataObject(response.results);
            setSelectedQuarters(response.results.quarter_id)
            setSelectedBusinessUnitList(response.results.business_unit_name)
            setSelectedTypeList(response.results.type_title)
            setSelectedItemCategoryList(response.results.item_category)
            setSelectedBanDescriptionList(response.results.ban_description)
            setSelectedBanNumberList(response.results.ban_number)
            // setSelectedMaterialDescriptionList(response.results.material_description)
            // setSelectedMaterialNumberList(response.results.material_number)
            setSelectedAvailableQuarterList(response.results.new_quarter_id)
            setSelectedCategoryList(response.results.category_title)
            setIsEdit(true);
        }
    }

    const getPayloadData = () => {
        return {
            "year": Number(selectedQuarters.slice(0, 4)),
            "time_frame": selectedQuarters.slice(4, 6),
            "business_unit": selectedBusinessUnitList,
            "type": selectedTypeList,
            "item_category": selectedItemCategoryList,
            "ban_description": selectedBanDescriptionList,
            "ban_number": selectedBanNumberList,
            // "material_description": selectedMaterialDescriptionList,
            // "material_number": selectedMaterialNumberList,
            "category": selectedCategoryList,
            "new_year": Number(selectedAvailableQuarterList.slice(0, 4)),
            "new_time_frame": selectedAvailableQuarterList.slice(4, 6),
            'form_id': props.formId
        }
    }

    const addNewData = async (e) => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST
        let response = await new APIService().post(url, getPayloadData())
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                    // showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                    showDialogueBox("Error", "All Fields Are Required To Submit!")
                }
            }
        } else {
            toast.success('New data added Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.postSubmitAndEditData();
            handleOnClose(false);
        }
    }

    const editExistingData = async (e) => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + props.selectedIdToEdit + '/'
        let response = await new APIService().patch(url, getPayloadData())
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("Non Field Errors")) {

                    showDialogueBox("Validation Error", response.results.message.substring(' Non Field Errors : '.length))
                } else {
                    showDialogueBox("Error", (response.results.message.split('\t')[0]).replace("null", "empty"))
                }
            }
        } else {
            toast.success('Data updated Successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000, });
            props.postSubmitAndEditData();
            handleOnClose(false);
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        toast.loading('Submitting, Please Wait!',
            {
                position: 'top-center',
                className: 'toast-popup',
                duration: 1000,
            });
        if (props.selectedIdToEdit !== undefined) {
            editExistingData(e);
        } else {
            addNewData(e);
        }
    }

    //Support Function For Filter List
    const createStringFromObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return encodeURIComponent(newString)
    }


    const getReleventFilters = (releventData, valueType) => {
        let releventFilters = ""
        if (releventData !== null) {
            if (valueType !== "Business Unit") {
                if (releventData['selectedBusinessUnitList'].length !== 0) {
                    releventFilters = releventFilters + `&selected_business_unit=${createStringFromObjectList([{ "value": releventData['selectedBusinessUnitList'] }])}`
                }
            }
            if (valueType !== "Type") {
                if (releventData['selectedTypeList'].length !== 0) {
                    releventFilters = releventFilters + `&selected_type=${createStringFromObjectList([{ "value": releventData['selectedTypeList'] }])}`
                }
            }
            if (valueType !== "Item Category") {
                if (releventData['selectedItemCategoryList'].length !== 0) {
                    releventFilters = releventFilters + `&selected_item_category=${createStringFromObjectList([{ "value": releventData['selectedItemCategoryList'] }])}`
                }
            }
            if (valueType !== "Quarter") {
                if (releventData['selectedQuarters'].length !== 0) {
                    releventFilters = releventFilters + `&selected_quarter=${createStringFromObjectList([{ "value": releventData['selectedQuarters'] }])}`
                }
            }
            if (valueType !== "Ban Description") {
                if (releventData['selectedBanDescriptionList'].length !== 0) {
                    releventFilters = releventFilters + `&selected_ban_description=${createStringFromObjectList([{ "value": releventData['selectedBanDescriptionList'] }])}`
                }
            }
            if (valueType !== "Ban Number") {
                if (releventData['selectedBanNumberList'].length !== 0) {
                    releventFilters = releventFilters + `&selected_ban_number=${createStringFromObjectList([{ "value": releventData['selectedBanNumberList'] }])}`
                }
            }
            // if (valueType !== "Material Description") {
            //     if (releventData['selectedMaterialDescriptionList'].length !== 0) {
            //         releventFilters = releventFilters + `&selected_material_description=${createStringFromObjectList([{ "value": releventData['selectedMaterialDescriptionList'] }])}`
            //     }
            // }
            // if (valueType !== "Material Number") {
            //     if (releventData['selectedMaterialNumberList'].length !== 0) {
            //         releventFilters = releventFilters + `&selected_material_number=${createStringFromObjectList([{ "value": releventData['selectedMaterialNumberList'] }])}`
            //     }
            // }
        }
        return releventFilters
    }

    const loadReleventData = (objs, valueType) => {
        toast.loading('Loading, Please Wait!',
            {
                position: 'top-center',
                className: 'toast-popup',
                duration: 2000,
            });

            loadBusinessUnitList(objs);
            loadItemCategoryList(objs);
            loadQuarterList(objs);
            loadBanDescriptionList(objs);
            loadBanNumberList(objs);

    }

    const setReleventDropDownValues = (objs, valueType) => {
        var newObj = releventData

        if (valueType === "Business Unit") {
            setSelectedBusinessUnitList(objs)
            newObj['selectedBusinessUnitList'] = objs
        }
        else if (valueType === "Type") {
            setSelectedTypeList(objs)
            newObj['selectedTypeList'] = objs
        }
        else if (valueType === "Item Category") {
            setSelectedItemCategoryList(objs)
            newObj['selectedItemCategoryList'] = objs
        }
        else if (valueType === "Quarter") {
            setSelectedQuarters(objs)
            newObj['selectedQuarters'] = objs
        }
        else if (valueType === "Ban Description") {
            setSelectedBanDescriptionList(objs)
            newObj['selectedBanDescriptionList'] = objs
        }
        else if (valueType === "Ban Number") {
            setSelectedBanNumberList(objs)
            newObj['selectedBanNumberList'] = objs
        }
        // else if (valueType === "Material Description") {
        //     setSelectedMaterialDescriptionList(objs)
        //     newObj['selectedMaterialDescriptionList'] = objs
        // }
        // else if (valueType === "Material Number") {
        //     setSelectedMaterialNumberList(objs)
        //     newObj['selectedMaterialNumberList'] = objs
        // }
        //Load Relevent Data
        loadReleventData(newObj, valueType);
    }

    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Category") {
            setSelectedCategoryList(objs)
        }
        else if (valueType === "Available Quarter") {
            setSelectedAvailableQuarterList(objs)
        }
    }

    const loadQuarterList = async (releventData = null, valueType = null) => {
        const releventFilters = getReleventFilters(releventData, valueType);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + QUARTERS + `?selected_form=${props.formId}` + releventFilters
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                const transformedData = response.results.map(({ label, value }) => ({ id: value, title: label }));
                // if (transformedData.length === 1){
                //     setSelectedQuarters(transformedData[0].id)
                // }
                setQuarterList(transformedData);
            }else {
                setQuarterList(response.results);
            }
        }
    }

    const loadBusinessUnitList = async (releventData = null, valueType = null) => {
        const releventFilters = getReleventFilters(releventData, valueType);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + `?selected_form=${props.formId}` + `&filter_type=business_unit__name` + releventFilters
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // if (response.results.length === 1){
                //     setSelectedBusinessUnitList(response.results[0].id)
                // }
                setBusinessUnitList(response.results);
            }else {
                setBusinessUnitList(response.results);
            }
        }
    }

    //AJAX Call To Load Category SAP List
    const loadTypeList = async (releventData = null, valueType = null) => {
        const releventFilters = getReleventFilters(releventData, valueType);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + STATUSES + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // if (response.results.length === 1){
                //     setSelectedTypeList(response.results[0].id)
                // }
                setTypeList(response.results);
            }else {
                setTypeList(response.results);
            }
        }
    }
    const loadItemCategoryList = async (releventData = null, valueType = null) => {
        const releventFilters = getReleventFilters(releventData, valueType);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + `?selected_form=${props.formId}` + `&filter_type=skuexclusionmaterial__item_category` + releventFilters
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand Category Mapping List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                if (response.results.length === 1){
                    setSelectedItemCategoryList(response.results[0].id)
                }
                setItemCategoryList(response.results);
            }else {
                setItemCategoryList(response.results);
            }
        }
    }

    const loadBanDescriptionList = async (releventData = null, valueType = null) => {
        const releventFilters = getReleventFilters(releventData, valueType);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + `?selected_form=${props.formId}` + `&filter_type=ban_description` + releventFilters
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand Category Mapping List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // if (response.results.length === 1){
                //     setSelectedBanDescriptionList(response.results[0].id)
                // }
                setBanDescriptionList(response.results);
            }else {
                setBanDescriptionList(response.results);
            }
        }
    }

    const loadBanNumberList = async (releventData = null, valueType = null) => {
        const releventFilters = getReleventFilters(releventData, valueType);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + `?selected_form=${props.formId}` + `&filter_type=ban_number` + releventFilters
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand Category Mapping List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // if (response.results.length === 1){
                //     setSelectedBanNumberList(response.results[0].id)
                // }
                setBanNumberList(response.results);
            }else {
                setBanNumberList(response.results);
            }
        }
    }

    // const loadMaterialDescriptionList = async (releventData = null, valueType = null) => {
    //     const releventFilters = getReleventFilters(releventData, valueType);

    //     let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + `?selected_form=${props.formId}` + `&filter_type=skuexclusionmaterial__material_description` + releventFilters
    //     let response = await new APIService().get(url);
    //     if (response.error) {
    //         console.log("error in Brand Category Mapping List API", response.results.message)
    //     } else {
    //         if (response.results.length > 0) {
    //             if (response.results.length === 1){
    //                 setSelectedMaterialDescriptionList(response.results[0].id)
    //             }
    //             setMaterialDescriptionList(response.results);
    //         }else {
    //             setMaterialDescriptionList(response.results);
    //         }
    //     }
    // }

    // const loadMaterialNumberList = async (releventData = null, valueType = null) => {

    //     const releventFilters = getReleventFilters(releventData, valueType);

    //     let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + `?selected_form=${props.formId}` + `&filter_type=skuexclusionmaterial__material` + releventFilters
    //     let response = await new APIService().get(url);
    //     if (response.error) {
    //         console.log("error in Brand Category Mapping List API", response.results.message)
    //     } else {
    //         if (response.results.length > 0) {
    //             if (response.results.length === 1){
    //                 setSelectedMaterialNumberList(response.results[0].id)
    //             }
    //             setMaterialNumberList(response.results);
    //         }else {
    //             setMaterialNumberList(response.results);
    //         }
    //     }
    // }

    const loadAvailableQuarterList = async () => {

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + INPUT_SUGGESTIONS + AVAILABLE_QUARTERS + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // const transformedData = response.results.map(({ label, value }) => ({ id: value, title: label }));
                // if (response.results.length === 1){
                //     setSelectedAvailableQuarterList(response.results[0].id)
                // }
                setAvailableQuarterList(response.results);
            }else {
                setAvailableQuarterList(response.results);
            }
        }
    }

    const loadCategoryList = async () => {

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + CATEGORIES + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                // const transformedData = response.results.map(({ label, value }) => ({ id: value, title: label }));
                // if (response.results.length === 1){
                //     setSelectedCategoryList(response.results[0].id)
                // }
                setCategoryList(response.results);
            }else {
                setCategoryList(response.results);
            }
        }
    }

    const loadSuggestionData = () => {
        loadBanDescriptionList();
        loadBanNumberList();
        // loadMaterialDescriptionList();
        // loadMaterialNumberList();
        loadQuarterList();
        loadBusinessUnitList();
        loadTypeList();
        loadItemCategoryList();
        loadCategoryList();
        loadAvailableQuarterList();
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (props.selectedIdToEdit !== undefined) {
                setSelectedQuarters(props.selectedObjToEdit.quarter_id)
                setSelectedBusinessUnitList(props.selectedObjToEdit.business_unit_name)
                setSelectedTypeList(props.selectedObjToEdit.type_title)
                setSelectedItemCategoryList(props.selectedObjToEdit.item_category)
                setSelectedBanDescriptionList(props.selectedObjToEdit.ban_description)
                setSelectedBanNumberList(props.selectedObjToEdit.ban_number)
                // setSelectedMaterialDescriptionList(props.selectedObjToEdit.material_description)
                // setSelectedMaterialNumberList(props.selectedObjToEdit.material_number)
                setSelectedAvailableQuarterList(props.selectedObjToEdit.new_quarter_id)
                setSelectedCategoryList(props.selectedObjToEdit.category_title)
                setIsEdit(true);
            }
            toast.loading('Loading, Please Wait!',
                {
                    position: 'top-center',
                    className: 'toast-popup',
                    duration: 2000,
                });
            loadSuggestionData();
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div>
            <Modal
                open={props.isFormModalOpen}
                // onClose={() => props.handleIsFormModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="brand-category-modal-form-header">
                        {isEdit ?
                            <p>ID : {props.selectedIdToEdit}</p>
                            : <p>Add New Entry</p>}
                        <div className="brand-category-modal-form-close-button" onClick={() => handleOnClose(false)}>
                            X
                        </div>
                    </div>
                    <div className="brand-category-modal-form-body">
                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Quarter</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedQuarters.length > 0 && isEdit &&
                                    <SKUAutoSuggestInput title="Quarter" dataList={quarterList}
                                        currentValue={selectedQuarters}
                                        autoSuggestOnChange={setReleventDropDownValues} 
                                        isNonEditableField={true} />}
                                {!isEdit &&
                                    // <SKUSingleSelectAutocomplete title="Quarter" options={quarterList}
                                    //     setParentDropdownValue={setReleventDropDownValues} />
                                        <TextField inputProps={{ style: { height: '9px' } }} className='m-qrtr' disabled value={props.previousClosedQuarter}/>
                                }
                            </div>
                            {/* </div> */}
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Business Unit</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedBusinessUnitList.length > 0 && isEdit &&
                                    <SKUAutoSuggestInput title="Business Unit" dataList={businessUnitList}
                                        currentValue={selectedBusinessUnitList}
                                        autoSuggestOnChange={setReleventDropDownValues} 
                                        isNonEditableField={true} />}
                                {!isEdit &&
                                    <SKUSingleSelectAutocomplete title="Business Unit" options={businessUnitList}
                                        setParentDropdownValue={setReleventDropDownValues} />}

                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Type(Newlist/Delist)</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedTypeList.length > 0 && isEdit &&
                                    <SKUAutoSuggestInput title="Type" dataList={typeList}
                                        currentValue={selectedTypeList}
                                        autoSuggestOnChange={setReleventDropDownValues} 
                                        isNonEditableField={true} />}
                                {!isEdit &&
                                    <SKUSingleSelectAutocomplete title="Type" options={typeList}
                                        setParentDropdownValue={setReleventDropDownValues} />}
                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Item Category</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedItemCategoryList.length > 0 && isEdit &&
                                    <SKUAutoSuggestInput title="Item Category" dataList={itemCategoryList}
                                        currentValue={selectedItemCategoryList}
                                        autoSuggestOnChange={setReleventDropDownValues} 
                                        isNonEditableField={true} />}
                                {!isEdit &&
                                    <SKUSingleSelectAutocomplete title="Item Category" options={itemCategoryList}
                                        setParentDropdownValue={setReleventDropDownValues} />}
                            </div>
                        </div>
                        <div className="brand-category-modal-form-input-container row m-0">

                            <div className="brand-category-modal-form-input col-2"></div>
                        </div>

                        <div className="brand-category-modal-form-input-container row m-0">
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Ban Description</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedBanDescriptionList.length > 0 && isEdit &&
                                    <SKUAutoSuggestInput title="Ban Description" dataList={banDescriptionList}
                                        currentValue={selectedBanDescriptionList}
                                        autoSuggestOnChange={setReleventDropDownValues} 
                                        isNonEditableField={true} />}
                                {!isEdit &&
                                    <SKUSingleSelectAutocomplete title="Ban Description" options={banDescriptionList}
                                        setParentDropdownValue={setReleventDropDownValues} />}
                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Ban Number</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedBanNumberList.length > 0 && isEdit &&
                                    <SKUAutoSuggestInput title="Ban Number" dataList={banNumberList}
                                        currentValue={selectedBanNumberList}
                                        autoSuggestOnChange={setReleventDropDownValues} 
                                        isNonEditableField={true} />}
                                {!isEdit &&
                                    <SKUSingleSelectAutocomplete title="Ban Number" options={banNumberList}
                                        setParentDropdownValue={setReleventDropDownValues} />}
                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Category</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedCategoryList.length > 0 && isEdit &&
                                    <SKUAutoSuggestInput title="Category" dataList={categoryList}
                                        currentValue={selectedCategoryList}
                                        autoSuggestOnChange={setDropDownValues}
                                        isNonEditableField={false} />}
                                {!isEdit &&
                                    <SKUSingleSelectAutocomplete title="Category" options={categoryList}
                                        setParentDropdownValue={setDropDownValues} />}
                            </div>
                            <div className="brand-category-modal-form-input col-3 p-0">
                                <div style={{ display: "flex" }}>
                                    <p>Quarter(Newlist/Delist)</p><p style={{ color: "#E61C2A" }}>*</p>
                                </div>
                                {selectedAvailableQuarterList.length > 0 && isEdit &&
                                <SKUAutoSuggestInput title="Available Quarter" dataList={availableQuarterList}
                                currentValue={selectedAvailableQuarterList}
                                autoSuggestOnChange={setDropDownValues}
                                isNonEditableField={false} />}
                                {!isEdit &&
                                    <SKUSingleSelectAutocomplete title="Available Quarter" options={availableQuarterList}
                                        setParentDropdownValue={setDropDownValues} />}
                            </div>
                        </div>
                    </div>
                    <div className="brand-category-modal-form-footer row m-0">
                        <div className="brand-category-modal-form-input col-6">
                            {/* <div style={{ display: "flex" }}>
                                <p>Comment</p><p style={{ color: "#E61C2A" }}>*</p>
                            </div>

                            <textarea className="brand-category-text-input-box"
                                style={{ width: "100%" }}
                                rows="4" cols="50"
                                name="comment"
                                value={dataObject.comment}
                                onChange={inputOnChange}
                            /> */}
                        </div>
                        <div className="brand-category-modal-form-input col-6">
                            <div style={{ float: "right" }}>
                                <button className="brand-category-form-modal-cancel-button mt-3" onClick={() => handleOnClose(false)}>Cancel</button>
                                <button className="brand-category-form-modal-submit-button mt-3" onClick={handleOnSubmit}>Submit</button>
                            </div>
                        </div>

                    </div>
                </Box>
            </Modal>
            {/* Message Dialogue Box */}
            {openDialogueBox ? <DialogueBox openDialogueBox={openDialogueBox}
                handleDialogueBox={handleDialogueBox}
                messageType={messageType}
                message={message} /> : ""}
            {/* Message Dialogue Box */}
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    )
};

export default SKUExclusionNewlistAndDelistFormModal;

