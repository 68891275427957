export const waterColumns = [
    {
        id: "country",
        label: "Country",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "plant",
        label: "Plant",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "Project Name",
        label: "Project Name",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "Start Date",
        label: "Start Date",
        minWidth: 100,
        align: 'right',

    },
    {
        id: "End Date",
        label: "End Date",
        minWidth: 100,
        align: 'right',
    },

    {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        align: 'right',

    },

];

export const wasteColumns = [
    {
        id: "country",
        label: "Country",
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'year',
        label: 'Year',
        minWidth: 100,
        align: 'right'
    },
    {
        id: "% Consumer packaging recyclable",
        label: "% Consumer packaging recyclable",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "Total Recovered for Recycling in Units",
        label: "Total Recovered for Recycling in Units",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "Total Placed in Markets in Units",
        label: "Total Placed in Markets in Units",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "% Primary Packaging Collected for recycling or reuse",
        label: "% Primary Packaging Collected for recycling or reuse",
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        align: 'right',

    },
]

export const sourcingColumns = [
    {
        id: 'year',
        label: 'Year',
        minWidth: 100,
        align: 'right'
    },
    {
        id: 'quarter',
        label: 'Quarter',
        minWidth: 100,
        align: 'right'
    },
    {
        id: "Ingredient",
        label: "Ingredient",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "Sustainable Volume (tonnes)",
        label: "Sustainable Volume (tonnes)",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "Total Volume (tonnes)",
        label: "Total Volume (tonnes)",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "% Sustainable Sourced",
        label: "% Sustainable Sourced",
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        align: 'right',

    },
]

export const pandcColumns = [
    {
        id: "country",
        label: "Country",
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'year',
        label: 'Year',
        minWidth: 100,
        align: 'right'
    },
    {
        id: 'month',
        label: 'Month',
        minWidth: 100,
        align: 'right'
    },
    {
        id: "No of Youth Empowered",
        label: "No of Youth Empowered",
        minWidth: 100,
        align: 'right',
    },
    // {
    //     id: "Cummulative No of YE",
    //     label: "Cummulative No of YE",
    //     minWidth: 100,
    //     align: 'right',
    // },
    {
        id: "Total No of Employees in Volunteering",
        label: "Total No of Employees in Volunteering",
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        align: 'right',

    },
]

export const zwcColumns = [
    {
        id: "country",
        label: "Country",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "Program Name",
        label: "Program Name",
        minWidth: 100,
        align: 'right',
    },

    {
        id: "Start Date",
        label: "Start Date",
        minWidth: 100,
        align: 'right',

    },
    {
        id: "End Date",
        label: "End Date",
        minWidth: 100,
        align: 'right',
    },

    {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        align: 'right',

    },
]
export const ciPartnerOrg = [
    {
        id: "country",
        label: "Country",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "year",
        label: "Year",
        minWidth: 100,
        align: 'right',
    },

    {
        id: "No of partnering organisations",
        label: "No of partnering organisations",
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        align: 'right',

    },
]

export const month = [
    { label: 'January', value: 'Jan' },
    { label: 'February', value: 'Feb' },
    { label: 'March', value: 'Mar' },
    { label: 'April', value: 'Apr' },
    { label: 'May', value: 'May' },
    { label: 'June', value: 'Jun' },
    { label: 'July', value: 'Jul' },
    { label: 'August', value: 'Aug' },
    { label: 'September', value: 'Sep' },
    { label: 'October', value: 'Oct' },
    { label: 'November', value: 'Nov' },
    { label: 'December', value: 'Dec' },
]

export const ciList = [
    { label: 'Youth Empowered', value: 'ci_youth_empowerment' },
    { label: 'Water', value: 'ci_water' },
    { label: 'WWW', value: 'ci_www' },
    { label: 'Ukrainian Crisis', value: 'ci_ukranian_crisis' },
    { label: 'Covid', value: 'ci_covid' },
    { label: 'Disaster Relief', value: 'ci_disaster_relief' },
    { label: 'Others', value: 'ci_other_local' },

]
export const year = []
for (let i = new Date().getFullYear() - 1; i < new Date().getFullYear() + 2; i++) {
    year.push({ label: i, value: i })
}

export const sweetnerList = [
    { label: 'Sugar', value: 'Sugar' },
    { label: 'Juice', value: 'Juice' },
    { label: 'HFCS', value: 'HFCS' },
]

export const pcList = [
    { label: '#YouthEmpowered and Total Volunteering', value: 'people_and_community' },
    { label: 'Zero Waste Cities', value: 'zero_waste_cities' },
    { label: 'Community Participants', value: 'community_participants' },
    { label: '#YouthEmpowered Roadmap Targets', value: 'youth_empowered_roadmap_targets'},
]

export const cpColumns = [
    {
        id: "year",
        label: "Year",
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'quarter',
        label: 'Quarter',
        minWidth: 100,
        align: 'right'
    },
    {
        id: 'No of Community Participants',
        label: 'No of Community Participants',
        minWidth: 100,
        align: 'right'
    },
    {
        id: "No of People in CCH",
        label: "No of People in CCH",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "% of Community Participants",
        label: "% of Community Participants",
        minWidth: 100,
        align: 'right',
    },
]

export const yrColumns = [
    {
        id: "bu",
        label: "BU",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "country",
        label: "Country",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "year",
        label: "Year",
        minWidth: 100,
        align: 'right',
    },
    {
        id: "No of Youth Empowered",
        label: "#YouthEmpowered",
        minWidth: 100,
        align: 'right',
    },
]