import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomHierarchyTable from "./reactTable";
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import {
    Input,
    Form,
    Space,
    Button,
    Select,
    Modal,
    Tooltip,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";

let excelErrorMessage = "";
function CustomHierarchyUser(props) {
    const [modal, contextHolder] = Modal.useModal();
    const [dataSource, setDataSource] = useState([...props.rowData]);
    const [filteredData, setFilteredData] = useState([]);
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const loadData = () => {
        setFilteredData([...props.rowData]);
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            loadData();
        }
        return function cleanup() {
            mounted = false;
        };
    }, []);

    const filterData = (tofilterWith) => {
        return [...new Set(filteredData.map((x) => x[tofilterWith]))];
    };

    const getFilterProps = (dataIndex, filterSuggessions) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => {
            const { Option } = Select;
            const handleChange = (value) => {
                setSelectedKeys(value);
            };

            return (
                <>
                    <Select
                        mode="multiple"
                        style={{
                            width: "200px",
                        }}
                        allowClear
                        placeholder="select a value"
                        onChange={handleChange}
                        optionLabelProp="label"
                    >
                        {filterSuggessions?.map((option) => {
                            return (
                                <Option key={option} value={option} label={option}>
                                    <Space style={{ fontSize: "11px" }}>{option}</Space>
                                </Option>
                            );
                        })}
                    </Select>

                    <Button
                        style={{ backgroundColor: "#E61C2A", color: "#F7F7F7" }}
                        onClick={() => {

                            confirm();
                        }}
                    >
                        Search
                    </Button>
                </>
            );
        },
        filterIcon: () => {
            return <SearchOutlined />;
        },

        onFilter: (value, record) => {
            const newRecord = record[dataIndex]?.toLowerCase().includes(value?.toLowerCase());
            return newRecord;
        },
    });

    const handleEdit = (value, dataIndex, record, text) => {
        const newData = [...dataSource];

        const index = newData.findIndex((item) => record.object_key === item.object_key);
        newData[index][dataIndex] = value

        setDataSource(newData);
    };

    const hasData = props.columns.length > 0;
    const defaultColumns = [
        ...props.columns,
        ...(hasData ? [{
            dataIndex: 'historyLog',
            title: 'History Log',
            render: (text, record) => (
                <Tooltip title={
                    <div>
                        Updated By: {record.updated_by} <br />
                        Added By: {record.added_by} <br />
                        Updated At: {record.updated_at} <br />
                        Created At: {record.created_at}
                    </div>
                }
                    arrowPointAtStyle={{ backgroundColor: 'red' }}
                    placement="top"
                    arrowPointAtCenter
                    autoAdjustOverflow
                    getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode.parentNode // set container element to parent of the parent node
                    }
                    overlayStyle={{ maxWidth: '700px' }}
                >
                    <span className="log-icon"><FontAwesomeIcon icon={faHistory} className="log-icon" style={{ fontSize: '15px' }} /></span>
                </Tooltip>
                // )] : []
            ),
        }] : [])
    ].map(item => {
        if (item.dataIndex === "level1" || item.dataIndex === "level2" || item.dataIndex === "level3" || item.dataIndex === "level4" || item.dataIndex === "level5" || item.dataIndex === "level6") {
            return {
                ...item, // spread the original item object
                ...getFilterProps(item.dataIndex, filterData(item.dataIndex)),
                editable: true, // set editable to true for the age column
                render: (text, record) => (
                    <div>
                        {(record.hierarchy_name !== "EDGE Hierarchy") ? (
                            <Input value={text} onChange={(e) => handleEdit(e.target.value, item.dataIndex, record, text)} />
                        ) : (
                            text
                        )}
                    </div>
                ),
            };
        }
        else if (item.dataIndex === "historyLog") {
            return { ...item }
        }
        else {
            return {
                ...item,
                className: 'country-column',
                ...getFilterProps(item.dataIndex, filterData(item.dataIndex)),

            }
        }
    });

    const saveUpdatedData = async () => {
        props.handleTableData(dataSource)
    };

    const onChange = (pagination, filters, sorter, extra) => {
        setFilteredData(extra.currentDataSource);
    };

    return (
        <div>
            <div className="user-view-container">
                <div className="brand-category-header-container row">
                    <div className="sc-btn ch-act col-2 brand-category-add-delete-export-button-container"> 
                        <button className="fx-sv ch-btn brand-category-button mt-3 col" type="button" value="Save Data"
                            disabled={props.disable} onClick={saveUpdatedData}
                            style={{
                                marginRight: "-20px",
                                width: '86px',
                                height: "33px",
                                backgroundColor: !props.disable ? "#E61C2A" : "#9B9595",
                            }}>
                            <FontAwesomeIcon icon={faSave} className="search-icon" size="1x" /> Save
                            <span className="hover-button"><p>Save table data</p></span>
                        </button>
                    </div>
                    <div
                        className="brand-category-table-container tb-ch"
                    >
                        <CustomHierarchyTable
                            columns={defaultColumns}
                            data={dataSource}
                            form={form}
                            onChange={onChange}
                            rowClass={"editable-row"}
                        />
                    </div>
                </div>
            </div>

            {contextHolder}

            <Modal
                title={excelErrorMessage}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            ></Modal>
        </div >
    );
}

export default CustomHierarchyUser;
