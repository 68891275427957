import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SingleSelectDropDown = (props) => {

    const [selectedValue, setSelectedValue] = React.useState("")
    const [selectedLabel, setSelectedLabel] = React.useState("")

    const handleOnChange = (e) => {
        // let selectedText = e.target.options[e.target.selectedIndex].text
        // setSelectedLabel(selectedText)
        props.setParentDropdownValue(e.target.value)
        setSelectedValue(e.target.value)
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            setSelectedValue(props.preSelectedValue);
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <>
            <select
                name="teamName"
                className="single-select-dropdown"
                onChange={handleOnChange}
                value={selectedValue !== "" ? selectedValue : ""}
                // value={selectedValue}
                required
            >
                <option value='' disabled >Select</option>
                {props.options.map(each => (
                    <option
                        className="select-dropdown-option"
                        key={each.id}
                        value={each.id}
                        name={each.title}
                        title={each.title}
                        // defaultValue={each.id == selectedValue}
                    >
                        {each.title}
                    </option>
                ))}
            </select>
        </> 
    );
};

export default SingleSelectDropDown;