import React, { useState, useEffect, createRef } from 'react';
import "../../App.css"
import DropDownCustom from '../../components/dropDownCustom';
import { ACCOUNTS, BASE_URL, COUNTRIES, USERS, DATES, EDGE_ECOMM, INPUT_FORMS, KBIS, ADMIN, CONSOLIDATED_DATA, REQUEST_STATUS, LIST, EXPORT, BEYOND_CORE_ASSORTMENT } from '../../constant/apiEndpoints';
import APIService from '../../services/APIService';
import MultipleSelectCheckmarks from '../../components/dropDownMultiSelectCustom'
import Spinner from 'react-bootstrap/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import {  Spin } from 'antd';
import { CSVLink } from "react-csv";
import moment from "moment";
import { Button } from "antd";
const RequestStatus1 = (props) => {
    const [isLoadingUi, setIsLoadingUi] = React.useState(false);
    const [active, setActive] = useState(false);
    const [isLoadingExport, setIsLoadingExport] = React.useState(false);
    const [exportData, setExportData] = React.useState([]);
    const [countryList, setCountryList] = React.useState([]);
    const [dateList, setDateList] = React.useState([]);
    const [selectedForm, setSelectedForm] = React.useState(props.formId)
    const [selectedCountryID, setSelectedCountryID] = React.useState("");
    const [selectedCountryTitle, setSelectedCountryTitle] = React.useState("");
    const [selectedPeriodID, setSelectedPeriodID] = React.useState("");
    const [selectedPeriodTitle, setSelectedPeriodTitle] = React.useState("");
    const [kbiInput, setKbiInput] = React.useState([]);
    const [kbiInputExport, setKbiInputExport] = React.useState([]);
    const [selectDataNote, setSelectDataNote] = React.useState(false);
    const [selectedCountries, setSelectedCountries] = React.useState([]);
    const [selectedPeriods, setSelectedPeriods] = React.useState([]);
    const [dropDownTimer, setDropDownTimer] = useState(0);
    const [loading, setLoading] = useState(true);
    const csvLink = createRef();

    const groupedData = (data, category)  => {
        const sortedData = data.sort((a, b) => a[category].localeCompare(b[category]));
        return sortedData
        }
    //AJAX Call To Load Countries
    const loadCountries = async () => {
       
        let url = BASE_URL + ACCOUNTS + USERS + COUNTRIES + `?form_id=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
          
            console.log("error in user countrylist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                const transformedData = response.results.map(({ id, title }) => ({ label: title, value: id }));
                setCountryList(transformedData);
               
            }
           
        }
    }

    //AJAX Call To Load Dates
    const loadDates = async () => {
      
        let url = BASE_URL + ACCOUNTS + DATES
        let response = await new APIService().get(url);
        if (response.error) {
           
            console.log("error in datelist API", response.results.message)
        } else {
            if (response.results.length > 0) {
                const transformedData = response.results.map(({ id, title, year, month }) => ({ label: title, value: id, year, month }));
                setDateList(transformedData);
              
            }
          
        }
    }

    //AJAX Call To Load Initial KBI Values
    const preLoadKBIValues = async () => {
        setIsLoadingUi(true)
        let url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + REQUEST_STATUS + LIST + `?selected_form=${selectedForm}`
        let urlBeyond = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT + REQUEST_STATUS + LIST + `?selected_form=${selectedForm}`
        let response = await new APIService().get(url);
        let responseBeyond = await new APIService().get(urlBeyond);
        if (response.error || responseBeyond.error) {
            setIsLoadingUi(false)
            console.log("error in kbivalues API", response.results.message || responseBeyond.results.message)
        } else {
            const transformedData = responseBeyond?.results.map(
                ({ kbi_title, brand_owner, ...rest }) => {
                let hasBrandOwner = brand_owner ? "-" : ""
                  return {
                    kbi_title: `${kbi_title} ${hasBrandOwner} ${brand_owner}`,
                    ...rest,
                  };
                }
              );
          
              transformedData?.map((val) => {
                delete val.brand_owner;
              });
              const newArray = response?.results?.concat(transformedData);
              let newArr = await groupedData(newArray, "country_title")
              setKbiInput(newArr);
             
            setIsLoadingUi(false)
            setLoading(false);
        }
    }

    //Generating Query Parameters(Filtering Purpose)
    const generateQueryParameters = (countryIDs, periodIDs) => {
        let selectedCountryFilter = ""
        let selectedPeriodFilter = ""
        if (countryIDs.length !== 0) {
            selectedCountryFilter = `&selected_country=${createStringFromCountryObjectList(countryIDs)}`
        }
        if (periodIDs.length !== 0) {
            let selected_period = createStringFromPeriodObjectList(periodIDs)
            selectedPeriodFilter = `&selected_period=${selected_period}`
        }
        return {
            'selectedCountryFilter': selectedCountryFilter,
            'selectedPeriodFilter': selectedPeriodFilter
        }
    }

    //Support Function For Country List
    const createStringFromCountryObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return newString
    }

    //Support Function For Country List
    const createStringFromPeriodObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            let selected_date = dateList.find(x => x.value === objs[i].value);
            if (newString.length === 0) {
                newString = selected_date.month.toString() + selected_date.year.toString()
            } else {
                newString = newString + "," + selected_date.month + selected_date.year
            }
        }
        return newString
    }

    //AJAX Call To Load KBI Values
    const loadKBIAPICall = async (selectedCountryFilter, selectedPeriodFilter) => {
        setIsLoadingUi(true)
        let url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + REQUEST_STATUS + LIST + `?selected_form=${selectedForm}` + selectedCountryFilter + selectedPeriodFilter
       
        let urlBeyond = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT + REQUEST_STATUS + LIST + `?selected_form=${selectedForm}` + selectedCountryFilter + selectedPeriodFilter
        let response = await new APIService().get(url);
        let responseBeyond = await new APIService().get(urlBeyond);
        if (response.error || responseBeyond.error) {
            setIsLoadingUi(false)
            console.log("error in kbivalues API", response.results.message ||  responseBeyond.results.message)
        } else {
            const transformedData = responseBeyond?.results?.map(
                ({ kbi_title, brand_owner, ...rest }) => {
                    let hasBrandOwner = brand_owner ? "-" : ""
                  return {
                    kbi_title: `${kbi_title} ${hasBrandOwner} ${brand_owner}`,
                    ...rest,
                  };
                }
              );
          
              transformedData?.map((val) => {
                delete val?.brand_owner;
              });
           
            const newArray = response?.results.concat(transformedData);
            let newArr = await groupedData(newArray, "country_title")
            setKbiInput(newArr);
           
            setIsLoadingUi(false)
        }
    }

    //AJAX Call To Load KBI Values
    const loadKBIValues = async (countryIDs, periodIDs) => {
      
        let queryParams =await generateQueryParameters(countryIDs, periodIDs);
       await loadKBIAPICall(queryParams['selectedCountryFilter'], queryParams['selectedPeriodFilter'])

    }

    const setDropDownValues =async (objs, valueType) => {
        if (valueType === "Country") {
            setSelectedCountries(objs)
        } else if (valueType === "Period") {
           
            setSelectedPeriods(objs)
        }
        //Loading KBI Values which is dependent on Country and Period Dropdown
        if (valueType === "Country") { //Period is already selected
           await loadKBIValues(objs, selectedPeriods); //(period, country)
        } else if (valueType === "Period") { //Country is already selected
            await loadKBIValues(selectedCountries, objs); //(period, country)
        }
    }

    const exportDataHandle = async () => {
        setIsLoadingExport(true)
        let queryParams =await  generateQueryParameters(selectedCountries, selectedPeriods);
        let url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + REQUEST_STATUS + EXPORT + `?selected_form=${selectedForm}` + queryParams['selectedCountryFilter'] + queryParams['selectedPeriodFilter']
        let urlBeyond = BASE_URL + INPUT_FORMS + EDGE_ECOMM +  BEYOND_CORE_ASSORTMENT +  REQUEST_STATUS + EXPORT + `?selected_form=${selectedForm}` + queryParams['selectedCountryFilter'] + queryParams['selectedPeriodFilter']

        let response = await new APIService().get(url);
        let responseBeyond = await new APIService().get(urlBeyond);
        if (response.error || responseBeyond.error) {
            setIsLoadingExport(false)
            console.log("error in kbivalues API", response.results.message ||  responseBeyond.results.message)
        } else {
            const transformedData = responseBeyond?.results?.map(
                ({ kbi_title, brand_owner, ...rest }) => {
                    let hasBrandOwner = brand_owner ? "-" : ""
                  return {
                    kbi_title: `${kbi_title} ${hasBrandOwner} ${brand_owner}`,
                    ...rest,
                  };
                }
              );
       
              transformedData?.map((val) => {
                delete val?.brand_owner;
              });
            const newArray = response?.results.concat(transformedData);
            let newArr = await groupedData(newArray, "country_title")
            setExportData(newArr);
            setActive(true);
            setIsLoadingExport(false)
        }
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            preLoadKBIValues();
            loadCountries();
            loadDates();
        }
        return function cleanup() {
            mounted = false
        }
    }, [])
     const headers = [
    {
      key: "country_title",
      label: "Country",
    },
    { key: "date", label: "Period" },
    { key: "kbi_title", label: "KBI Title" },
    { key: "kbi_value", label: "KBI Value" },
    { key: "reason", label: "Reason" },
    { key: "comment", label: "Comment" },
    { key: "status", label: "Status" },
    { key: "added_by", label: "Added By" },
    { key: "added_at", label: "Added On" },
    { key: "responded_by", label: "Responded By" },
    { key: "responded_at", label: "Responded On" },
    
  ];
  		
  const date = moment().format("YYYY-MM-DD");
  const time = moment().format("LT").replace(/\s/g, "");
  const nameOfExportedFile = `requestStatus${date}_${time}`;

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
     
    }
  }, [active]);

    return (
          <Spin tip="Your data is being transferred for export." spinning={isLoadingExport} size="large">
        <div className="user-view-container">
            <div className="user-view-header row p-0 mb-4 ms-4">
                <div className="col-5 p-0 m-0 row">
                    <div className="col-5 p-0 m-0">
                        {/* <DropDownCustom title="Country" options={countryList} setParentDropdownValue={setDropDownValues} /> */}
                        <MultipleSelectCheckmarks title="Country" options={countryList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="col-5 p-0 m-0">
                        {/* <DropDownCustom title="Period" options={dateList} setParentDropdownValue={setDropDownValues} /> */}
                        <MultipleSelectCheckmarks title="Period" options={dateList} setParentDropdownValue={setDropDownValues} />
                    </div>
                </div>
                <div className="col-3 p-0 m-0 my-auto">
                </div>
                <div className="col-4 p-0 mt-4">
                <input
            className="export-button col"
            type="button"
            value="Export"
            onClick={() => exportDataHandle()}
            // onClick={exportDataHandle}
          />
                <CSVLink
            style={{ textDecoration: "none", display: 'none'  }}
            filename={nameOfExportedFile}
            headers={headers}
            data={exportData}
            ref={csvLink} target="_blank"
          >
            <Button
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                backgroundColor: "#E61C2A",
                color: "#F7F7F7",
                marginTop: "15px",
              }}
              shape="round"
              // icon={<CaretDownFilled />}
            >
              Export
            </Button>
          </CSVLink>

                    {/* <input className="export-button col" type="button" value="Export" onClick={exportDataHandle} /> */}
                </div>
            </div>
            <Spin tip="Loading" spinning={isLoadingUi} size="large"> 
            {kbiInput.length > 0 ? (
                <div className="container table-container row p-2">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Country</th>
                                    <th scope="col">Period</th>
                                    <th scope="col">KBI Title</th>
                                    <th scope="col">KBI Value</th>
                                    <th scope="col">Reason</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Comment</th>
                                    <th scope="col">Added By</th>
                                    <th scope="col">Added On</th>
                                    <th scope="col">Responded By</th>
                                    <th scope="col">Responded On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {kbiInput.map((kbi) => (
                                    <tr key={kbi.id} className={kbi.selected ? "selected" : ""}>
                                        <td title={kbi.country_title}>{kbi.country_title}</td>
                                        <td title={kbi.date}>{kbi.date}</td>
                                        <td title={kbi.kbi_title}>{kbi.kbi_title}</td>
                                        <td title={kbi.kbi_value}>{kbi.kbi_value}</td>
                                        <td title={kbi.reason}>{kbi.reason}</td>
                                        <td title={kbi.status}>{kbi.status}</td>
                                        <td title={kbi.comment}>{kbi.comment}</td>
                                        <td title={kbi.added_by}>{kbi.added_by}</td>
                                        <td title={kbi.added_at}>{kbi.added_at}</td>
                                        <td title={kbi.responded_by}>{kbi.responded_by}</td>
                                        <td title={kbi.responded_at}>{kbi.responded_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>) : ""}
            {/* Table(If Data Present) */}
            {/* No Data Message */}
            {kbiInput.length === 0 && isLoadingUi === false ? (<div className="table-data-note"><p>No data found.</p>
                <p></p>
            </div>) : ""}
            </Spin>
            {/* No Data Message */}
            {/* Loader */}
            <Toaster />
            {/* Loader */}
        </div>
    </Spin>
    
    );
};

export default RequestStatus1;