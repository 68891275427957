import React, { useEffect, useState } from 'react'
import '../App.css'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { TextField, Tooltip } from '@mui/material'

const SKUAutoSuggestInput = (props) => {
  const [searchString, setSearchString] = useState("");

  const handleOnSearch = (string, results) => {
    if (string === "") {setSearchString(" ")
  }
  }

  const handleOnHover = (result) => {
    // the item hovered
  }

  const handleOnSelect = (item) => {
    // the item selected
    props.autoSuggestOnChange(item.id, props.title)
  }

  const handleOnFocus = () => {
  }

  const handleOnClear = () => {
    setSearchString("")
  };

  useEffect(()=>{
    const getObject = props.dataList.filter(e => e.id === props.currentValue)
    if (getObject.length > 0){
      setSearchString(getObject[0].title)
    } 
    else{
      setSearchString(props.currentValue)
    }
  }, [])
  return (
    <div className="brand-category-text-suggest-input-box-container">
      {props.isNonEditableField ? 
      <Tooltip title={props.currentValue}>
      <TextField className={'m-2'} disabled value={props.currentValue}/>
      </Tooltip>
      :
          <ReactSearchAutocomplete
            showItemsOnFocus={true}
            className="brand-category-text-suggest-input-box"
            placeholder={props.title}
            items={props.dataList}
            fuseOptions={{minMatchCharLength: 0,
               includeMatches: true,
              findAllMatches: false,
              threshold: 1,
              keys: ["title", "id"] }} // Search on both fields
            resultStringKeyName="title" // String to display in the results
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            showIcon={false}
            inputSearchString={searchString}
            // autoFocus={true}
            styling={{
              height: "5.5vh",
              width: "10vw !important",
              borderRadius: "4px",
              backgroundColor: "#FFFFFF",
              boxShadow: "none",
              fontSize: "2vh",
              fontFamily: "inherit",
              textDecoration: "none",
              textOverflow: "ellipsis",
              resize: "none",
              border: "1px solid #C4C4C4",
              boxSizing: "border-box",
              // iconColor: "green",
              // lineColor: "lightgreen",
              // placeholderColor: "darkgreen",
              // clearIconMargin: "3px 8px 0 0",
              zIndex: 2,
            }}
          />
}
    </div>
  )
}

export default SKUAutoSuggestInput;