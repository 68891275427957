import React, { useState, useEffect } from "react";
import CreateIcon from "@material-ui/icons/Create";
import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
// import Alert from '@mui/material/Alert';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import toast, { Toaster } from 'react-hot-toast';

const tableHeadColour = '#F2F2F2';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

//Material UI Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeadColour,
        color: theme.palette.common.black,
        fontWeight: 600,
        cursor: 'pointer',
        padding: '0px',
        zIndex: 1
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: tableHeadColour,
        fontSize: 14,
        padding: '2px 0px'
    },
    // [`&:hover`]: {
    //     backgroundColor: "blue !important"
    // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&:hover`]: {
        backgroundColor: "white !important"
    },

}));
//Material UI Styles

// Creating styles
const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    table: {
        minWidth: 650,
    },
    snackbar: {
        bottom: "104px",
    },
});

function YouthRoadmapTable(props) {
    // Creating style object
    const classes = useStyles();

    // Defining a state named rows
    // which we can update by calling on setRows function
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(props.tableDataList);
    }, [props.tableDataList]);

    // const [rows, setRows] = useState(
    //     props.tableDataList
    // );

    const [previous, setPrevious] = useState([]);

    // Initial states
    const [open, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(props.editTable);
    const [disable, setDisable] = React.useState(true);
    const [showConfirm, setShowConfirm] = React.useState();

    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setEdit(props.editTable)
        // return () => { setRows(props.editTable) };

    }, [props.editTable])


    // Function to handle edit
    // const handleEdit = (i) => {
    //     // If edit mode is true setEdit will
    //     // set it to false and vice versa
    //     setEdit(!isEdit);
    // };

    // The handleInputChange handler can be set up to handle
    // many different inputs in the form, listen for changes
    // to input elements and record their values in state
    const handleInputChange = (e, index) => {
        setPrevious(props.tableDataList)
        setDisable(false);
        const { name, value } = e.target;
        const list = [...rows];
        if (name === 'No of Youth Empowered') {
            list[index][name] = value;
        }
        setRows(list);
    };

    // Showing delete confirmation to users
    const handleConfirm = () => {
        setShowConfirm(true);
    };


    // Handle the case of delete confirmation where
    // user click yes delete a specific row of id:i
    const handleRemoveClick = (i) => {
        setShowConfirm(false);
        setEdit(false)
        props.handleCancelButton(false)
        setRows(props.initialData);
    };

    // Handle the case of delete confirmation
    // where user click no
    const handleNo = () => {
        setShowConfirm(false);
    };

    function isnullOrInvalid(val) {
        if (val === null || val === undefined || val === "") {
            return true
        }

        else {
            return false
        }
    }

    const handleOnSubmit = () => {
        const isYouth = props?.isYouth;
        if (isYouth) {
        } else {
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                if (isnullOrInvalid(element['No of Youth Empowered'])) {
                    toast.error('Value cannot be empty', { position: 'top-center', className: 'toast-popup', duration: 1500, });
                    return;
                }
            }
        }
        // setRows(props.initialData); // Reset rows state to initial data
        let obj = rows;
        props.handleEdit(obj);
        setDisable(false);
    }

    const formatNumberWithCommas = (value, columnId) => {
        // Check if the number already contains commas
        if (typeof value === 'number' && columnId !== 'year') {
          value = value.toString()
            if (typeof value === 'string' && value.includes(',')) {
            // Number already formatted with commas, return as is
            return value;
            } else {
            // Number doesn't have commas, format it
            const formattedNumber = parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 10});
            return formattedNumber;
            }
        }
        else{
            return value;
        }
    };
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                {/* <TableBody> */}
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    className={classes.snackbar}
                >
                    <Alert onClose={handleClose} severity="success">
                        Record saved successfully!
                    </Alert>
                </Snackbar>

                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <StyledTableRow className="bold-font">
                            {props.columns.map((column) => (
                                <StyledTableCell key={column.id}
                                    align={column.align}>{column.label}</StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            showConfirm && (
                                <div>
                                    <Dialog
                                        open={showConfirm}
                                        onClose={handleNo}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Cancel Update"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Are you sure to cancel update?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => handleRemoveClick()}
                                                color="primary"
                                                autoFocus
                                            >
                                                Yes
                                            </Button>
                                            <Button
                                                onClick={handleNo}
                                                color="primary"
                                                autoFocus
                                            >
                                                No
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            )
                        }
                        {props.tableDataList.map((row, i) => {
                            let currentYear = new Date().getFullYear()
                            return (
                                <TableRow key={row.id}>
                                    {props.columns.map((column) => {
                                        const value = row[column.id];
                                        var isYouth = column.id === 'No of Youth Empowered';
                                        var isYouthcss = column.id !== 'No of Youth Empowered' ? "#e8e6e6" : "#ffffff";

                                        return (
                                            (isEdit && isYouth && row.cut_off === false) ? (
                                                <TableCell className="tableCellYouth" style={{
                                                    width: '20%',
                                                    height: '100%',
                                                    padding:"0px",
                                                     margin:"0px"
                                                    // border: '1px solid #ddd',
                                                    // fontSSize: '12px',
                                                    // textAlign: 'center',
                                                    
                                                }}>
                                                    <input
                                                        type="number"
                                                        required
                                                        autoComplete="off"
                                                        value={value}
                                                        className="tableCellYouthinput"
                                                        name={column.id}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            padding: '10px',
                                                            // border: '1px solid #ddd',
                                                            fontSize: '12px',
                                                            
                                                            textAlign: 'center',
                                                            // borderRadius: '5px',
                                                            // boxShadow: '0 0 5px rgb(88 87 87)'
                                                        }}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell key={column.id} align={column.align}
                                                    style={{
                                                        width: '20%',
                                                        height: '100%',
                                                        padding: '10px',
                                                        border: '1px solid #ddd',
                                                        fontSSize: '12px',
                                                        textAlign: 'center',
                                                        borderRadius: '5px',
                                                        boxShadow: '0 0 5px rgb(88 87 87)',

                                                        // minWidth: column.minWidth,
                                                        // minHeight: '10px',
                                                        backgroundColor: isYouthcss
                                                        // borderRadius: '0px',
                                                        // padding: '4px'
                                                    }}>
                                                    {formatNumberWithCommas(value, column.id)}
                                                </TableCell>
                                            ))
                                    })}
                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
                <div>
                    {isEdit ? (
                        <div>
                            {props.tableDataList.length !== 0 && (
                                <div>
                                    <div className="brand-category-modal-form-footer row m-0">
                                        <div className="brand-category-modal-form-input col">
                                            <div style={{ float: "right" }}>
                                                <button className="brand-category-form-modal-cancel-button mt-3" onClick={handleConfirm}>Cancel</button>
                                                <button className="brand-category-form-modal-submit-button mt-3" onClick={handleOnSubmit}
                                                    style={{ backgroundColor: !disable ? "#E61C2A" : "#9B9595" }}
                                                    disabled={disable}
                                                >Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                        </div>
                    )}
                </div>
            </TableContainer>
        </Paper>
    );
}

export default YouthRoadmapTable;