import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import { CSVLink } from "react-csv";
import { read, utils, writeFile } from 'xlsx';

import MultiSelectDropDown from '../../Emission/multiSelectDropdown';
import Loading from '../../loadingHome';
import TableList from '../../Emission/TableList'
import { waterColumns, month } from '../StewardColumn';
import DeleteModal from '../../Emission/DeleteModal';
import EditModal from '../../Emission/EditModal';
import AddModal from '../AddModal';
import { addStewardRow, deleteStewardRow, editStewardRow, getStewardData, updatingDropdown } from '../../../actions/steward_action';

const Water = () => {

    const dispatch = useDispatch()
    const EmissionList = useSelector((state) => state.steward.tableData)
    const country = useSelector((state) => state.steward.countryList)
    const region = useSelector((state) => state.steward.regionList)
    const bu = useSelector((state) => state.steward.buList)
    const plant = useSelector((state) => state.steward.plantList)
    const year = useSelector((state) => state.steward.yearList)
    const headers = useSelector((state) => state.steward.columnList)
    const loading = useSelector((state) => state.steward.loading)
    const matrix = useSelector((state) => state.steward.matrix)
    const toastData = useSelector((state) => state.steward.toast)

    const [selectedRegion, setSelectedRegion] = useState([])
    const [selectedBU, setSelectedBU] = useState([])
    const [selectedCountry, setSelectedCountry] = useState([])
    const [selectedPlant, setSelectedPlant] = useState([])
    const [selectedYear, setSelectedYear] = useState([])
    const [selectedMonth, setSelectedMonth] = useState([])
    const [tableData, setTableData] = useState()
    const [exportData, setExportData] = useState([])
    const [checkboxList, setCheckboxList] = useState([])
    const [checkboxId, setCheckboxId] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedDropDown, setSelectedDropDown] = useState([])
    const [editRow, setEditRow] = useState({})
    const disabledOption = ['region', 'bu', 'country', 'plant', 'year', 'month']

    useEffect(() => {
        let updatedRegionData = []
        if (selectedRegion.length > 0) {
            for (let char of EmissionList) {
                if (selectedRegion.includes(char.region)) {
                    updatedRegionData.push(char)
                }
            }
        }
        else {
            updatedRegionData = EmissionList
        }
        let upDatedBuData = []
        if (selectedBU.length > 0) {
            for (let char of updatedRegionData) {
                if (selectedBU.includes(char.bu)) {
                    upDatedBuData.push(char)
                }
            }
        } else {
            upDatedBuData = updatedRegionData
        }
        let updatedCountryData = []
        if (selectedCountry.length > 0) {
            for (let char of upDatedBuData) {
                if (selectedCountry.includes(char.country)) {
                    updatedCountryData.push(char)
                }
            }
        }
        else {
            updatedCountryData = upDatedBuData
        }
        let updatedPlantData = []
        if (selectedPlant.length > 0) {
            for (let char of updatedCountryData) {
                if (selectedPlant.includes(char.plant)) {
                    updatedPlantData.push(char)
                }
            }
        }
        else {
            updatedPlantData = updatedCountryData
        }
        let updatedYearData = []
        if (selectedYear.length > 0) {
            for (let char of updatedPlantData) {
                if (selectedYear.includes(char.year)) {
                    updatedYearData.push(char)
                }
            }
        }
        else {
            updatedYearData = updatedPlantData
        }
        let updatedMonthData = []
        if (selectedMonth.length > 0) {
            for (let char of updatedYearData) {
                if (selectedMonth.includes(char.month)) {
                    updatedMonthData.push(char)
                }
            }
        }
        else {
            updatedMonthData = updatedYearData
        }
        setTableData(updatedMonthData)
    },
        [selectedBU, selectedCountry, selectedRegion, selectedMonth, selectedYear, selectedPlant])

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let res = tableData.map(({ id, selected, ...rest }) => ({ ...rest }));      
            setExportData(res)
        }
    }, [tableData])
    useEffect(() => {
        toast.promise(dispatch(getStewardData('water_stewardship')), {
            loading: "Loading. wait a moment!",
            success: 'Data loaded Successfully!',
            error: 'Something went wrong.',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });
    }, [])

    useEffect(() => {
        setTableData(EmissionList)      
        let res = EmissionList.map(({ id, selected, ...rest }) => ({ ...rest }));
        setExportData(res)
    }, [EmissionList])

    useEffect(() => {
        let obj = {}
        for (let header of headers) {
            if (header.key !== 'year' || header.key !== 'month') {
                obj[header.key] = ''
            }
        }
        delete obj.year
        delete obj.month
        setEditRow(obj)
    }, [headers])

    useEffect(() => {
        if (toastData.message && toastData.message.length > 0 && toastData.type === "error") {
            toast.error(toastData.message, { position: 'top-right', duration: 1500, });
        }
        else if (toastData.message && toastData.message.length > 0 && toastData.type === "success") {
            toast.success(toastData.message, { position: 'top-right', duration: 1500, });
        }
    }, [toastData])

    useEffect(() => {
        if (selectedDropDown === 'Region') {
            dispatch(updatingDropdown(selectedDropDown, selectedRegion, matrix))
            if (selectedPlant.length !== 0) {
                dispatch(updatingDropdown('Plant', selectedPlant, matrix))
            }
            if (selectedCountry.length !== 0) {
                dispatch(updatingDropdown('Country', selectedCountry, matrix))
            }
            if (selectedBU.length !== 0) {
                dispatch(updatingDropdown('BU', selectedBU, matrix))
            }

        }
        if (selectedDropDown === 'BU') {
            dispatch(updatingDropdown(selectedDropDown, selectedBU, matrix))
            if (selectedPlant.length !== 0) {
                dispatch(updatingDropdown('Plant', selectedPlant, matrix))
            }
            if (selectedCountry.length !== 0) {
                dispatch(updatingDropdown('Country', selectedCountry, matrix))
            }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
        if (selectedDropDown === 'Country') {
            dispatch(updatingDropdown(selectedDropDown, selectedCountry, matrix))
            if (selectedPlant.length !== 0) {
                dispatch(updatingDropdown('Plant', selectedPlant, matrix))
            }
            // if (selectedBU.length !== 0) {
            //     dispatch(updatingDropdown('BU', selectedBU, matrix))
            // }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
        if (selectedDropDown === 'Plant') {
            dispatch(updatingDropdown(selectedDropDown, selectedPlant, matrix))
            // if (selectedCountry.length !== 0) {
            //     dispatch(updatingDropdown('Country', selectedCountry, matrix))
            // }
            // if (selectedBU.length !== 0) {
            //     dispatch(updatingDropdown('BU', selectedBU, matrix))
            // }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
    }, [selectedRegion, selectedBU, selectedCountry, selectedPlant])

    const setDropDownValues = (val, valType) => {
        if (valType === 'Region') {
            let regionVal = []
            if (val.length > 0) {
                for (let char of val)
                    regionVal.push(char.value)
            }
            setSelectedRegion(regionVal)
        }
        if (valType === 'BU') {
            let buVal = []
            if (val.length > 0) {
                for (let char of val)
                    buVal.push(char.value)
            }
            setSelectedBU(buVal)
        }
        if (valType === 'Country') {
            let countryVal = []
            if (val.length > 0) {
                for (let char of val)
                    countryVal.push(char.value)
            }
            setSelectedCountry(countryVal)
        }
        if (valType === 'Plant') {
            let plantVal = []
            if (val.length > 0) {
                for (let char of val)
                    plantVal.push(char.value)
            }
            setSelectedPlant(plantVal)
        }
        if (valType === 'Year') {
            let yearVal = []
            if (val.length > 0) {
                for (let char of val)
                    yearVal.push(char.value)
            }
            setSelectedYear(yearVal)
        }
        if (valType === 'Month') {
            let monthVal = []
            if (val.length > 0) {
                for (let char of val)
                    monthVal.push(char.value)
            }
            setSelectedMonth(monthVal)
        }
        setSelectedDropDown(valType)
    }

    const setSelectedListInParent = (value) => {
        let ids = []
        let lists = []
        for (let char of value) {
            ids.push(char.id)
            lists.push(char)
        }
        setCheckboxList(lists)
        setCheckboxId(ids)
    }
    const handleEditClickInParent = (row) => {
        if (row['cut_off'] == true){
            setOpenAddModal(false)
            toast.error('Cut off time is going on. You will NOT be able to Save any changes.', { position: 'top-center', className: 'cutoff-popup', duration: 1500, });
        }
        else{
            setOpenEditModal(true)
            let obj = {}
            for (let header of headers) {
                obj[header.key] = row[header.key]
            }
            obj['id'] = row.id
            delete obj.year
            delete obj.month
            setEditRow(obj)
        }

    }

    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }
    const closeEditModal = () => {
        setOpenEditModal(false)
    }
    const handleDeleteButton = () => {
        setOpenDeleteModal(true)
    }

    const handleDelete = () => {
        setCheckboxId([])
        setCheckboxList([])
        setOpenDeleteModal(false)
        toast.promise(dispatch(deleteStewardRow('water_stewardship', tableData, checkboxList, checkboxId)), {
            loading: "Loading. wait a moment!",
            success: 'Row(s) deleted successfully!',
            error: (err) => err.response.data.message.error,
        },
            {
                position: 'top-center',
                className: 'cutoff-popup',
                duration: 2500,
            });
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setSelectedPlant([])
        setSelectedMonth([])

    }
    const handleEdit = (rowData) => {
        setOpenEditModal(false)
        toast.promise(dispatch(editStewardRow('water_stewardship', tableData, rowData)), {
            loading: "Loading. wait a moment!",
            success: 'Row edited successfully!',
            error: 'Something went wrong.',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setSelectedPlant([])
        setSelectedMonth([])
    }

    const AddModalOpen = () => {
        setOpenAddModal(true)
    }

    const closeAddMOdal = () => {
        setOpenAddModal(false)
    }

    const handleAddModal = (row) => {
        setOpenAddModal(false)
        toast.promise(dispatch(addStewardRow('water_stewardship', tableData, row)), {
            loading: "Loading. wait a moment!",
            success: 'Data load success.',
            error: (err) => err.response.data.message.error,
        },
            {
                position: 'top-center',
                className: 'cutoff-popup',
                duration: 2500,
            });
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedYear([])
        setSelectedPlant([])
        setSelectedMonth([])
    }

    function changeHeaderRank(header) {
        const arrOne = []
        const arrTwo = []
        header = header.filter(val => val.key !== "cut_off");
        header.map(val => {
            if (val.label == "Added_By" || val.label == "Updated_By" || val.label == "Created_Date" || val.label == "Updated_Date" || val.label == "added_by" || val.label == "updated_by" || val.label == "created_date" || val.label == "updated_date") {
                arrOne.push(val)
            }
            else {
                arrTwo.push(val)
            }
        })
        const seq = ["Created_Date", "created_date", "Added_By", "added_by", "Updated_Date","updated_date", "Updated_By","updated_by"];
        const newArr = []
        seq.map(val => {
            const head = header.filter(newval => newval.key == val);
            if (head?.length > 0) {
                newArr.push(head[0])
            }
        })
        const mergedArray = arrTwo.concat(newArr);
        return mergedArray
    }

    const headermapping = (header) => {
        const hea = {};
        header = changeHeaderRank(header);
        header.map(val => {
            const key = val.key;
            hea[key] = val.label;
        })
        return hea
    }

    // Map header names to values
    const mappedData = exportData.map(item => {
        const ndata = headermapping(headers); 
        if(Object.values(ndata).includes("added_by")) {
            ndata['added_by'] = 'created_by'
        }
        else{
            ndata['added_by'] = 'Created_by'
        }    
        return Object.keys(ndata).reduce((obj, key) => {
            obj[ndata[key]] = item[key];
            return obj;
        }, {});
    });

    const handleExport = () => {
        const wb = utils.book_new();
        const ws = utils.json_to_sheet(mappedData);
        utils.book_append_sheet(wb, ws, 'Data');
        writeFile(wb, `WaterStewardship.xlsx`);
    }
    return (
        <div>
            {region && region.length > 0 && bu && bu.length > 0 && country && country.length > 0
                && plant && plant.length > 0 && year && year.length &&
                <div className="user-view-container">
                    <div className="brand-category-header-container row">

                        <div className="col-10 brand-category-filter-container">
                            <div className="water-category-dropdown-container">
                                <MultiSelectDropDown title="Region" selected={selectedRegion.length === 0} options={region} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="water-category-dropdown-container">
                                <MultiSelectDropDown title="BU" selected={selectedBU.length === 0} options={bu} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="water-category-dropdown-container">
                                <MultiSelectDropDown title="Country" selected={selectedCountry.length === 0} options={country} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="water-category-dropdown-container">
                                <MultiSelectDropDown title="Plant" selected={selectedPlant.length === 0} options={plant} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="water-category-dropdown-container">
                                <MultiSelectDropDown title="Year" selected={selectedYear.length === 0} options={year} setParentDropdownValue={setDropDownValues} />
                            </div>
                            <div className="water-category-dropdown-container">
                                <MultiSelectDropDown title="Month" selected={selectedMonth.length === 0} options={month} setParentDropdownValue={setDropDownValues} />
                            </div>
                        </div>

                        <div className="col-2 brand-category-add-delete-export-button-container">
                        <button onClick={handleExport} className="brand-category-export-button mt-3 col" type="button" value="Export Data" >Export</button>
                            {checkboxList.length === 0 ?
                                <button className="brand-category-button mt-3 col" type="button" value="+ Add New" onClick={() => AddModalOpen(true)}><FontAwesomeIcon icon={faPlus} className="search-icon" size="1x" /> Add New</button>
                                : <button className="brand-category-button mt-3 col" type="button" value="Delete" onClick={handleDeleteButton}><FontAwesomeIcon icon={faTrash} className="search-icon" size="1x" /> Delete</button>}
                        </div>
                        {tableData && tableData.length > 0 &&
                            <div style={{ marginLeft: "-0.8rem", marginTop: "0.5rem" }} className="brand-category-table-container">
                                <TableList columns={waterColumns}
                                    channelCategoryMappingList={tableData}
                                    setSelectedListInParent={setSelectedListInParent}
                                    handleEditClickInParent={handleEditClickInParent}
                                //={props.formId} adminIds={adminIds} 
                                />
                            </div>
                        }
                    </div>
                    {openDeleteModal ?
                        <DeleteModal
                            message={checkboxList.length === EmissionList.length ?
                                "Entire table cannot be deleted " :
                                checkboxList.length > 0 ?
                                    "Are you sure that you want to delete the selected row(s) ?" : "Please select atleast one row"}
                            openDialogueBox={openDeleteModal}
                            handleDeleteDialogueBox={closeDeleteModal}
                            handleDelete={handleDelete}
                            rowSelected={checkboxList.length > 0}
                            allSelected={checkboxList.length === EmissionList.length}
                        /> : ''}
                    {openEditModal ?
                        <EditModal
                            message={"Edit"}
                            openDialogueBox={openEditModal}
                            handleEditDialogueBox={closeEditModal}
                            handleEdit={handleEdit}
                            rowSelected={editRow}
                            disabledOption={disabledOption}
                            type={'water'}
                        /> : ''}
                    {openAddModal ?
                        <AddModal
                            message={"Add the details"}
                            openDialogueBox={openAddModal}
                            handleAddDialogueBox={closeAddMOdal}
                            handleAdd={handleAddModal}
                            rowSelected={editRow}
                            disabledOption={disabledOption}
                            type={'water'}
                        /> : ''}
                </div>
            }
            <Toaster />
        </div>
    )
}

export default Water