import React, { useEffect, useState, useRef, useContext } from "react";
import { CSVLink } from "react-csv";
import { useCSVReader } from "react-papaparse";
import { Typography } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import DropDownCustom from "../../components/dropDownCustom";
import TableManualAdjustment from "../../components/tableManualAdjustment";
import {
  Input,
  Form,
  Space,
  Button,
  InputNumber,
  Col,
  Row,
  Select,
  Modal,
} from "antd";
import {
  SearchOutlined,
  SaveFilled,
  CaretUpFilled,
  CaretDownFilled,
} from "@ant-design/icons";
import {
  ACCOUNTS,
  BASE_URL,
  COUNTRIES,
  DATES,
  EDGE_ECOMM,
  MANUALADJUSTMENT,
  INPUT_FORMS,
  KBIS,
  USERS,
  OPSRPT,
  IMPORTEXCEL,
  UPDATE,
  SALESORG,
  CCHWEEK,
  RAWDATA,
  FIRST
} from "../../constant/apiEndpoints";
import Spinner from "react-bootstrap/Spinner";
import APIService from "../../services/APIService";
import DropDownCountry from "../../components/dropDownCountry";
import axios from 'axios';


let excelErrorMessage = "";
function ManualAdjustmentUser(props) {
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [noChanges, setNoChanges] = useState(true);
  const [modal, contextHolder] = Modal.useModal();
  const [hasErrorInLoop, setHasErrorInLoop] = React.useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentCountry, setCurrentCountry] = React.useState("");
  const [currentSalesOrg, setCurrentSalesOrg] = React.useState("");
  const [salesOrg, setSalesOrg] = React.useState([]);
  const [currentWeek, setCurrentWeek] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [activeWeek, setActiveWeek] = useState("");
  const [isCurrentWeekSeelected, setIsCurrentWeekSeelected] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [originalData, setOriginalData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [tempDataSource, setTempDataSource] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState("");
  const [rowsChanged, setRowsChanged] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataModified, setDataModified] = useState([]);
  const [count, setCount] = useState(2);
  const [filters, setFilters] = useState([]);
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const { CSVReader } = useCSVReader();
  const [countryList, setCountryList] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [dataCsv, setDataCsv] = React.useState([]);
  const [dateList, setDateList] = React.useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState("-");
  const [lastAddedBy, setLastAddedBy] = React.useState("-");
  const [selectedCountryID, setSelectedCountryID] = React.useState("");
  const [selectedCountryTitle, setSelectedCountryTitle] = React.useState("");
  const [selectedPeriodID, setSelectedPeriodID] = React.useState("");
  const [selectedPeriodTitle, setSelectedPeriodTitle] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [inputDisabled, setInputDisabled] = React.useState(true);
  const [selectedForm, setSelectedForm] = React.useState(props.formId);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [cchYearMonth, setCchYearMonth] = useState('');
 
  const [defaultcchYearMonth, setDefaultcchYearMonth] = useState([]);
  const EditableContext = React.createContext(null);


  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);

    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        setNoChanges(false);
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: "It should be a number",
            },
          ]}
        >
          <InputNumber
            ref={inputRef}
            step="0.0000001"
            controls={false}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const fetchDataFromRawTable = async () => {
    const date = await currentDate();
    var url = BASE_URL +
      INPUT_FORMS +
      OPSRPT +
      MANUALADJUSTMENT + CCHWEEK + `?selected_callday=${date.currentFullMonth}`;
    var response = await new APIService().get(url);
    console.log("response", moment().format("YYYY-MM-DD"))
    if (response.error) {
      setLoading(false);
      return showModal();
    } else {

      if (response?.results?.length > 0) {
        const Day_Of_Week = response.results[0].Day_Of_Week;
        const Month_Name = response.results[0].Month_Name;
        const Week_Of_Month = response.results[0].Week_Of_Month;
        var Week_Of_Year = response.results[0].Week_Of_Year;
        if (Week_Of_Year.toString().length == 1) {
          Week_Of_Year = `0${Week_Of_Year}`
        }
        const Year = response.results[0].Year;
        var yearWeek = `${Year}${Week_Of_Year}`;
        var url =
          BASE_URL +
          INPUT_FORMS +
          OPSRPT +
          MANUALADJUSTMENT + RAWDATA + `?selected_week=week${Week_Of_Month}&selected_year=${yearWeek}`;


        if (Week_Of_Month == 1) {
          //check data exists or not 
          let dataExists = await new APIService().get(url);
          const valuesExsits = dataExists.results.exists;

          if (valuesExsits) {
            return true
          }
          //if not then insert the data
          if (!valuesExsits) {
            //fetch data from old data to chrck if its new or not
            var url =
              BASE_URL +
              INPUT_FORMS +
              OPSRPT +
              MANUALADJUSTMENT + RAWDATA + FIRST;
            let resp = await new APIService().get(url);

            if (resp.results) {
              let date = resp.results.added_at;
              var a = moment(date.split('-')[0]).format()
              let diff = moment().diff(moment(a), 'days');
              if (diff > 10) {
                return true
              }
              else {
                let yearWeekNew = parseInt(yearWeek) - 1;
                let weekL = parseInt(Week_Of_Month) - 1;
                var url =
                  BASE_URL +
                  INPUT_FORMS +
                  OPSRPT +
                  MANUALADJUSTMENT + RAWDATA + `?selected_week=week${weekL}&selected_year=${yearWeekNew}&next_week=week${Week_Of_Month}&next_year=${yearWeek}`;

                let respp = await new APIService().post(url);
                if (respp.results) {
                  return true
                }

              }
            }

          }


        }
        //
        if (Week_Of_Month != 1) {

          let yearWeekNew = parseInt(yearWeek) - 1
          let weekL = parseInt(Week_Of_Month) - 1;
          var url =
            BASE_URL +
            INPUT_FORMS +
            OPSRPT +
            MANUALADJUSTMENT + RAWDATA +  `?selected_week=week${Week_Of_Month}&selected_year=${parseInt(yearWeek)}`;
          let dataExistsNew = await new APIService().get(url);
          const valuesExsits = dataExistsNew.results.exists;
         
          if (valuesExsits) {
            return true
          }
          //if not then insert the data
          if (!valuesExsits) {
            //fetch data from old data to chrck if its new or not\
            var url =
              BASE_URL +
              INPUT_FORMS +
              OPSRPT +
              MANUALADJUSTMENT + RAWDATA + `?selected_week=week${weekL}&selected_year=${yearWeekNew}&next_week=week${Week_Of_Month}&next_year=${parseInt(yearWeek)}`
            let respp = await new APIService().post(url);
            if (respp.results) {
              return true
            }



          }
        }


      }
    }
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchDataFromRawTable()
  //   }, 3600000); // Run every 1 hour
  //   // 14400000
  //   return () => clearInterval(interval);
  // }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const currentDate = async () => {
    const currentMonth = await moment().format("MMMM");
    const currentDate = await moment().format("D");
    const currentYear = await moment().format("YYYY");
    const currentFullMonth = await moment().format("YYYYMMDD");
    return { "currentMonth": currentMonth, "currentDate": currentDate, "currentYear": currentYear, "currentFullMonth": currentFullMonth }
  }

  //AJAX Call To Load Countriess
  const loadCurrentCchMonthData = async () => {
    const date = await currentDate();

    let url = BASE_URL +
      INPUT_FORMS +
      OPSRPT +
      MANUALADJUSTMENT + CCHWEEK + `?selected_callday=${date.currentFullMonth}`;
    let response = await new APIService().get(url);
    if (response.error) {
      setUserHasAccess(false);
      // excelErrorMessage = "You do not have authority to visit this page";
      // console.log("error in user API", response.results.message);
      // setLoading(false); 
      // return showModal();
    } else {
      setUserHasAccess(true);
      if (response.results.length > 0) {

        // setDayOfWeek(response.results[0].Day_Of_Week)
        // setActiveWeek(`week${response.results[0].Week_Of_Month}`)
        // setCurrentWeek(`week${response.results[0].Week_Of_Month}`)
        // setIsCurrentWeekSeelected(true);

        const Day_Of_Week = response.results[0].Day_Of_Week;
        const Month_Name = response.results[0].Month_Name;
        const Week_Of_Month = response.results[0].Week_Of_Month;
        var Week_Of_Year = response.results[0].Week_Of_Year;
        if (Week_Of_Year.toString().length == 1) {
          Week_Of_Year = `0${Week_Of_Year}`
        }
        const Year = response.results[0].Year;
        var yearWeek = `${Year}${Week_Of_Year}`;
        setCurrentPeriod(`${Month_Name}-${Year}`)
       
        
        // const [cchYear, setCchYear] = useState('');
        // const [cchMonthName, setCchMonthName] = useState('');
        // const [cchDayOfWeek, setCchDayOfWeek] = useState('');
        // const [cchWeekOfMonth, setCchWeekOfMonth] = useState('');
        // const [cchYearWeekData, setCchYearWeekDatah] = useState('');
        return {Year: Year, Month_Name: Month_Name, Day_Of_Week: Day_Of_Week, Week_Of_Month: Week_Of_Month, week: `week${response.results[0].Week_Of_Month}`, yearWeekData: yearWeek }
      }
    }
  };

 
  //editing cell logic start

  const handleSaveCountry = async (country) => {

    setCurrentCountry(country);
    await loadData(country, currentWeek, cchYearMonth, false);
  };
  const handleSaveSalesOrg = async (salesorg) => {
    setIsTableLoading(true);
    setCurrentSalesOrg(salesorg);
    setDataSource(originalData[salesorg])
    setTempDataSource(originalData[salesorg]);
    setFilteredData(originalData[salesorg]);
    setDataCsv(originalData[salesorg]);
    setIsTableLoading(false);
    // await loadData(currentCountry, salesorg, currentWeek,  false)
  };

  const handleSaveWeek = async (week) => {
    var num = parseInt(cchYearMonth);

    num = await defaultcchYearMonth.filter(val => val.week == week);
    setCchYearMonth(num[0].year);
    setCurrentWeek(week);
    if (week != activeWeek) {
      setIsCurrentWeekSeelected(false)
    }
    if (week == activeWeek) {
      setIsCurrentWeekSeelected(true)
    }
    await loadData(currentCountry, week, num[0].year, false)
  }
  const handleSave = (row) => {


    const obj = {
      id: row.id,
      ["material_id"]: row["material_id"],
      ["manually_adjusted_be"]: row["manually_adjusted_be"],
    };
    setDataModified([...dataModified, obj]);
    setRowsChanged([...rowsChanged, row.id]);

    const newData = [...dataSource];


    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  //editing cell logic end
  const setDropDownValues = (id, title, valueType) => {
    if (valueType === "Country") {
      setSelectedCountryID(id);
      setSelectedCountryTitle(title);
    } else if (valueType === "Period") {
      setSelectedPeriodID(id);
      setSelectedPeriodTitle(title);
    }
    setInputDisabled(true); //On Every Country And Period Change Edit Button Should Be Disabled

    //Loading KBI Values which is dependent on Country and Period Dropdown
  };

  const setupYearMonth = (week, year) => {
    let weekYearMonthCombo = []
   
    if(week == "week1"){
      weekYearMonthCombo.push(
        {"week": "week1", "year": parseInt(year)},
        {"week": "week2", "year": parseInt(year) + 1},
        {"week": "week3", "year": parseInt(year) + 2},
        {"week": "week4", "year": parseInt(year) + 3},
        {"week": "week5", "year": parseInt(year) + 4}
      )
    }
    if(week == "week2"){
      weekYearMonthCombo.push(
        {"week": "week1", "year": parseInt(year) -1},
        {"week": "week2", "year": parseInt(year)},
        {"week": "week3", "year": parseInt(year) + 1},
        {"week": "week4", "year": parseInt(year) + 2},
        {"week": "week5", "year": parseInt(year) + 3}
      )
    }
    if(week == "week3"){
      weekYearMonthCombo.push(
        {"week": "week1", "year": parseInt(year) -2},
        {"week": "week2", "year": parseInt(year) -1 },
        {"week": "week3", "year": parseInt(year)},
        {"week": "week4", "year": parseInt(year) + 1},
        {"week": "week5", "year": parseInt(year) + 2}
      )
    }
    if(week == "week4"){
      weekYearMonthCombo.push(
        {"week": "week1", "year": parseInt(year) -3},
        {"week": "week2", "year": parseInt(year) -2 },
        {"week": "week3", "year": parseInt(year) -1 },
        {"week": "week4", "year": parseInt(year)},
        {"week": "week5", "year": parseInt(year) + 1}
      )
    }
    if(week == "week5"){
      weekYearMonthCombo.push(
        {"week": "week1", "year": parseInt(year) -4},
        {"week": "week2", "year": parseInt(year) -3 },
        {"week": "week3", "year": parseInt(year) -2 },
        {"week": "week4", "year": parseInt(year) -1 },
        {"week": "week5", "year": parseInt(year)}
      )
    }
setDefaultcchYearMonth(weekYearMonthCombo) 
  }
  //AJAX Call To Load Countries
  const loadCountriesAndSalesOrg = async () => {
    //  let url = BASE_URL + ACCOUNTS + USERS + COUNTRIES + `?form_id=${props.formId}`
    //hardcoded

    // let url = BASE_URL +
    //   INPUT_FORMS +
    //   OPSRPT +
    //   MANUALADJUSTMENT + COUNTRIES + `?selected_form=${props.formId}`;


    //     const headers = {
    //     Accept: "application/json",
    //     Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access
    // }
    // const response = await axios.create({
    //     baseURL: BASE_URL,
    //     responseType: "json",
    //     headers: headers
    // }).post(INPUT_FORMS +
    //   OPSRPT +
    //   MANUALADJUSTMENT + COUNTRIES + `?selected_form=${props.formId}`, {
    //     ad_group_ids: JSON.parse(localStorage.userList),
    //     // harmonised_version: "Actual",
    //     // kbi_type: type
    //     //example
        
    // }, )
    const ad_group_ids = localStorage.getItem("userList")
        let url = BASE_URL +
          INPUT_FORMS +
          OPSRPT +
          MANUALADJUSTMENT + COUNTRIES;
    let response = await new APIService().post(url, ad_group_ids);
    console.log(response, "@@@@@@@@@@@$$$$$$$$$$$$$$$#############")
    if (response?.error) {
      setUserHasAccess(false);
      excelErrorMessage = "You do not have authority to visit this place";
      console.log("error in user countrylist API", response?.results?.message);
      setLoading(false);
      return showModal();
    } else {
      setUserHasAccess(true);
      if (response?.results?.length > 0) {
       
        var transformedData =await response.results.map(({ id, title }) => ({
          label: title,
          value: id,
        }));
    
        setCountryList(transformedData);
        // await loadSalesOrg(week);
        const cchData = await loadCurrentCchMonthData()
        setDayOfWeek(cchData.Day_Of_Week)
        setActiveWeek(`week${cchData.Week_Of_Month}`)
        setCurrentWeek(`week${cchData.Week_Of_Month}`)
        setIsCurrentWeekSeelected(true);
        setCchYearMonth(cchData.yearWeekData)
        
        await setupYearMonth(`week${cchData.Week_Of_Month}`, cchData.yearWeekData);
        
        await loadData(response?.results[0]?.id, cchData.week, cchData.yearWeekData, false);
        
      }
      if(response.data == 0){
      excelErrorMessage = "You do not have access to countries";
      console.log("error in user countrylist API", response?.results?.message);
      setLoading(false);
      }
    }
  };


  async function filterDataOnSalessOrg(data) {
    const unique = await [...new Set(data.map((item) => item.salesorg))];
    const arr = [];

    for (let i = 0; i < unique.length; i++) {
      const element = unique[i];
      const obj = {
        label: unique[i],
        value: unique[i],
      };
      arr.push(obj);
    }
    setSalesOrg(arr);
    if (arr.length > 0) {
      setCurrentSalesOrg(unique[0]);
      data = data.filter((ob) => ob.salesorg == unique[0]);
      setCurrentCountry(data);
    } else {
      data = [];
    }
    return arr;
  }
  async function groupBy(arr, property) {
    return await arr.reduce(function (memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }
  //AJAX Call To Load Countries
  const loadData = async (country = "", week = "", yearWeekData = "", isSaved = false) => {
  
    //  let url = BASE_URL + ACCOUNTS + USERS + COUNTRIES + `?form_id=${props.formId}`
    //hardcoded
    // const cchData = await loadCurrentCchMonthData()
   
    setIsTableLoading(true);
    let url =
      BASE_URL +
      INPUT_FORMS +
      OPSRPT +
      MANUALADJUSTMENT +
      `?selected_country=${country}&selected_week=${week}&selected_form=${props.formId}&selected_year=${yearWeekData}`;

    let response = await new APIService().get(url);
    if (response.error) {
      setLoading(false);
      setIsTableLoading(false);
      setUserHasAccess(false);
      console.log("error in user countrylist API", response.results.message);
    } 
    else if (week == 'week1' && response.results.length == 0) {
      setUserHasAccess(true);
      if (response.results.length == 0) {

        let url =
          BASE_URL +
          INPUT_FORMS +
          OPSRPT +
          MANUALADJUSTMENT +
          `?selected_country=${country}&selected_week=week5&selected_form=${props.formId}&selected_year=${parseInt(yearWeekData) - 1}`;

        let resp = await new APIService().get(url);
        if (resp?.results?.length > 0) {
       
          var groupBe = await groupBy(resp?.results, 'salesorg');
          const data = await filterDataOnSalessOrg(resp?.results)
        
          setOriginalData(groupBe);
          setDataSource(groupBe[data[0].label]);
          setTempDataSource(groupBe[data[0].label]);
          setFilteredData(groupBe[data[0].label]);
          setDataCsv(groupBe[data[0].label]);
          setCurrentSalesOrg(groupBe[data[0].label][0].salesorg);
          setCurrentCountry(groupBe[data[0].label][0].country);
         

          // setCurrentWeek(response?.results[0].currentCchWeek)
          if (isSaved) {
            countDown();
          }
          setLoading(false);
          setIsTableLoading(false);
        }
        else if (resp.results.length == 0) {
          let url =
            BASE_URL +
            INPUT_FORMS +
            OPSRPT +
            MANUALADJUSTMENT +
            `?selected_country=${country}&selected_week=week4&selected_form=${props.formId}&selected_year=${parseInt(yearWeekData) - 1}`;
          let respe = await new APIService().get(url);
          if (respe?.results?.length > 0) {
           
            var groupBe = await groupBy(respe?.results, 'salesorg');
            const data = await filterDataOnSalessOrg(respe?.results)
            setOriginalData(groupBe);
            setDataSource(groupBe[data[0].label]);
            setTempDataSource(groupBe[data[0].label]);
            setFilteredData(groupBe[data[0].label]);
            setDataCsv(groupBe[data[0].label]);
            setCurrentSalesOrg(groupBe[data[0].label][0].salesorg);
            setCurrentCountry(groupBe[data[0].label][0].country);

            // setCurrentWeek(response?.results[0].currentCchWeek)
            if (isSaved) {
              countDown();
            }
            setLoading(false);
            setIsTableLoading(false);
          }
          else {
            setLoading(false);
            setIsTableLoading(false);
            setDataSource([]);
            setTempDataSource([]);
            setFilteredData([]);
            setDataCsv([]);
            setSalesOrg([])
            setCurrentSalesOrg("");
            setCurrentCountry(country);
          
            // setCurrentWeek(response?.results[0].currentCchWeek)
          }

        }



      }
    }
    else {
      setUserHasAccess(true);
      if (response?.results?.length > 0) {
        var groupBe = await groupBy(response?.results, 'salesorg');
        console.log("response?.results[0].currentCchYearMont", response?.results[0].currentCchYearMonth)
       
        const data = await filterDataOnSalessOrg(response?.results)
        setOriginalData(groupBe);
        setDataSource(groupBe[data[0].label]);
        setTempDataSource(groupBe[data[0].label]);
        setFilteredData(groupBe[data[0].label]);
        setDataCsv(groupBe[data[0].label]);
        setCurrentSalesOrg(groupBe[data[0].label][0].salesorg);
        setCurrentCountry(groupBe[data[0].label][0].country);
        
        if (isSaved) {
          countDown();
        }
        setLoading(false);
        setIsTableLoading(false);
      } else {
        setLoading(false);
        setIsTableLoading(false);
        setDataSource([]);
        setTempDataSource([]);
        setFilteredData([]);
        setDataCsv([]);
        setSalesOrg([])
        setCurrentSalesOrg("");
        setCurrentCountry(country);
        // setCurrentPeriod("")
        // setCurrentWeek(response?.results[0].currentCchWeek)
      }
    }
  };
       
  
        
  // useEffect Method
  useEffect(() => {
    const cchData = loadCurrentCchMonthData();
    let mounted = true;
    if (mounted) {
      loadCountriesAndSalesOrg();
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const headers = [
    {
      key: "id",
      // key: "Material Key",
      label: "id",
    },
    {
      key: "material_id",
      // key: "Material Key",
      label: "Material Key",
    },

    {
      key: "material_name",
      // key: "Material Name",
      label: "Material Name",
    },
    {
      key: "posting_period",
      //key: "Posting Period",
      label: "Posting Period",
    },
    {
      key: "country",
      //key: "country",
      label: "Country Id",
    },
    {
      key: "country_name",
      //key: "Country Name",
      label: "Country Name",
    },
    {
      key: "salesorg",
      //key: "SalesOrg",
      label: "SalesOrg",
    },
    {
      key: "week",
      //key: "week",
      label: "Week",
    },
    {
      key: "month",
      //key: "week",
      label: "Month",
    },
    {
      key: "base_article_number",
      //key: "BAN",
      label: "BAN",
    },
    {
      key: "base_article_number_name",
      //key: "BAN Desc",
      label: "BAN Desc",
    },
    {
      key: "item_category",
      //key: "Item Category",
      label: "Item Category",
    },
    {
      key: "brand",
      //key: "brand",
      label: "Brand",
    },
    {
      key: "flavor",
      //key: "Flavor",
      label: "Flavor",
    },
    {
      key: "single_or_multi_serve",
      //key: "Single/Multi Serve",
      label: "Single/Multi Serve",
    },
    {
      key: "package_size",
      //key: "Package Size",
      label: "Package Size",
    },
    {
      key: "package_type",
      //key: "Package Type",
      label: "Package Type",
    },
    {
      key: "package",
      //key: "Package",
      label: "Package",
    },
    {
      key: "demand_area_key",
      //key: "RCP",
      label: "Demand Area Key",
    },
    {
      key: "demand_area_desc",
      //key: "RCP",
      label: "Demand Area",
    },
    {
      key: "sub_demand_area_key",
      //key: "RCP",
      label: "Sub Demand Area Key",
    },
    {
      key: "sub_demand_area_desc",
      //key: "RCP",
      label: "Sub Demand Area",
    },
    {
      key: "cpl_key",
      //key: "RCP",
      label: "CPL Key",
    },
    {
      key: "cpl_desc",
      //key: "RCP",
      label: "CPL",
    },
    {
      key: "rcp",
      //key: "RCP",
      label: "RCP",
    },
    {
      key: "manually_adjusted_be",
      //key: "Manually Adjusted BE",
      label: "Manually Adjusted BE",
    },
  ];

  const converToTableData = async (data) => {
    const newArray = data[0];
    var newArr = [];
    var dataNew = data.filter((val, i) => i !== 0);
    dataNew = dataNew.filter((val, i) => i !== dataNew.length);
    dataNew.map((val, index) => {
      // const arr = index;
      // const newAr = [arr].concat(val);
      var result = val.reduce(function (result, field, index) {
        result[newArray[index]] = field;
        return result;
      }, {});
      newArr.push(result);
    });

    newArr = newArr.filter(function (el) {
      return el["id"] !== "";
    });
    const transformed = newArr?.map((data) => ({
      id: data["id"],
      material_id: data["Material Key"],
      material_name: data["Material Name"],
      country: data["Country"],
      posting_period: data["Posting Period"],
      month: data["Month"],
      base_article_number: data["BAN"],
      base_article_number_name: data["BAN Desc"],
      item_category: data["Item Category"],
      brand: data["Brand"],
      flavor: data["Flavor"],
      single_or_multi_serve: data["Single/Multi Serve"],
      package_size: data["Package Size"],
      package_type: data["Package Type"],
      package: data["Package"],
      manually_adjusted_be: data["Manually Adjusted BE"],
      demand_area_desc: data["Demand Area"],
      sub_demand_area_desc: data["Sub Demand Area"],
      cpl_desc: data["CPL"],
      rcp: data["RCP"],
    }));



    await validateImportedData(transformed);
    // return newArr
  };


  const getRequestBody = (changedValues) => {
    let newArr = [];
    changedValues?.map((val) => {
      let body = {
        id: val?.id,
        manually_adjusted_be: val?.manually_adjusted_be,
      };
      newArr.push(body);
    });

    return newArr;
  };

  const onSubmitDataFromImport = async (changedValues, isSaved = true) => {
    let url = BASE_URL + INPUT_FORMS + OPSRPT + MANUALADJUSTMENT + UPDATE + `?selected_form=${props.formId}`;
    let response = await new APIService().post(url, changedValues);

    if (response.error) {
      if (response.status == 400) {
        excelErrorMessage = "you have added/Deleted aaditional row";
        setIsTableLoading(false);
        setNoChanges(true);
        return showModal();
        // if (response.results.message.includes("Comment")) {
        //     // showDialogueBox("Validation Error", response.results.message)
        //     showDialogueBox("Error", "Comment field is mandatory for historical data. Please add to proceed.")
        // } else {
        //     showDialogueBox("Error", "All fields are required and data must be valid!")
        // }
      }
    } else if (response.status == 201) {
      setIsTableLoading(false);
      setNoChanges(true);
      await loadData(currentCountry, currentWeek, cchYearMonth, isSaved);
    }
  };


  function compareTwoArrayOfObjects(array1, array2) {
    var id1 = "";
    var num1 = "";
    var num2 = "";
    var id2 = "";
    var material_id1 = "";
    var material_id2 = "";
    let arr = [];
    loop1: for (let i = 0; i < array1.length; i++) {
      num1 = isNaN(array1[i].manually_adjusted_be) ? 0 : parseInt(array1[i].manually_adjusted_be);
      id1 = array1[i].id;
      material_id1 = array1[i].material_id;
      num2 = isNaN(array2[i].manually_adjusted_be) ? 0 : parseInt(array2[i].manually_adjusted_be);
      id2 = array2[i].id;
      material_id2 = array2[i].material_id;
      if (id1 == id2) {
        if (!material_id1.includes(material_id2)) {
          break loop1;
        }
        if (num1 != num2 && material_id1.includes(material_id2)) {
          arr.push({
            id: id1,
            manually_adjusted_be: num2,
          });
        }
      } else {
        break loop1;
      }
    }


    return arr;
  }
  const validateImportedData = async (dataToCompare) => {
    //check length


    const modifiedDataSource = await dataSource.map(
      ({ salesorg, form_id, ...rest }) => ({ ...rest })
    );

    if (dataToCompare.length !== modifiedDataSource.length) {
      excelErrorMessage = "you have added/Deleted aaditional row";
      setIsTableLoading(false);
      return showModal();
    }
    const changedValues = await compareTwoArrayOfObjects(
      modifiedDataSource,
      dataToCompare
    );

    if (changedValues.length > 0) {
      let dataChanged = [];
      changedValues.map((val) => {
        if (isNaN(val["manually_adjusted_be"])) {
          excelErrorMessage =
            "Manual adjustment Number Should Be a Valid Number";
          setIsTableLoading(false);
          return showModal();
        }
      });

      await onSubmitDataFromImport(changedValues, false);
    } else {
      excelErrorMessage = "There is nothing to change";
      setIsTableLoading(false);
      return showModal();
    }
  };
  const filterData = (tofilterWith) => {
    let uniqueVals = [...new Set(filteredData.map((x) => x[tofilterWith]))];

    return uniqueVals;
  };

  const getFilterProps = (dataIndex, filterSuggessions) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const { Option } = Select;

      const handleChange = (value) => {
        setSelectedKeys(value);
      };

      return (
        <div className="filterSearch">
          <Row>
            <Col><Select
              mode="multiple"
              style={{
                width: '200px',
              }}
              allowClear
              placeholder="select a value"
              onChange={handleChange}
              optionLabelProp="label"
            >
              {filterSuggessions?.map((option) => {
                return (
                  <Option value={option} label={option}>
                    <Space style={{ fontSize: "11px" }}>{option}</Space>
                  </Option>
                );
              })}
            </Select>
            </Col>
            <Col > <Button
              style={{ backgroundColor: "#E61C2A", color: "#F7F7F7" }}
              onClick={() => {

                confirm();
              }}
            >
              Search
            </Button></Col>
          </Row>



        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },

    onFilter: (value, record) => {

      var newRecord;

      newRecord = record[dataIndex]?.toLowerCase().includes(value?.toLowerCase());

      return newRecord;
    },
  });

  const weekList = [
    {
      label: "Week 1",
      value: "week1",
    },
    {
      label: "Week 2",
      value: "week2",
    },
    {
      label: "Week 3",
      value: "week3",
    },
    {
      label: "Week 4",
      value: "week4",
    },
    {
      label: "Week 5",
      value: "week5",
    },
  ];

  const defaultColumns = [
    {
      // dataIndex: "material_id",
      dataIndex: "material_id",
      title: "Material Key",
      //fixed: "left",
      width: '8%',
      ...getFilterProps("material_id", filterData("material_id")),
    },

    {
      //  dataIndex: "material_name",
      dataIndex: "material_name",
      title: "Material Name",
      //fixed: "left",
      width: '14%',
      ...getFilterProps("material_name", filterData("material_name")),
    },
    {
      //dataIndex: "posting_period",
      dataIndex: "posting_period",
      title: "Posting Period",
      width: '6.6%',
      // hidden: true,
    },
    {
      //dataIndex: "posting_period",
      dataIndex: "week",
      title: "Week",
      width: '5.9%',
      ...getFilterProps("week", filterData("week")),
      // hidden: true,
    },
    {
      //dataIndex: "country",
      dataIndex: "country",
      title: "Country Id",
      width: '7.7%',
      hidden: true,
    },
    {
      //dataIndex: "country",
      dataIndex: "country_name",
      title: "Country",
      width: '7.7%',
      hidden: true,
    },
    {
      //dataIndex: "country",
      dataIndex: "salesorg",
      title: "salesOrg",
      width: '7.7%',
      hidden: true,
    },
    {
      //dataIndex: "base_article_number",
      dataIndex: "base_article_number",
      title: "BAN",
      ...getFilterProps("base_article_number", filterData("base_article_number")),
      width: '8%',

    },
    {
      //dataIndex: "base_article_number_name",
      dataIndex: "base_article_number_name",
      title: "BAN Desc",
      width: '13%',
      ...getFilterProps("base_article_number_name", filterData("base_article_number_name")),
    },
    {
      //dataIndex: "item_category",
      dataIndex: "item_category",
      title: "Item Category",
      width: "8%",
      //width: '6%',
      ...getFilterProps("item_category", filterData("item_category")),
    },
    {
      //dataIndex: "brand",
      dataIndex: "brand",
      title: "Brand",
      width: "7.5%",
      //width: '6%',
      ...getFilterProps("brand", filterData("brand")),
    },
    {
      //dataIndex: "flavor",
      dataIndex: "flavor",
      title: "Flavor",
      width: "7%",
      //width: '6%',
      ...getFilterProps("flavor", filterData("flavor")),
    },
    {
      //dataIndex: "single_or_multi_serve",
      dataIndex: "single_or_multi_serve",
      title: "Single/Multi Serve",
      width: '10.7%',
      ...getFilterProps("single_or_multi_serve", filterData("single_or_multi_serve")),
    },
    {
      //dataIndex: "package_size",
      dataIndex: "package_size",
      title: "Package Size",
      width: '7.7%',
      //width: '6%',
      ...getFilterProps("package_size", filterData("package_size")),
    },
    {
      //dataIndex: "package_type",
      dataIndex: "package_type",
      title: "Package Type",
      width: '8.4%',
      ...getFilterProps("package_type", filterData("package_type")),
    },
    {
      //dataIndex: "package",
      dataIndex: "package",
      title: "Package",
      width: "82px",
      width: '8%',
      ...getFilterProps("package", filterData("package")),
    },
    {
      //dataIndex: "package",
      dataIndex: "demand_area_key",
      title: "Demand Area Key",
      width: "82px",
      ...getFilterProps("demand_area_key", filterData("demand_area_key")),
    },
    {
      //dataIndex: "package",
      dataIndex: "demand_area_desc",
      title: "Demand Area",
      width: "82px",
      ...getFilterProps("demand_area_desc", filterData("demand_area_desc")),
    },
    {
      //dataIndex: "package",
      dataIndex: "sub_demand_area_key",
      title: "Sub Demand Area Key",
      width: "82px",
      ...getFilterProps("sub_demand_area_key", filterData("sub_demand_area_key")),
    },
    {
      //dataIndex: "package",
      dataIndex: "sub_demand_area_desc",
      title: "Sub Demand Area",
      width: "82px",
      ...getFilterProps("sub_demand_area_desc", filterData("sub_demand_area_desc")),
    },
    {
      //dataIndex: "package",
      dataIndex: "cpl_key",
      title: "CPL Key",
      width: "82px",
      ...getFilterProps("cpl_key", filterData("cpl_key")),
    },
    {
      //dataIndex: "package",
      dataIndex: "cpl_desc",
      title: "CPL",
      width: "82px",
      ...getFilterProps("cpl_desc", filterData("cpl_desc")),
    },
    {
      //dataIndex: "rcp",
      dataIndex: "rcp",
      title: "RCP",
      width: "70px",
      ...getFilterProps("rcp", filterData("rcp")),
      render: (record) => {
        // const formattedRecord = parseFloat(record).toFixed(1);
        const y = Math.round(parseFloat(record));
        return y.toLocaleString('en-US');

      },
      //width: '6%',
      //fixed: "right",
      // ...getFilterProps("rcp", filterData("rcp")),
    },
    {
      //dataIndex: "manually_Adjusted_be",
      dataIndex: "manually_adjusted_be",
      title: "Manually Adjusted BE",
      fixed: "right",
      //width: '8%',: ""
      width: "130px",
      editable: true,
      render: (record) => {
        const y = parseFloat(record)
        return y.toLocaleString('en-US');
      },

    },
  ].filter((item) => !item.hidden);

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        className: "class-in-td",
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const saveUpdatedData = async () => {
    if (dataSource.length > 0) {
      setNoChanges(true);
      let changedValues = compareTwoArrayOfObjects(tempDataSource, dataSource)
      if (changedValues.length > 0) {
        setIsTableLoading(true);
        await onSubmitDataFromImport(changedValues, true);
      } else {
        excelErrorMessage = "There is nothing to change";
        setIsTableLoading(false);
        setNoChanges(true);
        return showModal();
      }

      // setDataCsv(dataSource);

    }
  };

  const onChange = (pagination, filters, sorter, extra) => {

    setFilteredData(extra.currentDataSource);
    setFilteredInfo(filters);
  };
  const countDown = () => {
    let secondsToGo = 3;
    const instance = modal.success({
      title: "Your Changes has been saved successfully",
      // content: `This modal will be destroyed after ${secondsToGo} second.`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };
  const date = moment().format("YYYY-MM-DD");
  const time = moment().format("LT").replace(/\s/g, '');
  const nameOfExportedFile = `manualAdjustment${date}_${time}`;


  const clearAllFilters = () => {
    setFilteredData(dataSource);
    setFilteredInfo({})
  }

  return loading ? (
    <div className="table-data-note">
      <Spinner animation="border" />
    </div>
  ) :
    userHasAccess ? (
      <div>
        <div className="user-view-container">
          <div className="brand-category-header-container row">
            <div className="col-9 brand-category-filter-container">
              <div className="col-2" style={{ paddingRight: "30px" }}>
                <DropDownCountry
                  title="Country"
                  preSelectedValue={currentCountry}
                  options={countryList}
                  setParentDropdownValue={setDropDownValues}
                  handleSaveCountry={handleSaveCountry}
                />
              </div>
              {/* <div className="brand-category-dropdown-container"> */}
              <div className="col-2" style={{ paddingRight: "30px" }}>
                <DropDownCountry
                  title="SalesOrg"
                  preSelectedValue={currentSalesOrg}
                  options={salesOrg}
                  setParentDropdownValue={setDropDownValues}
                  handleSaveSalesOrg={handleSaveSalesOrg}
                />
              </div>

              <div className="col-2" >
                <DropDownCountry
                  title="Current Period Week"
                  preSelectedValue={currentWeek}
                  options={weekList}
                  setParentDropdownValue={setDropDownValues}
                  handleSaveWeek={handleSaveWeek}
                />
              </div>
              <div>
                <div className="col-5" style={{ paddingTop: "10px", paddingLeft: '30px', width: "100%" }}>

                  <div style={{ paddingBottom: "2px", fontSize: "14px" }} >
                    Period
                  </div>
                  <Typography style={{ fontSize: "13px", width: "100%" }}>
                    {currentPeriod && currentPeriod}
                  </Typography>

                </div>
              </div>

            </div>

            <div className="col-3 row">

              {
                isCurrentWeekSeelected &&
                <div className="col-4">
                  <CSVReader
                    onUploadAccepted={async (results) => {
                      if (results.data) {
                        setIsTableLoading(true);
                        await converToTableData(results.data);

                      }
                    }}
                  >
                    {({ getRootProps, acceptedFile, ProgressBar }) => (
                      <>
                        <div>
                          <Button
                            shape="round"
                            style={{ backgroundColor: "#E61C2A", color: "#F7F7F7", paddingLeft: "20px", paddingRight: "20px", marginTop: "15px" }}
                            //  icon={<CaretUpFilled />}
                            {...getRootProps()}
                          >
                            Import
                          </Button>
                          <div style={{ fontSize: "10px" }}>
                            {acceptedFile && acceptedFile.name}
                          </div>
                        </div>
                        <ProgressBar />
                      </>
                    )}
                  </CSVReader>
                </div>
              }

              <div className="col-4">
                <CSVLink
                  style={{ textDecoration: "none" }}
                  filename={nameOfExportedFile}
                  headers={headers}
                  data={dataCsv}
                >
                  <Button
                    style={{ paddingLeft: "20px", paddingRight: "20px", backgroundColor: "#E61C2A", color: "#F7F7F7", marginTop: "15px" }}
                    shape="round"
                  // icon={<CaretDownFilled />}
                  >
                    Export
                  </Button>
                </CSVLink>
              </div>

              {isCurrentWeekSeelected &&
                <div className="col-4">
                  <Button
                    type="primary"
                    disabled={noChanges}
                    onClick={saveUpdatedData}
                    style={{ paddingLeft: "20px", paddingRight: "20px", backgroundColor: !noChanges ? "#E61C2A" : null, color: !noChanges ? "#F7F7F7" : null, marginTop: "15px" }}
                    shape="round"
                  // icon={<SaveFilled />}
                  >
                    Save
                  </Button>
                </div>
              }
            </div>

            {/* {tableData && tableData.length > 0 && */}
            {isTableLoading ? (
              <div className="table-data-note">
                <Spinner animation="border" />
              </div>
            ) : (
              <div
                style={{ marginLeft: "-0.8rem", marginTop: "0.4rem" }}
                className="brand-category-table-container"
              >
                <TableManualAdjustment
                  scroll={{ y: 500, x: 500 }}
                  columns={columns}
                  data={dataSource}
                  form={form}
                  onChange={onChange}
                  components={components}
                  filteredInfo={filteredInfo}
                  rowClass={"editable-row"}
                />
              </div>
            )}
            {/* } */}
          </div>
        </div>

        {contextHolder}

        <Modal
          title={excelErrorMessage}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        ></Modal>
      </div>
    ) : <Modal
      title={excelErrorMessage}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    ></Modal>;
}

export default ManualAdjustmentUser;
