import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import '@rmwc/chip/styles';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import SKUQuarterSingleSelectDropDown from '../../components/skuQuarterSingleSelectDropDown';
import DropDownMultiSelectMFACustom from '../../components/dropDownMultiSelectMFACustom'
import SKUExclusionNewlistAndDelistDialogueBox from '../../components/skuExclusionNewlistAndDelistDialogueBox'
import SKUExclusionNewlistAndDelistFormModal from '../../components/skuExclusionNewlistAndDelistFormModal';
import TableSKUExclusionNewlistAndDelist from '../../components/tableSKUExclusionNewlistAndDelist';
import { BASE_URL, CHANNEL_CATEGORY_MAPPINGS, BRAND_CATEGORY_MAPPINGS, FILTERS, INPUT_SUGGESTIONS, INPUT_FORMS, MFA, DELETE, EXPORT, SKU_PERF, EXCLUSIONS, NEWLISTANDDELIST, QUARTERS, CUTOFFTIME } from '../../constant/apiEndpoints';
import { skuExclusionNewlistAndDelistInitialColumnsForm } from '../../constant/constants';
import { channelSuggestionData } from '../../constant/utils';
import APIService from '../../services/APIService';
import SKUBanPopupModal from '../../components/skuBanPopupModal';
import SKUSpecificSearchBar from '../../components/skuSpecificSearchBar';
const tableHeadColour = '#F2F2F2';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const initialColumns = skuExclusionNewlistAndDelistInitialColumnsForm

//Material UI Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeadColour,
        color: theme.palette.common.black,
        fontWeight: 600,
        cursor: 'pointer'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));
//Material UI Styles


const Consolidated2 = (props) => {

    const [adminIds, setAdminIds] = useState(props.adminIds);
    const [quarterList, setQuarterList] = React.useState([]);
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [selectedForm, setSelectedForm] = React.useState(props.formId)
    const [itemCategoryList, setItemCategoryList] = useState([]);
    const [selectedQuarters, setSelectedQuarters] = React.useState([]);
    const [selectedBusinessUnitList, setSelectedBusinessUnitList] = useState([]);
    const [selectedTypeList, setSelectedTypeList] = useState([]);
    const [selectedItemCategoryList, setSelectedItemCategoryList] = useState([]);
    const [selectedList, setSelectedList] = useState([]); //Button Handle
    const [columns, setColumns] = useState(initialColumns); //Columns Handle
    const [skuExclusionNewlistAndDelistData, setSKUExclusionNewlistAndDelistData] = useState([]); //Table Data Handle
    const [isCutOffTime, setCutOffTime] = useState(false);
    const [dropDownTimer, setDropDownTimer] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedIdToEdit, setSelectedIdToEdit] = useState();
    const [selectedObjToEdit, setSelectedObjToEdit] = useState({});
    const [openDeleteDialogueBox, setOpenDeleteDialogueBox] = useState(false);
    const [selectedBan, setSelectedBan] = useState(null);
    const [selectedBU, setSelectedBU] = useState("");
    const [selectedQuarter, setSelectedQuarter] = useState("");
    const [isBanPopupModalOpen, setIsBanPopupModalOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [previousClosedQuarter, setPreviousClosedQuarter] = useState("");

    const setSelectedListInParent = (value) => {
        setSelectedList(value)
    }

    const handleIsFormModalOpen = (value) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const currentQuarter = Math.floor(currentMonth / 3) + 1;
        let previousQuarterStart;
        if (currentQuarter === 1) {
            previousQuarterStart = new Date(currentYear - 1, 9, 1);
        }
        else {
            previousQuarterStart = new Date(currentYear, (currentQuarter - 2) * 3, 1);
        }
        const formattedPreviousClosedQuarter = `${previousQuarterStart.getFullYear()}Q${Math.floor(previousQuarterStart.getMonth() / 3) + 1}`;
        setPreviousClosedQuarter(formattedPreviousClosedQuarter)
        setIsFormModalOpen(value);
        setSelectedIdToEdit();
        setSelectedObjToEdit({});
    }

    const handleDeleteDialogueBox = () => {
        setOpenDeleteDialogueBox(!openDeleteDialogueBox)
    }

    const handleDeleteButton = () => {
        setOpenDeleteDialogueBox(true)
    }

    //Support Function For Filter List
    const createStringFromObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return encodeURIComponent(newString)
    }

    //AJAX Call To Load Channel Mapping List
    const loadSKUExclusionNewlistAndDelistDataAPICall = async (selectedQuarterFilter = "", selectedBusinessUnitFilter = "", selectedTypeFilter = "", selectedItemCategoryFilter = "", searchFilter = "") => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + `?selected_form=${props.formId}` + selectedQuarterFilter + searchFilter + selectedBusinessUnitFilter + selectedTypeFilter + selectedItemCategoryFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in channel exlusion list API", response.results.message)
        } else {
            // if (response.results.length > 0) {
            // const transformedData = response.results.map(({ id, title }) => ({ label: title, value: id }));
            setSKUExclusionNewlistAndDelistData(response.results);
            // setBrandCategoryListReference(response.results);
            // }
        }
        setLoading(false);
    }

    const handleDelete = async (comment) => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + DELETE
        let body = {
            "form_id": props.formId,
            "ids": selectedList.map((ele) => { return ele.id })
        }
        let response = await new APIService().delete(url, body)
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("This field may not be blank.")) {
                    toast.error('Comment is required!', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                } else {
                    toast.error('Something went wrong.', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                }
            }
        } else {
            toast.success('Data deleted successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000 });
            loadQuarterList()
            loadBusinessUnitList()
            loadTypeList()
            loadItemCategoryList()
            loadSKUExclusionNewlistAndDelistData(true, selectedQuarters, selectedBusinessUnitList, selectedTypeList, selectedItemCategoryList, search);
            handleDeleteDialogueBox();
            setSelectedList([])
        }
    }
    const handleExportButton = async () => {

        //Quarter Filter
        let selectedQuarterFilter = getQuarterFilter(null)
        //Business Unit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(null)
        //Type filter
        let selectedTypeFilter = getTypeFilter(null)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(null)

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + EXPORT + `?selected_form=${props.formId}` + selectedQuarterFilter + selectedBusinessUnitFilter + selectedTypeFilter + selectedItemCategoryFilter
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token')).access}`,
            })
        }).then(response =>
            response.blob().then(blob => {
                var today = new Date()
                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = "sku_exclusion_newlistdelist_" + date + "_" + time + ".xls";
                a.click();
            })
        );
    }

    const getQuarterFilter = (quarterIDs) => {
        //Getting User Selected Business Units By State/User Action
        let selectedQuarterList = quarterIDs !== null ? quarterIDs : selectedQuarters
        let selectedQuarterFilter = ""
        if (selectedQuarterList.length !== 0) {
            selectedQuarterFilter = `&selected_quarter=${createStringFromObjectList(selectedQuarterList)}`
        }
        return selectedQuarterFilter
    }

    const getBusinessUnitFilter = (businessUnitIDs) => {
        //Getting User Selected Data By State/User Action
        let selectedBusinessUnit = businessUnitIDs !== null ? businessUnitIDs : selectedBusinessUnitList
        let selectedBusinessUnitListFilter = ""
        if (selectedBusinessUnit.length !== 0) {
            selectedBusinessUnitListFilter = `&selected_business_unit=${createStringFromObjectList(selectedBusinessUnit)}`
        }
        return selectedBusinessUnitListFilter
    }

    const getTypeFilter = (typeIDs) => {
        //Getting User Selected Data By State/User Action
        let selectedType = typeIDs !== null ? typeIDs : selectedTypeList
        let selectedTypeFilter = ""
        if (selectedType.length !== 0) {
            selectedTypeFilter = `&selected_type=${createStringFromObjectList(selectedType)}`
        }
        return selectedTypeFilter
    }

    const getItemCategoryFilter = (itemCategoryIDs) => {
        //Getting User Selected Data By State/User Action
        let selectedItemCategory = itemCategoryIDs !== null ? itemCategoryIDs : selectedItemCategoryList
        let selectedItemCategoryFilter = ""
        if (selectedItemCategory.length !== 0) {
            selectedItemCategoryFilter = `&selected_item_category=${createStringFromObjectList(selectedItemCategory)}`
        }
        return selectedItemCategoryFilter
    }
    const getSearchFilter = (seachFilterArg) => {
        let searchFilter = ""
        if (seachFilterArg.length !== 0) {
            searchFilter = `&search=${encodeURIComponent(seachFilterArg)}`
        }
        return searchFilter
    }
    const loadQuarterList = async (businessUnitIDs = selectedBusinessUnitList, typeIDs = selectedTypeList, itemCategoryIDs = selectedItemCategoryList, searchFilterArg = search) => {
        //Business Unit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)
        //Type filter
        let selectedTypeFilter = getTypeFilter(typeIDs)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(itemCategoryIDs)
        //Search Filter
        let searchFilter = getSearchFilter(searchFilterArg);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + QUARTERS + `?selected_form=${props.formId}` + selectedBusinessUnitFilter + selectedTypeFilter + selectedItemCategoryFilter + searchFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setQuarterList(response.results);
            }
        }
    }

    const loadBusinessUnitList = async (quarterIDs = selectedQuarters, typeIDs = selectedTypeList, itemCategoryIDs = selectedItemCategoryList, searchFilterArg = search) => {
        //Quarter Filter
        let selectedQuarterFilter = getQuarterFilter(quarterIDs)
        //Type filter
        let selectedTypeFilter = getTypeFilter(typeIDs)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(itemCategoryIDs)
        //Search Filter
        let searchFilter = getSearchFilter(searchFilterArg);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + FILTERS + `?selected_form=${props.formId}` + `&filter_type=business_unit__name` + selectedQuarterFilter + selectedTypeFilter + selectedItemCategoryFilter + searchFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setBusinessUnitList(response.results);
            }
        }
    }

    //AJAX Call To Load Category SAP List
    const loadTypeList = async (quarterIDs = selectedQuarters, businessUnitIDs = selectedBusinessUnitList, itemCategoryIDs = selectedItemCategoryList, searchFilterArg = search) => {
        //Quarter Filter
        let selectedQuarterFilter = getQuarterFilter(quarterIDs)
        //Business Unit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)
        //Item Category Filter
        let selectedItemCategoryFilter = getItemCategoryFilter(itemCategoryIDs)
        //Search Filter
        let searchFilter = getSearchFilter(searchFilterArg);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + FILTERS + `?selected_form=${props.formId}` + `&filter_type=type__reference_title`+ selectedQuarterFilter + selectedBusinessUnitFilter + selectedItemCategoryFilter + searchFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setTypeList(response.results);
            }
        }
    }
    const loadItemCategoryList = async (quarterIDs = selectedQuarters, businessUnitIDs = selectedBusinessUnitList, typeIDs = selectedTypeList, searchFilterArg = search) => {
        //Quarter Filter
        let selectedQuarterFilter = getQuarterFilter(quarterIDs)
        //Business Unit filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)
        //Type filter
        let selectedTypeFilter = getTypeFilter(typeIDs)
        //Search Filter
        let searchFilter = getSearchFilter(searchFilterArg);

        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + NEWLISTANDDELIST + FILTERS + `?selected_form=${props.formId}` + `&filter_type=item_category` + selectedQuarterFilter + selectedBusinessUnitFilter + selectedTypeFilter + searchFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand Category Mapping List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setItemCategoryList(response.results);
            }
        }
    }

    const loadCutOffTime = async () => {
        let url = BASE_URL + INPUT_FORMS + SKU_PERF + EXCLUSIONS + CUTOFFTIME + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Channel List API", response.results.message)
        } else {
            if (response.results) {
                setCutOffTime(response.results.is_cut_off_time_on);
            }
        }
    }

    const loadSKUExclusionNewlistAndDelistData = async (useTimer, quarterIDs, businessUnitIDs, typeIDs, itemCategoryIDs, searchFilterArg = "",) => {

        let selectedQuarterFilter = getQuarterFilter(quarterIDs)

        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)

        let selectedTypeFilter = getTypeFilter(typeIDs)

        let selectedItemCategoryFilter = getItemCategoryFilter(itemCategoryIDs)

        let searchFilter = getSearchFilter(searchFilterArg);

        //Clearing Timeout Before Adding New Timeout(Previous Timeout)
        if (dropDownTimer) {
            clearTimeout(dropDownTimer);
        }
        //Add Timeout Before Hitting API
        if (useTimer) {
            setDropDownTimer(
                setTimeout(function () {
                    // loadBrandCategoryMappingAPICall(selectedBrandFilter, selectedSummarizedBrandFilter, selectedCategorySAPFilter);
                    toast.promise(loadSKUExclusionNewlistAndDelistDataAPICall(selectedQuarterFilter, selectedBusinessUnitFilter, selectedTypeFilter, selectedItemCategoryFilter, searchFilter), {
                        loading: "Loading. wait a moment!",
                        success: 'Data load success.',
                        error: 'Something went wrong.',
                    },
                        {
                            position: 'top-center',
                            className: 'toast-popup',
                        });
                }, 1500)
            )
        } else {
            loadSKUExclusionNewlistAndDelistDataAPICall();
        }
    }
    const handleSearchSubmit = (search) => {
        setSearch(search);
        loadBusinessUnitList(selectedQuarters, selectedTypeList, selectedItemCategoryList, search);
        loadTypeList(selectedQuarters, selectedBusinessUnitList, selectedItemCategoryList, search);
        loadItemCategoryList(selectedQuarters, selectedBusinessUnitList, selectedTypeList, search);
        loadSKUExclusionNewlistAndDelistData(true, selectedQuarters, selectedBusinessUnitList, selectedTypeList, selectedItemCategoryList, search);
    }
    const handleQuarterSelection = (value) => {
        setSelectedQuarters(value);
        loadBusinessUnitList(value, selectedTypeList, selectedItemCategoryList, search);
        loadTypeList(value, selectedBusinessUnitList, selectedItemCategoryList, search);
        loadItemCategoryList(value, selectedBusinessUnitList, selectedTypeList, search);
        loadSKUExclusionNewlistAndDelistData(true, value, selectedBusinessUnitList, selectedTypeList, selectedItemCategoryList, search);
    }

    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Business Unit") {
            setSelectedBusinessUnitList(objs)
            //To load relevent values in other dropdown filters
            loadQuarterList(objs, selectedTypeList, selectedItemCategoryList, search);
            loadTypeList(selectedQuarters, objs, selectedItemCategoryList, search);
            loadItemCategoryList(selectedQuarters, objs, selectedTypeList, search);
        }
        else if (valueType === "Type(Newlist/Delist)") {
            setSelectedTypeList(objs)
            //To load relevent values in other dropdown filters
            loadQuarterList(selectedBusinessUnitList, objs, selectedItemCategoryList, search);
            loadBusinessUnitList(selectedQuarters, objs, selectedItemCategoryList, search);
            loadItemCategoryList(selectedQuarters, selectedBusinessUnitList, objs, search);
        }
        else if (valueType === "Item Category") {
            setSelectedItemCategoryList(objs)
            //To load relevent values in other dropdown filters
            loadQuarterList(selectedBusinessUnitList, selectedTypeList, objs, search);
            loadBusinessUnitList(selectedQuarters, selectedTypeList, objs, search);
            loadTypeList(selectedQuarters, selectedBusinessUnitList, objs, search);
        }
        //Loading Channel Mapping List In Table
        loadChannelMappingsBasedOnFilters(objs, valueType);
    }


    const loadChannelMappingsBasedOnFilters = (objs, valueType) => {
        //Loading Channel Mapping Values which is dependent on Home OOH Dropdown
        if (valueType === "Business Unit") {
            loadSKUExclusionNewlistAndDelistData(true, selectedQuarters, objs, selectedTypeList, selectedItemCategoryList, search);
        }
        //Loading Channel Mapping Values which is dependent on Trade Channel Dropdown
        if (valueType === "Type(Newlist/Delist)") {
            loadSKUExclusionNewlistAndDelistData(true, selectedQuarters, selectedBusinessUnitList, objs, selectedItemCategoryList, search);
        }
        //Loading Channel Mapping Values which is dependent on Subtrade Channel Dropdown
        if (valueType === "Item Category") {
            loadSKUExclusionNewlistAndDelistData(true, selectedQuarters, selectedBusinessUnitList, selectedTypeList, objs, search);
        }
    }

    const handleEditClickInParent = (obj) => {
        setIsFormModalOpen(true)
        setSelectedIdToEdit(obj.id)
        setSelectedObjToEdit(obj)
    }

    const postSubmitAndEditData = () => {
        loadQuarterList()
        loadBusinessUnitList()
        loadTypeList()
        loadItemCategoryList()
        loadSKUExclusionNewlistAndDelistData(true, selectedQuarters, selectedBusinessUnitList, selectedTypeList, selectedItemCategoryList, search);
    }
    const handleBanPopupModal = (id = null, selectedBU = "", quarter = "") => {
        if (id !== null) {
            setSelectedBan(id)
        }
        if (selectedBU !== "") {
            setSelectedBU(selectedBU)
        }

        if (quarter !== "") {
            setSelectedQuarter(quarter)
        }
        setIsBanPopupModalOpen(!isBanPopupModalOpen)
    }
    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadQuarterList()
            loadBusinessUnitList()
            loadTypeList()
            loadItemCategoryList()
            // loadCutOffTime();
            loadSKUExclusionNewlistAndDelistData(false, selectedQuarters, selectedBusinessUnitList, selectedTypeList, selectedItemCategoryList, search);
        }
        return function cleanup() {
            mounted = false
        }

    }, [])

    return (
        <div className="user-view-container">
            <div className="brand-category-header-container row">
                <div className="col-10 brand-category-filter-container">
                    {/* <div className="brand-category-dropdown-container">
                        <div style={{height: "72px"}}>
                            <SKUQuarterSingleSelectDropDown options={quarterList}
                                setParentDropdownValue={handleQuarterSelection} />
                        </div>
                    </div> */}
                    <div className="brand-category-dropdown-container w-25">
                        <DropDownMultiSelectMFACustom title="Quarter" options={quarterList} setParentDropdownValue={handleQuarterSelection} />
                    </div>
                    <div className="brand-category-dropdown-container w-25">
                        <DropDownMultiSelectMFACustom title="Business Unit" options={businessUnitList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="brand-category-dropdown-container w-25">
                        <DropDownMultiSelectMFACustom title="Type(Newlist/Delist)" options={typeList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="brand-category-dropdown-container w-25">
                        <DropDownMultiSelectMFACustom title="Item Category" options={itemCategoryList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="col-3 p-0 seach-box-container">
                        <SKUSpecificSearchBar handleSearchSubmit={handleSearchSubmit} />
                    </div>
                </div>
                    <div className="col-2 brand-category-add-delete-export-button-container">
                        <button className="brand-category-export-button mt-3 col" type="button" value="Export Data" onClick={handleExportButton}>Export</button>

                        {selectedList.length === 0 ?
                             <button className="brand-category-button mt-3 col" type="button" value="+ Add New" disabled={isCutOffTime} onClick={() => handleIsFormModalOpen(true)}><FontAwesomeIcon icon={faPlus} className="search-icon" size="1x" /> Add New</button>
                            : <button className="brand-category-button mt-3 col" type="button" value="Delete" disabled={isCutOffTime} onClick={handleDeleteButton}><FontAwesomeIcon icon={faTrash} className="search-icon" size="1x" /> Delete</button>}
                    </div>
            </div>
            <div className="brand-category-body">
                <div className='other-mfa-table-holder'>
                    <div className='channel-category-chips-container'>
                            {/* CSS Purpose */}
                    </div>
                    {/* Loader(While Data Loading) */}
                    {loading ? <div className="table-data-note"><Spinner animation="border" />
                        <p></p>
                    </div> : ""}
                    {/* Loader(While Data Loading) */}
                    {/* Cut Off Time Note Banner */}
                    {
                        isCutOffTime && loading !== true ? <div className="sku-exclusion-cut-off-note-container" style={{ backgroundColor: "Yellow" }}>NOTE : Cut Off Time is going on. You will not be able to make any changes.</div> : ""
                    }
                    {/* Cut Off Time Note Banner */}
                    {skuExclusionNewlistAndDelistData.length > 0 ?
                        <div className="brand-category-table-container">
                            <TableSKUExclusionNewlistAndDelist columns={columns}
                            handleBanPopupModal={handleBanPopupModal}
                                skuExclusionNewlistAndDelistData={skuExclusionNewlistAndDelistData}
                                setSelectedListInParent={setSelectedListInParent}
                                handleEditClickInParent={handleEditClickInParent}
                                formId={props.formId}
                                isCutOffTime={isCutOffTime} />
                        </div>
                        : ''}
                    {/* No Data Message */}
                    {skuExclusionNewlistAndDelistData.length === 0 && loading === false ? (<div className="table-data-note"><p>No data found.</p>
                        <p></p>
                    </div>) : ""}
                    {/* No Data Message */}
                </div>
{/* Ban Popup Modal */}
            {isBanPopupModalOpen ?
                <SKUBanPopupModal isBanPopupModalOpen={isBanPopupModalOpen} handleBanPopupModal={handleBanPopupModal}
                    ban_number={selectedBan} business_unit={selectedBU} selectedForm={selectedForm} current_quarter={selectedQuarter}
                />
                : ""}
            {/* Ban Popup Modal */}
            </div>
            {/* Loader */}
            <Toaster />
            {/* Loader */}
            {isFormModalOpen ?
                <SKUExclusionNewlistAndDelistFormModal isFormModalOpen={isFormModalOpen} handleIsFormModalOpen={handleIsFormModalOpen}
                    formId={props.formId} selectedIdToEdit={selectedIdToEdit} selectedObjToEdit={selectedObjToEdit}
                    postSubmitAndEditData={postSubmitAndEditData} previousClosedQuarter={previousClosedQuarter}
                />
                : ""}
            {/* Message Dialogue Box */}
            {openDeleteDialogueBox ? <SKUExclusionNewlistAndDelistDialogueBox openDialogueBox={openDeleteDialogueBox}
                handleDeleteDialogueBox={handleDeleteDialogueBox}
                messageType="Delete"
                message="Are you sure you want to delete"
                handleDelete={handleDelete} /> : ""}
            {/* Message Dialogue Box */}

        </div>
    );

};

export default Consolidated2;