import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import '@rmwc/chip/styles';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import DropDownMultiSelectMFACustom from '../../components/dropDownMultiSelectMFACustom'
import BrandCategoryDialogueBox from '../../components/brandCategoryDialogueBox';
import PackageFormModal from '../../components/mfaPackageCategoryFormModal';
import TableMFAPackageMapping from '../../components/tableMFAPackageMappings';
import { BASE_URL, CHANNEL_CATEGORY_MAPPINGS, FILTERS, INPUT_SUGGESTIONS, INPUT_FORMS, MFA, DELETE, EXPORT, PACKAGE_MAPPINGS } from '../../constant/apiEndpoints';
import { packageInitailColumnsForm } from '../../constant/constants';
import { packageSuggestionData } from '../../constant/utils';
import APIService from '../../services/APIService';
const tableHeadColour = '#F2F2F2';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const initialColumns = packageInitailColumnsForm

//Material UI Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeadColour,
        color: theme.palette.common.black,
        fontWeight: 600,
        cursor: 'pointer'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));
//Material UI Styles


const Admin3 = (props) => {

    const [adminIds, setAdminIds] = useState(props.adminIds);
    const [packSizeList, setPackSizeList] = useState([]);
    const [packTypeList, setPackTypeList] = useState([]);
    const [selectedPackSizeList, setSelectedPackSizeList] = useState([]);
    const [selectedPackTypeList, setSelectedPackTypeList] = useState([]);
    const [selectedList, setSelectedList] = useState([]); //Button Handle
    const [columns, setColumns] = useState(initialColumns); //Columns Handle
    const [packageMappingList, setPackageMappingList] = useState([]); //Table Data Handle
    const [dropDownTimer, setDropDownTimer] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedIdToEdit, setSelectedIdToEdit] = useState();
    const [openDeleteDialogueBox, setOpenDeleteDialogueBox] = useState(false);
    const [suggestions, setSuggestions] = useState(packageSuggestionData);

    const setSelectedListInParent = (value) => {
        setSelectedList(value)
    }

    const handleIsFormModalOpen = (value) => {
        setIsFormModalOpen(value);
        setSelectedIdToEdit();
    }

    const handleDeleteDialogueBox = () => {
        setOpenDeleteDialogueBox(!openDeleteDialogueBox)
    }

    const handleDeleteButton = () => {
        setOpenDeleteDialogueBox(true)
    }

    //Support Function For Filter List
    const createStringFromObjectList = (objs) => {
        let newString = "" 
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return encodeURIComponent(newString)
    }

    //AJAX Call To Load SKU Performance List
    const loadPackageMappingAPICall = async (selectedPackSizeFilter = "", selectedPackTypeFilter = "") => {
        let url = BASE_URL + INPUT_FORMS + MFA + PACKAGE_MAPPINGS + `?selected_form=${props.formId}` + selectedPackSizeFilter + selectedPackTypeFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in package list API", response.results.message)
        } else {
            // if (response.results.length > 0) {
            // const transformedData = response.results.map(({ id, title }) => ({ label: title, value: id }));
            setPackageMappingList(response.results);
            // setBrandCategoryListReference(response.results);
            // }
        }
        setLoading(false);
    }
    const loadPackageSuggestion = async () => {
        let url = BASE_URL + INPUT_FORMS + MFA + PACKAGE_MAPPINGS + INPUT_SUGGESTIONS + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Package List API", response.results.message)
        } else {
            if (response.results) {
                setSuggestions(response.results);
            }
        }
    }
    const handleDelete = async (comment) => {
        let url = BASE_URL + INPUT_FORMS + MFA + PACKAGE_MAPPINGS + DELETE
        let body = {
            "form_id": props.formId,
            "comment": comment,
            "ids": selectedList.map((ele) => { return ele.package_id })
        }
        let response = await new APIService().delete(url, body)
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("This field may not be blank.")) {
                    toast.error('Comment is required!', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                } else {
                    toast.error('Something went wrong.', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                }
            }
        } else {
            toast.success('Data deleted successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000 });
            loadPackageMappingList(true, selectedPackSizeList, selectedPackTypeList);
            handleDeleteDialogueBox();
            setSelectedList([])
        }
    }
    const handleExportButton = async () => {

        let selectedPackSizeFilter = getPackageSizeFilter(null)

        let selectedPackTypeFilter = getpackageTypeFilter(null)

        // let selectedSubtradeChannelFilter = getSubtradeChannelFilter(null)

        let url = BASE_URL + INPUT_FORMS + MFA + PACKAGE_MAPPINGS + EXPORT + `?selected_form=${props.formId}` + selectedPackSizeFilter + selectedPackTypeFilter 
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token')).access}`,
            })
        }).then(response =>
            response.blob().then(blob => {
                var today = new Date()
                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = "package_" + date + "_" + time + ".csv";
                a.click();
            })
        );
    }
    const getPackageSizeFilter = (packSizeIDs) => {
        //Getting User Selected Countries By State/User Action
        let selectedPackSizeIDs = packSizeIDs !== null ? packSizeIDs : selectedPackSizeList
        let selectedPackSizeFilter = ""
        if (selectedPackSizeIDs.length !== 0) {
            selectedPackSizeFilter = `&selected_package_size=${createStringFromObjectList(selectedPackSizeIDs)}`
        }
        return selectedPackSizeFilter
    }

    const getpackageTypeFilter = (packTypeIDs) => {
        //Getting User Selected Countries By State/User Action
        let selectedPackTypeIDs = packTypeIDs !== null ? packTypeIDs : selectedPackTypeList
        let selectedPackTypeFilter = ""
        if (selectedPackTypeIDs.length !== 0) {
            selectedPackTypeFilter = `&selected_package_type=${createStringFromObjectList(selectedPackTypeIDs)}`
        }
        return selectedPackTypeFilter 
    }

   //AJAX Call To Load Package Size List
    const loadPackageSizeList = async (packTypeIDs = selectedPackTypeList) => {
        //Package Size filter
        let selectedPackageTypeFilter = getpackageTypeFilter(packTypeIDs)

        let url = BASE_URL + INPUT_FORMS + MFA + PACKAGE_MAPPINGS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=package_size` + selectedPackageTypeFilter 
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Package Size List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setPackSizeList(response.results);
            }
        }
    }

    //AJAX Call To Load Package Type List
    const loadPackageTypeList = async (packSizeIDs =selectedPackSizeList) => {
        //Package Type filter
        let selectedPackageSizeFilter = getPackageSizeFilter(packSizeIDs)

        let url = BASE_URL + INPUT_FORMS + MFA + PACKAGE_MAPPINGS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=package_type` + selectedPackageSizeFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setPackTypeList(response.results);
            }
        }
    }

    const loadPackageMappingList = async (useTimer, packSizeIDs, packTypeIDs) => {
        //Channel filter
        let selectedPackageSizeFilter = getPackageSizeFilter(packSizeIDs)

        let selectedPackageTypeFilter = getpackageTypeFilter(packTypeIDs)

        //Clearing Timeout Before Adding New Timeout(Previous Timeout)
        if (dropDownTimer) {
            clearTimeout(dropDownTimer);
        }
        //Add Timeout Before Hitting API
        if (useTimer) {
            setDropDownTimer(
                setTimeout(function () {
                    // loadBrandCategoryMappingAPICall(selectedBrandFilter, selectedSummarizedBrandFilter, selectedCategorySAPFilter);
                    toast.promise(loadPackageMappingAPICall(selectedPackageSizeFilter, selectedPackageTypeFilter), {
                        loading: "Loading. wait a moment!",
                        success: 'Data load success.',
                        error: 'Something went wrong.',
                    },
                        {
                            position: 'top-center',
                            className: 'toast-popup',
                        });
                }, 1500)
            )
        } else {
            loadPackageMappingAPICall();
        }
    }

    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Package Size") {
            setSelectedPackSizeList(objs)
            //To load relevent values in other dropdown filters
            loadPackageTypeList(objs);
            // loadSubtradeChannelList(objs, selectedTradeChannelList);
        }
        else if (valueType === "Package Type") {
            setSelectedPackTypeList(objs)
            //To load relevent values in other dropdown filters
            loadPackageSizeList(objs);
            // loadSubtradeChannelList(selectedHomeOOHList, objs);
        }
        // Loading Brand Mapping List In Table
        loadPackageMappingsBasedOnFilters(objs, valueType);
    }


    const loadPackageMappingsBasedOnFilters = (objs, valueType) => {
        //Loading Brand Category Values which is dependent on Brand Dropdown
        if (valueType === "Package Type") {
            loadPackageMappingList(true, selectedPackSizeList, objs);
        }
        //Loading Brand Category Values which is dependent on Summarized Brand Dropdown
        if (valueType === "Package Size") {
            loadPackageMappingList(true,  objs, selectedPackTypeList);
        }
    }

    const handleEditClickInParent = (id) => {
        setIsFormModalOpen(true)
        setSelectedIdToEdit(id)
    }

    const postSubmitAndEditData = () => {
        loadPackageMappingList(true, selectedPackSizeList, selectedPackTypeList);
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadPackageTypeList()
            loadPackageSizeList()
            loadPackageMappingList(false, selectedPackSizeList, selectedPackTypeList);
            loadPackageSuggestion()
        }
        return function cleanup() {
            mounted = false
        }

    }, [])

    return (
        <div className="user-view-container">
            <div className="brand-category-header-container row">
                <div className="col-10 brand-category-filter-container">
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Package Size" options={packSizeList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Package Type" options={packTypeList} setParentDropdownValue={setDropDownValues} />
                    </div>
                </div>
                {props.adminIds.includes(props.formId) ?
                    (<div className="col-2 brand-category-add-delete-export-button-container">
                        <button className="brand-category-export-button mt-3 col" type="button" value="Export Data" onClick={handleExportButton}>Export</button>

                        {selectedList.length === 0 ?
                            <button className="brand-category-button mt-3 col" type="button" value="+ Add New" onClick={() => handleIsFormModalOpen(true)}><FontAwesomeIcon icon={faPlus} className="search-icon" size="1x" /> Add New</button>
                            : <button className="brand-category-button mt-3 col" type="button" value="Delete" onClick={handleDeleteButton}><FontAwesomeIcon icon={faTrash} className="search-icon" size="1x" /> Delete</button>}
                    </div>) : ""}
            </div>
            <div className="brand-category-body">
                <div className='other-mfa-table-holder'>
                    <div className='channel-category-chips-container'>
                            {/* CSS Purpose */}
                    </div>
                    {/* Loader(While Data Loading) */}
                    {loading ? <div className="table-data-note"><Spinner animation="border" />
                        <p></p>
                    </div> : ""}
                    {/* Loader(While Data Loading) */}
                    {packageMappingList.length > 0 ?
                        <div className="brand-category-table-container">
                            <TableMFAPackageMapping columns={columns}
                                packageMappingList={packageMappingList}
                                setSelectedListInParent={setSelectedListInParent}
                                handleEditClickInParent={handleEditClickInParent}
                                formId={props.formId} adminIds={adminIds} />
                        </div>
                        : ''}
                    {/* No Data Message */}
                    {packageMappingList.length === 0 && loading === false ? (<div className="table-data-note"><p>No data found.</p>
                        <p></p>
                    </div>) : ""}
                    {/* No Data Message */}
                </div>

            </div>
            {/* Loader */}
            <Toaster />
            {/* Loader */}
            {isFormModalOpen ?
                <PackageFormModal isFormModalOpen={isFormModalOpen} handleIsFormModalOpen={handleIsFormModalOpen}
                    formId={props.formId} selectedIdToEdit={selectedIdToEdit} suggestions={suggestions}
                    postSubmitAndEditData={postSubmitAndEditData}
                />
                : ""}
            {/* Message Dialogue Box */}
            {openDeleteDialogueBox ? <BrandCategoryDialogueBox openDialogueBox={openDeleteDialogueBox}
                handleDeleteDialogueBox={handleDeleteDialogueBox}
                messageType="Delete"
                message="Are you sure you want to delete this Channel"
                handleDelete={handleDelete} /> : ""}
            {/* Message Dialogue Box */}

        </div>
    );

};

export default Admin3;