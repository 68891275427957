import axios from 'axios'

import { BASE_URL } from '../constant/apiEndpoints'

export const getEmissionData = (type,harmonised_version) => async (dispatch) => {
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/get_data/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        year: 2017,
        harmonised_version: harmonised_version,
        kbi_type: type
    }).then((response) => {
        if (response.data.status === 200) {
            dispatch({
                type: 'EMISSION_TABLE_DATA',
                payload: response.data.message.data
            })
            dispatch({
                type: 'EMISSION_COUNTRY_LIST',
                payload: response.data.message.region_filters.country
            })
            dispatch({
                type: 'EMISSION_BU_LIST',
                payload: response.data.message.region_filters.bu
            })
            dispatch({
                type: 'EMISSION_REGION_LIST',
                payload: response.data.message.region_filters.region
            })
            dispatch({
                type: 'EMISSION_COLUMN_LIST',
                payload: response.data.message.headers
            })
            dispatch({
                type: 'EMISSION_REGION_MATRIX',
                payload: response.data.message.region_matrix
            })
        }
    }).catch((e) => {
        //TODO: Send the error to a handler that actually shows it to the user
        console.log(e);
    })

}

export const sendUploadedFile = (tableData, filedata) => async (dispatch) => {
    const newUploadFile = filedata.map(({
            Region: region,
            Bu: bu,
            Country: country,
            Quarter: quarter,
            ...rest
    }) => ({
        region, bu, country, quarter, ...rest
    }))
    let loadDataFile = []
    for (let char of newUploadFile) {
        if (char.region !== '')
            loadDataFile.push(char)
    }
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    
    await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/upload/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        year: 2017,
        harmonised_version: "BaseLine",
        kbi_type: "emission",
        rows: loadDataFile
    }).then((response) => {
        if (response.data.status === 200) {
            dispatch(getEmissionData('emission','BaseLine'))
        }
        else {
            dispatch({
                type: 'EMISSION_TABLE_DATA',
                payload: tableData
            })
        }
    }).catch(e => {
        //TODO: Send the error to a handler that actually shows it to the user
        console.log(e)
        dispatch({
            type: 'EMISSION_TABLE_DATA',
            payload: tableData
        })
    })
}

export const sendUploadedRoadmapFile = (tableData, filedata) => async (dispatch) => {
    const newUploadFile = filedata.map(({
        Region: region,
        Bu: bu,
        Country: country,
        ...rest
    }) => ({
        region, bu, country, ...rest
    }))
    let loadDataFile = []
    for (let char of newUploadFile) {
        if (char.region !== '')
            loadDataFile.push(char)
    }
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    
    await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/upload/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        year: 2017,
        harmonised_version: "B020",
        kbi_type: "emission_2030_targets",
        rows: loadDataFile
    }).then((response) => {
        if (response.data.status === 200) {
            dispatch(getEmissionData('emission_2030_targets','B020'))
        } else {
            dispatch({
                type: 'EMISSION_TABLE_DATA',
                payload: tableData
            })
        }
    }).catch(e => {
        //TODO: Send the error to a handler that actually shows it to the user
        console.log(e)
        dispatch({
            type: 'EMISSION_TABLE_DATA',
            payload: tableData
        })
    })
}
export const editEmissionRow = (type,harmonised_version,tableData, rowData) => async (dispatch) => {
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }
    await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/edit/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        year: 2017,
        harmonised_version: harmonised_version,
        kbi_type: type,
        row: rowData
    }).then((response) => {
        if (response.data.status === 200) {
            dispatch(getEmissionData(type,harmonised_version))
        } else {
            dispatch({
                type: 'EMISSION_TABLE_DATA',
                payload: tableData
            })
        }
    }).catch(e => {
        //TODO: Send the error to a handler that actually shows it to the user
        console.log(e)
        dispatch({
            type: 'EMISSION_TABLE_DATA',
            payload: tableData
        })
    })
}
export const deleteEmissionRow = (type,harmonised_version,tableData, checkboxList, checkboxId) => async (dispatch) => {
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem('access_token')).access,
    }

    await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/delete/", {
        ad_group_ids: JSON.parse(localStorage.userList),
        year: 2017,
        harmonised_version: harmonised_version,
        kbi_type: type,
        rows: checkboxList
    }).then((response) => {
        if (response.data.status === 200) {
            dispatch(getEmissionData(type,harmonised_version))
        } else {
            dispatch({
                type: 'EMISSION_TABLE_DATA',
                payload: tableData
            })
        }
    }).catch(e => {
        //TODO: Send the error to a handler that actually shows it to the user
        console.log(e)
        dispatch({
            type: 'EMISSION_TABLE_DATA',
            payload: tableData
        })
    })
}

export const getForms = (token) => async (dispatch) => {
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
    }

    const userGroups = JSON.parse(localStorage.userList);

    await axios.create({
        baseURL: BASE_URL,
        responseType: "json",
        headers: headers
    }).post("/sustainability/get_forms/", {
        ad_group_ids: userGroups,
    }).then((response) => {
        if (response.data.status === 200) {
            if (response.data.message.form.includes("community_participants")){
                let index = response.data.message.form.indexOf('community_participants')
                delete response.data.message.form[index]
            }
            dispatch({
                type: 'FORMS_LIST',
                payload: response.data.message.form.reverse()
            })
        }
    })
    .catch((e) => {
        //TODO: Send the error to a handler that actually shows it to the user
        console.log(e)
    })
}

const dropdownData = (values) => {
    let list = []
    for (let val of values) {
        list.push({ label: val, value: val })
    }
    return list
}

export const updatingDropdown = (selectedType, selectedVal, matrix) => (dispatch) => {

    if (selectedType === 'Region') {
        let validBU = []
        let validCountry = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.region) || selectedVal.length === 0) {
                validBU.push(obj.bu)
                validCountry.push(obj.country)
            }
        }
        dispatch({
            type: 'EMISSION_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
        dispatch({
            type: 'EMISSION_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })

    }
    if (selectedType === 'BU') {
        let validRegion = []
        let validCountry = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.bu) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validCountry.push(obj.country)
            }
        };
        dispatch({
            type: 'EMISSION_COUNTRY_LIST',
            payload: dropdownData([...new Set(validCountry)])
        })
        dispatch({
            type: 'EMISSION_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })

    }
    if (selectedType === 'Country') {
        let validRegion = []
        let validBU = []
        for (let obj of matrix) {
            if (selectedVal.includes(obj.country) || selectedVal.length === 0) {
                validRegion.push(obj.region)
                validBU.push(obj.bu)
            }
        }
        dispatch({
            type: 'EMISSION_REGION_LIST',
            payload: dropdownData([...new Set(validRegion)])
        })
        dispatch({
            type: 'EMISSION_BU_LIST',
            payload: dropdownData([...new Set(validBU)])
        })

    }
}