import React, { useEffect, useState } from 'react';
import "../../App.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faCompass, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { grey, red, blue } from '@mui/material/colors';
const tableHeadColour = '#F2F2F2';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

//Material UI Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeadColour,
        color: theme.palette.common.black,
        fontWeight: 600,
        cursor: 'pointer',
        padding: '0px',
        zIndex: 1
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '2px 0px'
    },
    // [`&:hover`]: {
    //     backgroundColor: "blue !important"
    // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&:hover`]: {
        backgroundColor: "white !important"
    },

}));
//Material UI Styles

const TableList = (props) => {

    const [masterChecked, setMasterChecked] = React.useState(false);
    const [selectedList, setSelectedList] = React.useState([]);

    // Select/Unselect Table rows
    const onMasterCheck = (e) => {
        let tempList = props.channelCategoryMappingList;

        // Check/ UnCheck All Items
        tempList.map((each) => (each.selected = e.target.checked));

        setMasterChecked(e.target.checked);
        // setBrandCategoryMappingList(tempList);
        let newList = props.channelCategoryMappingList.filter((e) => e.selected)
        setSelectedList(newList);
        props.setSelectedListInParent(newList);
    }

    const onItemCheck = (e, row) => {
        let tempList = props.channelCategoryMappingList;
        tempList.map((each) => {
            if (each.id === row.id) {
                each.selected = e.target.checked;
            }
            return each;
        });

        //To Control Master Checkbox State
        const totalItems = props.channelCategoryMappingList.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        setMasterChecked(totalItems === totalCheckedItems);
        // setBrandCategoryMappingList(tempList);
        let newList = props.channelCategoryMappingList.filter((e) => e.selected)
        setSelectedList(newList);
        props.setSelectedListInParent(newList);
    }

    const handleEditClick = (id) => {
        props.handleEditClickInParent(id);
    }

    const formatNumberWithCommas = (value, columnId) => {
        // Check if the number already contains commas
        if (typeof value === 'number' && columnId !== 'year') {
          value = value.toString()
            if (typeof value === 'string' && value.includes(',')) {
            // Number already formatted with commas, return as is
            return value;
            } else {
            // Number doesn't have commas, format it
            const formattedNumber = parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 10});
            return formattedNumber;
            }
        }
        else{
            return value;
        }
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <StyledTableRow className="bold-font">
                            <StyledTableCell
                            >
                                <Checkbox id="mastercheck" checked={masterChecked} onChange={(e) => onMasterCheck(e)}
                                    {...label}
                                    // defaultChecked
                                    size="small"
                                    sx={{
                                        '& .MuiSvgIcon-root': { fontSize: 20 },
                                        color: grey[500],
                                        '&.Mui-checked': {
                                            color: red[500],
                                        },
                                    }} />
                            </StyledTableCell>
                            {props.columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={column.id == 'action' ? { position: 'sticky', right: 1, minWidth: column.minWidth, overflow: 'hidden', zIndex: 1 } : { minWidth: column.minWidth }}
                                    title="Click and wait to Sort"
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {props.channelCategoryMappingList
                            .map((row) => {
                                let selected = row.selected == "" ? false : true
                                return (
                                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        <StyledTableCell>
                                            <Checkbox id={row.id} checked={selected} onChange={(e) => onItemCheck(e, row)}
                                                {...label}
                                                // defaultChecked
                                                size="small"
                                                sx={{
                                                    '& .MuiSvgIcon-root': { fontSize: 20 },
                                                    color: grey[500],
                                                    '&.Mui-checked': {
                                                        color: red[500],
                                                    },
                                                }} />
                                        </StyledTableCell>
                                        {props.columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <StyledTableCell key={column.id} align={column.align}
                                                    style={column.id === 'action' ?
                                                        { position: 'sticky', right: 1, backgroundColor: 'white', overflow: 'hidden', color: '#E61C2A', minWidth: column.minWidth } :
                                                        { minWidth: column.minWidth, minHeight: '10px' }}
                                                >
                                                    {column.id === "action" ? <FontAwesomeIcon icon={faEdit} size="1x" onClick={() => handleEditClick(row)} /> : formatNumberWithCommas(value, column.id)}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            }
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default TableList;