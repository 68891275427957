import React from 'react';
import "../App.css";
import SKUSpecificDropDown from '../components/skuSpecificDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { grey } from '@mui/material/colors';
import { InputTextArea } from './textInputSKUExclusion';
import { Fade, Tooltip, Typography } from '@mui/material';
const tableHeadColour = grey[600];

//Material UI Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeadColour,
        color: theme.palette.common.white,
        fontWeight: 600,
        cursor: 'pointer'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //   border: 0,
    // },
}));
//Material UI Styles

const initialColumns = [
    { id: 'admin_delete_action', label: '', minWidth: 50, maxWidth: 200, align: 'center'},
    { id: 'business_unit_title', label: 'Business Unit', minWidth: 100, maxWidth: 200, align: 'center', is_sortable: true, is_sorted: false, sort_type: "asc", sort_name: "business_unit" },
    { id: 'category', label: 'Item Category', minWidth: 150, maxWidth: 250, align: 'center', is_sortable: false},
    { id: 'ban_description', label: 'BAN Description', minWidth: 300, maxWidth: '', align: 'center', is_sortable: true, is_sorted: false, sort_type: "asc", sort_name: "ban_description" },
    {
        id: 'ban_number',
        label: 'BAN Number',
        minWidth: 200,
        maxWidth: 200,
        align: 'center',
        is_sortable: true,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "ban_number",
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'net_contribution',
        label: 'Net Contribution',
        minWidth: 160,
        maxWidth: 160,
        align: 'right',
        is_sortable: true,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "net_contribution",
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'volume',
        label: 'Volume (in UC)',
        minWidth: 150,
        maxWidth: 150,
        align: 'right',
        is_sortable: true,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "volume",
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'exclusion',
        label: 'Exclusion Reason',
        minWidth: 200,
        maxWidth: 200,
        align: 'right',
        is_sortable: false,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "exclusion",
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'type',
        label: 'Narrative',
        minWidth: 330,
        maxWidth: 330,
        align: 'center',
        is_sortable: false,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "type",
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'comment',
        label: 'Comment',
        minWidth: 330,
        maxWidth: 330,
        align: 'center',
        is_sortable: false,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "comment",
        // format: (value) => value.toFixed(2),
    },
];

const TableSKUExclusion = (props) => {
    const [admin, setAdmin] = React.useState(props.admin)
    const [columns, setColumns] = React.useState(initialColumns);

    const handleBanPopupModal = (id = null, selectedBU="") => {
        props.handleBanPopupModal(id, selectedBU)
    }

    const handleSorting = (column) => {
        if (column.is_sortable) {
            var newData = []
            var data = columns
            let sort_type = "asc"
            if (column.is_sorted) {
                sort_type = column.sort_type === "asc" ? "desc" : "asc"
            }
            data.map(ele => {
                if (ele.id == column.id) {
                    newData.push({
                        ...ele,
                        is_sorted: true,
                        sort_type: sort_type,
                    })
                } else {
                    newData.push({
                        ...ele,
                        is_sorted: false,
                        sort_type: sort_type,
                    })
                }
            })
            props.handleSorting(newData);
            setColumns(newData);
        }
    }

    const getSortButton = (sortType) => {
        if (sortType === "asc") {
            return <FontAwesomeIcon icon={faArrowsAltV} className="sort-button" size="1x" title="Ascending" />
        } else if (sortType === "desc") {
            return <FontAwesomeIcon icon={faArrowsAltV} className="sort-button" size="1x" title="Descending" />
        }
    }

    const updateChangedSKUDeleteList = (sku_id, updatedElement) => {
        // var data = changedSKUExclusions
        var dataObjects = props.changedSKUDeleteListObjects

        var is_object_present = dataObjects.find(obj => {
            return obj.id === sku_id
        })

        if (is_object_present != undefined) {
            
                var removeIndex = dataObjects.findIndex(function (i) { return i.id === updatedElement.id });
                dataObjects.splice(removeIndex, 1);
                
    }
            
        else {
            // data.push(sku_id)
            dataObjects.push(updatedElement)
        }
        props.updateChangedSKUDelete(dataObjects);
    }

    //Dropdown Handler As Parent Component
    const setDeleteSKUListValues = (sku_id) => {
        //Assigning New Value On Change In Dropdown
        var newData = []
        var data = props.skuPerformanceList
        var updatedElement = {}

        data.map(ele => {
            if (ele.id === sku_id) {
                updatedElement = {
                    ...ele,
                }
                newData.push(updatedElement)
                updateChangedSKUDeleteList(sku_id, updatedElement);
            } else {
                newData.push(ele)
            }
        })
        props.setExclusionDropDownValues(newData);
    }

    const updateChangedSKUExclusions = (dropdownType, newValue, original_value, sku_id, updatedElement) => {
        // var data = changedSKUExclusions
        var dataObjects = props.changedSKUExclusionObjects

        var is_object_present = dataObjects.find(obj => {
            return obj.id === sku_id
        })

        if (is_object_present != undefined) {
            if (dropdownType === 'exclusion') {
                var removeIndex = dataObjects.findIndex(function (i) { return i.id === updatedElement.id });
                dataObjects.splice(removeIndex, 1);
                if (!(newValue === original_value && newValue !== "A")) {
                    dataObjects.push(updatedElement)
                }
            } else if (dropdownType === 'type') {
                var removeIndex = dataObjects.findIndex(function (i) { return i.id === updatedElement.id });
                dataObjects.splice(removeIndex, 1);
                if (!(newValue === original_value && is_object_present.exclusion_id === is_object_present.original_exclusion_id)) {
                    if (is_object_present.exclusion_id === "A") {
                        dataObjects.push(updatedElement)
                    }
                }
            }
            else {
                var removeIndex = dataObjects.findIndex(function (i) { return i.id === updatedElement.id });
                dataObjects.splice(removeIndex, 1);
                if (!(newValue == original_value && is_object_present.exclusion_id === is_object_present.original_exclusion_id)) {
                    if (is_object_present.exclusion_id === "A") {
                        dataObjects.push(updatedElement)
            }
        }
    }
            
        }
        else {
            // data.push(sku_id)
            dataObjects.push(updatedElement)
        }
        props.updateChangedSKUExclusions(dataObjects);
    }

    //Dropdown Handler As Parent Component
    const setExclusionDropDownValues = (dropdownType, newValue, sku_id) => {
        //Assigning New Value On Change In Dropdown
        var newData = []
        var data = props.skuPerformanceList
        var updatedElement = {}

        data.map(ele => {
            if (ele.id === sku_id) {
                dropdownType ==='exclusion' && newValue !== "A"
                ? updatedElement = {
                    ...ele,
                    // is_disable: false,
                    exclusion_id: dropdownType === 'exclusion' ? newValue : ele.exclusion_id,
                    type_id: null,
                    comment: null
                    // type_id: dropdownType === 'type' ? newValue : ele.exclusion_id !== 'A' || newValue !== 'A' ? "" : ele.type_id,
                }
                :
                updatedElement = {
                    ...ele,
                    // is_disable: false,
                    exclusion_id: dropdownType === 'exclusion' ? newValue : ele.exclusion_id,
                    type_id: dropdownType === 'type' ? newValue : ele.type_id,
                    comment: dropdownType === null ? newValue: ele.comment
                    // type_id: dropdownType === 'type' ? newValue : ele.exclusion_id !== 'A' || newValue !== 'A' ? "" : ele.type_id,
                }
                newData.push(updatedElement)
                //Updating changedSKUExclusions
                var original_value = dropdownType === 'exclusion' ? ele.original_exclusion_id : dropdownType === 'type' ?  ele.original_type_id: ele.comment
                
                updateChangedSKUExclusions(dropdownType, newValue, original_value, sku_id, updatedElement);
            } else {
                newData.push(ele)
            }
        })
        props.setExclusionDropDownValues(newData);
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <StyledTableRow className="bold-font">
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, width: column.maxWidth }}
                                    title="Click and wait to Sort"
                                    onClick={() => handleSorting(column)}
                                >
                                    {column.label}
                                    {/* Sort Icon Handled */}
                                    {column.is_sortable ?
                                        getSortButton(column.sort_type) :
                                        ""}
                                    {/* Sort Icon Handled */}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {props.skuPerformanceList
                            .map((row) => {
                                return (
                                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <StyledTableCell key={column.id} align={column.align} title={value}>
                                                    {column.id === "exclusion" ?
                                                        <div className="sku-exclusion-list-column-container">
                                                            <span className="exclusion-drop-down-container">
                                                                <SKUSpecificDropDown options={props.skuExclusionStatuses}
                                                                    sku_id={row.id}
                                                                    currentSelectedExclusion={row.exclusion_id}
                                                                    setParentDropdownValue={setExclusionDropDownValues}
                                                                    type={column.id}
                                                                    is_current_quarter={row.is_current_quarter}
                                                                    other={row.exclusion_id}
                                                                    cutofftime={props.isExclusionCutOffTime}
                                                                    admin={admin} />
                                                            </span>
                                                        </div>
                                                        : column.id === "type" ?
                                                            <div className="sku-exclusion-list-column-container">
                                                                {row.exclusion_id === "A" ?
                                                                <span className="exclusion-drop-down-container">
                                                                        <SKUSpecificDropDown options={props.skuExclusionTypes}
                                                                            sku_id={row.id}
                                                                            currentSelectedExclusion={row.type_id}
                                                                            setParentDropdownValue={setExclusionDropDownValues}
                                                                            type={column.id}
                                                                            is_current_quarter={row.is_current_quarter}
                                                                            other={row.exclusion_id}
                                                                            cutofftime={props.isNarrativeCutOffTime}
                                                                            admin={admin} />
                                                                </span>
                                                                : <div className="sku-exclusion-list-comment-column"><p >NA</p></div>}
                                                            </div>
                                                            : column.id === "comment" ?
                                                            row.exclusion_id === "A" &&
                                                                <div className="sku-exclusion-list-comment-column">
                                                                   <InputTextArea row={row}
                                                                   key={row.id}
                                                                   value={value}
                                                                   setExclusionDropDownValues={setExclusionDropDownValues}
                                                                   type={column.id}
                                                                    is_current_quarter={row.is_current_quarter}
                                                                    other={row.exclusion_id}
                                                                   cutofftime={props.isNarrativeCutOffTime}
                                                                   admin={admin}
                                                                   />
                                                                </div>
                                                                : column.id === "ban_number" ?
                                                                    <div className="sku-exclusion-list-comment-column" onClick={() => handleBanPopupModal(value, row.business_unit_id)} style={{ cursor: 'pointer' }} >
                                                                        <p style={{ color: "Blue" }} >{value}</p>
                                                                    </div>
                                                                    : column.id === "admin_delete_action" ?
                                                                    row.is_edited && admin && <> 
                                                                    {props.changedSKUDeleteListObjects.find(obj => {return obj.id === row.id}) ?
                                                                    <RemoveCircleIcon onClick={()=>setDeleteSKUListValues(row.id)}
                                                                    color="error"/>
                                                                    :
                                                                    <Tooltip key={row.id} title={<React.Fragment>
                                                                        <Typography>
                                                                            <b>Delete</b>: Select this option to revert the changes. 
                                                                            (<i>Reverting the changes will add this to best performing bans.</i>) 
                                                                            </Typography>
                                                                            </React.Fragment>}
                                                                            arrow
                                                                            placement="right-end"
                                                                            TransitionComponent={Fade}
                                                                            TransitionProps={{ timeout: 600 }}
                                                                            enterDelay={2000} 
                                                                            leaveDelay={200}>
                                                                    <RemoveCircleOutlineOutlinedIcon onClick={()=>setDeleteSKUListValues(row.id)}
                                                                    color="error"/>
                                                                    </Tooltip>
                                                                    }
                                                                    </>
                                                                : value}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            }
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default TableSKUExclusion;