export const msalConfig = {
    auth: {
        // clientId: "e78727c0-18cf-4ec7-bcde-84048cec213f",
        clientId: process.env.REACT_APP_CLIENT_ID,
        // authority: "https://login.microsoftonline.com/7a67a070-8ce9-4692-b1af-bf788306bc66",
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        // redirectUri: "http://localhost:3000/",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    // graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
    graphMeEndpoint: process.env.REACT_APP_GRAPH_ME_ENDPOINT
};