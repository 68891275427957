import React from "react";
import "../App.css"
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faInfo, faSignOutAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function RejectDialogueBox(props) {
    const [open, setOpen] = React.useState(false);
    const [reason, setReason] = React.useState("");

    //Dynamic Input onChange Handler
    const handleOnChange = (e) => {
        setReason(e.target.value)
    }

    const handleSubmit = () => {
        props.performSubmit(false, reason);
    }

    const handleDialogueClose = () => {
        props.handleRejectDialogueClose();
    }

    return (
        <Dialog open={props.openRejectDialogueBox}>
            <DialogTitle style={{ paddingTop: '20px', paddingBottom: '0px' }}>
                {/* <div className="dialogue-icon-container">
                    <FontAwesomeIcon icon={faEnvelope} className="dialogue-icon" size="1x"
                    />
                </div> */}
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center', maxWidth: '51vh' }}>
                <DialogContentText className="dialogue-box-header" style={{ paddingBottom: '10px' }}>
                    Reason For Rejection
                </DialogContentText>
                <div>
                    <textarea className="text-input-textarea" rows="3" cols="50"
                        placeholder="Type reason here..."
                        value={reason}
                        onChange={handleOnChange} 
                        required/>
                </div>
            </DialogContent>
            <DialogActions className='dialogue-button-container'>
                <Button className="dialogue-button-cancel" onClick={handleDialogueClose} autoFocus>
                    Cancel
                </Button>
                <Button className="dialogue-button-submit" onClick={handleSubmit} autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
