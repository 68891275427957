import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import "../../App.css"
import User1 from './user1';
import User2 from './user2';
import User3 from './user3';
import EmissionBaseLine from '../Emission/EmissonBaseLine';
import StewardShip from '../StewardShip/StewardShip';
import ManualAdjustmentUser from '../manualAdjustment/user';
import CustomHierarchy from '../custHier/customHierarchy';
import User4 from './user4';

const UserRoute = (props) => {
    const [adminIds, setAdminIds] = React.useState(localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')).user.form_admin_list : []);

    const loadComponent = (formId) => {
        switch (formId) {
            case 'formecomm':
                return <User1 formId={formId} />
            case 'formsku':
                return <User2 formId={formId} adminIds={adminIds} />
            case 'formmap':
                return <User3 formId={formId} adminIds={adminIds} />;
            case 'priceforecast':
                return <User4 formId={formId} adminIds={adminIds} />;
            case 'emission':
                return <EmissionBaseLine />;
            case 'sustainability':
                return <StewardShip />;
            case 'manualnum':
                return <ManualAdjustmentUser formId={formId} />;
            case 'custhier':
                return <CustomHierarchy />
            default:
                return;
        }
    }

    return (
        <>
            {
                loadComponent(props.formId)
            }
        </>
    );
};

const mapStateProps = (state) => {
    return {
        formId: state.sidebar.formId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateProps, mapDispatchToProps)(UserRoute);