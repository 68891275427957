import React, { useState, useEffect } from "react";
import { month, year, dates, intVal, floatVal } from './EmissionColumns'
import toast, { Toaster } from 'react-hot-toast';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '66vw',
    // height: '434px',
    minHeight: '40vh',
    bgcolor: 'background.paper',
    border: '2px solid FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1.5vh 3vh',
    overflow: 'auto',
    // display: 'flex'
};
export default function EditModal(props) {
    const disabledField = ['% Sustainable Sourced', '% Primary Packaging Collected for recycling or reuse',
        '% Consumer packaging recyclable']

    const [rowData, setRowData] = useState(props.rowSelected)
    const [newRowData, setNewRowData] = useState()
    const handleDialogueBox = () => {
        props.handleEditDialogueBox();
    }

    const handleOnSubmit = () => {
        for (let char in rowData) {
            if (rowData[char].length === 0) {
                toast.error('Value cannot be empty of ' + char.toUpperCase(), { position: 'top-center', className: 'toast-popup', duration: 1500, });
                return
            }
        }
        if (JSON.stringify(props.rowSelected) === JSON.stringify(rowData)) {
            toast.error('Please edit atleast one field .', { position: 'top-center', className: 'toast-popup', duration: 1500, });
            return
        }
        for (let char in rowData) {
            if (dates.includes(char)) {
                if (rowData[char].includes('/') && rowData[char].length === 8 &&
                    month.includes(rowData[char].split('/')[0].toLowerCase()) &&
                    year.includes(parseInt(rowData[char].split('/')[1]))) {
                    continue
                }
                else {
                    toast.error('Please enter correct format of ' + char, { position: 'top-center', className: 'toast-popup', duration: 1500, });
                    return
                }
            }
            // else if (intVal.includes(char)) {
            //     if (/^([0-9])*$/.test(rowData[char].toString().replace(/,/g, '')) === true) {
            //         continue
            //     }
            //     else {
            //         toast.error('Please enter correct format of ' + char, { position: 'top-center', className: 'toast-popup', duration: 1500, });
            //         return
            //     }
            // }
            else if (floatVal.includes(char)) {
                if (/^([0-9]*[.]{1}[0-9]*)$/.test(rowData[char].toString().replace(/,/g, '')) === true || /^([0-9])*$/.test(rowData[char].toString().replace(/,/g, '')) === true) {
                    continue
                }
                else {
                    toast.error('Please enter correct format of ' + char, { position: 'top-center', className: 'toast-popup', duration: 1500, });
                    return
                }
            }
        }
        let obj = rowData
        if (props.type === 'water' || props.type === 'zwc') {
            let startMonth = month.indexOf(rowData['Start Date'].substr(0, 3).toLowerCase())
            let endMonth = month.indexOf(rowData['End Date'].substr(0, 3).toLowerCase())
            let startYear = rowData['Start Date'].substr(4)
            let endYear = rowData['End Date'].substr(4)
            if (endYear < startYear || (startYear == endYear && endMonth < startMonth)) {
                toast.error('Start Date cannot be greater than End Date ', { position: 'top-center', className: 'toast-popup', duration: 1500, });
                return
            }
            obj = {
                ...rowData,
                month: month.indexOf(rowData['Start Date'].substr(0, 3).toLowerCase()) + 1,
                year: parseInt(rowData['Start Date'].substr(4))
            }
        }
        if (props.type === 'waste' || props.type === 'sourcing') {
            if (props.type === 'sourcing' && rowData['% Sustainable Sourced'] > 100) {
                toast.error('% Sustainable Sourced cannot be more than 100 % ', { position: 'top-center', className: 'toast-popup', duration: 1500, });
                return
            }
            else if (props.type === 'waste' && rowData['% Primary Packaging Collected for recycling or reuse'] > 100) {
                toast.error('% Primary Packaging Collected for recycling or reuse cannot be more than 100 % ', { position: 'top-center', className: 'toast-popup', duration: 1500, });
                return
            }
        }
        obj = {
            ...obj,
            id: props.rowSelected.id
        }
        props.handleEdit(obj);

    }

    const handleOnCancel = () => {
        props.handleEditDialogueBox();
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'Sustainable Volume (tonnes)') {
            let divisor = rowData['Total Volume (tonnes)'].toString().replace(/,/g, '')
            setRowData({
                ...rowData,
                [name]: parseFloat(value).toLocaleString('en-US'),
                '% Sustainable Sourced': ((value / divisor) * 100).toFixed(2)
            })

            //console.log(name, value, divisor, value / divisor, 'abcd1')
        }

        else if (name === 'Total Volume (tonnes)') {
            let divisor = rowData['Sustainable Volume (tonnes)'].toString().replace(/,/g, '')
            setRowData({
                ...rowData,
                [name]: parseFloat(value).toLocaleString('en-US'),
                '% Sustainable Sourced': ((divisor / value) * 100).toFixed(2)
            })
        }
        else if (name === 'Total Recovered for Recycling in Units') {
            let divisor = rowData['Total Placed in Markets in Units'].toString().replace(/,/g, '')
            setRowData({
                ...rowData,
                [name]: parseFloat(value).toLocaleString('en-US'),
                '% Primary Packaging Collected for recycling or reuse':
                    ((value / divisor) * 100).toFixed(2)
            })

        }
        else if (name === 'Total Placed in Markets in Units') {
            let divisor = rowData['Total Recovered for Recycling in Units'].toString().replace(/,/g, '')
            setRowData({
                ...rowData,
                [name]: parseFloat(value).toLocaleString('en-US'),
                '% Primary Packaging Collected for recycling or reuse':
                    ((divisor / value) * 100).toFixed(2)
            })

        }
        else if (name === 'Start Date' || name === 'End Date') {
            setRowData({
                ...rowData,
                [name]: value.charAt(0).toUpperCase() + value.slice(1)
            })
        }
        else {
            setRowData({
                ...rowData,
                [name]: intVal.includes(name) || floatVal.includes(name) ? parseFloat(value).toLocaleString('en-US') : value
            })
        }

    }
    useEffect(() => {
        const keysToRemove = ['added_by', 'updated_by', 'created_date', 'updated_date','cut_off'];
        let newRowData = { ...rowData };
        keysToRemove.forEach(key => delete newRowData[key]);
        setNewRowData(newRowData);
    }, []
    )
  

    return (
        <div>
            <Modal
                open={props.openDialogueBox}
                onClose={handleDialogueBox}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="brand-category-modal-form-header">
                        <p>Edit Details</p>
                        <div className="brand-category-modal-form-close-button" onClick={handleOnCancel}>
                            X
                        </div>
                    </div>
                    <br />
                    <div >
                        <div style={{ display: "flex", flexFlow: "wrap" }}>
                            {newRowData && Object.keys(newRowData).map(char => {
                                if (char !== 'id' && char !== 'selected') {
                                    if (intVal.includes(char) || floatVal.includes(char)) {
                                        return (
                                            <div style={{ marginBottom: "2rem" }} key={char}>
                                                <div style={{ fontSize: ".75rem" }}>{char.toUpperCase()}
                                                    <span style={{ color: "#E61C2A" }}> *</span> </div>
                                                <input
                                                    value={rowData[char].toString().replace(/,/g, '')}
                                                    type="number"
                                                    //min="1"
                                                    name={char}
                                                    required
                                                    autoComplete="off"
                                                    disabled={props.disabledOption.includes(char) || disabledField.includes(char)}
                                                    style={{ width: props.type !== "waste" ? "15rem" : "25rem", margin: ".4rem 2rem .4rem 0rem" }}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                        )
                                    }

                                    else {
                                        return (
                                            <div style={{ marginBottom: "2rem" }} key={char}>
                                                <div style={{ fontSize: ".75rem" }}>{char.toUpperCase()}
                                                    <span style={{ color: "#E61C2A" }}> *</span> </div>
                                                <input
                                                    value={rowData[char]}
                                                    type="text"
                                                    name={char}
                                                    required
                                                    autoComplete="off"
                                                    disabled={props.disabledOption.includes(char)}
                                                    style={{ width: props.type !== "waste" ? "15rem" : "25rem", margin: ".4rem 2rem .4rem 0rem" }}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                        )
                                    }

                                }

                            })}
                        </div>
                        <div className="brand-category-modal-form-footer row m-0">
                            <div className="brand-category-modal-form-input col">
                                <div style={{ float: "right" }}>
                                    <button className="brand-category-form-modal-cancel-button mt-3" onClick={handleOnCancel}>Cancel</button>
                                    <button className="brand-category-form-modal-submit-button mt-3" onClick={handleOnSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal >
            <Toaster />
        </div>

    )
}
