import { TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";


export const InputTextAreaSKU = (props) => {
    const [timer, setTimer] = useState(null)

    const handleDisable = () => {
        if(props.is_current_quarter === false){
            return true
        }
        else if(props.cutofftime === true){
            if (props.admin){
                return false
            }
            return true
        }
        else {
            if (props.type === 'type' && props.other !== 'A') {
                return true
            }
        }
        return false
    }

    const handleSKUInputChange = e => {
        clearTimeout(timer)
    
        const newTimer = setTimeout(() => {
            props.setExclusionDropDownValues( null, e.target.value,props.row.id)
        }, 1000)
        
        setTimer(newTimer)
      }
return(
<TextareaAutosize
    required
    key={props.row.id}
    minRows={2}
    placeholder="Type reason here..."
    className={'sku-text-area-input rounded px-1'}
    defaultValue={props.value !== null ? props.value : ""}
    onChange={handleSKUInputChange}    
    disabled={handleDisable()}
/>
)}

export const InputTextArea = React.memo(InputTextAreaSKU)